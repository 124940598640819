import { type CSSProperties } from "react";

type TCodejetRocket = {
  className?: string;
  style?: CSSProperties;
};

export const CodejetRocket = ({ className, style }: TCodejetRocket) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
      fill="white"
    />
    <path d="M11.7497 17.5V14.0004H8.27994V17.5H11.7497Z" fill="#D9D9D9" />
    <path
      d="M15.2496 8.75116V14.0003L11.7497 10.4997L11.7515 7.00187H8.24986V10.5004L4.75 14.0003V8.75116L10.0029 3.5L15.2496 8.75116Z"
      fill="#FF4900"
    />
  </svg>
);
