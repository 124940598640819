import { Subscriber } from "../../../../services/abstract/Subscriber/Subscriber";

type IFontModalService = {
  isOpen: boolean;
  properties: Record<string, string | number>;
  rootStyles: Record<string, string>;
  name: string;
  displayName: string;
  onChange: null | ((property: string, value: string) => void);
};

export class FontModalService extends Subscriber<IFontModalService> {
  state: IFontModalService = {
    isOpen: false,
    properties: {},
    rootStyles: {},
    name: "",
    displayName: "",
    onChange: null,
  };

  openModal = () => {
    this.state.isOpen = true;
    this.notifySubscribers();
  };

  closeModal = () => {
    this.state.isOpen = false;
    this.state.onChange = null;
    this.state.properties = {};
    this.state.rootStyles = {};
    this.state.name = "";
    this.state.displayName = "";

    this.notifySubscribers();
  };

  setStyles = (localVariables: IFontModalService["properties"], rootVariables: IFontModalService["rootStyles"]) => {
    this.state.properties = localVariables;
    this.state.rootStyles = rootVariables;
    this.setDisplayName();

    this.notifySubscribers();
  };

  setupData = (
    onChange: IFontModalService["onChange"],
    properties: IFontModalService["properties"],
    rootVariables: IFontModalService["rootStyles"],
    name: IFontModalService["name"],
    displayName: IFontModalService["displayName"]
  ) => {
    this.state.properties = properties;
    this.state.rootStyles = rootVariables;
    this.state.name = name;
    this.state.displayName = displayName;
    this.state.onChange = onChange;
    this.state.isOpen = true;
    this.notifySubscribers();
  };

  setDisplayName = () => {
    const { name, rootStyles, properties } = this.state;
    const formattedCategory = name.replace(/-/g, " ").trim();
    const fontSize = rootStyles[properties["font-size"]]
      ? rootStyles[properties["font-size"]]
      : properties["font-size"];
    const lineHeight = rootStyles[properties["line-height"]]
      ? rootStyles[properties["line-height"]]
      : properties["line-height"];
    this.state.displayName = `${formattedCategory} - ${fontSize}/${lineHeight}`;
  };
}

export const fontModalServiceInstance = new FontModalService();
