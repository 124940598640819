import { type CSSProperties } from "react";

type TAuto = {
  className?: string;
  style?: CSSProperties;
};

export const Auto = ({ className, style }: TAuto) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M12.375 8H4.375" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
