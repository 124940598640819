import { type CSSProperties } from "react";

type TAlignContentStretch = {
  className?: string;
  style?: CSSProperties;
};

export const AlignContentStretch = ({ className, style }: TAlignContentStretch) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M0.5 13V1M9.5 13V1M0.5 5.5L0.5 8.5C0.5 8.91421 0.83579 9.25 1.25 9.25L8.75 9.25C9.16423 9.25 9.5 8.91421 9.5 8.5V5.5C9.5 5.08577 9.16423 4.75 8.75 4.75L1.25 4.75C0.83579 4.75 0.5 5.08577 0.5 5.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
