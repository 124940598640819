import axios from "axios";
import dayjs from "dayjs";

import { userAxios } from "./UserAxiosInstance";
import { formatKeys } from "../../utils/formatKeys/formatKeys";
import { periodType } from "../../utils/periodType/periodType";

import type { TState, TUserServiceStorage } from "./UserService.types";
import type { CreateUserResponse, UserFieldsCamel } from "./UserAxiosInstance";
import { Subscriber } from "../abstract/Subscriber/Subscriber.ts";

const initialState = {
  ID: 0,
  USER_ID: "",
  FIGMA_ID: "",
  FIGMA_EMAIL: "",
  EMAIL: "",
  NAME: "",
  IMG_URL: "",
  CUSTOMER_ID: "",
  SUBSCRIPTION_STATUS: "",
  CURRENT_PERIOD_START: 0,
  CURRENT_PERIOD_END: 0,
  CREDITS: 0,
};

export class UserService extends Subscriber<TState> {
  private static instance: UserService;
  private STORAGE_NAME = "token";
  private currentTimestamp = dayjs().unix();

  data: TState["data"] = initialState;
  periodType: TState["periodType"] = "";

  state: TState = {
    data: this.data,
    periodType: this.periodType,
  };

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  public async init() {
    const user = await this.getUser();
    if (user) {
      this.setData(user.fields);
      this.notifySubscribers();
    }
  }

  public saveStorage(token: string) {
    const storageData = {
      token: token,
      time: dayjs(new Date()).unix(),
    };
    localStorage.setItem(this.STORAGE_NAME, JSON.stringify(storageData));
  }

  public getStorage(): TUserServiceStorage | null {
    const storage = localStorage.getItem(this.STORAGE_NAME);
    return storage !== null ? JSON.parse(storage) : null;
  }

  public deleteStorage() {
    localStorage.removeItem(this.STORAGE_NAME);
  }

  async isTokenValid(): Promise<boolean> {
    let isValid: boolean;
    try {
      const { data } = await userAxios.get("/api/auth/verify", { withCredentials: true });
      data.ok ? (isValid = true) : (isValid = false);
    } catch {
      isValid = false;
    }
    return isValid;
  }

  async createUser(isFigmaFlow: boolean): Promise<UserFieldsCamel | undefined> {
    const signupOrigin = isFigmaFlow ? "figma_plugin" : "app";
    try {
      const { data } = await userAxios.post(`/api/user?signupOrigin=${signupOrigin}`);
      this.setData(data.fields);
      this.setPeriodType(periodType());
      return formatKeys(data);
    } catch {
      console.error({ error: "Can not create user" });
    }
  }

  async getUser(): Promise<CreateUserResponse | undefined> {
    try {
      const { data } = await userAxios.get<CreateUserResponse>("/api/user");
      return data;
    } catch {
      console.error({ error: "Can not get users" });
    }
  }

  async setFigmaInfo({ figmaId, figmaEmail }: { figmaId: string; figmaEmail: string }): Promise<void> {
    this.state.figmaInfo = { figmaId, figmaEmail };
    this.notifySubscribers();
  }

  async clearFigmaAssingingInfo(): Promise<void> {
    if (!this.data) return;

    const { figmaId, figmaEmail } = { figmaId: this.data.FIGMA_ID, figmaEmail: this.data.FIGMA_EMAIL };

    await userAxios.patch(`/api/user/clear-figma-info`, {
      figmaId,
      figmaEmail,
    });

    this.data.FIGMA_ID = "";
    this.data.FIGMA_EMAIL = "";
    this.notifySubscribers();
  }

  async clearFigmaInfo(): Promise<void> {
    this.state.figmaInfo = undefined;
    this.notifySubscribers();
  }

  async getFigmaAssigningInfo(): Promise<number | undefined> {
    if (!this.state.figmaInfo) return;

    const { figmaId, figmaEmail } = this.state.figmaInfo;

    try {
      const data = await userAxios.patch(`/api/user/assign-figma-info`, {
        figmaId,
        figmaEmail,
      });

      if (this.data) {
        this.data.FIGMA_ID = figmaId;
        this.data.FIGMA_EMAIL = figmaEmail;
      }
      this.notifySubscribers();
      return data.status;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const response = err && err.response;
        const status = response && response.status;
        return status;
      }
    }
  }

  async check(): Promise<void> {
    return await userAxios.get("/api/auth/verify");
  }

  async userExists() {
    const user = await this.getUser();
    if (!user) return false;
    return user ?? false;
  }

  reduceCredits() {
    if (this.data?.CREDITS && this.data?.CREDITS > 0 && !this.isActiveSubscriptionFlag()) {
      this.data.CREDITS = this.data.CREDITS - 1;
    }
  }

  async logout() {
    try {
      userAxios.post("/api/auth/logout");
      this.data = initialState;
      this.notifySubscribers();
      return;
    } catch (e) {
      console.error(e);
    }
  }

  setData(data: TState["data"]) {
    this.data = data;
    this.notifySubscribers();
  }

  getData() {
    return this.data;
  }

  setPeriodType(period: TState["periodType"]) {
    this.periodType = period;
    this.notifySubscribers();
  }

  getPeriodType() {
    return this.periodType;
  }

  generateToastText(status = "") {
    if (status === "past_due")
      return "Payment is past due. Please update your payment information to avoid service interruption.";
    if (status === "unpaid") return "Payment failed. Please update your payment information to proceed.";
    if (status === "canceled") return "Payment canceled. Your subscription has been terminated.";
    if (status === "incomplete") return "Payment incomplete. Please try again or contact support for assistance.";
    if (status === "incomplete_expired") return "Payment incomplete. The payment window has expired. Please try again.";
    if (status === "paused") return "Payment paused. Your subscription is currently on hold.";
  }

  isActiveSubscriptionFlag = () => this.data?.SUBSCRIPTION_STATUS === "active";
  isActiveFlagEmpty = () => this.data?.SUBSCRIPTION_STATUS === "";
  isActiveSubscription = this.currentTimestamp < (this.data?.CURRENT_PERIOD_END ?? 0);
  isActiveLifeTime = () => this.data?.CURRENT_PERIOD_END === 1;
  isCanceledSubscription = () => this.data?.SUBSCRIPTION_STATUS === "canceled";
  isUndefinedSubscription = () => this.data?.SUBSCRIPTION_STATUS === undefined;

  canPurchaseSubscription = () => {
    const currentTimestamp = dayjs().unix();
    const currentPeriodEnd = this.data?.CURRENT_PERIOD_END || 0;
    const isActiveSubscription = currentTimestamp < currentPeriodEnd;
    const isCancelledSubscription = this.isCanceledSubscription();
    const isUndefinedSubscription = this.isUndefinedSubscription();
    const isActiveLifeTime = this.isActiveLifeTime();

    return (isCancelledSubscription || isUndefinedSubscription || !isActiveLifeTime) && !isActiveSubscription;
  };

  //TODO TO REMOVE
  addLandingiUser() {
    userAxios.get("/api/user/landingi");
  }
}
