import { type CSSProperties } from "react";

type TEyeSolid = {
  className?: string;
  style?: CSSProperties;
};

export const EyeSolid = ({ className, style }: TEyeSolid) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2.5 11.333C5.5 4.66634 14.5 4.66634 17.5 11.333"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.667C8.61925 14.667 7.5 13.5477 7.5 12.167C7.5 10.7862 8.61925 9.66699 10 9.66699C11.3807 9.66699 12.5 10.7862 12.5 12.167C12.5 13.5477 11.3807 14.667 10 14.667Z"
      fill="#727272"
      stroke="#727272"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
