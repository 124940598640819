import { ButtonNew } from "../ButtonNew";

import { Check } from "../../../../assets/svg/check";

import type { TMarketingPlanCard } from "./MarketingPlanCard.types";

import "./MarketingPlanCard.css";

export const MarketingPlanCard: TMarketingPlanCard = ({
  itemsDefinitions,
  header,
  subheader,
  price,
  priceDescription,
  selected,
  isFirst,
  isLast,
  onClick,
  highlitedText,
}) => {
  const containerClassName =
    "marketing-plan-card__container" +
    (isLast ? " without-border" : "") +
    (selected ? " selected" : "") +
    (isFirst ? " first" : "") +
    (isLast ? " last" : "");

  return (
    <div className={containerClassName}>
      <div>
        <div className="marketing-plan-card__header">
          <h2 className="marketing-plan-card__typography marketing-plan-card__heading">{header}</h2>
          <h3 className="marketing-plan-card__typography marketing-plan-card__subheading">{subheader}</h3>
        </div>
        <div className="marketing-plan-card__highlited-text marketing-plan-card__typography">{highlitedText}</div>
        <div className="marketing-plan-card__price">
          <span className="marketing-plan-card__typography marketing-plan-card__price-value">${price}</span>
          <span className="marketing-plan-card__typography marketing-plan-card__price-desc"> {priceDescription}</span>
        </div>
        <ul className="marketing-plan-card__list">
          {itemsDefinitions &&
            itemsDefinitions.map(({ textRegular }, index) => (
              <li className="marketing-plan-card__list-item" key={index}>
                <div className="marketing-plan-card__list-item__content">
                  <div className="marketing-plan-card__list-item__icon">
                    <Check />
                  </div>
                  <span className="marketing-plan-card__typography marketing-plan-card__list-item__text">
                    {textRegular}
                  </span>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <ButtonNew className="marketing-plan-card__button" orange={selected ? true : false} onClick={onClick}>
        {selected ? "Selected" : "Select"}
      </ButtonNew>
    </div>
  );
};
