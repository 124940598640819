import { Accordion } from "../../atoms/Accordion/Accordion";
import { ColorInput } from "../../atoms/ColorInput/ColorInput";

import { TNestedColorCategory } from "./NestedColorCategory.types";

export const NestedColorCategory: TNestedColorCategory = ({ categoryName, colors, categories, handleChange }) => {
  if (Object.keys(categories).length !== 0) {
    return (
      <Accordion label={categoryName} position="left">
        <ul className="color-mode__list">
          {Object.keys(categories).map((subcategory) => {
            return (
              <NestedColorCategory
                key={subcategory}
                categoryName={subcategory}
                {...categories[subcategory]}
                handleChange={handleChange}
              />
            );
          })}
        </ul>
      </Accordion>
    );
  }
  return (
    <Accordion label={categoryName} position="left">
      <ul className="color-mode__list color-mode__list--with-border">
        {colors.map(({ colorName, colorValue, colorLabel }) => {
          return (
            <li className="color-mode__variable" key={colorName}>
              <label className="color-mode__label">{colorLabel}</label>
              <ColorInput name={colorName} value={colorValue} onChange={handleChange} />
            </li>
          );
        })}
      </ul>
    </Accordion>
  );
};
