import { type CSSProperties } from "react";

type TDashboard = {
  className?: string;
  style?: CSSProperties;
};

export const Dashboard = ({ className, style }: TDashboard) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M12.5 6H8.5C8.22386 6 8 6.22386 8 6.5V12.5C8 12.7761 8.22386 13 8.5 13H12.5C12.7761 13 13 12.7761 13 12.5V6.5C13 6.22386 12.7761 6 12.5 6Z"
      stroke="#FF4900"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 1H8.5C8.22386 1 8 1.22386 8 1.5V3.51C8 3.78614 8.22386 4.01 8.5 4.01H12.5C12.7761 4.01 13 3.78614 13 3.51V1.5C13 1.22386 12.7761 1 12.5 1Z"
      stroke="#FF4900"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 1H1.5C1.22386 1 1 1.22386 1 1.5V7.5C1 7.77614 1.22386 8 1.5 8H5.5C5.77614 8 6 7.77614 6 7.5V1.5C6 1.22386 5.77614 1 5.5 1Z"
      stroke="#FF4900"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 9.98999H1.5C1.22386 9.98999 1 10.2138 1 10.49V12.5C1 12.7761 1.22386 13 1.5 13H5.5C5.77614 13 6 12.7761 6 12.5V10.49C6 10.2138 5.77614 9.98999 5.5 9.98999Z"
      stroke="#FF4900"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
