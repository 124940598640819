import { useState, useEffect } from "react";

import "./FontInput.css";

export const FontInput = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== localValue) {
        onChange(name, localValue);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [localValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const type = Number(value) ? "number" : "text";
  return (
    <>
      <input type={type} value={localValue} className="font-variables__input" onChange={(e) => handleChange(e)} />
    </>
  );
};
