import { ISendStateToComponent, ISubscriber } from "./Subscriber.types.ts";

export class Subscriber<T> implements ISendStateToComponent<T> {
  subscribers: ISendStateToComponent<T>["subscribers"] = [];
  state: T = {} as T;
  subscribe(callback: ISubscriber<T>): void {
    //Added to avoid fucked up react useEffect problem
    this.subscribers.push(callback);
  }

  //Added to avoid fucked up react useEffect problem
  //TODO maybe we should make small refactor with subscribe in useEffect
  subscribeInUseEffect(callback: ISubscriber<T>): void {
    if (this.subscribers.includes(callback)) return;
    this.subscribers.push(callback);
  }

  unsubscribe(callback: ISubscriber<T>): void {
    this.subscribers = this.subscribers.filter((el) => el !== callback);
  }

  notifySubscribers(): void {
    this.subscribers.forEach(this.sendStateToSubscriber);
  }

  sendStateToSubscriber = (sb: ISubscriber<T>) => sb({ ...this.state });
}
