import { LoaderLight } from "../../../../assets/svg/loader-light";
import { LoaderDark } from "../../../../assets/svg/loader-dark";

import type { TLoaderBackdrop } from "./LoaderBackdrop.types";

import "./LoaderBackdrop.css";

export const LoaderBackdrop: TLoaderBackdrop = () => {
  return (
    <div className="loader-backdrop">
      <LoaderLight className="loader-backdrop--light" />
      <LoaderDark className="loader-backdrop--dark" />
    </div>
  );
};
