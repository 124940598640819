import { Select } from "../../../atoms/Select";
import { Accordion } from "../../atoms/Accordion/Accordion";
import { FontInput } from "../../atoms/FontInput/FontInput";

import { safeFonts } from "../FontMode/constants";

import { TNestedFontCategory } from "./NestedFontCategory.types";

export const NestedFontCategory: TNestedFontCategory = ({
  mainCategoryName,
  categoryName,
  fonts,
  categories,
  handleChange,
}) => {
  if (Object.keys(categories).length !== 0) {
    return (
      <Accordion label={categoryName} position="left">
        <ul className="font-mode__list">
          {Object.keys(categories).map((subcategory) => {
            const { fonts, categories: nestedCategory } = categories[subcategory];
            return (
              <NestedFontCategory
                key={subcategory}
                mainCategoryName={mainCategoryName}
                categoryName={subcategory}
                fonts={fonts}
                categories={nestedCategory}
                handleChange={handleChange}
              />
            );
          })}
        </ul>
      </Accordion>
    );
  }
  return (
    <Accordion label={categoryName} position="left">
      <ul className="font-mode__list font-mode__list--with-border">
        {fonts?.map(({ fontName, fontValue, fontLabel }) => {
          return (
            <li className="font-mode__variable" key={fontName}>
              <label className="font-mode__label">{fontLabel}</label>
              {mainCategoryName === "font family" ? (
                <Select
                  key={fontName}
                  options={safeFonts}
                  defaultValue={fontValue}
                  onChange={(value) => handleChange(fontName, value)}
                />
              ) : (
                <FontInput name={fontName} value={fontValue} onChange={handleChange} />
              )}
            </li>
          );
        })}
      </ul>
    </Accordion>
  );
};
