import { useState } from "react";

import { styleManagerService } from "../../StylesManager.service";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick/useOutsideClick";
import { usePositionInScreen } from "../../../../../../../hooks/usePositionInScreen/usePositionInScreen";

import { type SelectOption } from "grapesjs";
import { type TOptionsDropdown } from "./OptionsDropdown.types";

import "./OptionsDropdown.css";

export const OptionsDropdown: TOptionsDropdown = () => {
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const { currentUnit, units, coordinates, onChange, closeUnitDropdown } = styleManagerService;
  const modalRef = useOutsideClick<HTMLDivElement>(() => {
    closeUnitDropdown();
  });

  const parentX = coordinates?.x ?? 0;
  const parentY = coordinates?.y ?? 0;
  const parentHeight = coordinates?.height ?? 0;

  const { coordinates: calculatedCoordinates } = usePositionInScreen(
    modalRef,
    { x: parentX, y: parentY + parentHeight },
    setIsPositionCalculated,
    4,
    parentHeight
  );

  const positionStyles = () => {
    if (!coordinates) return {};

    return {
      top: calculatedCoordinates.top,
      left: calculatedCoordinates.left,
      width: `${coordinates.width}px`,
    };
  };

  const handleChanges = (ev: any) => {
    if (!onChange) {
      console.error("onChange is not defined");
      return;
    }
    const value = ev.target.value === "remove" ? "" : ev.target.value;
    onChange(value);
    closeUnitDropdown();
  };

  // TODO focus checked input, maybe change logic from on change so user can use keyboard??
  //   useEffect(() => {
  //     const checkedInput: HTMLInputElement | undefined | null =
  //       modalRef?.current?.querySelector('input[type="radio"]:checked');

  //     // If a checked input is found, focus on it
  //     if (checkedInput) {
  //       checkedInput.focus();
  //     }
  //   }, [modalRef.current]);

  const getValue = (value: string | SelectOption) => {
    if (typeof value === "string") {
      return value;
    }
    return value.value || value.id;
  };

  const getLabel = (value: string | SelectOption) => {
    if (typeof value === "string") {
      return value;
    }
    return value.label || value.id;
  };

  const getChecked = (value: string | SelectOption, currentUnit: string) => {
    if (typeof value === "string") {
      return currentUnit === value;
    }
    return currentUnit === value.value || (value.value === "remove" && currentUnit === "");
  };

  return (
    <div
      className="radio-group custom-scrollbar"
      style={{ ...positionStyles(), opacity: isPositionCalculated ? 1 : 0 }}
      ref={modalRef}
    >
      <ul className="radio-group__list">
        {units.map((value: any) => {
          return (
            <li key={getValue(value)} className="radio-group__item">
              <input
                type="radio"
                id={getValue(value)}
                name="radio-group"
                min="0"
                value={getValue(value)}
                onChange={handleChanges}
                className="vs-hidden"
                checked={getChecked(value, currentUnit)}
              />
              <label htmlFor={getValue(value)}>{getLabel(value)}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
