import { type CSSProperties } from "react";

type TAlignStretch = {
  className?: string;
  style?: CSSProperties;
};

export const AlignStretch = ({ className, style }: TAlignStretch) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.375 12.5H2.375M14.375 3.5H2.375M6.875 12.5L9.875 12.5C10.2892 12.5 10.625 12.1642 10.625 11.75L10.625 4.25C10.625 3.83577 10.2892 3.5 9.875 3.5H6.875C6.46077 3.5 6.125 3.83577 6.125 4.25L6.125 11.75C6.125 12.1642 6.46077 12.5 6.875 12.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
