import React, { type SVGProps } from "react";
export const ChevronDownLight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Icon/chevron-down">
      <path
        id="Vector"
        d="M5 7.5L10 12.5L15 7.5"
        stroke="#71717A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
