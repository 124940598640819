import { type CSSProperties } from "react";

type TQuestionMark = {
  className?: string;
  style?: CSSProperties;
};

export const QuestionMark = ({ className, style }: TQuestionMark) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.20898 6.77752C4.20898 4.68358 5.90648 2.98608 8.00042 2.98608C10.0944 2.98608 11.7919 4.68358 11.7919 6.77752C11.7919 8.87148 10.0944 10.569 8.00042 10.569V12.5999"
      stroke="#64997A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 17.1358C7.72386 17.1358 7.5 16.9118 7.5 16.6358C7.5 16.3596 7.72386 16.1358 8 16.1358"
      stroke="#64997A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 17.1358C8.27614 17.1358 8.5 16.9118 8.5 16.6358C8.5 16.3596 8.27614 16.1358 8 16.1358"
      stroke="#64997A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
