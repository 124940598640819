import { useEffect, useState } from "react";

import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";
import { routerInstance } from "../../../machines/router/router.instance";
import { UserService } from "../../../services/UserService/UserService";

import { Dropdown } from "../../Dashboard/atoms/Dropdown";
import { Logotype } from "../../../../assets/svg/logotype";

import type { TUnauthorizedNavbar } from "./UnauthorizedNavbar.types";

import "./UnauthorizedNavbar.css";

const userService = UserService.getInstance();

export const UnauthorizedNavbar: TUnauthorizedNavbar = () => {
  const [_, subscribeUserService] = useState(userService.state);
  const [isLoginVisible, setIsLoginVisible] = useState(false);

  useEffect(() => {
    routerInstance.onTransition((state) => {
      if (state.value === "PICK_TEMPLATE") {
        setIsLoginVisible(true);
        return;
      }

      if (isLoginVisible) {
        setIsLoginVisible(false);
      }
    });

    userService.subscribe(subscribeUserService);
    return () => userService.unsubscribe(subscribeUserService);
  }, []);

  const hasUserData = userService.data?.USER_ID !== undefined && userService.data.USER_ID !== "";
  const showLoginButton = isLoginVisible && !hasUserData;

  return (
    <div className="unauthorized-navbar">
      <div className="unauthorized-navbar__container">
        <div className="unauthorized-navbar__logo" onClick={() => dashboardInstance.send("PROJECTS")}>
          <Logotype />
        </div>
        {showLoginButton ? (
          <div className="unauthorized-navbar__login">
            Already have an account?
            <button
              onClick={() => {
                routerInstance.send("LOGIN");
              }}
            >
              Login
            </button>
          </div>
        ) : (
          <Dropdown fallbackAvatar={true} />
        )}
      </div>
    </div>
  );
};
