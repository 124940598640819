import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const periodType = (start = 0, end = 0) => {
  // convert to milliseconds and create dayjs objects
  const dateStart = dayjs.unix(start);
  const dateEnd = dayjs.unix(end);

  if (end === 1) {
    return "lifetime";
  }

  // Check if the period is one year
  if (
    dateStart.add(1, "year").isSameOrAfter(dateEnd) &&
    dateStart.add(1, "year").subtract(1, "day").isSameOrBefore(dateEnd)
  ) {
    return "annual";
  }
  // Check if the period is one month
  else if (
    dateStart.add(1, "month").isSameOrAfter(dateEnd) &&
    dateStart.add(1, "month").subtract(1, "day").isSameOrBefore(dateEnd)
  ) {
    return "month";
  } else {
    return "Invalid period";
  }
};
