import { type CSSProperties } from "react";

type TBorderTopWidth = {
  className?: string;
  style?: CSSProperties;
};

export const BorderTopWidth = ({ className, style }: TBorderTopWidth) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14 12V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H12M14 6.5L14 9.5M9.5 14L6.5 14M14 2L2 2M2 12V13C2 13.2652 2.1054 13.5196 2.2929 13.7071C2.4804 13.8946 2.7348 14 3 14H4M2 6.5L2 9.5"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
