import { surveyAxios } from "./SurveyAxiosInstance";
import type { Answer } from "../../components/pages/CreateAccount/CreateAccount.service";

export interface SurveyQuestionsFields {
  ID: number;
  RECORD_ID: string;
  QUESTION: string;
  ANSWER_TYPE: string;
  ANSWERS: string[];
}

export interface SurveyQuestions {
  otherOptionValue: string;
  id: number;
  recordId: string;
  question: string;
  answerType: string;
  answers: string[];
  toSubmit?: string[];
  form: "NEW_USER" | "FEEDBACK";
}

export interface ISurvey {
  getSurvey: () => Promise<SurveyQuestions[]>;
  sendAnswers: (answers: Answer[]) => Promise<SurveyQuestions[]>;
}

export class Survey implements ISurvey {
  async getSurvey() {
    //refactor this function to use templatesAxios instance
    const { data } = await surveyAxios.get<SurveyQuestions[]>("/api/survey", {});

    return data;
  }

  //TODO write tests
  async sendAnswers(answers: Answer[]) {
    //refactor this function to use templatesAxios instance
    const { data } = await surveyAxios.post<SurveyQuestions[]>("/api/survey", {
      answers,
    });

    return data;
  }
}
