import { Lock } from "../../../../assets/svg/lock_icon";
import { CheckBadge } from "../../../../assets/svg/check_badge";
import { LikeMessage } from "../../../../assets/svg/like_message";

import type { TFooter } from "./Footer.types";

import "./Footer.css";

export const Footer: TFooter = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-content__container">
          <div className="footer-content__logo">
            <CheckBadge />
          </div>
          <h3 className="footer-content__heading">Guarantee</h3>
          <div className="footer-content__text">
            <p>Codejet comes with a 14-day money- back guarantee.</p>
          </div>
        </div>
        <div className="footer-content__container">
          <div className="footer-content__logo">
            <Lock />
          </div>
          <h3 className="footer-content__heading">Support</h3>
          <div className="footer-content__text">
            <p>Our team works 24/7 and can answer any question you have about Codejet.</p>
          </div>
        </div>
        <div className="footer-content__container">
          <div className="footer-content__logo">
            <LikeMessage />
          </div>
          <h3 className="footer-content__heading">Privacy</h3>
          <div className="footer-content__text">
            <p>We take your privacy seriously. Learn more about our privacy policy.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
