import { useEffect, useState } from "react";
import { unauthorizedTemplatesInstance } from "../../machines/unauthorized-templates/unauthorized-templates.instance";
import { Project } from "../../types/types";

export const useTemplates = (setIsSkeleton: (arg: boolean) => void): Project[] => {
  const [templates, setProjects] = useState<Project[]>([]);
  const templatesService = unauthorizedTemplatesInstance.getSnapshot().context.service;

  useEffect(() => {
    templatesService.subscribe((state) => setProjects(state));
    const templatesState = templatesService.state;
    if (templatesState.length === 0) {
      unauthorizedTemplatesInstance.send("FETCH_TEMPLATES");
      unauthorizedTemplatesInstance.onTransition((state) => {
        if (state.value === "AWAITING_TEMPLATE_SELECTION") {
          setIsSkeleton(false);
        }
      });
    } else {
      setIsSkeleton(false);
      setProjects(templatesState);
    }
    return () => {
      templatesService.unsubscribe((state) => setProjects(state));
    };
  }, []);

  return templates;
};
