import { type CSSProperties } from "react";

type TChevronDown = {
  className?: string;
  style?: CSSProperties;
};

export const ChevronDown = ({ className, style }: TChevronDown) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M6 9L12 15L18 9" stroke="#71717A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
