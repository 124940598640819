import { Footer } from "../Footer";

import { routerInstance } from "../../../machines/router/router.instance";
import { UnauthorizedNavbar } from "../UnauthorizedNavbar";

import { Breadcrumb } from "../../atoms/Breadcrumb";
import { PageHeader } from "../../atoms/PageHeader";
import { ButtonNew } from "../../atoms/ButtonNew";
import { BackButton } from "../../atoms/BackButton";
import { Seo } from "../../atoms/Seo";

import type { TOnboardingLayout } from "./OnboardingLayout.types";

import "./OnboardingLayout.css";

const routeStates = ["PICK_TEMPLATE", "SELECT_PLAN_SITE", "SELECT_MARKETING_PLAN", "WRAP_UP_MIDDLEWARE"];

export const OnboardingLayout: TOnboardingLayout = ({
  children,
  seoTitle,
  breadcrumbStage,
  header,
  subheader,
  selectedCardIndex,
  onContinue,
  onBack,
  withSkipButton,
  price,
  pageHeaderAvatar,
  isTotalSectionVisible,
  isHeaderVisible,
  isBackButtonVisible,
  isSmall,
}) => {
  return (
    <>
      <Seo title={seoTitle} />
      <UnauthorizedNavbar />
      <div className="onboarding-layout__main">
        <Breadcrumb stage={breadcrumbStage} handleOnClick={(index) => routerInstance.send(routeStates[index])} />
        <div className="onboarding-layout__wrapper">
          <div className={`onboarding-layout__container${isSmall ? " onboarding-layout__container-small" : ""}`}>
            {(isBackButtonVisible === undefined || isBackButtonVisible) && <BackButton onClick={onBack} />}
            {(isHeaderVisible === undefined || isHeaderVisible) && (
              <PageHeader header={header} subheader={subheader} avatar={pageHeaderAvatar} />
            )}
            {children}
          </div>
        </div>
      </div>
      {(isTotalSectionVisible === undefined || isTotalSectionVisible) && (
        <div className="onboarding-layout__total-sticky">
          <div className="onboarding-layout__total-container">
            <div className="onboarding-layout__total-skip">
              <ButtonNew
                className={"onboarding-layout__total-skip__button" + (withSkipButton ? " active" : "")}
                onClick={onContinue}
              >
                Skip
              </ButtonNew>
            </div>
            <div className="onboarding-layout__total-content">
              <span>Total:</span>
              <strong>{price ? `\$${price}` : "$0"}</strong>
              <ButtonNew
                className="onboarding-layout__continue-button"
                disabled={selectedCardIndex === null}
                onClick={onContinue}
              >
                Continue
              </ButtonNew>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
