import { type CSSProperties } from "react";

type TDirectionRow = {
  className?: string;
  style?: CSSProperties;
};

export const DirectionRow = ({ className, style }: TDirectionRow) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M1 4.9923H9.30769M13 4.9923L9.30769 8.6846V1.29999L13 4.9923Z"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
