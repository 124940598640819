import { type CSSProperties } from "react";

type TArrowUpRight = {
  className?: string;
  style?: CSSProperties;
};

export const ArrowUpRight = ({ className, style }: TArrowUpRight) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M5.83594 14.6673L14.1693 6.33398"
      stroke="#71717A"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83594 6.33398H14.1693V14.6673"
      stroke="#71717A"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
