export const iframe = (src: string) => ({
  tagName: "iframe",
  classes: ["iframe-no-pointer"],
  attributes: {
    frameborder: 0,
    allowfullscreen: true,
    title: "Video player",
    style: "width: 100%; height: 100%;",
    src,
  },
  resizable: true,
  draggable: false,
  droppable: false,
  selectable: false,
  editable: true,
});
