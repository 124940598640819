import type { SVGProps } from "react";

export const Divider = (props: SVGProps<SVGSVGElement>) => (
  <svg width="113" height="2" viewBox="0 0 113 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line x1="112.5" y1="1" y2="1" stroke="url(#paint0_linear_20270_2810)" />
    <defs>
      <linearGradient id="paint0_linear_20270_2810" x1="0" y1="0" x2="112.5" y2="0" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF4900" stopOpacity="0" />
        <stop offset="0.5" stopColor="#FF4900" />
        <stop offset="1" stopColor="#FF4900" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
