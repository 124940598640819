import { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "../../../../../../AlphaO/atoms/Button";
import { PropertyLabel } from "../../../styles-manager/components";
import { Add } from "../../../../../../../assets/svg/Add";
import { LinkProperty } from "./partials/LinkProperty";
import { ListItem } from "../partials/ListItem";

import type { Trait, Component } from "grapesjs";

export const NavBarTrait = ({ trait }: { trait: Trait }) => {
  const optionsRef = useRef<HTMLUListElement>(null);
  const [links, setLinks] = useState<Component[]>([]);
  const [selectedLink, setSelectedLink] = useState<Component | null>(null);
  const smartNavBar = trait.target;
  const domRect = optionsRef.current?.getBoundingClientRect();
  const listElementsCount = smartNavBar.find(".smart-navbar__menu-item").length;

  useEffect(() => {
    const components = smartNavBar.find(".smart-navbar__menu-link");
    setLinks(components);

    return () => {
      setLinks([]);
    };
  }, [listElementsCount]);

  const removeLink = (model: Component) => {
    // remove li element from menu list
    model.parent()?.remove();

    const newLinks = links.filter((link) => link.cid !== model.cid);

    setLinks(newLinks);
  };

  const addOption = () => {
    const linksList = smartNavBar.find(".smart-navbar__menu-list")[0];

    if (!linksList) return;

    const newComponent = linksList.components().add([
      {
        tagName: "li",
        layername: "Menu part",
        classes: ["smart-navbar__menu-item"],
        editable: false,
        removable: false,
        droppable: false,
        copyable: false,
        components: [
          {
            type: "link",
            content: "New link",
            classes: ["smart-navbar__menu-link"],
            contentEditable: false,
            draggable: false,
            removable: false,
            editable: false,
            droppable: false,
            copyable: false,
          },
        ],
      },
    ]);

    const newLink = newComponent[0].find(".smart-navbar__menu-link");
    if (newComponent[0]) {
      setLinks([...links, ...newLink]);
    }
  };

  const selectLink = (model: Component) => {
    if (selectedLink === null) {
      setSelectedLink(model);
      return;
    }

    if (selectedLink.cid !== model.cid) {
      setSelectedLink(model);
    }
  };

  const refetchData = () => {
    const components = smartNavBar.find(".smart-navbar__menu-link");
    setLinks(components);
  };

  const handleClosePopup = () => {
    setSelectedLink(null);
  };

  const reorderLink = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragLink = links[dragIndex];
      const newLinks = [...links];
      newLinks.splice(dragIndex, 1);
      newLinks.splice(hoverIndex, 0, dragLink);
      setLinks(newLinks);
    },
    [links]
  );

  const syncNavBarLinks = () => {
    const listElements = links.map((link) => link.parent() || {});

    const linksList = smartNavBar.find(".smart-navbar__menu-list")[0];

    if (!linksList || !listElements) return;

    const currentOrder = smartNavBar.find(".smart-navbar__menu-link");
    const shouldUpdate = !currentOrder.every((link, index) => link.cid === links[index].cid);

    // if the order is the same, we don't need to update the components
    if (!shouldUpdate) return;

    linksList.components().reset(listElements);
  };

  return (
    <li className="trait-manager__trait trait-manager__trait--option">
      <h3>
        <PropertyLabel text="Links" />
        <Button type="second" onClick={addOption} iconStart={<Add />} />
      </h3>
      {links.length !== 0 && (
        <ul className="trait-manager__options-list" ref={optionsRef}>
          {links.map((model, index) => {
            return (
              <ListItem
                key={model.cid}
                id={model.cid}
                index={index}
                model={model}
                removeModel={removeLink}
                selectModel={selectLink}
                moveModel={reorderLink}
                syncEditor={syncNavBarLinks}
              />
            );
          })}
        </ul>
      )}
      {selectedLink && (
        <LinkProperty comp={selectedLink} domRect={domRect} closePopup={handleClosePopup} notifyParent={refetchData} />
      )}
    </li>
  );
};
