import { useEffect, useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce/useDebounce";

import { Button } from "../Button";
import { Close } from "../../../../../assets/svg/close";

import { TOptionProperty } from "./OptionProperty.types";

import "./OptionProperty.css";

export const OptionProperty: TOptionProperty = ({ comp, domRect, closePopup, notifyParent }) => {
  const [value, setValue] = useState(comp.get("attributes")?.value || "");
  const [label, setLabel] = useState(comp.content);
  const debouncedValue = useDebounce(value, 500);
  const debouncedLabel = useDebounce(label, 500);

  useEffect(() => {
    setValue(comp.get("attributes")?.value || "");
    setLabel(comp.content);
  }, [comp]);

  useEffect(() => {
    const currentValue = comp.get("attributes")?.value;
    if (currentValue === debouncedValue) return;
    comp.setAttributes({ value: debouncedValue });
    notifyParent && notifyParent();
  }, [debouncedValue]);

  useEffect(() => {
    const currentLabel = comp.get("content");
    if (currentLabel === debouncedLabel) return;
    comp.set("content", debouncedLabel);
    notifyParent && notifyParent();
  }, [debouncedLabel]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === e.target.value) return;

    setValue(e.target.value);
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (label === e.target.value) return;

    setLabel(e.target.value);
  };

  if (!domRect) return null;

  return (
    <div
      className="option-popup"
      style={{
        top: domRect?.top,
        left: domRect?.left - 16,
      }}
    >
      <header className="option-popup__header">
        <h3>Properties</h3>
        <Button type="icon" iconStart={<Close />} onClick={closePopup} />
      </header>
      <div className="option-popup__content">
        <div className="option-popup__field">
          <label htmlFor={`option-value-${comp.cid}`} className="trait-manager__trait-label">
            Value
          </label>
          <input id={`option-value-${comp.cid}`} value={value} onChange={handleChangeValue} />
        </div>
        <div className="option-popup__field">
          <label htmlFor={`option-label-${comp.cid}`} className="trait-manager__trait-label">
            Label
          </label>
          <input id={`option-label-${comp.cid}`} value={label} onChange={handleChangeLabel} />
        </div>
      </div>
    </div>
  );
};
