import React, { type SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="x">
      <path id="Vector" d="M18 6L6 18" stroke="#71717A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        id="Vector_2"
        d="M6 6L18 18"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
