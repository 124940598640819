import { type SVGProps } from "react";
export const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 6.09668L9 17.0967L4 12.0967"
      stroke="#16A34A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
