import { LoadingWrapper } from "../../molecules/LoadingWrapper/LoadingWrapper";
import { LoaderBackdrop } from "../../molecules/LoaderBackdrop/LoaderBackdrop";

import type { TVerifyPage } from "./VerifyPage.types";

export const VerifyPage: TVerifyPage = () => {
  return (
    <LoadingWrapper renderState="AUTHENTICATED">
      <LoaderBackdrop />
    </LoadingWrapper>
  );
};
