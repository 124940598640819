import { useEffect, useRef, useState } from "react";

import { SelectOption } from "../../../../../AlphaO/molecules/SelectOption/SelectOption.tsx";
import { PropertyLabel } from "../../styles-manager/components";
import { OptionProperty } from "../../../../../AlphaO/atoms/OptionProperty/OptionProperty.tsx";
import { Button } from "../../../../../AlphaO/atoms/Button/Button.tsx";
import { Add } from "../../../../../../assets/svg/Add.tsx";

import { type Component, type Trait } from "grapesjs";

export const SelectOptionsTrait = ({ trait }: { trait: Trait }) => {
  const optionsRef = useRef<HTMLUListElement>(null);
  const [options, setOptions] = useState<Component[]>([]);
  const [selectOption, setSelectOption] = useState<Component | null>(null);
  const select = trait.target;
  const hasOptions = options.length > 0;
  const domRect = optionsRef.current?.getBoundingClientRect();

  useEffect(() => {
    const options = select.get("components")?.models || [];

    setOptions(options);
  }, []);

  const addOption = () => {
    const selectComponents = select.get("components");

    if (!selectComponents) return;

    // if props not in array return object and show type error => any[] return type in grpaejs
    const newComponent = selectComponents.add([
      {
        type: "option",
        tagName: "option",
        content: "New option",
        attributes: {
          value: "new-option",
        },
      },
    ]);

    if (newComponent) {
      setOptions([...options, ...newComponent]);
    }
  };

  const removeOption = (model: Component) => {
    setOptions(options.filter((option) => option.cid !== model.cid));
    if (selectOption?.cid === model.cid) {
      setSelectOption(null);
    }
  };

  const handleRemoveOptions = () => {
    options.forEach((option) => {
      option.remove();
    });

    setOptions([]);
    setSelectOption(null);
  };

  const handleSelectOption = (model: Component) => {
    setSelectOption(null);
    setSelectOption(model);
  };

  const refetchData = () => {
    const options = select.get("components")?.models || [];

    setOptions([...options]);
  };

  return (
    <li className="trait-manager__trait trait-manager__trait--option" style={{ position: "relative" }}>
      <h3>
        <PropertyLabel
          text="Options"
          modifier={hasOptions ? "edited" : "primary"}
          clearValue={hasOptions ? handleRemoveOptions : undefined}
        />
        <Button
          type="second"
          onClick={() => {
            addOption();
          }}
          iconStart={<Add />}
        />
      </h3>
      {options.length !== 0 && (
        <ul className="trait-manager__options-list" ref={optionsRef}>
          {options.map((model) => {
            return (
              <SelectOption
                key={model.cid}
                model={model}
                removeOption={removeOption}
                selectOption={handleSelectOption}
              />
            );
          })}
        </ul>
      )}
      {selectOption && (
        <OptionProperty
          comp={selectOption}
          domRect={domRect}
          closePopup={() => setSelectOption(null)}
          notifyParent={refetchData}
        />
      )}
    </li>
  );
};
