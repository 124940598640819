import { useEffect, useRef, useState } from "react";

import { productsService } from "../../../machines/products/products.instance";

import { FormModalService as FormModalService } from "./FormModal.service";

import { Header } from "../../atoms/Header";
import { Form } from "./atoms/Form";
import { ButtonNew } from "../../atoms/ButtonNew";
import { SelectOption } from "./atoms/Select/Select.types";

import CeoIcon from "../../../../assets/png/ceo-avatar.png";

import { TFormModal } from "./FormModal.types";
import { FormRef } from "./atoms/Form/Form.types";

import "./FormModal.css";

const formModalService = FormModalService.getInstance();

const companyCategory = [
  { label: "Services", value: "Services" },
  { label: "Fitness", value: "Fitness" },
  { label: "Creators", value: "Creators" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Education", value: "Education" },
  { label: "Restaurant", value: "Restaurant" },
  { label: "Travel", value: "Travel" },
  { label: "Nonprofits", value: "Nonprofits" },
  { label: "Other", value: "Other" },
];
const websiteGoal = [
  { label: "Offering services", value: "Offering services" },
  { label: "Promoting the business", value: "Promoting the business" },
  { label: "Showcasing personal work", value: "Showcasing personal work" },
  { label: "Acquiring leads", value: "Acquiring leads" },
  { label: "Building a community", value: "Building a community" },
  { label: "Sharing knowledge", value: "Sharing knowledge" },
];

export const FormModal: TFormModal = ({ onContinue, onCancel }) => {
  const [_, setState] = useState(false);
  const formRef = useRef<FormRef>(null);
  const isOpen = formModalService.state;
  const closeModal = () => formModalService.closeModal();
  const [companyCategorySelectedOption, setCompanyCategorySelectedOption] = useState<SelectOption | null>(null);
  const [websiteGoalSelectedOption, setWebsiteGoalSelectedOption] = useState<SelectOption | null>(null);
  const [businessName, setBusinessName] = useState("");
  const [additionalComment, setAdditionalComments] = useState("");

  useEffect(() => {
    formModalService.subscribe(setState);
    return () => formModalService.unsubscribe(setState);
  }, []);

  const handleValidate = () => {
    if (formRef.current) {
      const isValid = formRef.current.validate();

      return isValid;
    }

    return false;
  };

  const handleOnContinue = () => {
    if (!handleValidate()) {
      return;
    }

    const payload = {
      name: businessName,
      category: companyCategorySelectedOption?.value || "",
      goal: websiteGoalSelectedOption?.value || "",
      comments: additionalComment,
      serviceId: "expert-assistance",
      projectId: undefined,
    };

    productsService.setExpertAsistance(payload);

    onContinue();
    closeModal();
  };

  const handleOnCancel = () => {
    if (formRef.current) {
      formRef.current.reset();
    }

    onCancel();
    closeModal();
  };

  return (
    <div className="form-modal__wrapper">
      <div className={"form-modal__backdrop" + (isOpen ? " form-modal__backdrop--visible" : "")}>
        <div className={"form-modal" + (isOpen ? " form-modal--visible" : "")}>
          <Header
            iconSrc={CeoIcon}
            headingText="Let our experts create website just for you in under 24 hours!"
            subheadingText="Let us know what you need, and one of our experts will reach out to you after your order is placed."
          />
          <Form
            setCompanyCategorySelectedOption={setCompanyCategorySelectedOption}
            companyCategorySelectedOption={companyCategorySelectedOption}
            setWebsiteGoalSelectedOption={setWebsiteGoalSelectedOption}
            websiteGoalSelectedOption={websiteGoalSelectedOption}
            setBusinessName={setBusinessName}
            setAdditionalComments={setAdditionalComments}
            companyCategory={companyCategory}
            websiteGoal={websiteGoal}
            ref={formRef}
          />
          <div className="form-modal__button-group">
            <ButtonNew className="form-modal__button" onClick={handleOnCancel}>
              Cancel
            </ButtonNew>
            <ButtonNew className="form-modal__button" onClick={handleOnContinue} orange>
              Continue
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  );
};
