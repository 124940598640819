import { useEffect, useState } from "react";

import { cardData } from "./data/data";

import { useProducts } from "../../../hooks/useProducts/useProducts";

import { productsInstance } from "../../../machines/products/products.instance";
import { routerInstance } from "../../../machines/router/router.instance";

import { OnboardingLayout } from "../../molecules/OnboardingLayout";

import { MarketingPlanCard } from "../../atoms/MarketingPlanCard";
import { List } from "../../atoms/List";

import { Bot } from "../../../../assets/svg/bot";
import { Check } from "../../../../assets/svg/green_check";
import { Cons } from "../../../../assets/svg/cons_red";
import { Free } from "../../../../assets/svg/free";
import MarekImg from "../../../../assets/png/marek.png";

import { TSelectMarketingPlan } from "./SelectMarketingPlan.types";

import "./SelectMarketingPlan.css";

export const SelectMarketingPlan: TSelectMarketingPlan = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const { products, totalPrice } = useProducts();

  useEffect(() => {
    const selectedProduct = products.find((product) => product.productType === "marketingPlan");

    if (selectedProduct) {
      const cardIndex = cardData.findIndex((item) => item.priceId === selectedProduct.priceId);
      setSelectedCardIndex(cardIndex);
    }
  }, [products]);

  const onCardClickHandler = (index: number) => {
    const { priceId, type, productType, metadata, price } = cardData[index];
    const payload = { priceId, type, productType, price, metadata };

    if (index === selectedCardIndex) {
      productsInstance.send({ type: "UNSELECT_PLAN", payload });
      setSelectedCardIndex(null);

      return;
    }

    productsInstance.send({ type: "SELECT_MARKETING_PLAN", payload });
    setSelectedCardIndex(index);
  };

  return (
    <>
      <OnboardingLayout
        onBack={() => routerInstance.send("SELECT_PLAN_SITE")}
        onContinue={() => routerInstance.send("WRAP_UP_MIDDLEWARE")}
        seoTitle="Select marketing plan - Codejet"
        breadcrumbStage={3}
        selectedCardIndex={selectedCardIndex}
        header="Select a marketing plan"
        subheader="Frustrated your website isn’t making money? Our experts can drive you new visitors and leads to your site every month!"
        withSkipButton={true}
        price={Math.round(totalPrice * 100) / 100}
        pageHeaderAvatar={MarekImg}
      >
        <div className="select-marketing-plan__ai-container">
          <div className="select-marketing-plan__ai-container__icon">
            <Bot />
          </div>
          <div className="select-marketing-plan__ai-container__content">
            Boosted ads with AI for more personalised and effective results
          </div>
        </div>
        <div className="select-marketing-plan__plan-container">
          {cardData.map(
            (
              { itemsDefinitions, header, subheader, highlitedText, price, priceDescription, isFirst, isLast },
              index
            ) => {
              return (
                <MarketingPlanCard
                  key={index}
                  icon={<Free />}
                  itemsDefinitions={itemsDefinitions}
                  header={header}
                  subheader={subheader}
                  highlitedText={highlitedText}
                  price={price}
                  priceDescription={priceDescription}
                  isFirst={isFirst}
                  isLast={isLast}
                  selected={selectedCardIndex === index}
                  onClick={() => onCardClickHandler(index)}
                />
              );
            }
          )}
        </div>
        <div className="select-marketing-plan__list">
          <List
            heading="When you work with us:"
            itemsDefinitions={[
              {
                text: "Our experts create a custom ad plan that brings the right customers to your shop, guaranteeing more business for you.",
                icon: <Check />,
              },
              {
                text: "We take care of everything, so you don’t have to worry about a thing and can focus on your work.",
                icon: <Check />,
              },
              {
                text: "We keep improving your advertisements to save you money and get you more customers.",
                icon: <Check />,
              },
              {
                text: "Each month, we send you easy-to-read reports, so you know how your ads are performing and who’s interested in your services.",
                icon: <Check />,
              },
            ]}
          />
          <List
            heading="When you setup ads yourself:"
            itemsDefinitions={[
              { text: "Missed opportunities with poorly targeted ads", icon: <Cons /> },
              { text: "Struggling with setup across platforms like Google and Facebook", icon: <Cons /> },
              { text: "No idea if your ads are working until it’s too late", icon: <Cons /> },
            ]}
          />
        </div>
      </OnboardingLayout>
    </>
  );
};
