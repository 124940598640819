import { modalMachineInstance } from "../index.ts";

export const ModalViewDefault = () => {
  return (
    <div className="modal-content">
      <div>
        <div>Titile</div>
        <div>Subtitle</div>
        <button onClick={() => modalMachineInstance.send("CLOSE")}>Close</button>
      </div>
      <div>
        <div>Content</div>
        <div>Actions</div>
        <button>Save</button>
      </div>
    </div>
  );
};
