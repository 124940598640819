import type { SVGProps } from "react";

export const FigmaPlugin = (props: SVGProps<SVGSVGElement>) => (
  <svg width="75" height="86" viewBox="0 0 75 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dddd_20270_2797)">
      <rect x="5.5" y="3" width="64" height="64" rx="16" fill="white" />
      <rect x="6" y="3.5" width="63" height="63" rx="15.5" stroke="url(#paint0_linear_20270_2797)" />
      <path
        d="M27.502 23.772C27.502 21.1381 29.64 19 32.2739 19H37.0459V28.5433H32.2739C29.6399 28.5433 27.502 26.4058 27.502 23.772Z"
        fill="#D4D4D8"
      />
      <path
        d="M38.7301 28.5434V19H43.5035C46.1374 19 48.2755 21.1381 48.2755 23.772C48.2755 26.4058 46.1373 28.5439 43.5035 28.544L38.7301 28.5434Z"
        fill="#FF4900"
      />
      <path
        d="M48.2755 34.9995C48.2755 32.3657 46.1374 30.2282 43.5034 30.2282C40.8694 30.2282 38.7315 32.3658 38.7315 34.9995C38.7315 37.6334 40.8696 39.7715 43.5035 39.7715C46.1374 39.7715 48.2755 37.6334 48.2755 34.9995Z"
        fill="#D4D4D8"
      />
      <path
        d="M27.502 34.9995C27.502 32.3656 29.64 30.2275 32.2739 30.2275H37.0459V39.7718H32.2739C29.6401 39.7718 27.502 37.6335 27.502 34.9995Z"
        fill="#D4D4D8"
      />
      <path
        d="M32.2739 41.456H37.0459V46.228C37.0459 48.8619 34.9078 51 32.2739 51C29.64 51 27.502 48.8619 27.502 46.228C27.502 43.5941 29.64 41.456 32.2739 41.456Z"
        fill="#D4D4D8"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddd_20270_2797"
        x="0.166667"
        y="0.333333"
        width="74.6667"
        height="85.3333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20270_2797" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.66667" />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20270_2797" result="effect2_dropShadow_20270_2797" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_20270_2797" result="effect3_dropShadow_20270_2797" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13.3333" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_20270_2797" result="effect4_dropShadow_20270_2797" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_20270_2797" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_20270_2797" x1="37.5" y1="3" x2="37.5" y2="67" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
