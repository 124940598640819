import type { TList } from "./List.types";

import "./List.css";

export const List: TList = ({ heading, itemsDefinitions, className }) => {
  return (
    <div className={"list" + (className ? ` ${className}` : "")}>
      <h2 className="list-heading">{heading}</h2>
      <div className="list-items">
        {itemsDefinitions.map((item, index) => {
          return (
            <div className="list-item" key={index}>
              <div className="list-item__icon">{item.icon}</div>
              <span className="list-item-text">{item.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
