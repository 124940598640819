import { type CSSProperties } from "react";

type TDragDrop = {
  className?: string;
  style?: CSSProperties;
};

export const DragDrop = ({ className, style }: TDragDrop) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7.06904 3.38462C7.06904 4.14932 6.44912 4.76923 5.68442 4.76923C4.91972 4.76923 4.2998 4.14932 4.2998 3.38462C4.2998 2.61991 4.91972 2 5.68442 2C6.44912 2 7.06904 2.61991 7.06904 3.38462Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.06904 8C7.06904 8.7647 6.44912 9.38461 5.68442 9.38461C4.91972 9.38461 4.2998 8.7647 4.2998 8C4.2998 7.2353 4.91972 6.61538 5.68442 6.61538C6.44912 6.61538 7.06904 7.2353 7.06904 8Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.06904 12.6154C7.06904 13.3801 6.44912 14 5.68442 14C4.91972 14 4.2998 13.3801 4.2998 12.6154C4.2998 11.8507 4.91972 11.2308 5.68442 11.2308C6.44912 11.2308 7.06904 11.8507 7.06904 12.6154Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6844 3.38462C11.6844 4.14932 11.0645 4.76923 10.2998 4.76923C9.5351 4.76923 8.91519 4.14932 8.91519 3.38462C8.91519 2.61991 9.5351 2 10.2998 2C11.0645 2 11.6844 2.61991 11.6844 3.38462Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6844 8C11.6844 8.7647 11.0645 9.38461 10.2998 9.38461C9.5351 9.38461 8.91519 8.7647 8.91519 8C8.91519 7.2353 9.5351 6.61538 10.2998 6.61538C11.0645 6.61538 11.6844 7.2353 11.6844 8Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6844 12.6154C11.6844 13.3801 11.0645 14 10.2998 14C9.5351 14 8.91519 13.3801 8.91519 12.6154C8.91519 11.8507 9.5351 11.2308 10.2998 11.2308C11.0645 11.2308 11.6844 11.8507 11.6844 12.6154Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
