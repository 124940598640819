import { useEffect, useRef, useState } from "react";
import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { SelectButton } from "../SelectButton";

import { TIconNameProperty } from "./IconNameProperty.types";
import { editorService } from "../../../editor";

import "./IconNameProperty.css";

const options = [
  "10k",
  "home",
  "history",
  "apartment",
  "home_pin",
  "house",
  "build",
  "local_fire_department",
  "health_and_safety",
  "stadia_controller",
  "location_city",
  "domain",
  "real_estate_agent",
  "cottage",
  "home_work",
  "bed",
  "meeting_room",
  "chair",
  "home_repair_service",
  "location_away",
  "bedtime",
  "emergency_home",
  "other_houses",
  "home_app_logo",
  "grass",
  "shower",
  "kitchen",
  "clear_day",
  "location_home",
  "yard",
  "door_open",
  "bathtub",
  "king_bed",
  "roofing",
  "window",
  "holiday_village",
  "cooking",
  "nest_eco_leaf",
  "garage_home",
  "ev_station",
  "door_front",
  "foundation",
  "mode_heat",
  "nest_remote_comfort_seat",
  "room_preferences",
  "outdoor_grill",
  "laundry",
  "domain_add",
  "night_shelter",
  "deck",
  "battery_horiz_075",
  "weekend",
  "fireplace",
  "shield_with_heart",
  "villa",
  "tv_gen",
  "familiar_face_and_zone",
  "temp_preferences_eco",
  "tool_power_drill",
  "domain_disabled",
  "airwave",
  "outdoor_garden",
  "bedroom_parent",
  "gite",
  "chair_alt",
  "scene",
  "microwave",
  "oven_gen",
  "productivity",
  "bedroom_baby",
  "single_bed",
  "bathroom",
  "in_home_mode",
  "mode_cool",
  "battery_horiz_050",
  "cabin",
  "home_iot_device",
  "countertops",
  "living",
  "gate",
  "detector_smoke",
  "dishwasher_gen",
  "nest_display",
  "nest_heat_link_gen_3",
  "houseboat",
  "weather_snowy",
  "balcony",
  "activity_zone",
  "camera_indoor",
  "nest_remote",
  "house_siding",
  "bedroom_child",
  "microwave_gen",
  "add_home",
  "cleaning_bucket",
  "door_sliding",
  "setting_alert",
  "water_heater",
  "fence",
  "nest_cam_indoor",
  "dresser",
  "doorbeel",
  "home_storage",
  "arrow_more_up",
  "door_back",
  "emergency_heat",
  "aod",
  "add_home_work",
  "add_to_home_screen",
  "nest_heat_link_e",
  "camera_outdoor",
  "keetle",
  "nest_multi_room",
  "light_group",
  "detection_and_zone",
  "nest_cam_outdoor",
  "nest_secure_alarm",
  "google_wifi",
  "battery_horiz_000",
  "bedtime_off",
  "no_meeting_room",
  "multicooker",
  "digital_out_of_home",
  "mfg_nest_yale_lock",
  "tool_pilers_wire_stripper",
  "nest_thermostat_gen_3",
  "bungalow",
  "mode_heat_cool",
  "detector_alarm",
  "early_on",
  "tool_ladder",
  "nest_cam_iq_outdoor",
  "smart_outlet",
  "nest_cam_iq",
  "floor_lamp",
  "nest_mini",
  "nest_hello_doorbell",
  "nest_clock_farsight_digital",
  "home_speaker",
  "nest_display_max",
  "nest_audio",
  "motion_sensor_active",
  "zone_person_urgent",
  "home_max_dots",
  "nest_wifi_router",
  "chromecast_device",
  "house_with_shield",
  "cool_to_dry",
  "nest_farsight_weather",
  "chromecast_2",
  "shield_with_house",
  "home_max",
  "chalet",
  "window_closed",
  "battery_profile",
  "broadcast_on_home",
  "heat_pump_balance",
  "arming_countdown",
  "self_care",
  "nest_found_savings",
  "window_open",
  "detector_status",
  "range_hood",
  "source_environment",
  "home_mini",
  "tools_level",
  "tv_with_assistant",
  "zone_person_alert",
  "detector",
  "nest_thermostat_zirconium_eu",
  "arrows_more_down",
  "nest_cam_wired_stand",
  "family_home",
  "nest_true_radiant",
  "door_senor",
  "nest_cam_floodlight",
  "nest_detect",
  "climate_mini_split",
  "nest_wifi_point",
  "nest_doorbell_visitor",
  "nest_tag",
  "mode_heat_off",
  "nest_thermostat_sensor",
  "detector_co",
  "tools_installation_kit",
  "aod_tablet",
  "nest_connect",
  "nest_sunblock",
  "nest_thermostat_sensor_eu",
  "tools_philips",
  "doorbell_chime",
  "nest_thermostat_e_eu",
  "table_lamp",
  "mode_cool_off",
  "tools_flat_head",
  "detector_battery",
  "nest_wake_on_approach",
  "motion_sensor_urgent",
  "tamper_detection_on",
  "nest_wake_on_press",
  "motion_sensor_alert",
  "window_sensor",
  "zone_person_idle",
  "nest_cam_magnet_mount",
  "sensor_krx",
  "nest_cam_stand",
  "detector_offline",
  "nest_cam_wall_mount",
  "wall_lamp",
  "google_home_devices",
  "motion_sensor_idle",
  "water_pump",
  "nest_thermostat",
  "heat",
  "weather_hail",
  "skillet",
  "assistant_on_hub",
  "shelves",
  "vacuum",
  "home_health",
  "air_purifier",
  "garage_door",
  "google_tv_remote",
  "home_and_garden",
  "dishwasher",
  "help_clinic",
  "cleaning",
  "oven",
  "emergency_heat_2",
  "home_improvement_and_tools",
  "general_device",
  "assistant_device",
  "mode_dual",
  "aq",
  "nest_product",
  "sensors_krx_off",
  "stockpot",
  "aq_indoor",
  "hallway",
  "humidity_indoor",
  "nest_wifi_pro_2",
  "on_hub_device",
  "audio_video_receiver",
  "skillet_cooktop",
  "rotate_auto",
  "wifi_home",
  "wifi_proxy",
  "nest_wifi_pro",
  "farsight_digital",
  "history_2",
];

const formatArray = (arr: string[]) => {
  return arr.map((item, index) => {
    const label = item
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      id: (index + 1).toString(),
      value: item,
      label: label,
      name: label,
    };
  });
};

export const IconNameProperty: TIconNameProperty = () => {
  const [value, setValue] = useState("home");
  const editor = editorService.getEditor();
  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editor) {
      const seleted = editor.getSelected();
      const value = seleted?.getInnerHTML();
      setValue(value ?? "");
    }
  }, []);

  const handleChange = (ev: string) => {
    const selected = editor?.getSelected();

    setValue(ev);
    selected?.set("content", ev);
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    const target = propertyRef.current?.querySelector(".select-button") || propertyRef.current;
    const coordinates = target?.getBoundingClientRect();

    if (!coordinates) return;

    styleManagerService.openUnitDropdown(formatArray(options), value, coordinates, handleChange);
  };

  return (
    <div className={`property-select vertical full-width`} ref={propertyRef}>
      <PropertyLabel text={"Name"} modifier={"primary"} info={"Choose your Icon from list"} />
      <SelectButton currentOption={value} options={formatArray(options)} onClick={handleUnitDropdown} />
    </div>
  );
};
