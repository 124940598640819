import { type SVGProps } from "react";

export const Checked = (props: SVGProps<SVGSVGElement>) => (
  <svg width="64" height="72" viewBox="0 0 64 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_11711_14791)">
      <rect x="4" y="2" width="56" height="56" rx="12" fill="url(#paint0_linear_11711_14791)" />
      <rect x="4.5" y="2.5" width="55" height="55" rx="11.5" stroke="url(#paint1_linear_11711_14791)" />
      <path d="M25 31L29 35L39 25" stroke="#FF4900" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter
        id="filter0_ddd_11711_14791"
        x="0"
        y="0"
        width="64"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11711_14791" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_11711_14791" result="effect2_dropShadow_11711_14791" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_11711_14791" result="effect3_dropShadow_11711_14791" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_11711_14791" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_11711_14791" x1="32" y1="2" x2="32" y2="58" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint1_linear_11711_14791" x1="32" y1="2" x2="32" y2="58" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
