import { Avatar } from "../../Dashboard/atoms/Avatar";

import type { THeader } from "./Header.types";

import "./Header.css";

export const Header: THeader = ({ iconSrc, headingText, subheadingText }) => {
  return (
    <div className="header">
      {iconSrc && <Avatar src={iconSrc} className="header__avatar" />}
      {headingText && <div className="header__heading">{headingText}</div>}
      {subheadingText && <div className="header__subheading">{subheadingText}</div>}
    </div>
  );
};
