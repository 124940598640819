import { type SVGProps } from "react";

export const VisualEditor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="76" height="86" viewBox="0 0 76 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dddd_20270_2791)">
      <rect x="6" y="3" width="64" height="64" rx="16" fill="white" />
      <rect x="6.5" y="3.5" width="63" height="63" rx="15.5" stroke="url(#paint0_linear_20270_2791)" />
      <path
        d="M40.6562 41.666H44.6563M44.6563 41.666H48.6563M44.6563 41.666V37.666M44.6563 41.666V45.666"
        stroke="#FF4900"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.334 31.533V25.133C27.334 24.6911 27.6922 24.333 28.134 24.333H34.534C34.9758 24.333 35.334 24.6911 35.334 25.133V31.533C35.334 31.9749 34.9758 32.333 34.534 32.333H28.134C27.6922 32.333 27.334 31.9749 27.334 31.533Z"
        fill="#D4D4D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.334 25.333V31.333H34.334V25.333H28.334ZM26.334 25.133C26.334 24.1388 27.1399 23.333 28.134 23.333H34.534C35.5281 23.333 36.334 24.1388 36.334 25.133V31.533C36.334 32.5272 35.5281 33.333 34.534 33.333H28.134C27.1399 33.333 26.334 32.5272 26.334 31.533V25.133Z"
        fill="#D4D4D8"
      />
      <path
        d="M27.334 44.866V38.466C27.334 38.0241 27.6922 37.666 28.134 37.666H34.534C34.9758 37.666 35.334 38.0241 35.334 38.466V44.866C35.334 45.3079 34.9758 45.666 34.534 45.666H28.134C27.6922 45.666 27.334 45.3079 27.334 44.866Z"
        fill="#D4D4D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.334 38.666V44.666H34.334V38.666H28.334ZM26.334 38.466C26.334 37.4718 27.1399 36.666 28.134 36.666H34.534C35.5281 36.666 36.334 37.4718 36.334 38.466V44.866C36.334 45.8602 35.5281 46.666 34.534 46.666H28.134C27.1399 46.666 26.334 45.8602 26.334 44.866V38.466Z"
        fill="#D4D4D8"
      />
      <path
        d="M40.666 31.533V25.133C40.666 24.6912 41.0241 24.333 41.466 24.333H47.866C48.3079 24.333 48.666 24.6912 48.666 25.133V31.533C48.666 31.9748 48.3079 32.333 47.866 32.333H41.466C41.0241 32.333 40.666 31.9748 40.666 31.533Z"
        fill="#FF4900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.666 25.333V31.333H47.666V25.333H41.666ZM39.666 25.133C39.666 24.1389 40.4718 23.333 41.466 23.333H47.866C48.8602 23.333 49.666 24.1389 49.666 25.133V31.533C49.666 32.5271 48.8602 33.333 47.866 33.333H41.466C40.4718 33.333 39.666 32.5271 39.666 31.533V25.133Z"
        fill="#FF4900"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddd_20270_2791"
        x="0.666667"
        y="0.333333"
        width="74.6667"
        height="85.3333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20270_2791" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.66667" />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20270_2791" result="effect2_dropShadow_20270_2791" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_20270_2791" result="effect3_dropShadow_20270_2791" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13.3333" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_20270_2791" result="effect4_dropShadow_20270_2791" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_20270_2791" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_20270_2791" x1="38" y1="3" x2="38" y2="67" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
