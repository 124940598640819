import { useState, useEffect } from "react";
import { editorInstance } from "../../../editor/index.ts";
import { modalMachineInstance } from "../../index.ts";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";
import { cleanUrl } from "../../../../utils/clean-url.ts";

import { Close } from "../../../../../../../assets/svg/Close.tsx";
import { Hint } from "../../../../../../../assets/svg/Hint.tsx";
import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle.tsx";

import "./CustomDomain.css";

export const CustomDomainModal = () => {
  const [_, updateState] = useState(projectsService.state);
  const [editorState, setEditorState] = useState(editorInstance.state.value);
  const [healthCheckStatus, setHealthCheckStatus] = useState<"pending" | "success" | "error" | null>(null);
  const [customDomain, setCustomDomain] = useState("");
  const [domainError, setDomainError] = useState(false);

  useEffect(() => {
    projectsService.subscribe(updateState);
    editorInstance.onTransition((state) => {
      setEditorState(state.value);
    });
    return () => projectsService.unsubscribe(updateState);
  }, []);

  const activeProject = projectsService.getActiveProject();
  const canUpdate = customDomain.length > 0 && !domainError && editorState === "READY";
  const canCheckDomain = activeProject?.customDomain?.address !== "" && editorState === "READY";
  const canPublish =
    activeProject?.customDomain?.address !== "" && !activeProject?.customDomain?.published && editorState === "READY";
  const formattedCustomDomain = cleanUrl(activeProject?.customDomain?.address || "exampledomain.com");

  const handleEditCustomDomain = () => {
    if (!domainValidation(customDomain)) {
      setDomainError(true);
    } else {
      setHealthCheckStatus(null);
      editorInstance.send("SAVE_CUSTOM_DOMAIN", { customDomain });
    }
  };

  const handleCheckStatus = () => {
    setHealthCheckStatus("pending");
    projectsService
      .checkCustomDomainHealth()
      .then((data) => {
        if (data?.data.status === "active") {
          setHealthCheckStatus("success");
        } else {
          setHealthCheckStatus("error");
        }
      })
      .catch((e) => {
        console.error(e);
        setHealthCheckStatus("error");
      });
  };

  const domainValidation = (url: string) => {
    const urlRegex = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
    return urlRegex.test(url);
  };

  return (
    <div className="custom-domain-modal">
      <header className="domains-modal__header">
        <h2 className="domains-modal__heading">Custom domain</h2>
        {activeProject?.customDomain.address === "" ? (
          <p className="domains-modal__description">Add custom domain to your project</p>
        ) : (
          <p className="domains-modal__description">{activeProject?.customDomain.address}</p>
        )}
        <button
          type="button"
          className="domains-modal__close"
          onClick={() => modalMachineInstance.send("CLOSE")}
          aria-label="close modal"
        >
          <Close />
        </button>
      </header>
      <div className="domains-modal__separator"></div>
      <div className="custom-domain-modal__content">
        <div className="add-custom-domain">
          <span>https://</span>
          <label className={`add-custom-domain__label${domainError ? " add-custom-domain__label--error" : ""}`}>
            <input
              type="text"
              placeholder={formattedCustomDomain}
              value={customDomain}
              onChange={(e) => {
                setCustomDomain(e.target.value);
              }}
              onFocus={() => {
                setDomainError(false);
              }}
            />
            {customDomain !== "" && (
              <button className="add-custom-domain__clear-btn" onClick={() => setCustomDomain("")}>
                <Close />
              </button>
            )}
          </label>
          <button className="add-custom-domain__button" disabled={!canUpdate} onClick={handleEditCustomDomain}>
            {activeProject?.customDomain?.address ? "Edit domain" : "Add domain"}
          </button>
          {domainError && (
            <div className="add-custom-domain__invalid-domain">
              <AlertTriangle />
              Issue detected - Incorrect Domain
            </div>
          )}
        </div>

        <div className="domain-records">
          <div className="domain-records__record">
            <p className="domain-records__record-heading">
              Visit the admin console of your domain registrar (the website you bought your domain from) and create
              these <b>DNS Records:</b>
            </p>
            <div className="domain-records__record-table">
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Type</div>
                <div className="domain-records__record-cell">A</div>
                {/* <div className="domain-records__record-cell">CNAME</div> */}
              </div>
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Name</div>
                <div className="domain-records__record-cell">@</div>
                {/* <div className="domain-records__record-cell">WWW</div> */}
              </div>
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Value</div>
                <div className="domain-records__record-cell">51.83.255.250</div>
                {/* <div className="domain-records__record-cell">51.83.255.250</div> */}
              </div>
            </div>
          </div>
          <div className="domain-records__record">
            <p className="domain-records__record-heading">
              If you fail to connect, review domain provider instructions for “@” name necessity or delete and create{" "}
              <b>Backup Records:</b>
            </p>
            <div className="domain-records__record-table">
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Type</div>
                <div className="domain-records__record-cell">A</div>
                {/* <div className="domain-records__record-cell">CNAME</div> */}
              </div>
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Name</div>
                <div className="domain-records__record-cell">@</div>
                {/* <div className="domain-records__record-cell">WWW</div> */}
              </div>
              <div className="domain-records__record-column">
                <div className="domain-records__record-label">Value</div>
                <div className="domain-records__record-cell">51.83.255.250</div>
                {/* <div className="domain-records__record-cell">51.83.255.250</div> */}
              </div>
            </div>
          </div>
          <div className="domain-records__status">
            <Hint />
            <p className="domain-records__status-info">It may take up to few hours for DNS changes to take effect</p>
          </div>
        </div>
      </div>
      <div className="domains-modal__separator"></div>
      <footer className="custom-domains-modal__footer">
        <a href="https://docs.codejet.ai/tools/launch" rel="nofollow noreferrer" target="_blank">
          <Hint /> <span>Learn about custom domain</span>
        </a>
        <button
          className={`custom-domain__status-button${
            healthCheckStatus ? ` custom-domain__status-button--${healthCheckStatus}` : ""
          }`}
          disabled={!canCheckDomain}
          onClick={handleCheckStatus}
        >
          {!healthCheckStatus && "Check status"}
          {healthCheckStatus === "pending" && "Checking..."}
          {healthCheckStatus === "error" && "Domain invalid"}
          {healthCheckStatus === "success" && "Domain valid"}
        </button>
        <button
          className="custom-domain__publish-button"
          onClick={() => editorInstance.send("PUBLISH_PROJECT", { customDomain: true })}
          disabled={!canPublish}
        >
          Publish domain
        </button>
      </footer>
    </div>
  );
};
