import { useEffect, useState } from "react";

import { Seo } from "../../atoms/Seo";

import { Navigation } from "../../Dashboard/organisms/Navigation";
import { TTemplatesDashboards } from "./TemplatesDashboard.types";
import { templatesInstance } from "../../../machines/templates/templates.instance";
import { RenderContent } from "../../Dashboard/organisms/TemplatesSection/RenderContent";

import type { Project } from "../../../types/types";

import "./TemplatesDashboards.css";

export const TemplatesDashboards: TTemplatesDashboards = () => {
  const [templates, setProjects] = useState<Project[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isNoContent, setIsNoContent] = useState(false);
  const templatesService = templatesInstance.getSnapshot().context.service;

  useEffect(() => {
    templatesService.subscribe((state) => setProjects(state));
    const templatesState = templatesService.state;
    if (templatesState.length === 0) {
      templatesInstance.send("FETCH_TEMPLATES");
      templatesInstance.onTransition((state) => {
        if (state.value === "AWAITING_TEMPLATE_SELECTION") {
          setIsSkeleton(false);
        }
      });
    } else {
      setIsSkeleton(false);
      setProjects(templatesState);
    }
    return () => {
      templatesService.unsubscribe((state) => setProjects(state));
    };
  }, []);

  useEffect(() => {
    if (isSkeleton === false) {
      if (templates.length === 0) {
        setIsNoContent(true);
      }
    }
  }, [templates, isSkeleton]);

  const reversedTemplates = [...templates].reverse();

  return (
    <>
      <Seo title="My templates - Codejet" />
      <Navigation />
      <div className={`my-projects__content container`}>
        <h2 className="my-projects__projects-title">Templates</h2>
        <section className="my-projects__projects">
          <div className={`my-projects__projects-content${isNoContent ? " my-projects__projects-content--empty" : ""}`}>
            <RenderContent isSkeleton={isSkeleton} isNoContent={isNoContent} templates={reversedTemplates} />
          </div>
        </section>
      </div>
    </>
  );
};
