import { useState, useEffect } from "react";

import { Seo } from "../../atoms/Seo";
import { Chip } from "../../atoms/Chip";
import { ButtonNew } from "../../atoms/ButtonNew";
import { Pagination } from "../../atoms/Pagination";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";

import { Logotype } from "../../../../assets/svg/logotype";
import { CodejetIconNew } from "../../../../assets/svg/codejet_icon_new";

import { isMobile } from "../../../machines/dashboard/helper/isMobile";
import { CreateAccountService } from "./CreateAccount.service";
import { createUserFormInstance } from "../../../machines/create-user-form/create-user-form.instance";

import type { TCreateAccount } from "./CreateAccount.types";

import "./CreateAccount.css";

const service = CreateAccountService.getInstance();

export const CreateAccount: TCreateAccount = () => {
  const [questionCounter, setQuestionCounter] = useState(0);
  const [loaderStep, setLoaderStep] = useState(0);
  const [_, setSubscriber] = useState({});
  const isInitialScreen = questionCounter === 0;
  const answerOptions = service.getAnswerOptions();

  const titleText = () => (isInitialScreen ? "Welcome to Codejet" : service.getCurrentQuestion());

  const buttonText = () => {
    if (service.isLoading) return "Sending Questions";
    if (isInitialScreen) return "Start";
    return "Next";
  };

  const activeStep = service.isLoading ? loaderStep : questionCounter;

  const isProceedButtonDisabled = () => {
    if (isInitialScreen) return false;
    if (service.isLoading) return true;
    return service.isContinueButtonDisabled();
  };

  const handleProceedClick = () => {
    setQuestionCounter((prev) => prev + 1);
    if (questionCounter >= 1) createUserFormInstance.send("NEXT_QUESTION");
  };

  const handleChipClick = (ans: string, isChecked: boolean) =>
    createUserFormInstance.send("SAVE_ANSWER", { answer: ans, isChecked });

  useEffect(() => {
    service.subscribe(setSubscriber);
    if (isMobile()) {
      sessionStorage.setItem("isOnboarding", "true");
    }

    return () => {
      service.unsubscribe(setSubscriber);
    };
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (service.isLoading) {
      intervalId = setInterval(() => {
        setLoaderStep((prevStep) => (prevStep + 1) % (service.questions.length + 1));
      }, 350);
    } else {
      setLoaderStep(0);
    }

    return () => clearInterval(intervalId);
  }, [service.isLoading]);

  return (
    <>
      <Seo title="Onboarding - Codejet" />
      <div className="create-account">
        <div className="create-account__wrapper">
          <Logotype />
          <div className="create-account__content">
            <WeirdIconWrapper>
              <CodejetIconNew />
            </WeirdIconWrapper>
            <div className="create-account__introduction">
              <h1 className="create-account__title">{titleText()}</h1>
              <div className="create-account__options">
                {isInitialScreen ? (
                  <p className="create-account__description">
                    Before jumping into building your first CodeJet project, we would love to get to know you a little
                    bit better.
                  </p>
                ) : (
                  <div className="create-account__chips">
                    {answerOptions.map((ans) => (
                      <Chip
                        key={`${ans}-${questionCounter}`}
                        label={ans}
                        checked={service.getAnswerOptionsChecked(ans)}
                        onClick={(isChecked) => handleChipClick(ans, isChecked)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <ButtonNew
              orange
              className="create-account__proceed-btn"
              onClick={handleProceedClick}
              disabled={isProceedButtonDisabled()}
            >
              {buttonText()}
            </ButtonNew>
          </div>
          <div className="create-account__navigation">
            <Pagination steps={service.questions.length + 1} active={activeStep} />
          </div>
        </div>
      </div>
    </>
  );
};
