import { type CSSProperties } from "react";

type TComposition2 = {
  className?: string;
  style?: CSSProperties;
};

export const Composition2 = ({ className, style }: TComposition2) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M20 17.3333V18.6667C20 19.0203 19.8595 19.3595 19.6095 19.6095C19.3595 19.8595 19.0203 20 18.6667 20H17.3333M20 10V14M14 20H10M20 6.66667V5.33333C20 4.97971 19.8595 4.64057 19.6095 4.39052C19.3595 4.14048 19.0203 4 18.6667 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V18.6667C4 19.0203 4.14048 19.3595 4.39052 19.6095C4.64057 19.8595 4.97971 20 5.33333 20H6.66667"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
