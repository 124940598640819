import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Select } from "../Select";

import type { SelectOption } from "../Select/Select.types";
import type { TForm, ValidateState } from "./Form.types";

import "./Form.css";

const FormComponent: TForm = (
  {
    companyCategorySelectedOption,
    setCompanyCategorySelectedOption,
    websiteGoalSelectedOption,
    setWebsiteGoalSelectedOption,
    setBusinessName,
    setAdditionalComments,
    companyCategory,
    websiteGoal,
  },
  ref
) => {
  const businessNameRef = useRef<HTMLInputElement>(null);
  const additionalCommentsRef = useRef<HTMLTextAreaElement>(null);
  const [validationState, setValidationState] = useState<ValidateState>({
    isBusinessNameValid: undefined,
    isAdditionalCommentsValid: undefined,
    isCompanyCategoryValid: undefined,
    isWebsiteGoalValid: undefined,
  });

  const selectCompanyOption = (selectedOption: SelectOption) => {
    setCompanyCategorySelectedOption(selectedOption);
  };

  const selectWebsiteOption = (selectedOption: SelectOption) => {
    setWebsiteGoalSelectedOption(selectedOption);
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        const isBusinessNameValid = businessNameRef.current?.value.trim() !== "";
        const isAdditionalCommentsValid = additionalCommentsRef.current?.value.trim() !== "";
        const isCompanyCategoryValid = companyCategorySelectedOption !== null;
        const isWebsiteGoalValid = websiteGoalSelectedOption !== null;

        setValidationState({
          isBusinessNameValid,
          isAdditionalCommentsValid,
          isCompanyCategoryValid,
          isWebsiteGoalValid,
        });

        const isValid =
          isAdditionalCommentsValid && isBusinessNameValid && isCompanyCategoryValid && isWebsiteGoalValid;

        return isValid;
      },
      reset: () => {
        setValidationState({
          isBusinessNameValid: undefined,
          isAdditionalCommentsValid: undefined,
          isCompanyCategoryValid: undefined,
          isWebsiteGoalValid: undefined,
        });
      },
    }),
    [companyCategorySelectedOption, websiteGoalSelectedOption]
  );

  return (
    <div className="form-container">
      <form>
        <div className={`form-group__business-name${validationState.isBusinessNameValid === false ? " invalid" : ""}`}>
          <label htmlFor="business-name">
            Business name <span className="form-container__required">*</span>
          </label>
          <input
            type="text"
            className="business-name"
            placeholder="Enter your business name"
            aria-required="true"
            aria-describedby="business-name-description"
            onChange={(e) => setBusinessName(e.target.value)}
            ref={businessNameRef}
          />
        </div>
        <Select
          label="Company category"
          options={companyCategory}
          onChange={selectCompanyOption}
          selectedOption={companyCategorySelectedOption}
          isValid={validationState.isCompanyCategoryValid}
        />
        <Select
          label="Website goal"
          options={websiteGoal}
          onChange={selectWebsiteOption}
          selectedOption={websiteGoalSelectedOption}
          isValid={validationState.isWebsiteGoalValid}
          aria-describedby="comments-description"
        />
        <div className={`form-group__comments${validationState.isAdditionalCommentsValid === false ? " invalid" : ""}`}>
          <label htmlFor="additional-comments">
            Additional comments <span className="form-container__required">*</span>
          </label>
          <textarea
            className="additional-comments"
            placeholder="Enter comments"
            onChange={(e) => setAdditionalComments(e.target.value)}
            ref={additionalCommentsRef}
          ></textarea>
        </div>
      </form>
    </div>
  );
};

export const Form = forwardRef(FormComponent);
