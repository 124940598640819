import React, { type SVGProps } from "react";
export const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="mask0_12932_1384"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="17"
    >
      <rect x="0.666016" y="0.5" width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_12932_1384)">
      <path
        d="M4.66536 15.1667C4.2987 15.1667 3.98481 15.0362 3.7237 14.7751C3.46259 14.514 3.33203 14.2001 3.33203 13.8334V7.16675C3.33203 6.80008 3.46259 6.48619 3.7237 6.22508C3.98481 5.96397 4.2987 5.83342 4.66536 5.83342H5.33203V4.50008C5.33203 3.57786 5.65703 2.79175 6.30703 2.14175C6.95703 1.49175 7.74314 1.16675 8.66536 1.16675C9.58759 1.16675 10.3737 1.49175 11.0237 2.14175C11.6737 2.79175 11.9987 3.57786 11.9987 4.50008V5.83342H12.6654C13.032 5.83342 13.3459 5.96397 13.607 6.22508C13.8681 6.48619 13.9987 6.80008 13.9987 7.16675V13.8334C13.9987 14.2001 13.8681 14.514 13.607 14.7751C13.3459 15.0362 13.032 15.1667 12.6654 15.1667H4.66536ZM8.66536 11.8334C9.03203 11.8334 9.34592 11.7029 9.60703 11.4417C9.86814 11.1806 9.9987 10.8667 9.9987 10.5001C9.9987 10.1334 9.86814 9.81953 9.60703 9.55842C9.34592 9.2973 9.03203 9.16675 8.66536 9.16675C8.2987 9.16675 7.98481 9.2973 7.7237 9.55842C7.46259 9.81953 7.33203 10.1334 7.33203 10.5001C7.33203 10.8667 7.46259 11.1806 7.7237 11.4417C7.98481 11.7029 8.2987 11.8334 8.66536 11.8334ZM6.66536 5.83342H10.6654V4.50008C10.6654 3.94453 10.4709 3.4723 10.082 3.08341C9.69314 2.69453 9.22092 2.50008 8.66536 2.50008C8.10981 2.50008 7.63759 2.69453 7.2487 3.08341C6.85981 3.4723 6.66536 3.94453 6.66536 4.50008V5.83342Z"
        fill="white"
      />
    </g>
  </svg>
);
