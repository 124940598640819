import type { TIconButton } from "./IconButton.types";

import "./IconButton.css";

export const IconButton: TIconButton = ({ children, ariaLabel, onClick, className }) => {
  const baseClass = "icon-button";
  const classes = [baseClass, className].filter(Boolean).join(" ");
  return (
    <button className={classes} aria-label={ariaLabel} type="button" onClick={onClick}>
      {children}
    </button>
  );
};
