import type { SVGProps } from "react";
export const ConnectorNotActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width="215" height="6" viewBox="0 0 215 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M-0.000651121 3C-0.000651121 4.47276 1.19326 5.66667 2.66602 5.66667C4.13877 5.66667 5.33268 4.47276 5.33268 3C5.33268 1.52724 4.13877 0.333333 2.66602 0.333333C1.19326 0.333333 -0.000651121 1.52724 -0.000651121 3ZM2.66602 3.5H214.333V2.5H2.66602V3.5Z"
      fill="url(#paint0_linear_20466_2080)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20466_2080"
        x1="2.66602"
        y1="3.5"
        x2="214.333"
        y2="3.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4D4D8" />
        <stop offset="1" stopColor="#D4D4D8" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
