import { type CSSProperties } from "react";

type TRename = {
  className?: string;
  style?: CSSProperties;
};

export const Rename = ({ className, style }: TRename) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.1539 4.69234V1.92308C11.1539 1.41328 10.7407 1 10.2308 1H1.92308C1.41328 1 1 1.41328 1 1.92308V10.2308C1 10.7407 1.41328 11.1539 1.92308 11.1539H4.69234M6.07696 3.76925V8.38467M3.76925 4.69234V3.76925H8.38467V4.69234M5.15388 8.38467H7.00005M13 8.38482L8.65228 12.7326L6.68611 13.0003L6.96304 11.0341L11.3015 6.68635L13 8.38482Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
