import { useState } from "react";

import { ChevronDownLight as Chevron } from "../../../../assets/svg/chevron-down-light";

import type { TSelect } from "./Select.types";

import "./Select.css";

export const Select: TSelect = ({ onChange, options, defaultValue }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(defaultValue || "HTML");

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  const selectOption = (label: string, value?: string): void => {
    setSelectedOption(label);
    setIsOpen(false);
    if (onChange) {
      if (value) onChange(value);
      else onChange(label);
    }
  };

  return (
    <div className="select">
      <button
        className="select__button"
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls="select__dropdown"
        onClick={toggleDropdown}
      >
        <span className="select__selected-value select__typography">{selectedOption}</span>
        <span className={`select__arrow ${isOpen && "select__arrow--up"}`}>
          <Chevron />
        </span>
      </button>
      <div className={`select__dropdown-wrapper ${isOpen ? " active" : ""}`}>
        <ul className={`select__dropdown ${isOpen && "select__dropdown--active"}`} role="listbox" id="select__dropdown">
          {options.map((option, index) => {
            const id = `${option.label.toLocaleLowerCase()}${index}`;
            return (
              <li
                key={id}
                role="option"
                onClick={(e) => {
                  e.preventDefault(); // prevent other select to be changed
                  if (option.value) {
                    selectOption(option.label, option.value);
                  } else {
                    selectOption(option.label);
                  }
                }}
                className="select__option select__typography"
              >
                <input type="radio" id={id} name="output-framework" className="select__input" />
                <label htmlFor={id} className="select__label">
                  {/* <i className="select__icon">{option.icon}</i> */}
                  {option.label}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
