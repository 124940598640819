import { forwardRef } from "react";

import { IconButton } from "../../../../../atoms/IconButton";
import { HamburgerSlim } from "../../../../../../../assets/svg/hamburger-slim";
import { TemplateCardActionsProps } from "./TemplateCardActions.types";

export const TemplateCardActions = forwardRef<HTMLDivElement, TemplateCardActionsProps>(
  (
    { isLoading, isMenuOpen, isDuplicateModalOpen, handleOpenMenu, handleOpenMenuKey, handleOpenDuplicateModal },
    ref
  ) => {
    return (
      <div
        className={"project-card__actions" + (isLoading || isMenuOpen ? " project-card__actions--visible" : "")}
        role="menu"
        tabIndex={0}
        ref={ref}
        onClick={handleOpenMenu}
        onKeyDown={handleOpenMenuKey}
      >
        <div className="project-card__dropdown-wrapper">
          <IconButton
            className={"project-card__btn" + (isMenuOpen ? " project-card__btn--menu-open" : "")}
            ariaLabel="project context menu"
          >
            <HamburgerSlim className={isLoading ? "loading-dots-animation" : ""} />
          </IconButton>
          <div className={"project-card__dropdown" + (isMenuOpen ? " project-card__dropdown--visible" : "")}>
            <button
              className={`project-card__dropdown-item${
                isDuplicateModalOpen ? " project-card__dropdown-item--hovered" : ""
              }`}
              type="button"
              onClick={handleOpenDuplicateModal}
            >
              Add to My Projects
            </button>
          </div>
        </div>
      </div>
    );
  }
);
