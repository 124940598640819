import { extractInitials } from "../../../../utils/extractInitials";

import type { TAvatar } from "./Avatar.types";

import "./Avatar.css";

export const Avatar: TAvatar = ({ label, src, className }) => {
  const baseClass = "avatar";
  const classes = [baseClass, className].filter(Boolean).join(" ");
  let content;

  if (src) {
    content = <img className="avatar__image" src={src} alt={`${label}'s Avatar` || "Avatar"} />;
  }
  if (label) {
    const labelInitials = extractInitials(label);
    content = <span className="avatar__label">{labelInitials}</span>;
  }

  return <div className={classes}>{content}</div>;
};
