import { ComponentDefinition, Editor } from "grapesjs";
import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";

export type SelectorManagerState = {
  open: boolean;
};

export class SelectorManagerService extends Subscriber<SelectorManagerState> {
  state: SelectorManagerState = {
    open: false,
  };

  selectorManager?: Editor["SelectorManager"];
  component?: ComponentDefinition;

  setSelectorManager(selectorManager: Editor["SelectorManager"]) {
    this.selectorManager = selectorManager;
    this.notifySubscribers();
  }

  selectComponent(component: ComponentDefinition) {
    this.component = component;
    this.notifySubscribers();
  }
  deselectComponent() {
    this.component = undefined;
    this.notifySubscribers();
  }

  open() {
    this.state.open = true;
    this.notifySubscribers();
  }

  setComponents(value: boolean) {
    if (this.selectorManager) {
      this.selectorManager.setComponentFirst(value);
    }
    this.notifySubscribers();
  }
}
//This need to be loaded first  in editorMachine then used in SelectorManagerMachine
export const selectorManagerInstance = new SelectorManagerService();
