import { type CSSProperties } from "react";

type TDuplicate = {
  className?: string;
  style?: CSSProperties;
};

export const Duplicate = ({ className, style }: TDuplicate) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M6.53846 1H1.92308C1.41328 1 1 1.41328 1 1.92308V9.76923M8.38462 3.30769V6.07692H11.1538M8.84615 3.30769H4.23077C3.72097 3.30769 3.30769 3.72097 3.30769 4.23077V12.0769C3.30769 12.5867 3.72097 13 4.23077 13H10.2308C10.7406 13 11.1538 12.5867 11.1538 12.0769V5.61538L8.84615 3.30769Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
