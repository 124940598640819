import axios from "axios";
import { johnyWalkerAxios } from "./johnyWalkerAxios";

import type { AxiosResponse } from "axios";
import type { CSSProperties } from "react";
import type { Component } from "../../types/types";

export interface ITextField {
  figmaName: string;
  isAutoLayout: boolean;
  library: string;
  muiName: string;
  othersStyles: Record<string, CSSProperties>;
  variables: {
    props: {
      inputText: string;
      labelText: string;
      placeholderText: string;
      showSupportingText: boolean;
      supportingText: string;
    };
    styles: {
      input: CSSProperties;
      label: CSSProperties;
      supportingText: CSSProperties;
      sx: CSSProperties;
    };
  };
  variant: string;
}

export type TFigmaComponents = ITextField;

export type TAxiosGetFigmaFile = Component;

export class Figma {
  static getCallbackUrl() {
    return "/api/auth/figma/login";
  }

  static async getAccessToken(code: string) {
    return axios.get<string>(`/api/auth/figma/callback?code=${code}`);
  }

  static async getFigmaFile(url: string): Promise<AxiosResponse<TAxiosGetFigmaFile>> {
    //checks if the token should be refreshed
    axios.get("/api/auth/verify");

    return await johnyWalkerAxios
      .post<TAxiosGetFigmaFile>(
        "/api/figma/johny-walker",
        { data: { url } }
        // {
        //   headers: {
        //     Authorization: `Bearer ${userServiceStorage?.token.access_token ?? ""}`,
        //   },
        // }
      )
      .then((res) => res);
  }
}
