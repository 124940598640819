import { useEffect, useState } from "react";

import { cardData } from "./data/data";
import { useProducts } from "../../../hooks/useProducts/useProducts";

import { routerInstance } from "../../../machines/router/router.instance";
import { productsInstance } from "../../../machines/products/products.instance";

import { OnboardingLayout } from "../../molecules/OnboardingLayout";

import { SitePlanCard } from "../../atoms/SitePlanCard";

import { TSelectPlanSite } from "./SelectPlanSite.types";

import "./SelectPlanSite.css";

export const SelectPlanSite: TSelectPlanSite = () => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const { products, totalPrice } = useProducts();

  useEffect(() => {
    const selectedProduct = products.find((product) => product.productType === "sitePlan");

    if (selectedProduct) {
      const cardIndex = cardData.findIndex((item) => item.priceId === selectedProduct.priceId);
      setSelectedCardIndex(cardIndex);
    }
  }, [products]);

  const onCardClickHandler = (index: number) => {
    const { priceId, type, productType, price, metadata } = cardData[index];
    const payload = { priceId, type, productType, price, metadata };

    if (index === selectedCardIndex) {
      productsInstance.send({ type: "UNSELECT_PLAN", payload });
      setSelectedCardIndex(null);

      return;
    }

    productsInstance.send({ type: "SELECT_SITE_PLAN", payload });
    setSelectedCardIndex(index);
  };

  return (
    <>
      <OnboardingLayout
        seoTitle="Select a site plan - Codejet"
        breadcrumbStage={2}
        header="Select a site plan"
        subheader="Our site plans offer an easy-to-use website editor, affordable hosting, customer lead management, and tools to help you attract new clients."
        selectedCardIndex={selectedCardIndex}
        onContinue={() => routerInstance.send("SELECT_MARKETING_PLAN")}
        onBack={() => routerInstance.send("PICK_TEMPLATE")}
        price={Math.round(totalPrice * 100) / 100}
      >
        <div className="select-plan-site__container">
          {/* <div className="select-plan-site__annual-discount">
            <span className="select-plan-site__annual-discount__content">Annual discount</span>
            <Switcher />
          </div> */}
          <div className="select-plan-site__plan-container">
            {cardData.map(
              (
                {
                  itemsDefinitions,
                  header,
                  subheader,
                  price,
                  priceDescription,
                  isFirst,
                  isLast,
                  isBestSeller,
                  withoutBorder,
                  icon,
                },
                index
              ) => {
                return (
                  <SitePlanCard
                    key={index}
                    icon={icon}
                    itemsDefinitions={itemsDefinitions}
                    header={header}
                    subheader={subheader}
                    isBestSeller={isBestSeller}
                    price={price}
                    discount={undefined}
                    priceDescription={priceDescription}
                    isFirst={isFirst}
                    isLast={isLast}
                    withoutBorder={withoutBorder}
                    selected={selectedCardIndex === index}
                    onClick={() => onCardClickHandler(index)}
                  />
                );
              }
            )}
          </div>
        </div>
      </OnboardingLayout>
    </>
  );
};
