import { type CSSProperties } from "react";

type TSelection = {
  className?: string;
  style?: CSSProperties;
};

export const Selection = ({ className, style }: TSelection) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M5.00006 13C5.00006 13.5523 5.44779 14 6.00008 14H13.0002C13.5525 14 14.0002 13.5523 14.0002 13V5.99984C14.0002 5.44755 13.5525 4.99982 13.0002 4.99982M9.00014 4.99982H6.00008C5.44779 4.99982 5.00006 5.44755 5.00006 5.99984V8.9999M2 3.00002C2 2.44773 2.44773 2 3.00002 2H10.0002C10.5525 2 11.0002 2.44773 11.0002 3.00002V10.0002C11.0002 10.5525 10.5525 11.0001 10.0002 11.0001H3.00002C2.44773 11.0001 2 10.5525 2 10.0002V3.00002Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
