import { type CSSProperties } from "react";

type TBorderRightWidth = {
  className?: string;
  style?: CSSProperties;
};

export const BorderRightWidth = ({ className, style }: TBorderRightWidth) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4 14H3C2.7348 14 2.4804 13.8946 2.2929 13.7071C2.1054 13.5196 2 13.2652 2 13V12M9.5 14L6.5 14M2 9.5L2 6.5M14 14L14 2M4 2H3C2.7348 2 2.4804 2.1054 2.2929 2.2929C2.1054 2.4804 2 2.7348 2 3V4M9.5 2L6.5 2"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
