import { useEffect, useState } from "react";

import { editorService } from "../../../pages/Alpha_O/modules/editor";
import { projectsService } from "../../../../machines/projects/projects.instance";
import { ColorInput } from "../../atoms/ColorInput/ColorInput";
import { Accordion } from "../../atoms/Accordion/Accordion";
import { NestedColorCategory } from "../NestedColorCategory/NestedColorCategory";

import { calculateUpdatedColors, getCategoriesFromColors } from "./utils";

import { TColorMode, Categories } from "./ColorMode.types";

import "./ColorMode.css";

export const ColorMode: TColorMode = ({ mode, currentMode, selector, setLocalColors }) => {
  const [globalVariables, setGlobalVariables] = useState<Record<string, any> | undefined>(undefined);
  const editor = editorService.getEditor();
  const isGlobal = globalVariables !== undefined;
  const categories: Categories = globalVariables ? getCategoriesFromColors(globalVariables) : {};
  const colorReferences = projectsService.getActiveProject()?.colorReferencesToMix
    ? JSON.parse(projectsService.getActiveProject()?.colorReferencesToMix || "")
    : {};
  const colorReferenceKeys = Object.keys(colorReferences);

  useEffect(() => {
    if (!editor) return;
    const rootStyle = editor.Css?.getRule(selector)?.attributes.style;
    setGlobalVariables(rootStyle);
  }, []);

  useEffect(() => {
    if (!editor) return;
    if (globalVariables && mode === currentMode && colorReferenceKeys.length !== 0) {
      let newLocalColors = {};
      // set up local colors for every reference color
      colorReferenceKeys.forEach((colorKey) => {
        const colorValue = globalVariables[colorKey];
        newLocalColors = {
          ...newLocalColors,
          ...calculateUpdatedColors({ colorName: colorKey, colorValue, colorReferences, editor }),
        };
      });
      // get current local colors
      const localStyleVariables = editor.Css.getRule(".local-styles-variables")?.attributes.style;
      const colors =
        editor.Css.setRule(".local-styles-variables", { ...localStyleVariables, ...newLocalColors })?.attributes
          .style || {};
      setLocalColors(colors);
    }
  }, [currentMode, isGlobal]);

  const handleChange = async (name: string, value: string) => {
    if (!globalVariables || !editor) return;
    if (globalVariables[name] === value) return;

    const newRule = editor?.Css.setRule(selector, { ...globalVariables, [name]: value });

    // update local-styles-variables
    if (colorReferenceKeys.includes(name)) {
      const localColors = editor.Css.getRule(".local-styles-variables")?.attributes.style;
      const calculatedLocalColors = calculateUpdatedColors({
        colorName: name,
        colorValue: value,
        colorReferences,
        editor,
      });
      const newLocalColors =
        editor?.Css.setRule(".local-styles-variables", { ...localColors, ...calculatedLocalColors })?.attributes
          .style || {};
      setLocalColors(newLocalColors);
    }

    await editor?.store();
    setGlobalVariables(newRule.attributes.style);
  };

  if (!globalVariables || globalVariables.length === 0) return null;

  return (
    <div className={`color-mode${currentMode === mode ? " color-mode--active" : ""}`}>
      <ul className="color-mode-wrapper">
        {Object.keys(categories).map((category) => {
          return (
            <Accordion label={category} key={category} variant>
              <ul className="color-mode__list">
                {Object.keys(categories[category].categories).length !== 0 ? (
                  <li className="color-mode__list--subcategory">
                    {Object.keys(categories[category].categories).map((subcategory) => {
                      return (
                        <NestedColorCategory
                          key={subcategory}
                          categoryName={subcategory}
                          {...categories[category].categories[subcategory]}
                          handleChange={handleChange}
                        />
                      );
                    })}
                  </li>
                ) : null}
                {categories[category].colors.map(({ colorName, colorValue, colorLabel }) => {
                  return (
                    <li className="color-mode__variable" key={colorName}>
                      <label className="color-mode__label">{colorLabel}</label>
                      <ColorInput name={colorName} value={colorValue} onChange={handleChange} />
                    </li>
                  );
                })}
              </ul>
            </Accordion>
          );
        })}
      </ul>
    </div>
  );
};
