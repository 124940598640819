import { useState, useEffect, useRef } from "react";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick/useOutsideClick";

import { More } from "../../../../../../../assets/svg/More";

import { type TTagComponent } from "./TagComponent.types";

import "./TagComponent.css";

export const TagComponent = ({ label, remove, duplicate, disable, rename, isDisabled, secondary }: TTagComponent) => {
  const tagRef = useRef<HTMLDivElement>(null);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [localLabel, setLocalLabel] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const haveActions = remove || disable || duplicate || remove;
  const dropdownRef = useOutsideClick<HTMLUListElement>(() => setIsMoreOpen(false));

  const handleKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };
  const showRenameInput = () => {
    setIsRenameOpen(true);
    setIsMoreOpen(false);
  };
  const handleDuplicateClick = () => {
    if (duplicate) {
      duplicate();
    }
    setIsMoreOpen(false);
  };
  const handleDeleteClick = () => {
    if (remove) {
      remove();
    }
    setIsMoreOpen(false);
  };
  const handleDisableClick = () => {
    if (disable) {
      disable();
    }
    setIsMoreOpen(false);
  };

  useEffect(() => {
    if (tagRef.current) {
      const { top, left, height, width } = tagRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: top + height + 2,
        left: left + width,
      });
    }
  }, [tagRef.current]);

  return (
    <div className={`tag${isDisabled ? " tag--disabled" : ""}${secondary ? " tag--secondary" : ""}`} ref={tagRef}>
      <div className="tag__label-wrapper">
        {isRenameOpen ? (
          <input
            type="text"
            className="tag__rename-input"
            autoFocus
            value={localLabel}
            onBlur={(e) => {
              if (rename && e.target.value.trim() !== "") {
                rename(e.target.value);
              }
              setIsRenameOpen(false);
            }}
            onChange={(e) => {
              setLocalLabel(e.target.value);
            }}
            onKeyDown={handleKeyEnter}
          />
        ) : (
          <span className="tag__label">{label}</span>
        )}
      </div>
      {haveActions && (
        <button aria-label="Remove selector" className="tag__more" onClick={() => setIsMoreOpen(true)}>
          <More />
        </button>
      )}
      {isMoreOpen && (
        <ul
          className="tag__more-dropdown"
          ref={dropdownRef}
          style={{
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            transform: "translate(-100%, 0)",
          }}
        >
          <li className="tag__more-option">
            <button onClick={showRenameInput}>Rename</button>
          </li>
          <li className="tag__more-option">
            <button onClick={handleDuplicateClick}>Duplicate</button>
          </li>
          <li className="tag__more-option">
            <button onClick={handleDeleteClick}>Delete</button>
          </li>
          {disable ? (
            <li className="tag__more-option">
              <button onClick={handleDisableClick}>{isDisabled ? "Enable" : "Disable"}</button>
            </li>
          ) : null}
        </ul>
      )}
    </div>
  );
};
