import type { SVGProps } from "react";

export const DoneCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="2.05176" width="52" height="52" rx="26" fill="#DCFCE7" />
    <rect x="2" y="2.05176" width="52" height="52" rx="26" stroke="white" strokeWidth="4" />
    <path
      d="M36 22.0518L25 33.0518L20 28.0518"
      stroke="#16A34A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
