import { WeirdIconWrapper } from "../../../atoms/WeirdIconWrapper";

import { AppTour } from "../../../../../assets/svg/app-tour";

import type { TTutorialCard } from "./TutorialCard.types";

import "./TutorialCard.css";

export const TutorialCard: TTutorialCard = ({ icon: iconProp, title, subtitle, className, onClick }) => {
  const baseClass = "tutorial-card";
  const classes = [baseClass, className].filter(Boolean).join(" ");
  const icon = iconProp ? (
    iconProp
  ) : (
    <WeirdIconWrapper>
      <AppTour />
    </WeirdIconWrapper>
  );

  if (typeof onClick !== "function") {
    return (
      <a className={classes} tabIndex={0} href={onClick} target="_blank" rel="noreferrer noopener">
        {icon}
        <div className="tutorial-card__content">
          <span className="tutorial-card__title">{title}</span>
          <span className="tutorial-card__subtitle">{subtitle}</span>
        </div>
      </a>
    );
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <div className={classes} tabIndex={0} role="button" onClick={onClick} onKeyDown={handleKeyDown}>
      {icon}
      <div className="tutorial-card__content">
        <span className="tutorial-card__title">{title}</span>
        <span className="tutorial-card__subtitle">{subtitle}</span>
      </div>
    </div>
  );
};
