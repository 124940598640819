import { ArrowLeft } from "../../../../assets/svg/arrow_left";

import type { TBackButton } from "./BackButton.types";

import "./BackButton.css";

export const BackButton: TBackButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className="code-jet__back-button">
      <ArrowLeft />
      <span>Back</span>
    </div>
  );
};
