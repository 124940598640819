import { Button } from "../../../../../../../AlphaO/atoms/Button";
import { Close } from "../../../../../../../../assets/svg/Close";

import { TPropertiesPopup } from "./PropertiesPopup.types";

import "./PropertiesPopup.css";

export const PropertiesPopup: TPropertiesPopup = ({ closePopup, sx, children, title = "Properties" }) => {
  return (
    <div className="option-popup" style={sx}>
      <header className="option-popup__header">
        <h3>{title}</h3>
        <Button type="icon" iconStart={<Close />} onClick={closePopup} />
      </header>
      <div className="option-popup__content">{children}</div>
    </div>
  );
};
