import { useEffect, useRef, useState } from "react";
import { Selector } from "grapesjs";

import { selectorManagerInstance } from "./selector-manager.service";
import { styleManagerService } from "../styles-manager/StylesManager.service";

import { Button } from "../../../../AlphaO/atoms/Button";
import { TagComponent } from "./components/TagComponent";
import { Selection } from "../../../../../assets/svg/Selection";
import { ChevronDown } from "../../../../../../assets/svg/chevron_down";
import { Add } from "../../../../../assets/svg/Add";
import { Tag } from "../../../../../assets/svg/Tag";
import { TargetSquare } from "../../../../../assets/svg/TargetSquare";

import "./SelectorManagerMenu.css";

const focusActiveComponents = ["codejet_button-", "codejet_pagination_button-"];

export const SelectorManagerMenu = () => {
  const selectorManagerRef = useRef<HTMLDivElement>(null);
  const [_, updateSelectorManagerState] = useState(selectorManagerInstance.state);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const selectorManager = selectorManagerInstance.selectorManager;
  const componentFirst = selectorManager?.getConfig().componentFirst;
  const selectors = selectorManager?.getSelected();
  const currentState = selectorManager?.getState() === "" ? "State" : selectorManager?.getState();
  const component = styleManagerService.state.component;
  const componentId = component?.getId() || "";
  const activeSelectorsCount = selectors ? selectors.filter((selector) => !selector.attributes.active).length : 0;
  const componentType = component?.get("type") || component?.get("tagName") || componentId;
  const isFigmaButton = selectors?.find((selector) => {
    const label = selector.getLabel();
    return focusActiveComponents.some((componentClassName) => label.startsWith(componentClassName));
  });
  const isActiveFigmaButton = (name: string) => isFigmaButton && name === "active";
  const isNotButtonAndFocusActive = (name: string) => !isFigmaButton && name === "focus:active";
  const shouldEscState = (name: string) => isActiveFigmaButton(name) || isNotButtonAndFocusActive(name);

  const availableStates =
    selectorManager
      ?.getStates()
      .map((state) => {
        if (shouldEscState(state.getName())) return null;
        return {
          id: state.getName(),
          name: state.getName(),
          label: state.getLabel(),
        };
      })
      .filter((el) => el !== null) || [];

  useEffect(() => {
    selectorManagerInstance.subscribe((state) => updateSelectorManagerState(state));

    return () => {
      selectorManagerInstance.unsubscribe(updateSelectorManagerState);
    };
  }, []);

  const selectClassSelector = () => {
    if (selectorManager && componentFirst) {
      selectorManagerInstance.setComponents(false);
    }
  };

  const selectComponentSelector = () => {
    if (selectorManager && !componentFirst) {
      selectorManagerInstance.setComponents(true);
    }
  };

  const changeState = (state: string) => {
    if (state === "State") {
      selectorManager?.setState("");
    } else {
      selectorManager?.setState(state);
    }
  };

  const handleOpenStateDropdown = () => {
    if (availableStates && availableStates.length > 0 && selectorManagerRef.current) {
      const stateWithDefault = [{ id: "State", name: "State", label: "State" }, ...availableStates].filter(
        (state) => state.name !== currentState
      );
      const target =
        selectorManagerRef.current?.querySelector(".selector-manager__state") ?? selectorManagerRef.current;
      const coordinates = target.getBoundingClientRect();
      if (!coordinates) return;

      styleManagerService.openUnitDropdown(
        stateWithDefault,
        selectorManager?.getState() || "",
        coordinates,
        changeState
      );
    }
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  return (
    <div className="selector-manager" ref={selectorManagerRef}>
      <header className="selector-manager__header">
        <h2 className="selector-manager__heading">
          <span>Selection</span>
          <Selection />
        </h2>
        <button
          className={`selector-manager__state${currentState === "" ? " selector-manager__state--default" : ""}`}
          onClick={handleOpenStateDropdown}
        >
          {currentState || "State"}
          <ChevronDown />
        </button>
      </header>
      <div className="selector-manager__content">
        <div className="selector-manager__selectors-wrapper">
          <Button
            type="sixth"
            iconStart={<Tag />}
            className={componentFirst ? "" : "on"}
            onClick={selectClassSelector}
          />
          {!isInputVisible ? (
            <>
              <ul className="selector-manager__selectors">
                {selectors?.map((selected: Selector, idx) => {
                  const label = selected.getLabel();
                  const isDisabled: boolean = !selected.getActive();
                  const hideDisable = activeSelectorsCount === 1 && !isDisabled;

                  return (
                    <TagComponent
                      key={selected.getLabel() + idx}
                      label={label}
                      isDisabled={isDisabled}
                      remove={() => {
                        selectorManager?.removeSelected(selected);
                      }}
                      rename={(newName: string) => {
                        selectorManager?.rename(selected, newName);
                        selectorManagerInstance.notifySubscribers();
                      }}
                      disable={
                        hideDisable
                          ? undefined
                          : () => {
                              selected.setActive(!selected.getActive());
                              selectorManagerInstance.notifySubscribers();
                            }
                      }
                      duplicate={() => {
                        const cloned = selected.clone();
                        selectorManager?.addSelected(`${cloned.attributes.name} cloned`);
                        selectorManagerInstance.notifySubscribers();
                      }}
                    />
                  );
                })}
                <li style={{ marginLeft: "auto" }}>
                  <Button type="fourth" iconStart={<Add />} onClick={() => setIsInputVisible(true)} />
                </li>
              </ul>
            </>
          ) : (
            <div className="selector-manager__selectors">
              <input
                type="text"
                autoFocus
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    selectorManagerInstance.selectorManager?.addSelected(e.target.value);
                    e.target.value = "";
                  }
                  setIsInputVisible(false);
                }}
                onKeyDown={handleEnterPress}
                className="selector-manager__add-input"
              />
            </div>
          )}
        </div>
      </div>
      <div className="selector-manager__content">
        <div className="selector-manager__selectors-wrapper">
          <Button
            type="sixth"
            iconStart={<TargetSquare />}
            className={componentFirst ? "on" : ""}
            onClick={selectComponentSelector}
          />
          <div className="selector-manager__selectors">
            <TagComponent label={componentType} secondary />
          </div>
        </div>
      </div>
    </div>
  );
};
