import { useEffect, useState } from "react";
import { Trait } from "grapesjs";

import { useDebounce } from "../../../../../../hooks/useDebounce/useDebounce";

export const Text = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState(trait.getValue());
  const debouncedValue = useDebounce(value, 500);
  const placeholder = trait.get("placeholder") || "";

  useEffect(() => {
    trait.setValue(value);
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <li key={trait.cid} className={`trait-manager__trait`}>
      <label className="trait-manager__trait-label">{trait.getLabel()}</label>
      <input className="" onChange={handleChange} value={value} placeholder={placeholder} />
    </li>
  );
};
