import { type CSSProperties } from "react";

type TImage = {
  className?: string;
  style?: CSSProperties;
};

export const Image = ({ className, style }: TImage) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10.2061 14C9.9089 12.4169 9.05888 10.9909 7.80773 9.97648C6.55658 8.96203 4.98569 8.42512 3.37536 8.46153C2.91455 8.4603 2.45467 8.50286 2.00192 8.58863M13.9907 10.6861C13.2473 10.4351 12.4678 10.3072 11.6831 10.3077C10.6898 10.3055 9.70654 10.5066 8.79382 10.8985M2.92308 14H13.0769C13.5867 14 14 13.5867 14 13.0769V2.92308C14 2.41328 13.5867 2 13.0769 2H2.92308C2.41328 2 2 2.41328 2 2.92308V13.0769C2 13.5867 2.41328 14 2.92308 14ZM9.98999 7.25C10.8184 7.25 11.49 6.57843 11.49 5.75C11.49 4.92157 10.8184 4.25 9.98999 4.25C9.16156 4.25 8.48999 4.92157 8.48999 5.75C8.48999 6.57843 9.16156 7.25 9.98999 7.25Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
