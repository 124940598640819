import { useEffect, useRef, useState } from "react";
import { Trait } from "grapesjs";

import { pagesService } from "../../pages/pages.service";
import { editorService } from "../../editor";
import { styleManagerService } from "../../styles-manager/StylesManager.service";

import { SelectButton } from "../../styles-manager/components/SelectButton";
import { Switch } from "../../../../../AlphaO/atoms/Switch";
import { Email } from "../../../../../../assets/svg/Email";
import { Phone } from "../../../../../../assets/svg/Phone";
import { Link } from "../../../../../../assets/svg/Link";
import { TargetSquare } from "../../../../../../assets/svg/TargetSquare";
import { PageLink } from "../../../../../../assets/svg/PageLink";
import { Sections2 } from "../../../../../../assets/svg/Sections2";
import { Radio } from "./partials/Radio";

import { useDebounce } from "../../../../../../hooks/useDebounce/useDebounce";

const icons: Record<string, JSX.Element> = {
  Link: <Link />,
  Email: <Email />,
  Phone: <Phone />,
  Page: <PageLink />,
  Section: <Sections2 />,
};

const getHrefType = (href: string) => {
  if (href.startsWith("mailto:")) {
    return "Email";
  }
  if (href.startsWith("tel:")) {
    return "Phone";
  }

  if (href.startsWith("pageId#")) {
    return "Page";
  }

  if (href.startsWith("#")) {
    return "Section";
  }

  return "Link";
};

const hrefTypes = [
  { id: "Link", name: "Link", value: "Link" },
  { id: "Page", name: "Page", value: "Page" },
  { id: "Section", name: "Section", value: "Section" },
  { id: "Email", name: "Email", value: "Email" },
  { id: "Phone", name: "Phone", value: "Phone" },
] as const;

const hrefOptionsWithIcons = hrefTypes.map((option) => {
  return { ...option, icon: icons[option.name] };
});

type HrefType = (typeof hrefTypes)[number]["value"];

export const Href = ({ trait }: { trait: Trait }) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [pages, subscribePagesService] = useState(pagesService.state);
  const [localValue, setLocalValue] = useState(trait.getValue());
  const [emailValue, setEmailValue] = useState({
    email: "",
    subject: "",
  });
  const [type, setType] = useState("Link");
  const [showSelect, setShowSelect] = useState(false);
  const options = hrefTypes;
  const debouncedLocalValue = useDebounce(localValue, 1000);
  const debouncedEmailValue = useDebounce(emailValue, 1000);

  const routing =
    pages?.map((page) => {
      return { id: page.getId(), name: page.getName(), value: page.getId(), label: page.getName() };
    }) || [];
  // in here we get our div with class desktop, variable classes
  const mainComponent = editorService.getEditor()?.getComponents().at(0);
  // in here we get all "sections" in our main component
  const sections =
    mainComponent?.components().map((component) => {
      return {
        id: component.getId(),
        name: component.getId(),
        value: `#${component.getId()}`,
        label: `#${component.getId()}`,
      };
    }) || [];

  useEffect(() => {
    pagesService.subscribe(subscribePagesService);

    const newType = getHrefType(localValue);
    setType(newType);

    if (newType === "Phone") {
      setLocalValue(localValue.replace("tel:", ""));
    }

    if (newType === "Email") {
      const [emailPart, subjectPart] = localValue.replace("mailto:", "").split("?");
      const email = emailPart;
      const subject = subjectPart ? subjectPart.replace("subject=", "") : "";
      setEmailValue({ email, subject });
    }

    if (newType === "Page") {
      setLocalValue(localValue.replace("pageId#", ""));
    }

    if (newType === "Section") {
      const isOneOfOptions = sections.find(
        (section) => section.value === localValue.replace("#", "") || section.value === localValue
      )
        ? true
        : false;
      setLocalValue(localValue);
      setShowSelect(isOneOfOptions);
    }

    return () => {
      pagesService.unsubscribe(subscribePagesService);
    };
  }, []);

  useEffect(() => {
    if (type === "Link") {
      trait.setValue(localValue);
    }
    if (type === "Phone") {
      trait.setValue(`tel:${localValue}`);
    }
    if (type === "Section") {
      if (localValue.startsWith("#")) {
        trait.setValue(localValue);
      } else {
        trait.setValue(`#${localValue}`);
      }
    }
    if (type === "Page") {
      trait.setValue(`pageId#${localValue}`);
    }
  }, [debouncedLocalValue]);

  useEffect(() => {
    if (type === "Email") {
      const link = `mailto:${emailValue.email}?subject=${emailValue.subject}`;
      trait.setValue(link);
    }
  }, [debouncedEmailValue]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;
    const name = ev.target.name;
    if (type === "Link") {
      setLocalValue(newValue);
      return;
    }

    if (type === "Phone") {
      setLocalValue(`${newValue}`);
      return;
    }

    if (type === "Email") {
      setEmailValue((prev) => {
        return { ...prev, [name]: newValue };
      });
    }

    if (type === "Section") {
      setLocalValue(`${newValue.startsWith("#") ? newValue : `#${newValue}`}`);
    }
  };

  const handleSelectChange = (newValue: string) => {
    setLocalValue(newValue);
  };

  const handleChangeType = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value as HrefType;

    setType(newValue);
    setLocalValue("");
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (routing.length > 0) {
      const target = selectRef.current?.querySelector(".select-button") || selectRef.current;
      const coordinates = target?.getBoundingClientRect();
      if (!coordinates) return;
      const options = type === "Page" ? routing : sections;

      styleManagerService.openUnitDropdown(options, localValue, coordinates, handleSelectChange);
    }
  };

  // const handleComponentSelect = async () => {
  //   const editor = editorService.getEditor();
  //   if (!editor) return;
  //   const canvasDoc = editor.Canvas.getDocument();
  //   console.log("Canvas doc", canvasDoc);

  //   const canvasClickHandler = (event: MouseEvent) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     const selectedComponent = editor.getSelected();
  //     const components = editor.getWrapper()?.find("*");
  //     const component = components.find((comp) => comp.view.el === event.target);
  //     if (component) {
  //       console.log("Component selected", component);
  //       canvasDoc.removeEventListener("mousedown", canvasClickHandler);
  //       editor.select(selectedComponent);
  //     } else {
  //       console.log("Component not selected");
  //     }
  //   };
  //   canvasDoc.addEventListener("mousedown", canvasClickHandler);
  // };

  return (
    <li
      key={trait.cid}
      className="trait-manager__trait trait-href"
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <Radio
        options={hrefOptionsWithIcons}
        currentOption={hrefOptionsWithIcons.find((option) => option.value === type) || hrefOptionsWithIcons[0]}
        onChange={handleChangeType}
      />
      {(type === "Link" || type === "Phone") && (
        <div className="trait-href__wrapper">
          <label className="trait-manager__trait-label">{type === "Link" ? "Url" : "Phone"}</label>
          <input
            value={localValue}
            placeholder={type === "Link" ? "e.g. https://google.com" : "e.g. +55123456789"}
            onChange={handleChange}
          />
        </div>
      )}
      {type === "Email" && (
        <div className="trait-href__email">
          <div>
            <label className="trait-manager__trait-label">Email</label>
            <input name="email" placeholder="eg. mail@gmail.com" value={emailValue.email} onChange={handleChange} />
          </div>
          <div>
            <label className="trait-manager__trait-label">Subject</label>
            <input name="subject" placeholder="eg. My Subject" value={emailValue.subject} onChange={handleChange} />
          </div>
        </div>
      )}
      {type === "Page" && (
        <div ref={selectRef} className="trait-href__wrapper">
          <label className="trait-manager__trait-label">Page</label>
          <SelectButton
            currentOption={localValue}
            options={routing}
            onClick={handleUnitDropdown}
            placeholder="Select page"
          />
        </div>
      )}
      {type === "Section" && (
        <>
          {showSelect ? (
            <div ref={selectRef} className="trait-href__wrapper">
              <label className="trait-manager__trait-label">Element ID</label>
              <SelectButton
                currentOption={localValue}
                options={sections}
                onClick={handleUnitDropdown}
                placeholder="Select section"
              />
            </div>
          ) : (
            <div className="trait-href__wrapper">
              <label className="trait-manager__trait-label">Element ID</label>
              <input value={localValue} placeholder="e.g. #section" onChange={handleChange} />
            </div>
          )}
          {/* <button onClick={handleComponentSelect}>
            <TargetSquare />
          </button> */}
          <div
            className="trait-href__wrapper"
            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <label className="trait-manager__trait-label">Show Element List</label>
            <Switch
              isActive={showSelect}
              onChange={() => {
                setShowSelect((prev) => !prev);
              }}
            />
          </div>
        </>
      )}
    </li>
  );
};
