import { ButtonNew } from "../ButtonNew";

import type { TNewTemplateCard } from "./NewTemplateCard.types";

import "./NewTemplateCard.css";

export const NewTemplateCard: TNewTemplateCard = ({ name, thumbnail, onUseTemplate, onPreview }) => {
  return (
    <>
      <div className="template-card" tabIndex={0} role="button">
        <div className="template-card__thumbnail">
          <img className="template-card__thumbnail-img" src={thumbnail} loading="lazy" alt="template thumbnail" />
          <div className="template-card__dark-background"></div>
          <div className="template-card__hover-buttons">
            <ButtonNew className="template-card__thumbnail-button" orange onClick={onUseTemplate}>
              Use this template
            </ButtonNew>
            <ButtonNew className="template-card__thumbnail-button" onClick={onPreview}>
              Preview
            </ButtonNew>
          </div>
        </div>
        <div className="template-card__info">
          <div className="template-card__typography">
            <div className="template-card__title">{name}</div>
            <div className="template-card__price">Free</div>
          </div>
        </div>
      </div>
    </>
  );
};
