import { TPropertiesPopupField } from "./PropertiesPopupField.types";

export const PropertiesPopupField: TPropertiesPopupField = ({ id, value, handleChangeValue, title = "Value" }) => {
  return (
    <div className="option-popup__field">
      <label htmlFor={`option-value-${id}`} className="trait-manager__trait-label">
        {title}
      </label>
      <input id={`option-value-${id}`} value={value} onChange={handleChangeValue} />
    </div>
  );
};
