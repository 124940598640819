import { type CSSProperties } from "react";

type TJustifyEvenly = {
  className?: string;
  style?: CSSProperties;
};

export const JustifyEvenly = ({ className, style }: TJustifyEvenly) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2.25 14V2M8.25 14V2M14.25 14V2M6 3.5H4.5C4.08579 3.5 3.75 3.83579 3.75 4.25V11.75C3.75 12.1642 4.08579 12.5 4.5 12.5H6C6.41423 12.5 6.75 12.1642 6.75 11.75V4.25C6.75 3.83579 6.41423 3.5 6 3.5ZM12 3.5H10.5C10.0858 3.5 9.75 3.83579 9.75 4.25V11.75C9.75 12.1642 10.0858 12.5 10.5 12.5H12C12.4142 12.5 12.75 12.1642 12.75 11.75V4.25C12.75 3.83579 12.4142 3.5 12 3.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
