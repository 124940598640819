import { EditorService } from "../editor/editor.service.ts";
import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";
import { TraitServiceState } from "./index.ts";
import { Trait } from "grapesjs";

export class TraitService extends Subscriber<TraitServiceState> {
  state = {
    traits: [],
  };
  editorService: EditorService;
  isInitialized = false;
  constructor(editor: EditorService) {
    super();
    this.editorService = editor;
  }

  update(state: TraitServiceState) {
    this.state = state;
  }

  getTraitsFromSelectedComponent(): Trait[] {
    const editor = this.editorService.getEditor();

    if (!editor) {
      return [];
    }

    const component = editor.getSelected();

    if (component) {
      //@ts-ignore
      return component.get("traits") || [];
    }

    return [];
  }
}
