type OptionsProp = Option[];

type Option = {
  id: string;
  value: string;
  name?: string;
  icon?: JSX.Element;
};

export const Radio = ({
  options,
  currentOption,
  onChange,
}: {
  options: OptionsProp;
  currentOption: Option;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <ul className="trait-href__options">
      {options.map((option) => {
        const isCurrentValue = currentOption.value === option.value;
        const optionName = option.name ?? option.value;
        return (
          <li key={option.id} className={`trait-href__option${isCurrentValue ? " trait-href__option--selected" : ""}`}>
            <label htmlFor={`${option.name}-${option.id}`}>
              <input
                type="radio"
                name={option.id}
                id={`${option.name}-${option.id}`}
                value={option.value as string}
                checked={isCurrentValue}
                onChange={onChange}
                className="vs-hidden"
              />
              {option.icon ?? <span>{optionName}</span>}
            </label>
          </li>
        );
      })}
    </ul>
  );
};
