import { useEffect, useState } from "react";
import { Accordion } from "../../atoms/Accordion/Accordion";

import { editorService } from "../../../pages/Alpha_O/modules/editor";
import { Hint } from "../../../../assets/svg/Hint";
import { Select } from "../../../atoms/Select";
import { FontMode } from "../FontMode/FontMode";
import { ComingSoon } from "../../../atoms/CoomingSoon";
import { getSelectorFromActiveProject } from "./helpers";

import { TFontVariables, Selector } from "./FontVarIables.types";

import "./FontVariables.css";
import { LocalFontVariables } from "../LocalFontVariables/LocalFontVariables";

export const FontVariables: TFontVariables = () => {
  const [currentMode, setCurrentMode] = useState<string>("");
  const [activeTab, setActiveTab] = useState<"Global" | "Local">("Global");
  const [selectors, setSelectors] = useState<Selector[]>([]);
  const editorCSS = editorService.getEditor()?.getCss();
  const isCSSReady = editorCSS && editorCSS.length > 0;

  useEffect(() => {
    if (editorCSS) {
      const selectors = getSelectorFromActiveProject(editorCSS);
      if (selectors && selectors.length !== 0) {
        setCurrentMode(selectors[0].mode);
        setSelectors(selectors);
      }
    }
  }, [isCSSReady]);

  if (selectors.length === 0 || !editorCSS?.includes(selectors[0].selector)) return null;

  return (
    <div className="font-variables">
      {/* <Accordion label="Font variables" className="font-variables__heading"> */}
      <div className="font-variables__content">
        <div className="font-variables__tabs">
          <button
            className={`font-variables__tab${activeTab === "Global" ? " font-variables__tab--active" : ""}`}
            onClick={() => setActiveTab("Global")}
          >
            Global variables
          </button>
          <button
            className={`font-variables__tab${activeTab === "Local" ? " font-variables__tab--active" : ""}`}
            onClick={() => setActiveTab("Local")}
          >
            Local variables
          </button>
        </div>
        <div className={`font-variables__tabpanel${activeTab === "Global" ? " font-variables__tabpanel--active" : ""}`}>
          {selectors.length > 1 ? (
            <div className="font-variables__theme-selector">
              <span className="font-variables__mode-label">
                <Hint />
                <span>Mode</span>
              </span>

              <Select
                defaultValue={currentMode}
                options={selectors.map(({ mode }) => ({ label: mode }))}
                onChange={(selected) => setCurrentMode(selected)}
              />
            </div>
          ) : null}
          {selectors.map(({ selector, mode }) => {
            return <FontMode key={selector} selector={selector} isActiveMode={mode === currentMode} />;
          })}
        </div>
        <div className={`rootStyle__tabpanel${activeTab !== "Global" ? "rootStyle__tabpanel--active" : ""}`}>
          <LocalFontVariables rootVariables={selectors.find((selector) => selector.mode === currentMode) || null} />
        </div>
      </div>
      {/* </Accordion> */}
    </div>
  );
};
