import type { TLoadingDots } from "./LoadingDots.types";
import "./LoadingDots.css";

export const LoadingDots: TLoadingDots = () => {
  return (
    <span className="loading-dots">
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};
