import { useState, useEffect } from "react";

export const usePositionInScreen = (
  ref: React.RefObject<HTMLDivElement>,
  position: { x: number; y: number },
  setIsPositionCalculated: (state: boolean) => void,
  offsetY = 0,
  bellowWindowYOffset = 0,
  showAbove = true
) => {
  const [coordinates, setCoordinates] = useState<any>({ top: 0, left: 0 });

  useEffect(() => {
    if (ref.current) {
      const refPosition = ref.current.getBoundingClientRect();
      const { innerHeight } = window;

      const isBelowWindow = position.y + refPosition.height + offsetY > innerHeight;

      const howMuchLower = position.y + refPosition.height + offsetY - innerHeight;

      if (showAbove) {
        setCoordinates({
          left: position.x + "px",
          top: isBelowWindow
            ? position.y - refPosition.height - bellowWindowYOffset - offsetY + "px"
            : position.y + offsetY + "px",
        });
      } else {
        setCoordinates({
          left: position.x + "px",
          top: isBelowWindow ? position.y - howMuchLower - bellowWindowYOffset + "px" : position.y + offsetY + "px",
        });
      }

      setIsPositionCalculated(true);
    }
  }, [position.y, ref.current]);

  return { coordinates };
};
