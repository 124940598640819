import { Subscriber } from "../../../services/abstract/Subscriber/Subscriber";

export class ConnectFigmaModalService extends Subscriber<boolean> {
  static instance: ConnectFigmaModalService;
  static getInstance() {
    if (!ConnectFigmaModalService.instance) {
      ConnectFigmaModalService.instance = new ConnectFigmaModalService();
    }
    return ConnectFigmaModalService.instance;
  }

  state = false;
  openModal = () => {
    this.state = true;
    this.notifySubscribers();
  };
  closeModal = () => {
    this.state = false;
    this.notifySubscribers();
  };
}
