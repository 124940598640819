import { type CSSProperties } from "react";

type TSections = {
  className?: string;
  style?: CSSProperties;
};

export const Sections = ({ className, style }: TSections) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask id="mask0_4_7754" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_4_7754)">
      <path d="M3.5 5.30762V3.80762H20.5V5.30762H3.5ZM3.5 20.1921V18.6921H20.5V20.1921H3.5Z" fill="#727272" />
      <path
        d="M5.30775 15.95C4.94508 15.95 4.65841 15.8306 4.41391 15.5861C4.16941 15.3416 4.05 15.0549 4.05 14.6923V9.30775C4.05 8.94508 4.16941 8.65841 4.41391 8.41391C4.65841 8.16941 4.94509 8.05 5.30775 8.05H18.6923C19.0549 8.05 19.3416 8.16941 19.5861 8.41391C19.8306 8.65841 19.95 8.94508 19.95 9.30775V14.6923C19.95 15.0549 19.8306 15.3416 19.5861 15.5861C19.3416 15.8306 19.0549 15.95 18.6923 15.95H5.30775Z"
        stroke="#727272"
        strokeWidth="1.1"
      />
    </g>
  </svg>
);
