import type { Component, Editor } from "grapesjs";
import type { Project, Page, ProjectPages } from "../../../../../types/types";
import { Projects as ProjectsService } from "../../../../../services/Projects/Projects";

/**
 * Loads a project into the editor.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project to load.
 * @param projectsService - The service for managing projects.
 * @returns A promise that resolves when the project is loaded.
 */
export const loadProject = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  await editor.load({ projectId: activeProject?.projectId });

  if (activeProject.pages) {
    const pagesToUpdate = getPagesToUpdate(activeProject.pages);
    if (pagesToUpdate.length !== 0) {
      await updatePagesInEditor(editor, activeProject.projectId, pagesToUpdate, projectsService);
      projectsService.projectFetched(activeProject.projectId);
    }
  }
};

/**
 * Retrieves the pages that need to be updated from the given project pages.
 *
 * @param pages - The project pages object.
 * @returns An array of key-value pairs representing the pages that need to be updated.
 */
export const getPagesToUpdate = (pages: ProjectPages): [string, Page][] => {
  return Object.entries(pages).filter(([_, page]) => page.needUpdate);
};

/**
 * Updates the pages in the editor based on the provided parameters.
 *
 * @param editor - The editor instance.
 * @param projectId - The ID of the project.
 * @param pagesToUpdate - An array of page updates, where each update is represented by a tuple containing the key and the updated page.
 * @param projectsService - The service used to retrieve OVH pages.
 * @returns A Promise that resolves once the pages have been updated in the editor.
 */
export const updatePagesInEditor = async (
  editor: Editor,
  projectId: string,
  pagesToUpdate: [string, Page][],
  projectsService: ProjectsService
) => {
  const ovhPages: Page[] = await projectsService.getOvhPagesByProjectId(projectId);

  pagesToUpdate.forEach(([key, page]) => {
    const ovhPage = ovhPages.find((ovhPage) => ovhPage.frameId === page.frameId);
    if (!ovhPage) return;

    const isPageInEditor = editor.Pages.get(page.frameId);
    const isMainPage = editor.Pages.getMain().id === page.frameId;
    const ovhPageBody = ovhPage.component.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";

    if (isPageInEditor) {
      editor.Pages.remove(page.frameId);
      editor.Pages.add({
        id: ovhPage.frameId,
        name: ovhPage.name,
        component: ovhPage.component,
        styles: ovhPage.styles,
        type: true,
      });
    }

    if (isMainPage) {
      editor.Pages.select(ovhPage.frameId);
    }

    if (!isPageInEditor) {
      editor.Pages.add({
        id: page.frameId,
        name: ovhPage.name,
        styles: ovhPage.styles,
        component: ovhPageBody,
      });
    }
  });
};

/**
 * Fallback function for loading a project.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project.
 * @param projectsService - The projects service.
 * @returns A Promise that resolves when the project is loaded.
 */
export const fallbackLoadProject = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  const pages: Page[] = await projectsService.getPagesByProjectId(activeProject.projectId);

  // event if in editor.config we don't specify pages, we need to remove the default page because editor add blank page
  if (pages && pages.length !== 0) {
    const defaultPage = editor.Pages.getMain();
    editor.Pages.remove(defaultPage);
  }

  let resetLink = "";
  // let componentsLink = "";

  pages.forEach(({ frameId, name, component, styles }) => {
    const body = component.match(/<body>[\s\S]*<\/body>/)?.[0] || "<body></body>";

    if (resetLink === "") {
      resetLink = component.match(/<link.*?reset.*?>/)?.[0] || "";
    }

    // if (componentsLink === "") {
    //   const componentsRegex = /<link\s+rel="stylesheet"\s+href="(\/api\/project-data\/components[^"]*)"\s*\/?>/;
    //   const componentsMatch = component.match(componentsRegex);
    //   componentsLink = componentsMatch ? componentsMatch[0] : "";
    // }

    editor.Pages.add({
      id: frameId,
      name,
      styles: styles,
      component: body,
    });
  });

  projectsService.scripts.push('<meta name="viewport" content="width=device-width, initial-scale=1.0" />');
  projectsService.scripts.push(
    '<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200">'
  );

  projectsService.scripts.push(resetLink);
  // projectsService.scripts.push(componentsLink);

  const mainPage = editor.Pages.getMain();
  editor.Pages.select(mainPage);

  projectsService.projectFetched(activeProject.projectId);
};

/**
 * Adds scripts to the editor.
 *
 * @param editor - The editor instance.
 * @param projectsService - The projects service.
 */
export const addScriptsToEditor = (editor: Editor, projectsService: ProjectsService) => {
  projectsService.scripts.forEach((script: string) => {
    editor.Canvas.getDocument().head.innerHTML += script;
  });
};

/**
 * Handles custom code for the editor.
 *
 * @param editor - The editor instance.
 * @param activeProject - The active project.
 * @param projectsService - The projects service.
 */
export const handleCustomCode = async (editor: Editor, activeProject: Project, projectsService: ProjectsService) => {
  const history = await projectsService.getHistory(activeProject.projectId);
  const customCode = (history.metadata.customCode as string) || undefined;

  if (customCode !== undefined) {
    const scriptCode = `
        if (document.readyState !== "loading") {
          ${customCode}
        }
      `;

    const scriptTagId = "customScriptTag";
    const script = editor.Canvas.getDocument().getElementById(scriptTagId);
    if (script) {
      script.textContent = scriptCode;
    } else {
      const script = document.createElement("script");
      script.id = scriptTagId;
      script.type = "text/javascript";
      script.textContent = scriptCode;
      editor.Canvas.getDocument().head.appendChild(script);
    }

    if (!activeProject.hasOwnProperty("jsScripts")) {
      activeProject.jsScripts = [];
    }

    activeProject.jsScripts.push(scriptCode);

    const customHead = (history.metadata.customHead as string) || undefined;
    if (customHead) {
      editor.Canvas.getDocument().head.innerHTML += customHead;
    }
  }
};

export const manageSmartNavbarPlacement = async (editor: Editor, component: Component) => {
  const { type } = component.attributes;

  if (type === "smartNavbar") {
    const editorWrapper = editor.getWrapper();
    // need to get element with variables so plugin component's work properly
    const componentWithVariables = editor.getWrapper()?.find(".local-styles-variables")[0];

    // at first place we want to add component in our page with variables
    // so for e.g.buttons will have access to variables
    if (componentWithVariables) {
      if (componentWithVariables.components().at(0).get("type") === "smartNavbar") return;

      component.move(componentWithVariables, { at: 0 });
      component.set("draggable", false);
      return;
    }

    // if we don't have variables we want to add component to editorWrapper
    if (editorWrapper) {
      component.move(editorWrapper, { at: 0 });
      component.set("draggable", false);
    }
  }
};
