import { styleManagerService } from "../../StylesManager.service";

import { DragDrop } from "../../../../../../../assets/svg/DragDrop";
import { Trash3 } from "../../../../../../../assets/svg/Trash3";
import { Button } from "../../../../../../AlphaO/atoms/Button";

import { TStackLayer } from "./StackLayer.types";

import "./StackLayer.css";

const getBackgroundPreview = (cssObj: Record<string, any>) => {
  if ("color" in cssObj) {
    return { backgroundColor: cssObj.color };
  }

  if ("background" in cssObj) {
    return { backgroundColor: cssObj.background };
  }

  if ("borderColor" in cssObj) {
    return { backgroundColor: cssObj.borderColor };
  }

  if ("box-shadow-color" in cssObj) {
    return { backgroundColor: cssObj["box-shadow-color"] };
  }

  if ("text-shadow-color" in cssObj) {
    return { backgroundColor: cssObj["text-shadow-color"] };
  }

  if ("background-type" in cssObj) {
    if (cssObj["background-type"] === "color") {
      return { backgroundColor: cssObj["background-image-color"] };
    }
    if (cssObj["background-type"] === "gradient") {
      return { backgroundImage: cssObj["background-image-gradient"] };
    }
  }

  if ("background-image" in cssObj) {
    if (cssObj["background-image"].includes("https://codejet")) {
      let image = cssObj["background-image"];
      // TODO fix this, this because layer separator is not working properly
      if (cssObj["background-image"].endsWith(",")) {
        image = cssObj["background-image"].slice(0, -1);
      }
      return { backgroundImage: image };
    }
  }

  return null;
};

export const StackLayer: TStackLayer = ({ layer, property, selectedLayer, setSelectedLayer }) => {
  const label = layer?.getLabel();
  const stylePreview = getBackgroundPreview(layer?.getValues() || {});

  if (!layer) return null;

  return (
    <div key={layer.getId()} className="stack-property__layer">
      <button
        onClick={() => {
          styleManagerService.notifySubscribers();
        }}
        className="stack-property__drag"
      >
        <DragDrop />
      </button>
      <button
        onClick={() => {
          if (!selectedLayer) {
            layer.select();
            setSelectedLayer(undefined);
            setSelectedLayer(property.getSelectedLayer());
            return;
          }
          if (layer.getIndex() === selectedLayer.getIndex()) {
            setSelectedLayer(undefined);
            return;
          }
          layer.clear();
          layer.select();
          setSelectedLayer(undefined);
          setSelectedLayer(property.getSelectedLayer());
        }}
        className="stack-property__label"
      >
        {label}
      </button>
      {stylePreview && <div style={stylePreview} className="stack-property__preview"></div>}
      <Button
        type="icon"
        iconStart={<Trash3 />}
        onClick={() => {
          layer.remove();
          if (property.layers.length === 0) {
            property.clear();
          }
          styleManagerService.notifySubscribers();
        }}
        className="stack-property__remove-action"
      />
    </div>
  );
};
