import { useRef, useEffect, useState } from "react";
import { Trait } from "grapesjs";

import { styleManagerService } from "../../styles-manager/StylesManager.service";

import { SelectButton } from "../../styles-manager/components/SelectButton";

export const Select = ({ trait }: { trait: Trait }) => {
  const ref = useRef<HTMLLIElement>(null);
  const [value, setValue] = useState(trait.getValue());

  const options = trait.getOptions().map((option) => ({
    id: option.id,
    label: option.label ?? option.id,
  }));

  const hasOptions = options?.length > 0;

  const handleChange = (newValue: string) => {
    if (value !== newValue) {
      trait.setValue(newValue);
      setValue(newValue);
    }
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (hasOptions) {
      const target = ref.current?.querySelector(".select-button") || ref.current;
      const coordinates = target?.getBoundingClientRect();
      if (!coordinates) return;

      styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
    }
  };

  return (
    <li key={trait.cid} className="trait-manager__trait" ref={ref}>
      <label className="trait-manager__trait-label">{trait.getLabel()}</label>
      <SelectButton currentOption={value} options={options} onClick={handleUnitDropdown} />
    </li>
  );
};
