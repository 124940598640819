import { PropertyLabel } from "../PropertyLabel";

import { propertyIconMap } from "../../helpers/propertyIconMap";

import { TRadioProperty } from "./RadioProperty.types";

import "./RadioProperty.css";

export const RadioProperty: TRadioProperty = ({ property, rotateIcons = false }) => {
  const options = property.get("options") || [];
  const value = property.getValue();
  const canClear = property.canClear();
  const className = property.get("className");

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    property.upValue(ev.target.value);
  };

  return (
    <>
      {/* TODO move somewhere ?? */}
      {property.get("property") === "align-self" && <div className="property-divider">Flex child</div>}{" "}
      <div className={`property-radio${className ? ` ${className}` : ""}`}>
        <PropertyLabel
          text={property.getLabel()}
          modifier={canClear ? "edited" : "primary"}
          clearValue={canClear ? () => property.clear() : undefined}
        />
        <ul className="property-select__options">
          {options.map((option) => {
            const isCurrentValue = value === option.value;
            return (
              <li
                key={option.id}
                className={`property-radio__option${isCurrentValue ? " property-radio__option--selected" : ""}${
                  rotateIcons ? " property-radio__option--rotate" : ""
                }`}
              >
                <label htmlFor={`${property.getName()}-${option.id}`}>
                  <input
                    type="radio"
                    name={property.getId()}
                    id={`${property.getName()}-${option.id}`}
                    value={option.value}
                    checked={isCurrentValue}
                    onChange={(ev) => handleChange(ev)}
                    className="vs-hidden"
                  />
                  {propertyIconMap[option.name as keyof typeof propertyIconMap] || option.label}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
