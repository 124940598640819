import { AddComponentTypeOptions, Component } from "grapesjs";
import { video, iframe } from "./components-definitions";

export const componentsDefinitions: { id: string; props: AddComponentTypeOptions }[] = [
  {
    id: "flex-section",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["flex-section"],
          styles: `
          .flex-section { display: flex; flex-direction: row; padding: 10px; }
          `,
          traits: [
            {
              type: "class_select",
              options: [
                { value: "flex-container", name: "Flex Container", id: "flex-container" },
                { value: "flex-item", name: "Flex Item", id: "flex-item" },
              ],
              label: "Flex Type",
            },
          ],
        },
      },
      view: {},
    },
  },
  {
    id: "cjRow",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-grid-row"],
          styles: `
          .cj-grid-row { display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap; min-height: auto; padding: 10px 0; }
          `,
        },
      },
      view: {},
    },
  },
  {
    id: "cjColumn",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-grid-column"],
          styles: `
          .cj-grid-column { flex: 1 1 100%;}
          .cj-grid-column--2 { flex: 0 0 50%; }
          .cj-grid-column--3 { flex: 0 0 33%; }
          `,
        },
      },
      view: {},
    },
  },
  {
    id: "cjSection",
    props: {
      model: {
        defaults: {
          tagName: "section",
          classes: ["cj-section"],
          styles: `
          .cj-section { width: 100%; padding: 50px 0; };
          `,
        },
      },
    },
  },
  {
    id: "cjContainer",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-container"],
          styles: `
          .cj-container { width: 90%; max-width: 1200px; margin: 0 auto; };
          `,
        },
      },
    },
  },
  {
    id: "Separator",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["cj-separator"],
          styles: `
          .cj-separator { width: auto;  height: 3px; margin: 1rem; background-color: #FF4900; }
          `,
        },
      },
    },
  },
  {
    id: "link",
    props: {
      isComponent: (el) => el.tagName === "A",
      model: {
        defaults: {
          tagName: "a",
          classes: ["cj-link"],
          attributes: { href: "" },
          traits: [
            "title",
            {
              type: "checkbox",
              name: "target",
              label: "Open in new tab",
              valueTrue: "_blank",
              valueFalse: "_self",
              options: [
                { id: "_self", label: "Self" },
                { id: "_blank", label: "Blank" },
              ],
            },
            "href",
          ],
          styles: `
          .cj-link { color: inherit; display: inline-block; vertical-align: top; max-width: 100%; text-decoration: none; }
          `,
        },
      },
    },
  },
  {
    id: "link-box",
    props: {
      model: {
        defaults: {
          tagName: "a",
          classes: ["link-box"],
          attributes: { href: "" },
          styles: `
          .link-box { color: inherit; display: inline-block; vertical-align: top; max-width: 100%; text-decoration: none; }
          `,
        },
      },
    },
  },
  {
    id: "codejetVideo",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["video-wrapper"],
          components: [video],
          traits: [
            "id",
            {
              type: "select",
              name: "videoProvider",
              label: "Provider",
              options: [
                { id: "html", label: "HTML5 Source" },
                { id: "youtube", label: "Youtube Source" },
                { id: "youtube-no-cookie", label: "Youtube (no cookie)" },
              ],
            },
          ],
          attributes: {
            videoProvider: "html",
          },
          styles: `
            .video-wrapper {
              max-width: 1024px;
              width: 100%;
              height: auto;
              aspect-ratio: 16/9;
            }
          `,
        },
        init() {
          this.on("change:attributes:videoProvider", this.handleVideoProviderChange);
        },
        handleVideoProviderChange(component: Component, traitValue: string) {
          const currentChild = component.get("components")?.at(0);
          if (!currentChild) throw new Error("[components:handleVideoProviderChange]: No child component found");

          if (traitValue === "youtube") {
            // Replacing the video component with an iframe
            currentChild.replaceWith(iframe("https://www.youtube.com/embed/?"));
          }

          if (traitValue === "youtube-no-cookie") {
            // Replacing the video component with an iframe
            currentChild.replaceWith(iframe("https://www.youtube-nocookie.com/embed/?"));
          }

          if (traitValue === "html") {
            // Replacing the iframe component with a video
            currentChild.replaceWith(video);
          }
        },
      },
    },
  },
  {
    id: "form",
    props: {
      model: {
        defaults: {
          tagName: "form",
          script: () => {
            const form = document.querySelector<HTMLFormElement>(".cj-form");
            if (!form) return;

            form?.addEventListener("submit", async (e: Event) => {
              e.preventDefault();
              if (!e.target) return;

              const formElement = e.target as HTMLFormElement;
              const button = formElement.querySelector("button");
              if (!button) return;

              // Przygotowanie danych z formularza
              const formData = new FormData(formElement);

              formElement.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
                const input = checkbox as HTMLInputElement;
                if (!formData.has(input.name)) {
                  formData.append(input.name, "false");
                }
              });

              const parsedFormData = Object.fromEntries(formData);
              if (Object.keys(parsedFormData).length === 0) throw new Error("Form is empty");

              const url = `%_WINDOW_ORIGIN_%/api/form-mailer`;
              const formEmail = formElement.getAttribute("email");
              const formSubject = formElement.getAttribute("subject");

              const data = {
                email: formEmail || "",
                subject: formSubject || "",
                data: parsedFormData,
              };

              const strigifiedData = JSON.stringify(data);

              // Zarządzanie stanem przycisku
              button.disabled = true;
              button.textContent = "Loading..."; // Tekst informujący o ładowaniu
              button.style.border = "1px solid gray";
              button.style.cursor = "not-allowed";

              fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: strigifiedData,
              })
                .then(() => {
                  button.style.border = "2px solid green";
                  button.textContent = "Success!";
                })
                .catch((error) => {
                  if (error) {
                    button.style.border = "2px solid red";
                    button.textContent = "Error!";
                  }
                })
                .finally(() => {
                  button.disabled = false;
                  button.style.cursor = "pointer";
                });
            });
          },
          classes: ["cj-form"],
          traits: [
            {
              type: "text",
              label: "Title Message",
              name: "subject",
              placeholder: "Enter your title message",
            },
            {
              type: "text",
              label: "Email Destination",
              name: "email",
              placeholder: "Enter your email",
            },
          ],
          attributes: { subject: "", email: "" },
          styles: `.cj-form { display: flex; flex-direction: column; align-items: flex-start; gap: 0.25rem; padding: 1rem; background-color: var(--colours_natural_mid-gray, #ffffff); border-radius: 8px;}`,
        },
      },
    },
  },
  {
    id: "label",
    props: {
      isComponent: (el) => el.tagName === "LABEL",
      model: {
        defaults: {
          tagName: "label",
          classes: ["cj-label"],
          traits: ["id", "title", "for"],
          styles: `
          .cj-label { color: var(--colours_text--icon_deep-darker-gray, #000000) ; font-family: Arial; font-size: 14px; font-weight: 400; line-height: 22px; text-align: left; vertical-align: top; }
          `,
        },
      },
    },
  },
  {
    id: "input",
    props: {
      isComponent: (el) => {
        const isInput = el.tagName === "INPUT";
        let isValidType = false;
        if (isInput) {
          isValidType = ["text", "email", "number", "password"].includes((el as HTMLInputElement).type || "");
        }
        return isInput && isValidType;
      },
      model: {
        defaults: {
          tagName: "input",
          classes: ["cj-input"],
          traits: [
            "name",
            "placeholder",
            {
              type: "select",
              name: "type",
              label: "Type",
              options: [
                { id: "text", value: "text" },
                { id: "email", value: "email" },
                { id: "number", value: "number" },
                { id: "password", value: "password" },
              ],
            },
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
          ],
          attributes: { placeholder: "Enter your text here", type: "text" },
          styles: `
          .cj-input { outline: none; background-color: transparent; font-size: 16px; font-family: Arial; font-weight: 400; letter-spacing: 0%; line-height: 28px; color: var(--colours_text--icon_deep-darker-gray, #000000); padding: 16px 24px; border: 1px solid rgb(101, 101, 101);border-radius: 12px; overflow: hidden; }
          .cj-input::placeholder { color: var(--colours_text--icon_deep-darker-gray, #000000) ; opacity: 0.4; }
          .cj-input:focus { border-top-color: var(--colours_brand_primary-500, #FF4900); border-right-color: var(--colours_brand_primary-500, #FF4900); border-bottom-color: var(--colours_brand_primary-500, #FF4900); border-left-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "select",
    props: {
      model: {
        defaults: {
          tagName: "select",
          classes: ["cj-select"],
          traits: [
            "name",
            { type: "checkbox", name: "required", label: "required" },
            { type: "select-options", label: "Options" },
          ],
          styles: `
          .cj-select { color: var(--colours_text--icon_deep-darker-gray, #000000) ; padding: 16px 24px; border-radius: 8px; overflow: hidden; }
          .cj-select option { color: var(--colours_text--icon_deep-darker-gray, #000000) ;padding: 16px 24px; }
          `,
        },
      },
      view: {
        // dropdown is not selectable in editor
        onRender() {
          this.el.addEventListener("mousedown", (e) => {
            e.preventDefault();
          });
        },
      },
    },
  },
  {
    id: "option",
    props: {
      model: {
        defaults: {
          tagName: "option",
          classes: ["cj-option"],
          styles: `
          .cj-option { color: var(--colours_text--icon_deep-darker-gray, #000000) ;padding: 0.5rem; }
          `,
          layerable: false,
        },
      },
    },
  },
  {
    id: "button",
    props: {
      isComponent: (el) => el.tagName === "BUTTON",
      model: {
        defaults: {
          traits: ["id", "data-redirect"],
          tagName: "button",
          classes: ["cj-button"],
          styles: `
          .cj-button { display: flex; flex-direction: row; align-items: center; justify-content: center; flex-wrap: nowrap; gap: 12px; padding: 18px; background-color: var(--primary-500, #FF4900); border: none; border-radius: 12px; }
          .cj-button:hover { background-image: var(--primary-700, #FF4900); }
          .cj-button:focus { background-image: var(--primary-900, #FF4900); }
          .cj-button span { color: var(--colourstext--iconwhite, #ffffff); font-family: Arial; font-size: 16px; font-weight: 400; line-height: 24px; text-align: left; vertical-align: top; }
          `,
        },
      },
    },
  },
  {
    id: "checkbox",
    props: {
      isComponent: (el) => {
        const isInput = el.tagName === "INPUT";
        let isValidType = false;
        if (isInput) {
          isValidType = (el as HTMLInputElement).type === "checkbox";
        }
        return isInput && isValidType;
      },
      model: {
        defaults: {
          tagName: "input",
          attributes: {
            type: "checkbox",
          },
          classes: ["cj-checkbox"],
          traits: [
            "id",
            "name",
            "value",
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
            {
              type: "checkbox",
              name: "checked",
              label: "Checked",
            },
          ],
          styles: `.cj-checkbox { width: 20px; height: 20px; border: 2px solid rgba(101, 101, 101, 1); border-radius: 2px; background-color: transparent; }
          .cj-checkbox:checked { border-color: var(--colours_brand_primary-500, #FF4900); accent-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "radio",
    props: {
      isComponent: (el) => {
        const isInput = el.tagName === "INPUT";
        let isValidType = false;
        if (isInput) {
          isValidType = (el as HTMLInputElement).type === "radio";
        }
        return isInput && isValidType;
      },
      model: {
        defaults: {
          tagName: "input",
          attributes: {
            type: "radio",
          },
          traits: [
            "id",
            "name",
            "value",
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
            {
              type: "checkbox",
              name: "checked",
              label: "Checked",
            },
          ],
          classes: ["cj-radio"],
          styles: `
            .cj-radio { width: 20px; height: 20px; }
            .cj-radio:checked { accent-color: var(--colours_brand_primary-500, #FF4900); }
          `,
        },
      },
    },
  },
  {
    id: "textfield",
    props: {
      isComponent: (el) => el.tagName === "TEXTAREA",
      model: {
        defaults: {
          tagName: "textarea",
          traits: [
            "name",
            "placeholder",
            {
              type: "checkbox",
              name: "required",
              label: "Required",
            },
          ],
          classes: ["cj-textfield"],
          styles: `
          .cj-textfield { outline: none; background-color: transparent;   font-size: 16px; font-family: Arial; font-weight: 400; letter-spacing: 0%; line-height: 28px; color: var(--colours_text--icon_deep-darker-gray, #000000); padding: 16px 24px; border: 1px solid rgb(101, 101, 101);border-radius: 12px; overflow: hidden; }
          .cj-textfield::placeholder { color: var(--colours_text--icon_deep-darker-gray, #000000) ; opacity: 0.4; }
          .cj-textfield:focus { border-top-color: var(--colours_brand_primary-500, #FF4900); border-right-color: var(--colours_brand_primary-500, #FF4900); border-bottom-color: var(--colours_brand_primary-500, #FF4900); border-left-color: var(--colours_brand_primary-500, #FF4900 ); }
          `,
        },
      },
    },
  },
  {
    id: "smartNavbar",
    props: {
      model: {
        defaults: {
          tagName: "div",
          classes: ["smart-navbar"],
          traits: [
            {
              type: "smartNavbar",
            },
          ],
          script: () => {
            const navbarOpen = document.querySelector(".smart-navbar__mobile-open");
            const navbarClose = document.querySelector(".smart-navbar__mobile-close");
            const navigation = document.querySelector(".smart-navbar");
            const navigationMenu = document.querySelector(".smart-navbar__nav");

            navbarOpen?.addEventListener("click", () => {
              navigation?.classList.add("active");
              navigationMenu?.classList.add("active");
              navbarOpen?.classList.add("hidden");
              navbarClose?.classList.add("show");
              document.body.style.overflow = "hidden";
            });
            navbarClose?.addEventListener("click", () => {
              navigation?.classList.remove("active");
              navigationMenu?.classList.remove("active");
              navbarOpen?.classList.remove("hidden");
              navbarClose?.classList.remove("show");
              document.body.removeAttribute("style");
            });
          },
        },
      },
    },
  },
  {
    id: "icon",
    props: {
      model: {
        defaults: {
          tagName: "span",
          classes: ["material-symbols-outlined"],
          // attributes: { class: "fas fa-star" },
          // styles: `
          // .cj-icon { color: var(--colours_text--icon_deep-darker-gray, #000000); font-size: 24px; }
          // `,
          content: "home",
        },
      },
      view: {},
    },
  },
];
