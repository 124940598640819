import { TUnits } from "./Units.types";

import "./Units.css";

export const Units: TUnits = ({ currentUnit, onClick }) => {
  return (
    <button className={`units${typeof onClick === "undefined" ? " units--placeholder" : ""}`} onClick={onClick}>
      <span className="units__text">{currentUnit}</span>
    </button>
  );
};
