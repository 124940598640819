import { View } from "../modal.service.ts";
import { ModalViewDefault } from "./default.tsx";
import { DomainsModal } from "./Domains/Domains.tsx";
import { CustomDomainModal } from "./CustomDomain/CustomDomain.tsx";
import { UnpublishDomainModal } from "./UnpublishDomain/UnpublishDomain.tsx";
import { UploadAsset } from "./UploadAssets/UploadAsset.tsx";
import { UpgradeModal } from "./UpgradeModal/UpgradeModal.tsx";

export const views: View[] = [
  {
    id: "defaultView",
    component: ModalViewDefault,
  },
  {
    id: "domains",
    component: DomainsModal,
  },
  {
    id: "customDomain",
    component: CustomDomainModal,
  },
  {
    id: "unpublishDomain",
    component: UnpublishDomainModal,
  },
  {
    id: "uploadAsset",
    component: UploadAsset,
  },
  {
    id: "upgradeModal",
    component: UpgradeModal,
  },
];
