import React, { type SVGProps } from "react";
export const DiscordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="discord 1">
      <path
        id="Vector"
        d="M5.08398 13.8333C9.25065 15.9166 11.7507 15.9166 15.9173 13.8333M13.4167 15.0833L14.25 16.7499C14.25 16.7499 17.7261 15.643 18.8333 13.8333C18.8333 12.9999 19.2751 7.04441 16.3333 5.08325C15.0833 4.24992 13 3.83325 13 3.83325L12.1667 5.49992H10.5M7.60625 15.0833L6.77291 16.7499C6.77291 16.7499 3.2968 15.643 2.18958 13.8333C2.18958 12.9999 1.74784 7.04441 4.68958 5.08325C5.93958 4.24992 8.02291 3.83325 8.02291 3.83325L8.85623 5.49992H10.5229M7.58398 12.1666C6.89363 12.1666 6.33398 11.4204 6.33398 10.4999C6.33398 9.57942 6.89363 8.83325 7.58398 8.83325C8.27434 8.83325 8.83398 9.57942 8.83398 10.4999C8.83398 11.4204 8.27434 12.1666 7.58398 12.1666ZM13.416 12.1666C12.7257 12.1666 12.166 11.4204 12.166 10.4999C12.166 9.57942 12.7257 8.83325 13.416 8.83325C14.1063 8.83325 14.666 9.57942 14.666 10.4999C14.666 11.4204 14.1063 12.1666 13.416 12.1666Z"
        stroke="#27272A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
