import { createMachine } from "xstate";
import { ModalService } from "./modal.service.ts";
import { views } from "./viewes";

export const modalMachine = createMachine<{
  service: ModalService;
}>(
  {
    id: "modal",
    initial: "READY",
    context: {
      service: new ModalService(views),
    },
    states: {
      CHANGE_VIEW: {
        invoke: {
          src: "changeView",
          onDone: {
            target: "READY",
          },
          onError: {
            target: "ERROR",
          },
        },
      },

      READY: {
        entry: "notify",
        on: {
          OPEN: "OPEN",
          CLOSE: "CLOSE",
          CHANGE_VIEW: "CHANGE_VIEW",
        },
      },

      CLOSE: {
        invoke: {
          src: "closeModal",
          onDone: {
            target: "READY",
          },
          onError: {
            target: "ERROR",
          },
        },
      },

      OPEN: {
        invoke: {
          src: "openModal",
          onDone: {
            target: "READY",
          },
          onError: {
            target: "ERROR",
          },
        },
      },

      ERROR: {},
    },
  },
  {
    services: {
      closeModal: ({ service }) => {
        service.close();
        return Promise.resolve();
      },

      openModal: ({ service }, { modalId }: { modalId: string }) => {
        service.open(modalId);
        return Promise.resolve();
      },

      changeView: ({ service }, { viewId }: { viewId: string }) => {
        service.open(viewId);
        return Promise.resolve();
      },
    },

    actions: {
      notify: (context) => {
        context.service.notifySubscribers();
      },
    },
  }
);
