import { type CSSProperties } from "react";

type TAlignCenter = {
  className?: string;
  style?: CSSProperties;
};

export const AlignCenter = ({ className, style }: TAlignCenter) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.875 8H2.875M4.375 4.25V11.75C4.375 12.1642 4.71079 12.5 5.125 12.5H12.625C13.0392 12.5 13.375 12.1642 13.375 11.75V4.25C13.375 3.83577 13.0392 3.5 12.625 3.5H5.125C4.71079 3.5 4.375 3.83577 4.375 4.25Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
    />
  </svg>
);
