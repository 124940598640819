import { Page } from "grapesjs";
import { Subscriber } from "../../../../services/abstract/Subscriber/Subscriber";

type IPageActionsModalService = {
  isOpen: boolean;
  position: { x: number; y: number };
  setIsRenameInputVisible: null | ((state: boolean) => void);
  setIsVisible: null | ((state: boolean) => void);
  page: Page | null;
};

export class PageActionsModalService extends Subscriber<IPageActionsModalService> {
  state: IPageActionsModalService = {
    isOpen: false,
    position: { x: 0, y: 0 },
    setIsRenameInputVisible: null,
    setIsVisible: null,
    page: null,
  };

  closeModal = () => {
    this.state.isOpen = false;
    this.state.setIsRenameInputVisible = null;
    this.state.page = null;
    // this.state.position = { x: 0, y: 0 };
    this.notifySubscribers();
  };

  setPosition = (position: IPageActionsModalService["position"]) => {
    this.state.position = position;
    this.notifySubscribers();
  };

  setupActionsModal = ({
    setIsRenameInputVisible,
    position,
    page,
  }: {
    setIsRenameInputVisible: (state: boolean) => void;
    position: IPageActionsModalService["position"];
    page: Page;
  }) => {
    this.state.position = position;
    this.state.setIsRenameInputVisible = setIsRenameInputVisible;
    this.state.page = page;
    this.state.isOpen = true;
    this.notifySubscribers();
  };
}

export const pageActionsServiceInstance = new PageActionsModalService();
