import { AssetManagerService } from "./asset-manager.service.ts";
import { assetManagerMachine } from "./asset-manager.machine.ts";
import { interpret } from "xstate";

//This need to be loaded first  in editorMachine then used in AssetManagerMachine
export const assetManagerService = new AssetManagerService();

export const assetManagerInstance = interpret(assetManagerMachine(assetManagerService)).start();
// assetManagerInstance.onTransition((state) => {
//   console.log("Asset Manager State", state.value);
// });
