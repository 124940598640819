import { type SVGProps } from "react";
export const Lock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.666 11V7.99998C17.666 5.23858 15.4275 3 12.666 3C9.90458 3 7.66604 5.23858 7.66604 7.99998V11M8.46602 21H16.866C18.5462 21 19.3863 21 20.028 20.673C20.5925 20.3854 21.0514 19.9265 21.339 19.362C21.666 18.7202 21.666 17.8802 21.666 16.2V15.8C21.666 14.1199 21.666 13.2797 21.339 12.638C21.0514 12.0736 20.5925 11.6146 20.028 11.327C19.3863 11 18.5462 11 16.866 11H8.46602C6.78584 11 5.94578 11 5.30405 11.327C4.73956 11.6146 4.28061 12.0736 3.993 12.638C3.66602 13.2797 3.66602 14.1199 3.66602 15.8V16.2C3.66602 17.8802 3.66602 18.7202 3.993 19.362C4.28061 19.9265 4.73956 20.3854 5.30405 20.673C5.94578 21 6.78584 21 8.46602 21Z"
      stroke="#FF4900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
