import { useState, useEffect, useRef } from "react";

import { PropertyLabel } from "../PropertyLabel";
import { StyleManagerInput } from "../StyleManagerInput";

import { editorService } from "../../../editor";
import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";

import { TIconColorProperty } from "./IconColorProperty.types";

import "./IconColorProperty.css";

export const IconColorProperty: TIconColorProperty = () => {
  const editor = editorService.getEditor();
  const placeholder = "rgba(0, 0, 0, 1)";
  const [value, setValue] = useState(placeholder);
  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editor) {
      const selected = editor?.getSelected();
      const attributes = selected?.getAttributes();
      const id = attributes?.id;
      const rule = editor?.Css.getRule(`#${id}`);
      const color: string | undefined = rule?.attributes.style?.color;
      setValue(color ?? placeholder);

      if (!color) {
        selected?.setAttributes({
          style: {
            color: placeholder,
          },
        });
      }
    }
  }, []);

  const handleChange = (string: string) => {
    const selected = editor?.getSelected();

    setValue(string);
    selected?.setAttributes({
      style: {
        color: string,
      },
    });
  };

  const onClickHandler = () => {
    if (!propertyRef.current) return;
    const position = propertyRef.current.getBoundingClientRect();
    colorPickerServiceInstance.setupColorPicker(
      value,
      handleChange,
      {
        x: position.left,
        y: position.top,
      },
      "left"
    );
  };

  return (
    <div className="color-property vertical full-width" ref={propertyRef}>
      <PropertyLabel
        text="Color"
        modifier={false ? "edited" : "primary"}
        //   clearValue={canClear ? () => property.clear() : undefined}
      />
      <StyleManagerInput
        type="text"
        value={value}
        handleChange={handleChange}
        hasUnits={false}
        placeholder={placeholder}
        color={true}
        handleColorPicker={onClickHandler}
      />
    </div>
  );
  return <></>;
};
