import type { TPagination } from "./Pagination.types";

import "./Pagination.css";

export const Pagination: TPagination = ({ steps, active }) => {
  return (
    <div className="pagination">
      {Array.from({ length: steps }, (_, index) => (
        <div key={index} className={`dot ${index === active ? "dot--active" : ""}`} />
      ))}
    </div>
  );
};
