export function extractVariables(css: string, classList?: string[]) {
  // console.log("css", css);
  let variables = "";
  let styles = css;

  // Helper function to extract and remove blocks matching the regex
  function extractBlocks(regex: RegExp) {
    let match;
    while ((match = regex.exec(styles)) !== null) {
      variables += match[0] + "\n";
      styles = styles.replace(match[0], "");
      regex.lastIndex = 0; // Reset regex index to handle overlapping matches
    }
  }

  // Regex to match :root blocks with any selectors following it
  const rootRegex = /:root\s*[^}]*{[^}]*}/g;
  extractBlocks(rootRegex);

  // Create and apply regex for each class in classList
  classList?.forEach((cls) => {
    const classRegex = new RegExp(`\\.${cls}\\s*{[^}]*}`, "g");
    extractBlocks(classRegex);
  });

  // Helper function to format CSS code
  // function formatCSS(css: string) {
  //   let formattedCSS = "";
  //   let lines = css.split("\n");
  //   let indentLevel = 0;

  //   lines.forEach((line) => {
  //     line = line.trim();
  //     if (line.endsWith("}")) {
  //       indentLevel--;
  //     }

  //     if (line) {
  //       formattedCSS += "  ".repeat(indentLevel) + line + "\n";
  //     }

  //     if (line.endsWith("{")) {
  //       indentLevel++;
  //     }
  //   });

  //   return formattedCSS.trim();
  // }

  // console.log("variables", variables);

  return {
    styles: styles,
    variables: variables,
  };
}
