import { createMachine } from "xstate";
import { StyleManagerService } from "./StylesManager.service.ts";
import { assetManagerService } from "../asset-manager/index.ts";

export const stylesManagerMachine = (styleManagerService: StyleManagerService) => {
  return createMachine(
    {
      id: "stylesManager",
      initial: "START",
      context: {
        service: styleManagerService,
      },

      states: {
        START: {
          on: {
            INIT: "INIT",
          },
        },
        INIT: {
          invoke: {
            src: "init",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: ["notify"],
          on: {
            SELECT_COMPONENT: "SELECT_COMPONENT",
            ERROR: "ERROR",
            AWAIT_FOR_IMAGE: "AWAIT_FOR_IMAGE",
          },
        },

        AWAIT_FOR_IMAGE: {
          entry: (context, event) => {
            context.service.setChangeUrlFunc(event.func);
          },
          on: {
            NEW_ASSET_UPLOADED: {
              target: "NEW_ASSET_UPLOADED",
            },
            CANCEL_BG_CHANGE: {
              actions: (context) => {
                context.service.setChangeUrlFunc(undefined);
              },
              target: "READY",
            },
          },
        },

        NEW_ASSET_UPLOADED: {
          invoke: {
            src: "upProperty",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        SELECT_COMPONENT: {
          invoke: {
            src: "selectComponent",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ERROR: {
          entry: () => {
            console.error("Error");
          },
        },
      },
    },
    {
      services: {
        selectComponent: async ({ service }, event) => {
          service.setComponent(event.component, event.sectors);
          return Promise.resolve();
        },

        init: async ({ service }) => {
          // TODO maybe i need do something here
          return Promise.resolve();
        },

        upProperty: async ({ service }, event) => {
          // i need to be sure that new images are loaded
          await assetManagerService.loadAssets();
          event.files.map((element: string) => {
            service.upImageProperty(element);
          });
          Promise.resolve();
        },
      },
      actions: {
        notify: ({ service }) => {
          service.notifySubscribers();
        },
      },
      guards: {},
    }
  );
};
