import type { SVGProps } from "react";

export const GoogleLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_20304_1261)">
      <path
        d="M20.2623 10.1878C20.2623 9.36841 20.1958 8.77047 20.052 8.15039H10.6953V11.8487H16.1874C16.0768 12.7678 15.4788 14.1519 14.15 15.082L14.1314 15.2058L17.0898 17.4976L17.2948 17.5181C19.1771 15.7796 20.2623 13.2218 20.2623 10.1878Z"
        fill="#4285F4"
      />
      <path
        d="M10.6956 19.9322C13.3863 19.9322 15.6452 19.0463 17.2951 17.5183L14.1504 15.0822C13.3088 15.669 12.1794 16.0787 10.6956 16.0787C8.0603 16.0787 5.82358 14.3403 5.02625 11.9375L4.90938 11.9474L1.8332 14.3281L1.79297 14.4399C3.43175 17.6954 6.79793 19.9322 10.6956 19.9322Z"
        fill="#34A853"
      />
      <path
        d="M5.02673 11.9363C4.81635 11.3163 4.69459 10.6518 4.69459 9.96534C4.69459 9.27878 4.81635 8.61443 5.01566 7.99435L5.01009 7.86229L1.89535 5.44336L1.79345 5.49183C1.11803 6.84275 0.730469 8.35977 0.730469 9.96534C0.730469 11.5709 1.11803 13.0879 1.79345 14.4388L5.02673 11.9363Z"
        fill="#FBBC05"
      />
      <path
        d="M10.6956 3.85336C12.5669 3.85336 13.8292 4.66168 14.549 5.33718L17.3615 2.59107C15.6342 0.985496 13.3863 0 10.6956 0C6.79793 0 3.43175 2.23672 1.79297 5.49214L5.01518 7.99466C5.82358 5.59183 8.0603 3.85336 10.6956 3.85336Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_20304_1261">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
