import { editorService } from "../../editor";

import type { CssRule } from "grapesjs";

const editor = () => editorService.getEditor();

const outlinedSymbolsCssRule = () => (!editor() ? undefined : editor()?.Css.getRule(".material-symbols-outlined"));

const roundedSymbolsCssRule = () => (!editor() ? undefined : editor()?.Css.getRule(".material-symbols-rounded"));

const materialSymbolsSharpRule = () => (!editor() ? undefined : editor()?.Css.getRule(".material-symbols-sharp"));

/**
 * Checks if none of the Material Symbols CSS rules are present.
 *
 * This constant evaluates to `true` if all of the following CSS rules are not defined:
 * - `outlinedSymbolsCssRule`
 * - `roundedSymbolsCssRule`
 * - `materialSymbolsSharpRule`
 *
 * @constant {boolean} hasNoMaterialSymbols - Indicates the absence of Material Symbols CSS rules.
 */
export const hasNoMaterialSymbols = () =>
  !outlinedSymbolsCssRule || !roundedSymbolsCssRule || !materialSymbolsSharpRule;

type TMaterialSymbolsRules = {
  outlined: CssRule | undefined;
  rounded: CssRule | undefined;
  sharp: CssRule | undefined;
};
/**
 * Retrieves the CSS rules for different styles of Material Symbols.
 *
 * @returns {TMaterialSymbolsRules} An object containing the CSS rules for outlined, rounded, and sharp Material Symbols.
 */
export const getMaterialSymbolRules = (): TMaterialSymbolsRules => {
  return {
    outlined: outlinedSymbolsCssRule(),
    rounded: roundedSymbolsCssRule(),
    sharp: materialSymbolsSharpRule(),
  };
};

/**
 * Applies a callback function to each defined material symbol rule.
 *
 * This function retrieves the outlined, rounded, and sharp material symbol rules,
 * filters out any undefined rules, and then applies the provided callback function
 * to each remaining rule.
 *
 * @param cb - A callback function that takes a `CssRule` or `undefined` as an argument.
 */
export const materialSymbolRules = (cb: (rule: CssRule | undefined) => void) => {
  const { outlined, rounded, sharp } = getMaterialSymbolRules();
  const array = [outlined, rounded, sharp].filter(Boolean);
  if (array.length === 0) throw new Error("No material symbol rules found");
  for (const rule of array) {
    cb(rule);
  }
};

/**
 * Parses a string to extract key-value pairs and returns them as a Map.
 * The input string should contain pairs in the format: `'key' value`.
 *
 * @param str - The input string containing key-value pairs.
 * @returns A Map where each key is a string and each value is the corresponding value from the input string.
 */
const parseString = (str: string) => {
  const properties = new Map();
  const regex = /'([^']+)'\s+([^\s,]+)/g;
  let match;
  while ((match = regex.exec(str)) !== null) {
    const key = match[1];
    const value = match[2];
    properties.set(key, value);
  }
  return properties;
};

/**
 * Creates an object to manage properties parsed from a string.
 *
 * @param str - The input string to parse into properties.
 * @returns An object with methods to set, get, and convert properties to a string.
 *
 * @example
 * const propertyManager = createPropertyString("color: red; font-size: 14px;");
 * propertyManager.setProperty("margin", "10px");
 * console.log(propertyManager.getProperty("color")); // Outputs: red
 * console.log(propertyManager.toString()); // Outputs: 'color' red, 'font-size' 14px, 'margin' 10px
 */
export const createPropertyString = (str: string) => {
  const properties = parseString(str);

  const setProperty = (name: string, value: string | number) => {
    properties.set(name, value);
  };

  const getProperty = (name: string) => {
    return properties.get(name);
  };

  const toString = () => {
    let result = "";
    for (let [key, value] of properties.entries()) {
      if (result !== "") result += ", ";
      result += `'${key}' ${value}`;
    }
    return result;
  };

  return {
    setProperty,
    getProperty,
    toString,
  };
};

/**
 * Validates the format of a given string against a specific pattern and checks if the values
 * fall within the allowed ranges.
 *
 * The expected format of the input string is:
 * `'FILL' <number>, 'wght' <number>, 'GRAD' <number>, 'opsz' <number>`
 *
 * Example of a valid string: `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24`
 *
 * @param input - The string to be validated.
 * @returns `true` if the string matches the pattern and the values are within the allowed ranges, otherwise `false`.
 */
export const validateStringFormat = (input: string) => {
  // Tworzymy wzorzec wyrażenia regularnego do sprawdzenia ogólnego formatu
  const pattern = /'FILL' (\d), 'wght' (\d{3}), 'GRAD' (-?\d+), 'opsz' (\d+)/;
  const match = input.match(pattern);

  if (!match) {
    return false; // String nie pasuje do wzorca
  }

  // Dopasowane wartości zamieniamy na liczby
  const fill = parseInt(match[1], 10);
  const wght = parseInt(match[2], 10);
  const grad = parseInt(match[3], 10);
  const opsz = parseInt(match[4], 10);

  // Definiujemy dozwolone wartości dla poszczególnych właściwości
  const validFill = [0, 1];
  const validWght = [100, 200, 300, 400, 500, 600, 700];
  const validGrad = [-25, 0, 200];
  const validOpsz = [20, 24, 40, 48];

  // Sprawdzamy, czy każda właściwość zawiera dozwoloną wartość
  return validFill.includes(fill) && validWght.includes(wght) && validGrad.includes(grad) && validOpsz.includes(opsz);
};
