import { type CSSProperties } from "react";

type TPreview = {
  className?: string;
  style?: CSSProperties;
};

export const Preview = ({ className, style }: TPreview) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1 11.9273C1.00331 12.1199 1.05936 12.3079 1.16204 12.4709C1.26472 12.6338 1.4101 12.7656 1.58239 12.8517C1.75444 12.9489 1.94869 13 2.1463 13C2.34391 13 2.53816 12.9489 2.71021 12.8517L10.7066 7.90595C10.8806 7.82213 11.0274 7.69091 11.1301 7.52738C11.2328 7.36384 11.2873 7.17464 11.2873 6.98151C11.2873 6.78838 11.2328 6.59918 11.1301 6.43565C11.0274 6.27211 10.8806 6.14089 10.7066 6.05707L2.71021 1.1483C2.53816 1.05108 2.34391 1 2.1463 1C1.94869 1 1.75444 1.05108 1.58239 1.1483C1.4101 1.23444 1.26472 1.36617 1.16204 1.52915C1.05936 1.69213 1.00331 1.88014 1 2.07274V11.9273Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
