import { type SVGProps } from "react";
export const CheckBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.00001 12L11 14L15.5 9.49999M9.20191 20.6009C9.52963 20.5575 9.86071 20.6464 10.1218 20.8476L11.3251 21.7708C11.7228 22.0764 12.2761 22.0764 12.6727 21.7708L13.9215 20.812C14.1548 20.6331 14.4492 20.5542 14.7403 20.5931L16.3024 20.7987C16.7991 20.8642 17.2779 20.5875 17.4701 20.1242L18.0712 18.6709C18.1834 18.3987 18.3989 18.1831 18.6711 18.0709L20.1243 17.4699C20.5876 17.2788 20.8642 16.7988 20.7987 16.3021L20.601 14.7966C20.5576 14.4688 20.6465 14.1377 20.8476 13.8766L21.7708 12.6733C22.0763 12.2755 22.0763 11.7222 21.7708 11.3256L20.812 10.0767C20.6332 9.84337 20.5543 9.54895 20.5932 9.25783L20.7987 7.69567C20.8642 7.19899 20.5876 6.72013 20.1243 6.52795L18.6711 5.92683C18.3989 5.81461 18.1834 5.59906 18.0712 5.32685L17.4701 3.87356C17.279 3.41024 16.7991 3.13358 16.3024 3.19913L14.7403 3.40468C14.4492 3.44468 14.1548 3.36579 13.9226 3.18802L12.6738 2.22916C12.2761 1.92361 11.7228 1.92361 11.3262 2.22916L10.0774 3.18802C9.84409 3.36579 9.54967 3.44468 9.25855 3.4069L7.69645 3.20136C7.19983 3.1358 6.72097 3.41246 6.52879 3.87578L5.92884 5.32907C5.81551 5.60017 5.59998 5.81572 5.32889 5.92906L3.87568 6.52903C3.41238 6.72127 3.13574 7.20013 3.20129 7.69681L3.40682 9.25897C3.4446 9.55009 3.36572 9.84451 3.18796 10.0767L2.22915 11.3256C1.92362 11.7234 1.92362 12.2767 2.22915 12.6733L3.18796 13.9222C3.36683 14.1555 3.44571 14.4499 3.40682 14.7411L3.20129 16.3032C3.13574 16.7999 3.41238 17.2788 3.87568 17.4709L5.32889 18.0721C5.60109 18.1843 5.81663 18.3998 5.92884 18.672L6.52987 20.1253C6.72097 20.5887 7.20097 20.8653 7.69759 20.7997L9.20191 20.6009Z"
      stroke="#FF4900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
