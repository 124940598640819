import { getWindow } from "../../utils/getWindow/getWindow";
import { paymentsAxios } from "./PaymentsAxiosInstance";

export type ProductPricing = {
  priceId: string;
  type: "oneOffProduct" | "subscription";
};
export class PaymentsService {
  static async createCheckoutSession(items: ProductPricing[], projectId: string) {
    try {
      const { data } = await paymentsAxios.post<Record<"url", string>>("/api/stripe/create-checkout-session", {
        items,
        projectId,
      });
      getWindow().location.replace(data.url);
    } catch (error) {
      console.error("createCheckoutSession", error);
      throw Promise.reject();
    }
  }

  static async createPortalSession() {
    try {
      const { data } = await paymentsAxios.post<Record<"url", string>>("/api/stripe/create-portal-session", {});
      getWindow().location.replace(data.url);
    } catch (error) {
      console.error("createCheckoutSession", error);
      throw Promise.reject();
    }
  }
}
