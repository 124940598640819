import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber";

export class FigmaInfoModal extends Subscriber<boolean> {
  static instance: FigmaInfoModal;
  static getInstance() {
    if (!FigmaInfoModal.instance) {
      FigmaInfoModal.instance = new FigmaInfoModal();
    }
    return FigmaInfoModal.instance;
  }

  state = false;
  openModal = () => {
    this.state = true;
    this.notifySubscribers();
  };
  closeModal = () => {
    this.state = false;
    this.notifySubscribers();
  };
}
