export const getPropertiesBySelector = (selector: Record<string, any>) => {
  return Object.entries(selector).reduce<Record<string, Record<string, string | number>>>(
    (acc, [fontKey, fontValue]) => {
      const [variable, CssProp] = fontKey.split("_");
      const formatedKey = variable.startsWith("--") ? variable.slice(2) : variable;

      if (acc[formatedKey] === undefined) {
        acc[formatedKey] = {};
      }
      if (typeof fontValue === "string" && fontValue.startsWith("var(") && fontValue.endsWith(")")) {
        fontValue = fontValue.slice(4, -1);
      }
      acc[formatedKey][CssProp] = fontValue;
      return acc;
    },
    {}
  );
};
export const getDisplayName = (
  category: string,
  rootStyles: Record<string, string>,
  properties: Record<string, string | number>
) => {
  const formattedCategory = category.replace(/-/g, " ").trim();
  const fontSize = rootStyles[properties["font-size"]] ? rootStyles[properties["font-size"]] : properties["font-size"];
  const lineHeight = rootStyles[properties["line-height"]]
    ? rootStyles[properties["line-height"]]
    : properties["line-height"];
  return `${formattedCategory} - ${fontSize}/${lineHeight}`;
};
