import type { TTemplate, TTemplateFromDB } from "../../types";
import { formatKeys } from "../../utils/formatKeys/formatKeys";
import axios, { AxiosResponse, AxiosResponseHeaders } from "axios";
import mockResponse from "./mock.json";
import type { SurveyQuestions, SurveyQuestionsFields } from "./Survey";
import { getWindow } from "../../utils/getWindow/getWindow";

export type Response = AxiosResponse<TTemplateFromDB[]>;
export type MappedResponse = AxiosResponse<TTemplate[]>;

declare global {
  interface Window {
    __vitest_worker__: boolean;
  }
}

const surveyAxios = axios.create();

surveyAxios.interceptors.request.use((config) => {
  if (getWindow()["__vitest_worker__"]) {
    return Promise.reject({
      mock: true,
      status: 200,
      data: mockResponse as SurveyQuestionsFields[],
      headers: {} as AxiosResponseHeaders,
    });
  } else {
    return config;
  }
});
surveyAxios.interceptors.response.use(
  (response: Response): MappedResponse | Response => {
    if (response.config.method !== "get") return response;
    const result = response.data.map((item) => {
      return formatKeys<TTemplateFromDB, TTemplate>(item);
    });

    return { ...response, data: result };
  },
  (error) => {
    if (error.mock) {
      const result = error.data.map((item: SurveyQuestionsFields) => {
        return formatKeys<SurveyQuestionsFields, SurveyQuestions>(item);
      });
      return { ...error, data: result };
    }
    return Promise.reject(error);
  }
);

export { surveyAxios };
