import { useEffect, useState } from "react";
import { modalMachineInstance } from "./index.ts";
import { ModalServiceState } from "./modal.service.ts";
import "./style.css";

export const ModalContainer = () => {
  const [modal, setModal] = useState<ModalServiceState>({ isOpen: false, views: [] });
  useEffect(() => {
    modalMachineInstance.start();
    modalMachineInstance.initialState.context.service.subscribe((st) => setModal(st));
    return () => {
      modalMachineInstance.stop();
      modalMachineInstance.initialState.context.service.unsubscribe(setModal);
    };
  }, []);

  if (!modal.isOpen || !modal.selectedView) {
    return null;
  }

  const ViewComponent = modal.selectedView.component;

  return (
    <div className="modal">
      <div className="modal__content">
        <ViewComponent />
      </div>
    </div>
  );
};
