import axios, { AxiosResponse, AxiosResponseHeaders, InternalAxiosRequestConfig } from "axios";
import createUserMock from "./mocks/createUser.json";
import getUserMock from "./mocks/getUser.json";
import { getWindow } from "../../utils/getWindow/getWindow";

export type Response = AxiosResponse<UserFields | CreateUserResponse>;

declare global {
  interface Window {
    __vitest_worker__: boolean;
  }
}

const userAxios = axios.create();

const isGetUser = (config: InternalAxiosRequestConfig) => config.method === "get";
userAxios.interceptors.request.use((config) => {
  if (getWindow()["__vitest_worker__"]) {
    return Promise.reject({
      mock: true,
      status: 200,
      data: isGetUser(config) ? getUserMock : createUserMock,
      headers: {} as AxiosResponseHeaders,
      isGetUser: isGetUser(config),
    });
  } else {
    return config;
  }
});

export type UserFields = {
  ID: number;
  USER_ID: string;
  FIGMA_ID: string;
  FIGMA_EMAIL: string;
  EMAIL: string;
  NAME: string;
  IMG_URL: string;
  CUSTOMER_ID?: string;
  SUBSCRIPTION_STATUS?: string;
  CURRENT_PERIOD_START?: number;
  CURRENT_PERIOD_END?: number;
  CREDITS?: number;
};

export type UserFieldsCamel = {
  id: number;
  userId: string;
  figmaId: string;
  email: string;
  name: string;
  imgUrl: string;
  customerId?: string;
  subscriptionStatus?: string;
  currentPeriodStart?: number;
  currentPeriodEnd?: number;
  credits?: number;
};

export type CreateUserResponse = {
  id: string;
  createdTime: string;
  fields: UserFields;
};

userAxios.interceptors.response.use(
  (response: Response): Response => {
    return response;
  },
  (error) => {
    if (error.mock) {
      return error;
    }
    // if (error.response?.status === 401) {
    //   authInstance.send("SESSION_EXPIRED");
    // }
    return Promise.reject(error);
  }
);

export { userAxios };
