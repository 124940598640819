import React, { type SVGProps } from "react";
export const AppTourIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_11833_485)">
      <rect x="4" y="2" width="40" height="40" rx="8" fill="url(#paint0_linear_11833_485)" />
      <rect x="4.5" y="2.5" width="39" height="39" rx="7.5" stroke="url(#paint1_linear_11833_485)" />
      <path
        d="M31.5 19.501V27L26.5 21.999L26.5025 17.0019H21.5V22L16.5 27V19.501L24.0044 11.999L31.5 19.501Z"
        fill="white"
      />
      <path d="M26.5 31.9998V27.0003H21.543V31.9998H26.5Z" fill="#D4D4D8" />
      <path
        d="M31.5 19.501V27L26.5 21.999L26.5025 17.0019H21.5V22L16.5 27V19.501L24.0044 11.999L31.5 19.501Z"
        fill="#FF4900"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_11833_485"
        x="0"
        y="0"
        width="48"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11833_485" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_11833_485" result="effect2_dropShadow_11833_485" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_11833_485" result="effect3_dropShadow_11833_485" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_11833_485" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_11833_485" x1="24" y1="2" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint1_linear_11833_485" x1="24" y1="2" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
