import { type CSSProperties } from "react";

type TLink = {
  className?: string;
  style?: CSSProperties;
};

export const Link = ({ className, style }: TLink) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M6.20404 7.74508H10.5961M6.79205 5.00007H5.14505C3.62902 5.00007 2.40002 6.22907 2.40002 7.74509C2.40004 9.26112 3.62902 10.4901 5.14504 10.4901L6.79204 10.4901M10.008 10.49H11.655C13.171 10.49 14.4 9.26106 14.4 7.74504C14.4 6.22896 13.171 5.00001 11.655 5L10.008 5.00001"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
