import React, { type SVGProps } from "react";

export const Personal = (props: SVGProps<SVGSVGElement>) => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.5447 38.2481C47.1599 37.6356 46.6568 37.1058 46.0648 36.6899C45.473 36.274 44.804 35.9803 44.0973 35.8258C41.3932 35.197 39.0481 33.5233 37.5742 31.1706C36.1004 28.8179 35.6177 25.9774 36.2317 23.27C36.4677 22.2235 36.3893 21.1307 36.0063 20.1286C35.6233 19.1266 34.9527 18.26 34.0789 17.6379C33.2049 17.0157 32.1667 16.6657 31.0944 16.6316C30.0223 16.5976 28.9639 16.8812 28.0523 17.4466C27.1408 18.0122 26.4166 18.8344 25.9708 19.8101C25.525 20.7859 25.3774 21.8716 25.5467 22.9309C25.7159 23.9902 26.1944 24.9759 26.922 25.7642C27.6495 26.5524 28.5938 27.1082 29.6362 27.3615C30.9848 27.6657 32.26 28.233 33.3889 29.031C34.5177 29.829 35.478 30.842 36.2146 32.0117C36.9512 33.1816 37.4497 34.4853 37.6814 35.8481C37.9133 37.2109 37.8738 38.6061 37.5654 39.9537C37.3827 40.8224 37.4153 41.7226 37.6602 42.5758C37.9052 43.429 38.3552 44.2094 38.9709 44.8489C39.5866 45.4884 40.3492 45.9676 41.1926 46.2448C42.0359 46.522 42.9342 46.5886 43.8092 46.4391C44.6842 46.2896 45.5094 45.9283 46.2128 45.3868C46.9162 44.8452 47.4764 44.14 47.8448 43.3323C48.2131 42.5246 48.3783 41.6391 48.3261 40.753C48.2737 39.8668 48.0056 39.0068 47.5447 38.2481Z"
      fill="#FF4900"
    />
    <path
      d="M14.0759 32.2064C15.0281 32.7261 16.1138 32.9494 17.1938 32.8476C18.2739 32.7459 19.2988 32.3238 20.1372 31.6355C20.9757 30.9472 21.5894 30.0241 21.8995 28.9846C22.2097 27.9451 22.2022 26.8367 21.8779 25.8015C21.4669 24.4814 21.3206 23.0934 21.4471 21.7167C21.5737 20.34 21.9707 19.0018 22.6155 17.7789C23.2603 16.556 24.1401 15.4724 25.2044 14.5901C26.2688 13.7078 27.4969 13.0444 28.8182 12.6377C29.8429 12.3168 30.7498 11.6998 31.4247 10.8646C32.0997 10.0294 32.5125 9.01322 32.6112 7.94391C32.71 6.87459 32.4903 5.79995 31.9798 4.85529C31.4692 3.91056 30.6906 3.13796 29.742 2.63473C28.7934 2.13151 27.7171 1.92006 26.6487 2.02712C25.5801 2.13411 24.5672 2.55474 23.7372 3.23615C22.9072 3.9175 22.2973 4.82916 21.9842 5.85634C21.6711 6.88353 21.6689 7.98037 21.9777 9.00882C22.7884 11.6639 22.5143 14.5322 21.2153 16.9857C19.9163 19.4391 17.6982 21.278 15.0466 22.1C13.9998 22.4035 13.0682 23.0143 12.3725 23.8533C11.6767 24.6923 11.2488 25.7208 11.1442 26.8057C11.0397 27.8906 11.2632 28.9819 11.7859 29.9383C12.3086 30.8947 13.1063 31.6722 14.0759 32.1701V32.2064Z"
      fill="#FF4900"
    />
    <path
      d="M28.0833 31.3162C27.3596 31.3416 26.6482 31.5112 25.9908 31.815C25.3334 32.1187 24.7432 32.5506 24.2548 33.0853C22.36 35.112 19.7397 36.3061 16.967 36.4064C14.1942 36.5067 11.4945 35.5051 9.45813 33.6206C8.93197 33.1269 8.31295 32.7427 7.637 32.4904C6.96112 32.2381 6.24178 32.1227 5.52084 32.1509C4.63344 32.1927 3.76971 32.4512 3.00517 32.9036C2.24057 33.356 1.59842 33.9887 1.13466 34.7464C0.670828 35.5041 0.399583 36.3639 0.344454 37.2505C0.289392 38.1373 0.452113 39.0239 0.81855 39.8333C1.18499 40.6425 1.74388 41.3499 2.44655 41.8935C3.14923 42.4371 3.97423 42.8004 4.84963 42.9518C5.72502 43.1032 6.62422 43.0381 7.46862 42.762C8.31308 42.486 9.07709 42.0075 9.69397 41.3682C10.6324 40.3522 11.7621 39.5312 13.0182 38.9525C14.2745 38.3737 15.6325 38.0484 17.0146 37.9953C18.3967 37.9423 19.7757 38.1623 21.0726 38.643C22.3695 39.1238 23.5588 39.8556 24.5724 40.7967C25.2323 41.3929 26.0282 41.8184 26.8905 42.0358C27.7529 42.2533 28.6554 42.2562 29.5191 42.0442C30.3828 41.8322 31.1814 41.4119 31.8451 40.8198C32.5087 40.2278 33.0172 39.4822 33.3261 38.6482C33.635 37.8142 33.7348 36.9172 33.6169 36.0357C33.4989 35.1542 33.1667 34.3151 32.6493 33.5916C32.132 32.8682 31.4454 32.2826 30.6494 31.8859C29.8534 31.4893 28.9724 31.2936 28.0833 31.3162Z"
      fill="#FF4900"
    />
  </svg>
);
