import { type CSSProperties } from "react";

type TGradient = {
  className?: string;
  style?: CSSProperties;
};

export const Gradient = ({ className, style }: TGradient) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14 2V14M5 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H5C5.55228 14 6 13.5523 6 13V3C6 2.44772 5.55228 2 5 2ZM10.5 2H9.5C8.94772 2 8.5 2.44772 8.5 3V13C8.5 13.5523 8.94772 14 9.5 14H10.5C11.0523 14 11.5 13.5523 11.5 13V3C11.5 2.44772 11.0523 2 10.5 2Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
