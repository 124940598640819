import { type CSSProperties } from "react";

type TSettings = {
  className?: string;
  style?: CSSProperties;
};

export const Settings = ({ className, style }: TSettings) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7 8.8C7.99414 8.8 8.8 7.99414 8.8 7C8.8 6.00586 7.99414 5.2 7 5.2C6.00586 5.2 5.2 6.00586 5.2 7C5.2 7.99414 6.00586 8.8 7 8.8Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5734 6.03724L10.9148 4.44688L11.8 3.4L10.6 2.2L9.55882 3.08977L7.93468 2.42184L7.56118 1H6.3886L6.00946 2.44068L4.42265 3.10958L3.4 2.2L2.2 3.4L3.07202 4.47331L2.4235 6.06778L1 6.4V7.6L2.44067 7.9933L3.10945 9.57982L2.2 10.6L3.4 11.8L4.4747 10.9242L6.0382 11.5674L6.4 13H7.6L7.9627 11.5679L9.55306 10.9093C9.81814 11.0988 10.6 11.8 10.6 11.8L11.8 10.6L10.9095 9.54964L11.5683 7.9588L12.9999 7.58632L13 6.4L11.5734 6.03724Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
