import { type CSSProperties } from "react";

type TUndo = {
  className?: string;
  style?: CSSProperties;
};

export const Undo = ({ className, style }: TUndo) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.69231 5.09231H9.30769C11.3469 5.09231 13 6.74541 13 8.78462C13 10.8238 11.3469 12.4769 9.30769 12.4769H7M1 5.09231L4.69231 1.4V8.78462L1 5.09231Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
