import type { TComingSoon } from "./ComingSoon.types";

import "./ComingSoon.css";

export const ComingSoon: TComingSoon = ({ children, className, fullWidth }) => {
  return (
    <div className={`coming-soon${className ? ` ${className}` : ""}`}>
      <div className={`coming-soon__content${fullWidth ? " coming-soon__content--full-width" : ""}`}>{children}</div>
      <div className="coming-soon__tooltip">Coming soon</div>
    </div>
  );
};
