import { createMachine } from "xstate";
import { CreateAccountService } from "../../components/pages/CreateAccount/CreateAccount.service.ts";
import { dashboardInstance } from "../dashboard/dashboard.instance";
import { routerInstance } from "../router/router.instance.ts";

const createUserFormMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
    tsTypes: {} as import("./create-user-form.machine.typegen").Typegen0,
    predictableActionArguments: true,
    id: "CREATE_USER_FORM",
    initial: "START",
    states: {
      START: {
        always: {
          target: "FETCH_QUESTIONS",
        },
      },

      FETCH_QUESTIONS: {
        invoke: {
          src: "fetchQuestions",
          onDone: {
            target: "QUESTIONS",
            actions: () => routerInstance.send("NEW_USER_FORM"),
          },
          onError: {
            target: "ERROR",
            actions: () => console.log("error"),
          },
        },
      },

      QUESTIONS: {
        on: {
          SAVE_ANSWER: {
            target: "SAVE_ANSWER",
          },
          NEXT_QUESTION: {
            target: "IS_FINAL_QUESTION",
          },
          PREVIOUS_QUESTION: {
            target: "PREVIOUS_QUESTION",
          },
        },
      },

      SAVE_ANSWER: {
        invoke: {
          src: "saveAnswer",
          onDone: {
            target: "QUESTIONS",
          },
        },
      },

      IS_FINAL_QUESTION: {
        always: [{ target: "PREPARE_ANSWERS", cond: "isFinalQuestion" }, { target: "NEXT_QUESTION" }],
      },

      NEXT_QUESTION: {
        invoke: {
          src: "nextQuestion",
          onDone: {
            target: "QUESTIONS",
            actions: () => console.log("NEXT QUESTION"),
          },
        },
      },

      PREVIOUS_QUESTION: {
        invoke: {
          src: "previousQuestion",
          onDone: {
            target: "QUESTIONS",
            actions: () => console.log("PREVIOUS QUESTION"),
          },
        },
      },

      PREPARE_ANSWERS: {
        invoke: {
          src: "prepareAnswers",
          onDone: {
            target: "ANSWERS_READY",
          },
        },
      },

      ANSWERS_READY: {
        entry: ["isLoading", () => dashboardInstance.send("ANSWERS_READY_TO_SEND")],
        on: {
          SEND_ANSWERS: "SEND_ANSWERS",
        },
      },

      SEND_ANSWERS: {
        entry: () => console.log("SEND_ANSWERS"),
        invoke: {
          src: "sendAnswers",
          onDone: {
            target: "FINISHED",
            actions: [() => dashboardInstance.send("DASHBOARD"), "isNotLoading"],
          },
          onError: {
            target: "ERROR",
          },
        },
      },

      FINISHED: {
        type: "final",
      },
      ERROR: {},
    },
  },
  {
    actions: {
      isLoading: () => CreateAccountService.getInstance().loading(true),
      isNotLoading: () => CreateAccountService.getInstance().loading(true),
    },
    services: {
      prepareAnswers: () => {
        CreateAccountService.getInstance().prepareAnswers();
        return Promise.resolve();
      },
      sendAnswers: async () => {
        return CreateAccountService.getInstance().sendAnswers();
      },
      fetchQuestions: async () => {
        return CreateAccountService.getInstance().fetchQuestions();
      },
      nextQuestion: async () => {
        return CreateAccountService.getInstance().nextQuestion();
      },
      previousQuestion: async () => {
        return CreateAccountService.getInstance().handleBack();
      },
      saveAnswer: (_, options: { answer: string; isChecked: boolean }) => {
        return CreateAccountService.getInstance().submitAnswer(options.answer, options.isChecked);
      },
    },
    guards: {
      isFinalQuestion: () => {
        return CreateAccountService.getInstance().isFinalQuestion();
      },
    },
  }
);

export { createUserFormMachine };
