import { type CSSProperties } from "react";

type TCornerBottomLeft = {
  className?: string;
  style?: CSSProperties;
};

export const CornerBottomLeft = ({ className, style }: TCornerBottomLeft) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4 4V9.33334C4 10.0405 4.28096 10.7189 4.78104 11.2189C5.28115 11.7189 5.95941 12 6.66667 12H12"
      stroke="#E4E4E7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
