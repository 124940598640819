import { ButtonNew } from "../../../atoms/ButtonNew";
import { WeirdIconWrapper } from "../../../atoms/WeirdIconWrapper";

import { Catalog } from "../../../../../assets/svg/catalog";

import { ImportModal } from "../../molecules/ImportModal/ImportModal.service";

const importModal = ImportModal.getInstance();

export const NoContent = () => {
  return (
    <div className="my-projects__projects-no-content">
      <WeirdIconWrapper>
        <Catalog />
      </WeirdIconWrapper>
      <h1 className="my-projects__projects-content-title">No projects yet</h1>
      <h2 className="my-projects__projects-content-subtitle">
        Click{" "}
        <ButtonNew
          orange
          type="text"
          className="my-projects__projects-content-subtitle-btn"
          onClick={importModal.openModal}
        >
          Import from Figma
        </ButtonNew>{" "}
        to add your first project
      </h2>
    </div>
  );
};
