import type { SVGProps } from "react";

export const DesignSystem = (props: SVGProps<SVGSVGElement>) => (
  <svg width="75" height="86" viewBox="0 0 75 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dddd_20270_2785)">
      <rect x="5.5" y="3" width="64" height="64" rx="16" fill="white" />
      <rect x="6" y="3.5" width="63" height="63" rx="15.5" stroke="url(#paint0_linear_20270_2785)" />
      <path
        d="M37.0298 20.8046L31.9726 25.8618C31.7123 26.1222 31.7123 26.5443 31.9726 26.8046L37.0298 31.8618C37.2902 32.1222 37.7123 32.1222 37.9726 31.8618L43.0298 26.8046C43.2902 26.5443 43.2902 26.1222 43.0298 25.8618L37.9726 20.8046C37.7123 20.5443 37.2902 20.5443 37.0298 20.8046Z"
        fill="#FF4900"
      />
      <path
        d="M37.0298 38.1377L31.9726 43.1949C31.7123 43.4553 31.7123 43.8773 31.9726 44.1377L37.0298 49.1949C37.2902 49.4553 37.7123 49.4553 37.9726 49.1949L43.0298 44.1377C43.2902 43.8773 43.2902 43.4553 43.0298 43.1949L37.9726 38.1377C37.7123 37.8773 37.2902 37.8773 37.0298 38.1377Z"
        fill="#D4D4D8"
      />
      <path
        d="M23.3046 34.5288L28.3619 29.4716C28.6222 29.2113 29.0443 29.2113 29.3047 29.4716L34.3618 34.5288C34.6222 34.7892 34.6222 35.2112 34.3618 35.4716L29.3047 40.5288C29.0443 40.7892 28.6222 40.7892 28.3619 40.5288L23.3046 35.4716C23.0443 35.2112 23.0443 34.7892 23.3046 34.5288Z"
        fill="#D4D4D8"
      />
      <path
        d="M45.6959 29.4716L40.6387 34.5288C40.3783 34.7892 40.3783 35.2112 40.6387 35.4716L45.6959 40.5288C45.9563 40.7892 46.3784 40.7892 46.6387 40.5288L51.6959 35.4716C51.9563 35.2112 51.9563 34.7892 51.6959 34.5288L46.6387 29.4716C46.3784 29.2113 45.9563 29.2113 45.6959 29.4716Z"
        fill="#D4D4D8"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddd_20270_2785"
        x="0.166667"
        y="0.333333"
        width="74.6667"
        height="85.3333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20270_2785" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.66667" />
        <feGaussianBlur stdDeviation="1.33333" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_20270_2785" result="effect2_dropShadow_20270_2785" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_20270_2785" result="effect3_dropShadow_20270_2785" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13.3333" />
        <feGaussianBlur stdDeviation="2.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect3_dropShadow_20270_2785" result="effect4_dropShadow_20270_2785" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_20270_2785" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_20270_2785" x1="37.5" y1="3" x2="37.5" y2="67" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
