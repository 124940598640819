import { Editor } from "grapesjs";
import { Categories } from "./types";
import { TColorReferences, TRgbaColorArray } from "./ColorMode.types";

export const formatLabel = (label: string) => {
  const categories = label.match(/[^_]+(?=_)/g);
  //remove all _ and replace with space
  let formattedLabel = label.replace(/_/g, " ");
  // remove categories
  formattedLabel = formattedLabel.replace("colours", " ");
  categories?.forEach((category) => {
    formattedLabel = formattedLabel.replace(category, "");
  });
  // replace - sign with space
  formattedLabel = formattedLabel.replace(/-/g, " ");
  // remove multiple spaces
  formattedLabel = formattedLabel.replace(/ {2,}/g, " ");
  return formattedLabel;
};

const formatCategory = (label: string) => {
  return label.replace(/_/g, " ").replace(/-/g, " ");
};

const addColorToCategory = (categoryObj: any, keys: string[], currentKey: string, currentValue: string) => {
  if (keys.length === 0) return;
  const currentCategory = formatCategory(keys[0]);
  if (!(currentCategory in categoryObj)) {
    categoryObj[currentCategory] = { colors: [], categories: {} };
  }

  if (keys.length === 1) {
    categoryObj[currentCategory].colors.push({
      colorLabel: formatLabel(currentKey),
      colorName: currentKey,
      colorValue: currentValue,
    });
  } else {
    addColorToCategory(categoryObj[currentCategory].categories, keys.slice(1), currentKey, currentValue);
  }
};

export const getCategoriesFromColors = (colors: Record<string, any>) => {
  return Object?.entries(Object.assign({}, colors)).reduce<Categories>((acc, [currentKey, currentValue]) => {
    // check if value is color
    if (!currentValue.startsWith("rgba(")) return acc;

    let keys = currentKey.match(/[^_]+(?=_)/g);
    // if we don't have keys we need to add default keys
    if (!keys) {
      keys = ["--colours", "without-category"];
    }
    // theme colours start with --colours
    // when some create own category, add default --colours category for consistency
    if (keys[0] !== "--colours") {
      keys.unshift("--colours");
    }

    keys.shift();

    addColorToCategory(acc, keys, currentKey, currentValue);

    return acc;
  }, {});
};

export const blendTwoColors = (color1: TRgbaColorArray, color2: TRgbaColorArray): TRgbaColorArray => {
  // Decompose each color into red, green, blue, and alpha components
  const [r1, g1, b1, a1] = color1;
  const [r2, g2, b2, a2] = color2;

  // Calculate the mixed alpha value
  const mixedAlpha = a1 + a2 * (1 - a1);

  // Return a transparent color for fully transparent alpha
  if (mixedAlpha === 0) {
    return [0, 0, 0, 0];
  }

  // Calculate and normalize mixed color components
  const mixedR = (r1 * a1 + r2 * a2 * (1 - a1)) / mixedAlpha;
  const mixedG = (g1 * a1 + g2 * a2 * (1 - a1)) / mixedAlpha;
  const mixedB = (b1 * a1 + b2 * a2 * (1 - a1)) / mixedAlpha;
  return [mixedR, mixedG, mixedB, mixedAlpha];
};

export const extractColorValues = (color: string): TRgbaColorArray => {
  const regex = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const matches = color.match(regex);
  if (matches) {
    const [, r, g, b, a] = matches;
    return [parseInt(r), parseInt(g), parseInt(b), parseFloat(a)];
  }
  return [0, 0, 0, 0];
};

export const mixColors = (colors: string[]): string => {
  const [firstColor, ...restColors] = colors.map((color) => extractColorValues(color));
  const [mixedR, mixedG, mixedB, mixedA] = restColors.reduce((acc, color) => {
    return blendTwoColors(acc, color);
  }, firstColor);
  // parseFloat remove trailing zeros
  return `rgba(${mixedR.toFixed()}, ${mixedG.toFixed()}, ${mixedB.toFixed()}, ${parseFloat(mixedA.toFixed(2))})`;
};

export const calculateUpdatedColors = ({
  colorName,
  colorValue,
  colorReferences,
  editor,
}: {
  colorName: string;
  colorValue: string;
  colorReferences: TColorReferences;
  editor: Editor | null;
}) => {
  if (!editor) return;
  const localColorsToChange = colorReferences[colorName];
  const newColors: Record<string, string> = {};
  Object.keys(localColorsToChange).forEach((key) => {
    const colorEntry = localColorsToChange[key];
    // if --primary-500: var(--colours_brand_primary-500);
    if (typeof colorEntry === "string") {
      newColors[key] = colorValue;
      // if primary-500: ["rgba(0,0,0,0)", "--colours_brand_primary-500"];
    } else if (Array.isArray(colorEntry)) {
      // replace "--colours_brand_primary-500" with colorValue
      const updatedColors = colorEntry.map((color) => (color === colorName ? colorValue : color));
      // if there is only one color, return it
      if (colorEntry.length === 1) {
        return (newColors[key] = colorValue);
      }

      newColors[key] = mixColors(updatedColors);
    }
  });

  return newColors;
};
