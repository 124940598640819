import { useEffect, useState } from "react";

import useOutsideClick from "../../../../../hooks/useOutsideClick/useOutsideClick";

import { IconButton } from "../../../../atoms/IconButton";

import { FigmaInfoModal as FigmaInfoModallService } from "./FigmaInfoModal.service";

import { Close } from "../../../../../../assets/svg/close";

import { TFigmaInfoModal } from "./FigmaInfoModal.types";

import "./FigmaInfoModal.css";

const importModal = FigmaInfoModallService.getInstance();

export const FigmaInfoModal: TFigmaInfoModal = ({ message }) => {
  const [_, setState] = useState(false);
  useEffect(() => {
    importModal.subscribe(setState);
    return () => importModal.unsubscribe(setState);
  }, []);
  const isOpen = importModal.state;
  const closeModal = () => importModal.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);

  return (
    <div className="figma-info-modal__wrapper">
      <div className={"figma-info-modal__backdrop" + (isOpen ? " figma-info-modal__backdrop--visible" : "")}>
        <div className={`figma-info-modal ${isOpen && "figma-info-modal--visible"}`} ref={ref}>
          <div className="figma-info-modal__header">
            <span className="figma-info-modal__typography figma-info-modal__typography--bold">Info</span>
            <IconButton
              className="figma-info-modal__btn-icon"
              ariaLabel="Close 'Import design from Figma' modal"
              onClick={() => closeModal()}
            >
              <Close />
            </IconButton>
          </div>
          <div className="figma-info-modal__typography figma-info-modal__typography--regular">{message}</div>
        </div>
      </div>
    </div>
  );
};
