import { WeirdIconWrapper } from "../../../../atoms/WeirdIconWrapper";

import { Template } from "../../../../../../assets/svg/template";

import type { TOffer } from "./Offer.types";

import "./Offer.css";

export const Offer: TOffer = ({
  isOrangeBackground,
  headingText,
  subheadingText,
  price,
  priceDescription,
  onClick,
}) => {
  return (
    <div className={`offer__description ${isOrangeBackground ? "orange-background" : ""}`} onClick={onClick}>
      <WeirdIconWrapper>
        <Template className="offer__icon" />
      </WeirdIconWrapper>
      <h2 className="offer__typography offer__description-heading">{headingText}</h2>
      <h3 className="offer__typography offer__description-subheading">{subheadingText}</h3>
      <div className="offer__price-section">
        <strong className="offer__typography offer__price-value">${price}</strong>
        <span className="offer__typography offer__price-value__description">&nbsp;{priceDescription}</span>
      </div>
    </div>
  );
};
