import { TFallbackAvatar } from "./FallbackAvatar.types";

export const FallbackAvatar: TFallbackAvatar = ({ username, size = "40px" }) => {
  // Funkcja generująca losowe tło na podstawie nazwy użytkownika
  const generateBackgroundColor = (input: string): string => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 80%)`;
    return color;
  };

  // Wyciąganie pierwszej litery username
  const firstLetter = username.charAt(0).toUpperCase();

  // Generowanie koloru tła
  const backgroundColor = generateBackgroundColor(username);

  return (
    <div
      style={{
        backgroundColor,
        width: size,
        height: size,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `calc( ${size} * 0.4)`,
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      }}
    >
      {firstLetter}
    </div>
  );
};
