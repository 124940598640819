import axios, { AxiosResponseHeaders, AxiosResponse } from "axios";
import type { TAxiosGetFigmaFile } from ".";
import { getWindow } from "../../utils/getWindow/getWindow";
import { authInstance } from "../../machines/auth/auth.instance";

type Response = AxiosResponse<TAxiosGetFigmaFile>;

declare global {
  interface Window {
    __vitest_worker__: boolean;
  }
}

const johnyWalkerAxios = axios.create();

johnyWalkerAxios.interceptors.request.use((config) => {
  if (getWindow()["__vitest_worker__"]) {
    return Promise.reject({
      mock: true,
      status: 200,
      data: { status: 200, data: { stopa: "" } },
      headers: {} as AxiosResponseHeaders,
    });
  } else {
    return config;
  }
});

johnyWalkerAxios.interceptors.response.use(
  (response: Response): Response => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      authInstance.send("SESSION_EXPIRED");
    }
    return Promise.reject(error);
  }
);

export { johnyWalkerAxios };
