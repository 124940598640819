import { type CSSProperties } from "react";

type TTag = {
  className?: string;
  style?: CSSProperties;
};

export const Tag = ({ className, style }: TTag) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="Vector">
      <path
        d="M1 7.33951L1 2.2465C1 1.55805 1.55808 0.999986 2.24652 0.999986H7.33952C7.67011 0.999986 7.98717 1.13137 8.22093 1.36509L16.6349 9.77906C17.1217 10.2659 17.1217 11.0551 16.6349 11.5419L11.5419 16.6349C11.0551 17.1217 10.2659 17.1217 9.77908 16.6349L1.3651 8.22092C1.13133 7.98716 1 7.67009 1 7.33951Z"
        stroke="#71717A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.67444 5.05116C5.67444 5.39538 5.39539 5.67442 5.05118 5.67442C4.70696 5.67442 4.42792 5.39538 4.42792 5.05116C4.42792 4.707 4.70696 4.42791 5.05118 4.42791C5.39539 4.42791 5.67444 4.707 5.67444 5.05116Z"
        stroke="#71717A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
