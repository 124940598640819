import { useState, useEffect, useRef } from "react";

import { PropertyLabel } from "../PropertyLabel";
import { SelectButton } from "../SelectButton";
import { editorService } from "../../../editor";
import { materialSymbolRules } from "../../helpers/materialSymbols";
import { styleManagerService } from "../../StylesManager.service";

import type { TIconStyleProperty } from "./IconStyleProperty.types";

import "./IconStyleProperty.css";

const options = [
  {
    id: "1",
    value: "outlined",
    name: "Outlined",
    label: "Outlined",
  },
  {
    id: "2",
    value: "rounded",
    label: "Rounded",
    name: "Rounded",
  },
  {
    id: "3",
    value: "sharp",
    label: "Sharp",
    name: "Sharp",
  },
];

export const IconStyleProperty: TIconStyleProperty = () => {
  const [value, setValue] = useState("outlined");
  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    materialSymbolRules((rule) => {
      const selector = rule?.getSelectorsString();

      if (selector) {
        const value = selector.replace(".material-symbols-", "");

        setValue(value);
      }
    });
  }, []);

  const handleChange = (ev: string) => {
    const editor = editorService.getEditor();

    materialSymbolRules((rule) => {
      if (editor) {
        const childrenArrayWithSelector = editor.getWrapper()?.find(`.material-symbols-${value}`);
        if (childrenArrayWithSelector !== undefined && rule) {
          setValue(ev);
          editor.trigger("component:update", {
            materialSymbolType: ev,
          });

          editor.Css.addRules(`.material-symbols-${ev}`);
          editor.Css.setRule(`.material-symbols-${ev}`, rule?.getStyle());

          childrenArrayWithSelector.forEach((child) => {
            child.removeClass(`material-symbols-${value}`);
            child.addClass(`material-symbols-${ev}`);
          });

          editor.Css.remove(`.material-symbols-${value}`);
        }
      }
    });
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    const target = propertyRef.current?.querySelector(".select-button") || propertyRef.current;
    const coordinates = target?.getBoundingClientRect();

    if (!coordinates) return;

    styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
  };

  return (
    <div className={`property-select vertical full-width`} ref={propertyRef}>
      <PropertyLabel text={"Style"} modifier={"primary"} />
      <SelectButton currentOption={value} options={options} onClick={handleUnitDropdown} />
    </div>
  );
};
