import { useEffect, useState } from "react";

import { editorInstance, editorService } from "../../../editor";

import { ChevronDown } from "../../../../../../../../assets/svg/chevron_down";

import { type TElementsBlock } from "./ElementsBlock.types";

import "./ElementsBlock.css";

export const ElementsBlock: TElementsBlock = ({ label, category, filter }) => {
  const [isUnfolded, setIsUnfolded] = useState(true);
  const categoryBlocks = editorService.blocks.filter((block) => {
    const filterByCategory = block.category === category;
    const isBlockLabelIncludesFilter = block.label.toLowerCase().includes(filter.toLowerCase());
    const filterByUser = filter !== "" ? isBlockLabelIncludesFilter : true;

    return filterByCategory && filterByUser;
  });
  // open category if search is active
  useEffect(() => {
    if (filter !== "" && !isUnfolded) {
      setIsUnfolded(true);
    }
  }, [filter]);

  if (categoryBlocks.length === 0) return null;

  return (
    <>
      <h2
        className={`blocks-menu__heading${isUnfolded ? " active" : ""}`}
        onClick={() => setIsUnfolded((prev) => !prev)}
      >
        {label}
        <ChevronDown />
      </h2>
      {isUnfolded && (
        <ul className="blocks-menu__blocks-list">
          {categoryBlocks.map((block) => {
            return (
              <li
                draggable
                key={block.id}
                onDragEnd={() => editorInstance.send("DRAG_END", { id: block.id })}
                onDragStart={() => editorInstance.send("DRAG_START", { id: block.id })}
              >
                <div className="blocks-menu__block-element">
                  <figure
                    className="blocks-menu__svg-wrapper"
                    dangerouslySetInnerHTML={{ __html: block.media || "" }}
                  ></figure>
                  <span className="truncate-text">{block.label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
