import { useState, useEffect } from "react";
import { fontModalServiceInstance } from "./FontModal.service";
import { Select } from "../../../atoms/Select";
import { Close } from "../../../../assets/svg/Close";
import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";

import { TFontModal } from "./FontModal.types";

import "./FontModal.css";
import { extractSelectedOptionName, fontWeightToNumber, getFontModalProperties, getPreviewStyles } from "./utils";

export const FontModal: TFontModal = () => {
  const [modalState, setModalState] = useState(fontModalServiceInstance.state);
  const ref = useOutsideClick<HTMLDivElement>(() => {
    fontModalServiceInstance.closeModal();
  });

  const exampleStyles = getPreviewStyles(modalState.properties);
  const fontModalProperties = getFontModalProperties(modalState.properties, modalState.rootStyles);

  useEffect(() => {
    fontModalServiceInstance.subscribe(setModalState);
    return () => {
      fontModalServiceInstance.unsubscribe(setModalState);
    };
  }, []);

  const handleChange = (property: string, value: string) => {
    // get full properties and variables for change
    const fullProperty = `--${modalState.name}_${property}`;
    let fullVariable;
    // css don't support font-weight as string in some cases so we need to convert it to number
    if (property === "font-weight") {
      const rootFontWeight = modalState.rootStyles[value];
      fullVariable = fontWeightToNumber(rootFontWeight);
      value = fullVariable;
    } else {
      fullVariable = `var(${value})`;
    }
    modalState.onChange?.(fullProperty, fullVariable);
    // set new properties used to calculate example styles
    const updatedProperties = { ...modalState.properties, [property]: value };
    fontModalServiceInstance.setStyles(updatedProperties, modalState.rootStyles);
  };

  if (!modalState.isOpen) return null;
  return (
    <div className="font-modal" ref={ref}>
      <h2 className="font-modal__heading">
        <span>Edit text style</span>
        <button aria-label="Close font modal" onClick={fontModalServiceInstance.closeModal}>
          <Close />
        </button>
      </h2>
      <div
        className="font-modal__example"
        style={{
          ...modalState.rootStyles,
          ...exampleStyles,
        }}
      >
        <span>Reg</span>
      </div>
      <div className="font-modal__properties">
        <div className="font-modal__label">
          <span>Name</span>
          <span className="font-modal__display-name">{modalState.displayName}</span>
        </div>

        <span className="font-modal__label font-modal__label--spacing">Properties</span>

        {Object.keys(fontModalProperties).length !== 0 &&
          Object.entries(fontModalProperties).map(([key, value], index) => {
            const { currentVar, options } = value;
            const selectedOption = extractSelectedOptionName(currentVar, modalState.rootStyles);
            const currentProperty = currentVar.cssProperty.replace("-", " ");
            // need to overlap select with label
            const zIndex = 999 - index;

            return (
              <label
                className="font-modal__label font-modal__label--relative"
                htmlFor=""
                key={`${modalState.name}-${key}-${index}`}
                style={{ zIndex: zIndex }}
              >
                <span className="font-modal__current-property">{currentProperty}</span>

                <Select
                  options={Object.entries(options).map(([_, rootValue]) => ({
                    label: rootValue.label,
                    value: rootValue.variable,
                  }))}
                  defaultValue={selectedOption}
                  onChange={(value) => handleChange(currentVar.cssProperty, value)}
                />
              </label>
            );
          })}
      </div>
    </div>
  );
};
