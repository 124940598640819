import { useEffect, useState } from "react";
import { modalMachineInstance } from "../../index.ts";
import { projectsService } from "../../../../../../../machines/projects/projects.instance.ts";
import { editorInstance } from "../../../editor/index.ts";
import { AlertTriangle } from "../../../../../../../../assets/svg/alert-triangle.tsx";

import "./UnpublishDomain.css";

export const UnpublishDomainModal = () => {
  const [_, updateState] = useState(projectsService.state);

  useEffect(() => {
    projectsService.subscribe(updateState);
    return () => projectsService.unsubscribe(updateState);
  }, []);

  const activeProject = projectsService.getActiveProject();

  const handleUnpublish = () => {
    if (editorInstance.getSnapshot().context.domainToUnpublish === "customDomain") {
      editorInstance.send("UNPUBLISH_PROJECT", {
        projectId: activeProject?.projectId,
        customDomain: activeProject?.customDomain.address,
      });
    } else {
      editorInstance.send("UNPUBLISH_PROJECT", {
        projectId: activeProject?.projectId,
      });
    }
  };

  return (
    <div className="unpublish-modal">
      <div className="unpublish-modal__info-wrapper">
        <div className="unpublish-modal__svg-wrapper">
          <AlertTriangle />
        </div>
        <h2>Unpublish this domain</h2>
        <p>
          Are you sure you want to unpublish this domain? Unpublishing works instantaneously. You can always publish
          your project again
        </p>
      </div>
      <div className="unpublish-modal__cta-wrapper">
        <button
          className="unpublish-modal__cta unpublish-modal__cta--cancel"
          onClick={() => modalMachineInstance.send("CHANGE_VIEW", { viewId: "domains" })}
        >
          Cancel
        </button>
        <button className="unpublish-modal__cta unpublish-modal__cta--unpublish" onClick={handleUnpublish}>
          Unpublish
        </button>
      </div>
    </div>
  );
};
