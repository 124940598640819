import { Home } from "../../../assets/svg/Home";
// import { Rocket } from "../../../../../assets/svg/Rocket";
// import { MoneyWithdraw } from "../../../assets/svg/MoneyWithdraw";
// import { SearchAlt } from "../../../../../assets/svg/SearchAlt";
// import { Fonts } from "../../../../../assets/svg/Fonts";
// import { Backups } from "../../../../../assets/svg/Backups";
// import { Analytics } from "../../../../../assets/svg/Analytics";
// import { CustomCode } from "../../../../../assets/svg/CustomCode";

import type { TProjectSettingsNavigation } from "./ProjectSettingsNavigation.types";

import "./ProjectSettingsNavigation.css";
import { ChevronDown } from "../../../assets/svg/ChevronDown";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";
import { Button } from "../../AlphaO/atoms/Button";
import { Starter } from "../../../assets/svg/Starter";
import { useEffect, useState } from "react";
import { projectsInstance } from "../../../machines/projects/projects.instance";
import { Project } from "../../../types/types";

export const ProjectSettingsNavigation: TProjectSettingsNavigation = ({ activeTab, setActiveTab }) => {
  const [activeProject, setActiveProject] = useState<Project>();
  const { priceId = "" } = activeProject?.subscription || {};

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
  }, []);

  return (
    <aside className="project-settings__navigation">
      <button className="project-settings__breadcrumb" onClick={() => dashboardInstance.send("PROJECTS")}>
        <ChevronDown />
        Back to my projects
      </button>
      <button
        className={`project-settings__navigation-button${
          activeTab === "GENERAL" ? " project-settings__navigation-button--active" : ""
        }`}
        onClick={() => {
          if (activeTab !== "GENERAL") setActiveTab("GENERAL");
        }}
      >
        <Home />
        <span className="project-settings__navigation-text">General</span>
      </button>
      {/* <button className="project-settings__navigation-button">
        <Rocket />
        <p className="project-settings__navigation-text">Publish</p>
      </button> */}
      <button
        className={`project-settings__navigation-button${
          activeTab === "PLANS" ? " project-settings__navigation-button--active" : ""
        }`}
        onClick={() => {
          if (activeTab !== "PLANS") setActiveTab("PLANS");
        }}
      >
        <Home />
        <p className="project-settings__navigation-text">Site plan</p>
      </button>
      {/* <button className="project-settings__navigation-button">
        <SearchAlt /> <p className="project-settings__navigation-text">SEO</p>
      </button>
      <button className="project-settings__navigation-button">
        <Fonts /> <p className="project-settings__navigation-text">Fonts</p>
      </button>
      <button className="project-settings__navigation-button">
        <Backups />
        <p className="project-settings__navigation-text">Backups</p>
      </button>
      <button className="project-settings__navigation-button">
        <Analytics />
        <p className="project-settings__navigation-text">Analytics</p>
      </button>
      <button className="project-settings__navigation-button">
        <CustomCode /> <p className="project-settings__navigation-text">Custom code</p>
      </button> */}

      {priceId === "" && (
        <div className="project-settings__info-card">
          <p className="project-settings__info-card-label">Current website plan</p>
          <p className="project-settings__info-card-plan">
            <Starter className="project-settings__info-card-svg" />
            Starter plan
          </p>
          <p className="project-settings__info-card-heading">Need more project pages or custom domain ?</p>
          <p className="project-settings__info-card-description">
            Upgrade to the Essential Plan for enhanced capabilities to create and publish up to 50 pages on your custom
            domains.
          </p>
          <Button type="secondary" onClick={() => setActiveTab("PLANS")}>
            View plans
          </Button>
        </div>
      )}
    </aside>
  );
};
