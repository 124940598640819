import React, { type SVGProps } from "react";
export const AppTour = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_11268_1219)">
      <rect x="4" y="2" width="40" height="40" rx="8" fill="url(#paint0_linear_11268_1219)" />
      <rect x="4.5" y="2.5" width="39" height="39" rx="7.5" stroke="url(#paint1_linear_11268_1219)" />
      <path
        d="M25.9922 27H28.9922M28.9922 27H31.9922M28.9922 27V24M28.9922 27V30"
        stroke="#FF4900"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19.4V14.6C16 14.2686 16.2686 14 16.6 14H21.4C21.7314 14 22 14.2686 22 14.6V19.4C22 19.7314 21.7314 20 21.4 20H16.6C16.2686 20 16 19.7314 16 19.4Z"
        fill="#D4D4D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 14.75V19.25H21.25V14.75H16.75ZM15.25 14.6C15.25 13.8544 15.8544 13.25 16.6 13.25H21.4C22.1456 13.25 22.75 13.8544 22.75 14.6V19.4C22.75 20.1456 22.1456 20.75 21.4 20.75H16.6C15.8544 20.75 15.25 20.1456 15.25 19.4V14.6Z"
        fill="#D4D4D8"
      />
      <path
        d="M16 29.4V24.6C16 24.2686 16.2686 24 16.6 24H21.4C21.7314 24 22 24.2686 22 24.6V29.4C22 29.7314 21.7314 30 21.4 30H16.6C16.2686 30 16 29.7314 16 29.4Z"
        fill="#D4D4D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 24.75V29.25H21.25V24.75H16.75ZM15.25 24.6C15.25 23.8544 15.8544 23.25 16.6 23.25H21.4C22.1456 23.25 22.75 23.8544 22.75 24.6V29.4C22.75 30.1456 22.1456 30.75 21.4 30.75H16.6C15.8544 30.75 15.25 30.1456 15.25 29.4V24.6Z"
        fill="#D4D4D8"
      />
      <path
        d="M26 19.4V14.6C26 14.2686 26.2686 14 26.6 14H31.4C31.7314 14 32 14.2686 32 14.6V19.4C32 19.7314 31.7314 20 31.4 20H26.6C26.2686 20 26 19.7314 26 19.4Z"
        fill="#FF4900"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.75 14.75V19.25H31.25V14.75H26.75ZM25.25 14.6C25.25 13.8544 25.8544 13.25 26.6 13.25H31.4C32.1456 13.25 32.75 13.8544 32.75 14.6V19.4C32.75 20.1456 32.1456 20.75 31.4 20.75H26.6C25.8544 20.75 25.25 20.1456 25.25 19.4V14.6Z"
        fill="#FF4900"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_11268_1219"
        x="0"
        y="0"
        width="48"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11268_1219" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_11268_1219" result="effect2_dropShadow_11268_1219" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_11268_1219" result="effect3_dropShadow_11268_1219" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_11268_1219" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_11268_1219" x1="24" y1="2" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint1_linear_11268_1219" x1="24" y1="2" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
