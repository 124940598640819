export const video = {
  tagName: "video",
  classes: ["iframe-no-pointer"],
  attributes: {
    frameborder: 0,
    allowfullscreen: true,

    title: "Video player",
    style: "width: 100%; height: 100%;",
    src: "",
    controls: true,
    autoplay: false,
    loop: false,
  },
  resizable: true,
  draggable: false,
  droppable: false,
  selectable: false,
  editable: true,
};
