import React, { useEffect, useState } from "react";

import { PropertiesPopup } from "../../partials/PropertiesPopup";
import { PropertiesPopupField } from "../../partials/PropertiesPopupField";

import { useDebounce } from "../../../../../../../../hooks/useDebounce/useDebounce";

import type { Component } from "grapesjs";

type TLinkPropertyProperties = {
  comp: Component;
  domRect: DOMRect | undefined;
  closePopup: () => void;
  notifyParent: () => void;
};

type TLinkProperty = (props: TLinkPropertyProperties) => React.ReactNode;

export const LinkProperty: TLinkProperty = ({ comp, domRect, closePopup, notifyParent }) => {
  const [value, setValue] = useState(comp.content || "");
  const debouncedValue = useDebounce(value, 500);
  const XOffset = 16;

  useEffect(() => {
    setValue(comp.content || "");
  }, [comp]);

  useEffect(() => {
    const currentValue = comp.content;
    if (currentValue === debouncedValue) return;
    comp.set("content", debouncedValue);
    notifyParent && notifyParent();
  }, [debouncedValue]);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === e.target.value) return;

    setValue(e.target.value);
  };

  if (!domRect) return null;

  // popup position
  const sx = {
    top: domRect?.top,
    left: domRect?.left - XOffset,
  };

  return (
    <PropertiesPopup sx={sx} closePopup={closePopup} title="Menu link">
      <PropertiesPopupField id={comp.cid} value={value} handleChangeValue={handleChangeValue} title="Label" />
    </PropertiesPopup>
  );
};
