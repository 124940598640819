import { type CSSProperties } from "react";

type TEmail = {
  className?: string;
  style?: CSSProperties;
};

export const Email = ({ className, style }: TEmail) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2.80005 5.30769L8.27462 9.09778C8.59073 9.31662 9.00938 9.31662 9.32547 9.09778L14.8 5.30769M2.80005 12.2308V3.92308C2.80005 3.41328 3.21332 3 3.72313 3H13.877C14.3868 3 14.8 3.41327 14.8 3.92308V12.2308C14.8 12.7406 14.3868 13.1538 13.877 13.1538H3.72313C3.21332 13.1538 2.80005 12.7406 2.80005 12.2308Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
