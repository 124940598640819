import { type CSSProperties } from "react";

type TJustifyBetween = {
  className?: string;
  style?: CSSProperties;
};

export const JustifyBetween = ({ className, style }: TJustifyBetween) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M1.25 13V1M13.25 13V1M5 2.5H2C1.58579 2.5 1.25 2.83579 1.25 3.25V10.75C1.25 11.1642 1.58579 11.5 2 11.5H5C5.41423 11.5 5.75 11.1642 5.75 10.75V3.25C5.75 2.83579 5.41423 2.5 5 2.5ZM12.5 2.5H9.5C9.08579 2.5 8.75 2.83579 8.75 3.25V10.75C8.75 11.1642 9.08579 11.5 9.5 11.5H12.5C12.9142 11.5 13.25 11.1642 13.25 10.75V3.25C13.25 2.83579 12.9142 2.5 12.5 2.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
