import { Option } from "./FontModal.types";

const getAvailableRootVariables = (rootStyles: Record<string, string>, extractedCategory: string, groupKey: string) => {
  const sizeKeys = ["font-size", "line-height"];
  return Object.entries(rootStyles).reduce<Option["options"]>((acc, [key, value]) => {
    if (key.includes(extractedCategory)) {
      const variableLabel = key.split("_").at(-1)?.replace("-", " ") ?? key;
      return {
        ...acc,
        [key]: {
          label: sizeKeys.includes(groupKey) ? `${variableLabel} - ${value}` : variableLabel,
          value: value,
          category: extractedCategory,
          variable: key,
        },
      };
    }

    return acc;
  }, {});
};

export const getFontModalProperties = (
  localStyle: Record<string, string | number>,
  rootStyles: Record<string, string>
): Record<string, Option> => {
  const changeableKeys = ["font-family", "font-size", "font-weight", "line-height"];

  return Object.entries(localStyle).reduce<any>((acc, [key, value]) => {
    if (!changeableKeys.includes(key)) return acc;
    // for now only weight is number not css variable
    const extractedCategory = isNaN(Number(value)) && typeof value === "string" ? value.split("_")[1] : "weight";

    const availableValues = getAvailableRootVariables(rootStyles, extractedCategory, key);
    acc[key] = {
      currentVar: {
        cssProperty: key,
        cssValue: value,
      },
      options: availableValues,
    };
    return acc;
  }, {});
};

export const getPreviewStyles = (properties: Record<string, string | number>) => {
  return Object.entries(properties).reduce((acc, [key, value]) => {
    const cssKeysCamel = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    if (key === "font-family" || key === "font-weight") {
      return {
        ...acc,
        [cssKeysCamel]: typeof value === "string" && value.startsWith("--") ? `var(${value})` : value,
      };
    }
    if (key === "font-size" || key === "line-height") {
      return {
        ...acc,
        [cssKeysCamel]: typeof value === "string" && value.startsWith("--") ? `calc(var(${value}) * 1px)` : value,
      };
    }
    return acc;
  }, {});
};

export const fontWeightToNumber = (fontWeight: string) => {
  switch (fontWeight) {
    case "Thin":
      return "100"; // Thin weight
    case "Extra Light":
      return "200"; // Extra light weight
    case "Light":
      return "300"; // Light weight
    case "Regular":
      return "400"; // Normal weight
    case "Medium":
      return "500"; // Medium weight
    case "Semi Bold":
      return "600"; // Semi bold weight
    case "Bold":
      return "700"; // Bold weight
    case "Extra Bold":
      return "800"; // Extra bold weight
    case "Black":
      return "900"; // Black weight
    case "Italic":
      return "400"; // Italic does not change weight, only style
    case "Bold Italic":
      return "700"; // Bold weight with italic style
    default:
      return "400"; // Default to normal weight if unrecognized
  }
};
const fontWeightToString = (fontWeight: string) => {
  switch (fontWeight) {
    case "100":
      return "Thin"; // Thin weight
    case "200":
      return "Extra Light"; // Extra light weight
    case "300":
      return "Light"; // Light weight
    case "400":
      return "Regular"; // Normal weight
    case "500":
      return "Medium"; // Medium weight
    case "600":
      return "Semi Bold"; // Semi bold weight
    case "700":
      return "Bold"; // Bold weight
    case "800":
      return "Extra Bold"; // Extra bold weight
    case "900":
      return "Black"; // Black weight
    default:
      return "Regular"; // Default to normal weight if unrecognized
  }
};

export const extractSelectedOptionName = (currentVar: Option["currentVar"], rootStyles: Record<string, string>) => {
  const isVariable = currentVar.cssValue.startsWith("--");

  if (currentVar.cssProperty === "font-weight" && !isVariable) return fontWeightToString(currentVar.cssValue);

  if (!isVariable) return currentVar.cssValue;

  // get name from --typography_category_variable
  const selectedOptionValue = rootStyles[currentVar.cssValue] ?? "";
  let selectedOptionName = currentVar.cssValue.split("_").at(-1) ?? currentVar.cssValue;
  selectedOptionName = selectedOptionName.replace("-", " ");

  if (currentVar.cssProperty === "font-family") return selectedOptionName;
  if (currentVar.cssProperty === "font-size" || currentVar.cssProperty === "line-height")
    return `${selectedOptionName} - ${selectedOptionValue}`;

  return selectedOptionName;
};
