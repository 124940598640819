import { useEffect, useState } from "react";
import { projectsService } from "../../../../machines/projects/projects.instance";
import { Hint } from "../../../../assets/svg/Hint";

import "./UpdateNotification.css";

export const UpdateNotification = () => {
  const [_, updateProjectsState] = useState(projectsService.state);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const activeProject = projectsService.getActiveProject();
  const shouldShowNotfication =
    activeProject &&
    !activeProject.isSynced &&
    (activeProject.subdomain.published || activeProject.customDomain.published);

  useEffect(() => {
    projectsService.subscribe(updateProjectsState);

    return () => {
      projectsService.unsubscribe(updateProjectsState);
    };
  }, []);

  useEffect(() => {
    if (activeProject?.isSynced === false) {
      setIsInfoVisible(true);
      setTimeout(() => {
        setIsInfoVisible(false);
      }, 10000);
    }
  }, [activeProject?.isSynced]);

  if (!shouldShowNotfication) return null;

  return (
    <div className={`update-changes${isInfoVisible ? " show" : ""}`}>
      <h2 className="update-changes__heading">
        <Hint />
        <span>Publish Updates</span>
      </h2>

      <div className="update-changes__info-wrapper">
        <p className="update-changes__info">Go to Publish and update to go live with your latest changes.</p>
      </div>
    </div>
  );
};
