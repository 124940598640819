import { useEffect, useState, useRef } from "react";
import { styleManagerService } from "../styles-manager/StylesManager.service.ts";

import { TraitManager } from "../trait-manager/TraitManager.tsx";
import { StylesManager } from "../styles-manager/StylesManager.tsx";
import { OptionsDropdown } from "../styles-manager/components/OptionsDropdown/OptionsDropdown.tsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const isActive = (view: number, currentView: number) =>
  view === currentView ? " right-sidebar__tab--active " : "";

export const RightSideBar = () => {
  const ref = useRef<HTMLUListElement | null>(null);
  const [view, setView] = useState(0);
  const [_, subscribeStyleMangerService] = useState(styleManagerService.state);
  const usUnitModalVisible = styleManagerService.unitModalVisible;

  useEffect(() => {
    styleManagerService.subscribe((st) => subscribeStyleMangerService(st));

    return () => {
      styleManagerService.unsubscribe(subscribeStyleMangerService);
    };
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    const handleScroll = () => {
      styleManagerService.closeUnitDropdown();
    };
    ref.current.addEventListener("scroll", handleScroll);
    return () => ref.current?.removeEventListener("scroll", handleScroll);
  }, [ref.current]);

  if (!styleManagerService.state.component) {
    return <div className="main-section__right-sidebar right-sidebar"></div>;
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="main-section__right-sidebar right-sidebar">
          <div className="right-sidebar__tabs">
            <button className={`right-sidebar__tab` + isActive(0, view)} onClick={() => setView(0)}>
              Styles
            </button>
            <button className={`right-sidebar__tab` + isActive(1, view)} onClick={() => setView(1)}>
              Properties
            </button>
          </div>
          <div
            style={{
              display: isActive(0, view) ? "grid" : "none",
            }}
            className="styles-wrapper"
          >
            <StylesManager ref={ref} />
          </div>
          {isActive(1, view) && <TraitManager />}
        </div>
        {usUnitModalVisible && <OptionsDropdown />}
      </DndProvider>
    </>
  );
};
