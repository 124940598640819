import { useRef } from "react";

import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { StyleManagerInput } from "../StyleManagerInput";

import { getValueFromVariable } from "../../helpers/getValueFromVariable";
import { getUnitFromVariable } from "../../helpers/getUnitFromVariable";
import { propertyIconMap } from "../../helpers/propertyIconMap";

import { TNumberProperty } from "./NumberProperty.types";

import "./NumberProperty.css";

export const NumberProperty: TNumberProperty = ({ property, allVariables }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const defValue = property.getDefaultValue();
  const value = property.getValue({ noDefault: true }) ?? "";
  const canClear = property.canClear();
  const units = property.get("units");
  const usedUnit = property.get("unit");
  const hasUnits = (units && units.length > 1) || false;
  const min = property.get("min");
  const max = property.get("max");
  const step = property.get("step");
  const className = property.get("className");
  const propertyIcon = property.get("icon");
  const iconStart = propertyIcon && propertyIconMap[propertyIcon] ? propertyIconMap[propertyIcon] : null;
  const parentStyle = property.getParentTarget()?.getStyle()[property.getName()];

  const hasVariable = parentStyle?.includes("var");
  const finalValue = value === "" && parentStyle ? getValueFromVariable(allVariables, parentStyle) : value;
  const parentUnit = parentStyle && getUnitFromVariable(parentStyle);

  const finalUnit = value === "" && parentUnit ? parentUnit : usedUnit;

  const onChange = (ev: string) => {
    if (ev === value) return;

    if (max && parseFloat(ev) > max) {
      ev = max.toString();
    }

    if (min && parseFloat(ev) < min) {
      ev = min.toString();
    }

    property.upValue(ev || "");
  };

  const changeUnit = (unit: string) => {
    if (unit === usedUnit) return;
    property.set("unit", unit);
    // if their is some variable and i change unit, change for now variable to number value
    if (hasVariable) {
      property.upValue(finalUnit);
    }
  };

  const handleOpenUnitsDropdown = () => {
    if (units && units.length > 0) {
      // TODO children 1 so if modal should display above is above input not above label
      // maybe use .input-wrapper class for this ?? maybe forward ref??
      const coordinates = propertyRef.current?.children[1].getBoundingClientRect();
      if (!coordinates || !hasUnits) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(units, usedUnit, coordinates, changeUnit);
    }
  };

  return (
    <div className={`property${className ? " " + className : ""}`} ref={propertyRef}>
      <PropertyLabel
        text={property.get("name") || property.getLabel()}
        modifier={canClear ? "edited" : "primary"}
        clearValue={canClear ? () => property.clear() : undefined}
      />
      <StyleManagerInput
        handleChange={onChange}
        value={finalValue}
        placeholder={defValue}
        type="number"
        min={min}
        max={max}
        step={step}
        unit={finalUnit}
        hasUnits={hasUnits}
        handleOpenUnitsDropdown={handleOpenUnitsDropdown}
        modifier
        iconStart={iconStart}
      />
    </div>
  );
};
