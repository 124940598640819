import type { SVGProps } from "react";
export const Shield = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.33398 12.1381L11.334 14.1381L15.334 10.1381M20.334 13.1381C20.334 18.1381 16.834 20.6381 12.674 22.0881C12.4561 22.162 12.2195 22.1584 12.004 22.0781C7.83398 20.6381 4.33398 18.1381 4.33398 13.1381V6.13814C4.33398 5.87293 4.43934 5.61857 4.62688 5.43104C4.81441 5.2435 5.06877 5.13814 5.33398 5.13814C7.33398 5.13814 9.83398 3.93814 11.574 2.41814C11.7858 2.23714 12.0553 2.1377 12.334 2.1377C12.6126 2.1377 12.8821 2.23714 13.094 2.41814C14.844 3.94814 17.334 5.13814 19.334 5.13814C19.5992 5.13814 19.8536 5.2435 20.0411 5.43104C20.2286 5.61857 20.334 5.87293 20.334 6.13814V13.1381Z"
      stroke="#FF4900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
