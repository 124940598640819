import { useEffect, useRef, useState } from "react";

import { PropertyLabel } from "../PropertyLabel";
import { Switch } from "../../../../../../AlphaO/atoms/Switch";

import { editorService } from "../../../editor";

import type { ChangeEvent } from "react";
import type { TIconFillProperty } from "./IconFillProperty.types";

import "./IconFillProperty.css";
import { materialSymbolRules, createPropertyString } from "../../helpers/materialSymbols";

export const IconFillProperty: TIconFillProperty = () => {
  const editor = editorService.getEditor();
  const [value, setValue] = useState(false);

  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        const fill = ps.getProperty("FILL");
        setValue(fill === "1" ? true : false);
      }
    });
  }, []);

  const handleChange = (_ev?: ChangeEvent<HTMLInputElement>) => {
    if (!editor) return;
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        setValue(!value);

        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        ps.setProperty("FILL", !value ? "1" : "0");
        const newFontVariationSettings = ps.toString();

        rule.set("style", {
          "font-variation-settings": newFontVariationSettings,
        });
      }
    });
  };

  const handleClear = () => {
    if (!editor) return;
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        setValue(false);
        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        ps.setProperty("FILL", "0");
        const newFontVariationSettings = ps.toString();

        rule.set("style", {
          "font-variation-settings": newFontVariationSettings,
        });
      }
    });
  };

  return (
    <div className={`property-select full-width`} ref={propertyRef}>
      <PropertyLabel
        text={"Fill"}
        modifier={value ? "edited" : "primary"}
        clearValue={value ? () => handleClear() : undefined}
        info={
          "Fill gives you the ability to modify the default icon style. A single icon can render both unfilled and filled states."
        }
      />
      <Switch isActive={value} onChange={handleChange} />
    </div>
  );
};
