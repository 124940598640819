import { ChevronDown } from "../../../../../../../assets/svg/ChevronDown";

import { TSelectButton } from "./SelectButton.types";

import "./SelectButton.css";

export const SelectButton: TSelectButton = ({ currentOption, options, placeholder, onClick }) => {
  const valueFromOptions = options.find(
    (option) => option.value === currentOption || option.id === currentOption
  )?.label;
  const isRemove = currentOption === "";
  const displayValue = valueFromOptions ? valueFromOptions : placeholder;
  return (
    <button className={`select-button${isRemove ? " select-button--placeholder" : ""}`} onClick={onClick}>
      <span className="select-button-text">{displayValue || "Auto"}</span>
      <ChevronDown className="select-button__end-icon" />
    </button>
  );
};
