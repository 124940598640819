import axios, { AxiosResponse, AxiosResponseHeaders } from "axios";
import mockResponse from "./mock.json";
import { getWindow } from "../../utils/getWindow/getWindow.ts";
import { Project } from "../../types/types.ts";
import { authInstance } from "../../machines/auth/auth.instance.ts";

export type Response = AxiosResponse<Project[]>;

declare global {
  interface Window {
    __vitest_worker__: boolean;
  }
}

const templatesAxios = axios.create();

templatesAxios.interceptors.request.use((config) => {
  if (getWindow()["__vitest_worker__"]) {
    return Promise.reject({
      mock: true,
      status: 200,
      data: mockResponse,
      headers: {} as AxiosResponseHeaders,
    });
  } else {
    return config;
  }
});
templatesAxios.interceptors.response.use(
  (response: Response): Response => {
    return response;
  },
  (error) => {
    if (error.mock) {
      const result = error.data.map((item: Project) => {
        return item;
      });
      return { ...error, data: result };
    }
    if (error.response?.status === 401) {
      authInstance.send("SESSION_EXPIRED");
    }
    return Promise.reject(error);
  }
);

export { templatesAxios };
