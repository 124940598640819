import { useRef } from "react";
import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { SelectButton } from "../SelectButton";

import { getValueFromVariable } from "../../helpers/getValueFromVariable";

import { TSelectProperty } from "./SelectProperty.types";

import "./SelectProperty.css";

export const SelectProperty: TSelectProperty = ({ property, allVariables }) => {
  const propertyRef = useRef<HTMLDivElement>(null);
  const options = property.get("options") || [];
  const value = property.getValue();
  const info = property.get("info");
  const canClear = property.canClear();
  const hasOptions = options.length > 0;
  const className = property.get("className");

  const finalValue = value.startsWith("var(") ? getValueFromVariable(allVariables, value) : value;

  const handleChange = (ev: string) => {
    property.upValue(ev);
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    if (hasOptions) {
      const target = propertyRef.current?.querySelector(".select-button") || propertyRef.current;
      const coordinates = target?.getBoundingClientRect();
      if (!coordinates) return;

      // TODO maybe move to machine
      styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
    }
  };
  return (
    <div
      className={`property-select${className ? ` ${className}` : ""}${
        finalValue ? "" : " property-select--placeholder"
      }`}
      ref={propertyRef}
    >
      <PropertyLabel
        text={property.getLabel()}
        modifier={canClear ? "edited" : "primary"}
        clearValue={canClear ? () => property.clear() : undefined}
        info={info}
      />
      <SelectButton currentOption={value} options={options} onClick={handleUnitDropdown} />
    </div>
  );
};
