import { useState, useEffect, useRef } from "react";
import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { SelectButton } from "../SelectButton";

import { TIconSizeProperty } from "./IconSizeProperty.types";

import "./IconSizeProperty.css";
import { createPropertyString, materialSymbolRules } from "../../helpers/materialSymbols";

const options = [
  {
    id: "1",
    value: "20",
    name: "20",
    label: "20",
  },
  {
    id: "2",
    value: "24",
    label: "24",
    name: "24",
  },
  {
    id: "3",
    value: "40",
    label: "40",
    name: "40",
  },
  {
    id: "3",
    value: "48",
    label: "48",
    name: "48",
  },
];

export const IconSizeProperty: TIconSizeProperty = () => {
  const [value, setValue] = useState("100");
  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        const grade = ps.getProperty("opsz");
        setValue(grade);
      }
    });
  }, []);

  const handleChange = (ev: string) => {
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        setValue(ev);

        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        ps.setProperty("opsz", ev);
        const newFontVariationSettings = ps.toString();

        rule.set("style", {
          "font-variation-settings": newFontVariationSettings,
        });
      }
    });
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    const target = propertyRef.current?.querySelector(".select-button") || propertyRef.current;
    const coordinates = target?.getBoundingClientRect();

    if (!coordinates) return;

    styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
  };

  return (
    <div className={`property-select vertical full-width`} ref={propertyRef}>
      <PropertyLabel
        text={"Optical size"}
        modifier={"primary"}
        info={
          "For the image to look the same at different sizes, the stroke weight (thickness) changes as the icon size scales. Optical Size offers a way to automatically adjust the stroke weight when you increase or decrease the symbol size."
        }
      />
      <SelectButton currentOption={value} options={options} onClick={handleUnitDropdown} />
    </div>
  );
};
