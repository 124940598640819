import { createMachine } from "xstate";
import { BlockProperties, Editor, grapesjs, ProjectData, StorageOptions } from "grapesjs";
import { editorTemplateConfig } from "../../editorTemplate.config.ts";
import { EditorTemplateService } from "./editorTemplate.service.ts";
import {
  addScriptsToEditor,
  fallbackLoadProject,
  getPagesToUpdate,
  handleCustomCode,
  loadProject,
  updatePagesInEditor,
} from "./helpers.ts";
import { pagesTemplateService } from "../pages/pages.service.ts";

import { templatesService } from "../../../../../machines/templates/templates.instance.ts";
import { Projects } from "../../../../../services/Projects/Projects.ts";

// /**
//  * Loads a project into the editor.
//  *
//  * @param editor - The editor instance.
//  * @param activeProject - The active project to load.
//  * @param projectsService - The service for managing projects.
//  * @returns A promise that resolves when the project is loaded.
//  */
// export const loadProject = async (editor: Editor, activeProject: Templates, projectsService: TemplateService) => {
//   await editor.load({ projectId: activeProject?.projectId });

//   if (activeProject.pages) {
//     const pagesToUpdate = getPagesToUpdate(activeProject.pages);
//     if (pagesToUpdate.length !== 0) {
//       await updatePagesInEditor(editor, activeProject.projectId, pagesToUpdate, projectsService);
//       projectsService.projectFetched(activeProject.projectId);
//     }
//   }
// };

export type EditorContext = {
  editor?: Editor;
  service: EditorTemplateService;
};
export const editorMachine = (service: EditorTemplateService) =>
  createMachine<EditorContext>(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QGEBKBRAggFXQfQFUBldVPAMQHlUBZAOiO01WwGIBtABgF1FQAHAPawAlgBcRggHZ8QAD0QBGACzK6ADgBM6xYoDsmzgDYjATlPqjAGhABPJQGZFGsw6169p5Z3UBWAL7+NmhYuIQkZFS0dOTo2MgAEngAigTojACSlAByRKwQ0mB0IlIAboIA1kUhOPjEpBTU9LHxSanp2Fm5CCXlAMYAhhLSXNyjskKiwzJI8ojKRmqmDnrKpoqceoq+yirKNvYIXhqm2pp66uoOvtu+eoHBGLXhDVHNcYkpaZk5eWAATv9BP86PwADZDABmwIAtnQamF6pEmjEPm1vp1fj0yoJBtNRuNZpNxJIZqAFAgFksVmsNlsdnsDogjA5OHRfL5zL5Wb4jOo9EYHiAEXUIo1ou0frlWERMAA1fCYXIAdVIhIEwhJ0lkFL8ahZ6mMmx2nB8miZCF8miMdE43lWpiM1sUmgcgqCwqeiLFbzoksx0uy6AAGtgvh0uuqQMTpjrEHq6KZjJp9G5tEYLhbdr5E9yXeY6ZZVkKRS9kRKMV08gAFDByrLEcNSqMx0lxhAJpNO1NaIvqC36NTKPOGLzWvnKEte0WvFGyhV4JVEVWofKFYo4qrw6dl8X0eeKlWkbH9Iakgk8Caa2OzXXHZTqZROF1GO7D812eZXdlu3ycTQ7H+r6KFOoQzuW9AZEQFAZNkmAADJNgGHCXkS15trejj6HQygplopp+CY-afpSdx0BciwsnonCKI+tEBB6pZInudBQTBcGIf6XQcIovBoVMGHklheg4Xhhg+LyfJZro5GWAsnB3Ga5igc8zG+kGoZIdxBRSEUvSVNUO5qSiGlhlxOQnriZ4jDwLbodqmEIIoTimHQ1ocuoFhJvoqwWucDiJgKkkrB4r6aCp3qztEtboPWlCNuZ2RrrpG7lFuTE+iiMVxQllYWfpeLnrZqEagJDlCR2RjODcHlbGczkWjoOHmOYbgCk6VoMY8YG7r6MXVswh7LqQeQ6Xpm6GT1xnRRgA0YIuR6oEQlmFTZYwldG9lknMTmrJo7JOu+2zeA4pxZmRnBugs5zaOYPgReBLFLiu0FPAAIgAmjK6DZG9C3DUtdlldtFIpqdOF8nop18qYkm+OdObcpRmxXNolhdZ6U2ZdEJC-f9L3JeNaWTap2P7j9f3PSNK3WVIF58aVWog4gAHg3cOjXGmdpeFmhi2pynl7KoZheA9vVzhT+MjawAJAiC4JQrC25Y1F5N41TS00-ixUM5twPtqzrns853L4Ws+wkTcAU0ay9EbFbIFClIggQHAsgZarV7645AC01gkX7tqmqavh+JsejclDGMexBDBMCwXtM+2uEDqyLjLHyOyaK6OwOGL03vK0Wm-InN4Va6rmnLRHiGJcnkDpsdBul4l1Vd4hrWvnZN+nluSl4JO0mCJVePrDEftwOig2lVY5uMYnmbF3qsMPKQ0rv35U7YWOHcsapzjwKWarLmTg3Pol0CtsS+x2x5CwQhxfZBvzNORsbKGgYKb6NnSbEYcuw2mHM+S+yxtjqGvixUyj9n7tm2BsOgh1TDUVoqcMKflLh0F0EgiO5gPDtwgX1OsDZoKJRgY5beQC97nB2IfEisMEFPmtEmM+LpuQEKyrNQaUslpkIqnAtkiDkGeXch+f+Dh9pI25DdWG11o5GW7hrV6WBPq8K3rDfaCx+TnFOJyXQWZxE-lfOIgwMi27sJxpLRRqjQbiJzNsQ6eY3QrH9mIiRV0HBPmwRcSw5jmiwSggkdAb1rEsyhs4R0-JqKcHWBmeGJFVBsiRqoZQeC-DUTkSrWOpBUDUBCQgfy4TIZRJiRHC0NxXLWlZNE9GtEHB50CP4IAA */
      predictableActionArguments: true,
      id: "ALPHA_O_TEMPLATES",
      initial: "START",
      context: {
        service,
      },
      states: {
        START: {
          invoke: {
            src: "initEditor",
            onDone: {
              target: "MOUNT_BLOCKS",
            },
          },
        },

        MOUNT_BLOCKS: {
          invoke: {
            src: "mountBlocks",
            onDone: {
              target: "MOUNT_COMPONENTS",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        MOUNT_COMPONENTS: {
          invoke: {
            src: "mountComponents",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: "notify",
          on: {
            CHANGE_DEVICE_TYPE: "CHANGE_DEVICE_TYPE",
            TOGGLE_PREVIEW: "TOGGLE_PREVIEW",
            TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
            TOGGLE_OUTLINE: "TOGGLE_OUTLINE",
          },
        },

        CHANGE_DEVICE_TYPE: {
          invoke: {
            src: "changeDeviceType",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_PREVIEW: {
          invoke: {
            src: "togglePreview",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_FULLSCREEN: {
          invoke: {
            src: "toggleFullscreen",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        TOGGLE_OUTLINE: {
          invoke: {
            src: "toggleOutline",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        FINISH: {
          type: "final",
        },

        ERROR: {},
        //
      },
    },
    {
      actions: {
        notify: (context) => {
          context.service.notifySubscribers();
        },
      },
      services: {
        initEditor: (context) => {
          const editor = grapesjs.init(editorTemplateConfig);
          context.service.setEditor(editor);

          // editor.on("block:custom", (props) => {
          //   service.setBlocksModel(props);
          // });

          // editor.on("layer:custom", () => {
          //   const layerManagerContainer = document.getElementById("gjs-layer-manager");
          //   if (layerManagerContainer) {
          //     layerManagerContainer.appendChild(editor.LayerManager.render());
          //   }
          // });

          editor.on("load", async () => {
            const activeTemplates = templatesService.getActiveProject();

            if (activeTemplates) {
              try {
                await loadProject(editor, activeTemplates, templatesService as unknown as Projects);
              } catch (error) {
                await fallbackLoadProject(editor, activeTemplates, templatesService as unknown as Projects);
                context.service.setIsLoaded(true);
                const pages = editor.Pages.getAll();
                pagesTemplateService.state = pages;
              } finally {
                context.service.setIsLoaded(true);
                const pages = editor.Pages.getAll();
                pagesTemplateService.state = pages;
              }
            }

            // TODO try to find another way to do this
            setTimeout(() => {
              editor.stopCommand("sw-visibility");
              editor.runCommand("preview");
              editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: false }));
            }, 250);

            // if (activeTemplates !== undefined) {
            //   console.log({activeTemplates})
            //   try {
            //     await loadProject(editor, activeTemplates, templatesService as unknown as Projects);
            //   } catch (e) {
            //     console.error("[:load] - ", e);
            //     await fallbackLoadProject(editor, activeTemplates, templatesService as unknown as Projects);
            //   } finally {
            //     context.service.setIsLoaded(true);
            //     const pages = editor.Pages.getAll();
            //     pagesService.state = pages;
            //   }
            // }

            service.notifySubscribers();
          });

          const initPageScripts = async () => {
            const head = editor.Canvas.getDocument()?.head;
            const canAddCustomHead = head && head?.innerHTML === "";
            if (!canAddCustomHead) return;
            try {
              const activeProject = templatesService.getActiveProject();
              if (activeProject) {
                addScriptsToEditor(editor, templatesService as unknown as Projects);
                await handleCustomCode(editor, activeProject, templatesService as unknown as Projects);
              } else {
                console.error("[canvas:frame:load] - No active project");
              }
            } catch (e) {
              console.error("[canvas:frame:load] - ", e);
            }
          };

          editor.on("canvas:frame:load", async () => {
            await initPageScripts();
          });

          editor.on("page:select", async () => {
            await initPageScripts();
          });

          //TODO move to plugin and create function to inject projectService
          editor.Storage.add("remote", {
            async load() {
              // throw new Error('Machine')
              //get active project
              const activeProject = templatesService.getActiveProject();
              if (activeProject) {
                // remove other projects from local storage

                const projectKey = "project-config-" + activeProject.projectId;

                for (let i = 0; i < sessionStorage.length; i++) {
                  // Get the key name at index i
                  const key = sessionStorage.key(i);

                  if (!key) continue;

                  if (key.startsWith("project-config-") && !key.startsWith(projectKey)) {
                    sessionStorage.removeItem(key);
                  }
                }

                //first load from local storage
                //TODO check how to delete all data from local storage when project is deleted or user logs out
                // const localData = sessionStorage.getItem(projectKey);
                // TODO if i comment local storage part get TS errors, check why !!!
                const localData = false;

                if (localData) {
                  const data = JSON.parse(localData);
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  templatesService.scripts = data.scripts;
                  return data.config;
                }

                // if no local storage load from history
                // if backend dont find hostory return object with config as {}, and metadata from project its needed for adding scripts later on
                const history = await templatesService.getHistory(activeProject?.projectId);

                if (Object.keys(history.config).length === 0) {
                  throw new Error("No config in history");
                }

                if (history) {
                  //Add scripts for next step
                  //TODO add meta field for projectService
                  templatesService.scripts = history.scripts;
                  return history.config;
                }
                throw new Error("No history found");
              }
            },

            async store(data) {
              //get active project ID and save project JSON
              //

              const activeProject = templatesService.getActiveProject();

              //get all scripts from editor head as strings
              // const scripts = Array.from(editor.Canvas.getDocument().head.children).map((script) => script.outerHTML);

              const scripts = templatesService.scripts;
              if (activeProject) {
                // console.log("saving to session storage");
                //save to local storage
                sessionStorage.setItem(
                  "project-config-" + activeProject?.projectId,
                  JSON.stringify({
                    scripts,
                    config: data,
                  })
                );
              }
            },
          });

          return Promise.resolve();
        },

        mountComponents: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          for (const componentDef of service.componentsDef) {
            editor.Components.addType(componentDef.id, componentDef.props);
          }

          return Promise.resolve();
        },

        mountBlocks: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          //mount block from service to editor
          for (const block of service.blocks) {
            if (block.id) {
              editor.Blocks.add(block.id, block);
            }
          }

          //Load blocks added from plugins to service
          const blockMap = editor.Blocks.getAllMap();
          for (const key in blockMap) {
            if (service.blocks.find((block) => block.id === key)) continue;
            service.blocks.push(blockMap[key].attributes as BlockProperties);
          }

          return Promise.resolve();
        },

        changeDeviceType: ({ service }, { deviceType }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          editor.setDevice(deviceType);

          setTimeout(() => {
            editor.stopCommand("preview");
            editor.runCommand("preview");
          }, 400);

          return Promise.resolve();
        },

        togglePreview: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("preview")) {
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: true }));
            // add pointer events none to video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.addClass("iframe-no-pointer");
            });
            editor.stopCommand("preview");
          } else {
            // remove pointer events from video iframes
            const iframes = editor.getWrapper()?.find(".video-wrapper iframe");
            iframes?.forEach((iframe) => {
              iframe.removeClass("iframe-no-pointer");
            });

            editor.stopCommand("sw-visibility");
            editor.runCommand("preview");
            editor.DomComponents.getWrapper()?.onAll((comp) => comp.is("text") && comp.set({ editable: false }));
          }

          return Promise.resolve();
        },

        toggleFullscreen: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("fullscreen")) {
            editor.stopCommand("fullscreen");
          } else {
            editor.runCommand("fullscreen", { target: ".alpha-o" });
          }

          return Promise.resolve();
        },

        toggleOutline: ({ service }) => {
          const editor = service.getEditor();
          if (!editor) return Promise.resolve();

          if (editor.Commands.isActive("sw-visibility")) {
            editor.stopCommand("sw-visibility");
          } else {
            editor.runCommand("sw-visibility");
          }

          return Promise.resolve();
        },
      },
    }
  );
