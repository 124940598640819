import { ButtonNew } from "../../../atoms/ButtonNew";

import { CheckMarker } from "../../../../../assets/svg/check-marker";

import { TPlan } from "./Plan.types";

import "./Plan.css";

export const Plan: TPlan = ({
  title,
  icon,
  price,
  frequency,
  description,
  features,
  closeDate,
  startDate,
  actionButtonLabel,
  actionButtonOnClick,
  actionButtonHref,
  actionCurrentPlan,
  actionButtonLoading,
}) => {
  return (
    <article className="plan" data-priceplan-type={title.toLocaleLowerCase()}>
      <header className="plan__header">
        {icon ? icon : null}
        <div className="plan__title-wrapper">
          <h3 className="plan__title">{title}</h3>
          <p className="plan__description">{description}</p>
        </div>
        <div className="plan__price-container">
          <span className="plan__price">{price}</span>
          {frequency && <span className="plan__frequency">{frequency}</span>}
        </div>
      </header>

      <div className="plan__actions">
        <ButtonNew
          className="plan__action-button"
          disabled={actionCurrentPlan || startDate !== ""}
          orange={!actionButtonLabel.includes("Downgrade") && !actionCurrentPlan}
          onClick={actionButtonOnClick}
          href={actionButtonHref}
          loading={actionButtonLoading}
        >
          {startDate ? (
            ` (will begin on ${startDate})`
          ) : (
            <>
              {actionButtonLabel}
              {closeDate ? ` (until ${closeDate})` : ""}
            </>
          )}
        </ButtonNew>
      </div>
      <section className="plan__features">
        <ol className="plan__features-list">
          {features.map((feature, index) => (
            <li key={index} className="plan__feature">
              {typeof feature === "string" ? (
                <>
                  <CheckMarker className="plan__feature-icon" />
                  <span dangerouslySetInnerHTML={{ __html: feature }} />
                </>
              ) : (
                <>
                  {!feature.icon ? <CheckMarker /> : <i>{feature.icon}</i>}
                  <span dangerouslySetInnerHTML={{ __html: feature.label }} />
                </>
              )}
            </li>
          ))}
        </ol>
      </section>
    </article>
  );
};
