import { Subscriber } from "../../../services/abstract/Subscriber/Subscriber";

export class UseTemplateModalService extends Subscriber<boolean> {
  static instance: UseTemplateModalService;
  static getInstance() {
    if (!UseTemplateModalService.instance) {
      UseTemplateModalService.instance = new UseTemplateModalService();
    }
    return UseTemplateModalService.instance;
  }

  state = false;
  openModal = () => {
    this.state = true;
    this.notifySubscribers();
  };
  closeModal = () => {
    this.state = false;
    this.notifySubscribers();
  };
}
