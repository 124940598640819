import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";

import { NoContent } from "./NoContent";
import { TemplateCard } from "../../atoms/TemplateCard";

import { type Project } from "../../../../types/types";
import { NewTemplateCard } from "../../../atoms/NewTemplateCard";
import { UseTemplateModalService } from "../../../molecules/UseTemplateModal/UseTemplateModal.service";
import { productsInstance } from "../../../../machines/products/products.instance";
import { templatesService } from "../../../../machines/templates/templates.instance";
import { useState } from "react";
import { Product } from "../../../../services/Products/Products";
import { ExperAssistanceFlow } from "../../../molecules/ExperAssistanceFlow";

export const RenderContent = ({
  isSkeleton,
  isNoContent,
  templates,
}: {
  isSkeleton: boolean;
  isNoContent: boolean;
  templates: Project[];
}) => {
  const useTemplateModalService = UseTemplateModalService.getInstance();
  const [expertAssistanceProduct, setExpertAsistanceProduct] = useState<Product | undefined>();

  const openUseTemplateModal = (projectId: string) => {
    productsInstance.send({ type: "PICK_TEMPLATE", payload: { projectId } });
    useTemplateModalService.openModal();
  };

  const onPreviewHandler = (projectId: string) => {
    templatesService.setActiveProject(projectId);
    dashboardInstance.send({ type: "ALPHA_O_TEMPLATES", payload: { projectId } });
  };

  if (isSkeleton) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TemplateCard key={index} skeleton style={{ animationDelay: `calc(${index} * 250ms)`, height: "30rem" }} />
        ))}
      </>
    );
  } else if (isNoContent) {
    return <NoContent />;
  } else {
    return (
      <>
        {templates.map((project) => {
          return (
            <NewTemplateCard
              key={project.projectId}
              name={project.name}
              thumbnail={project.thumbnail}
              onUseTemplate={() => openUseTemplateModal(project.projectId)}
              onPreview={() => onPreviewHandler(project.projectId)}
            />
          );
        })}
        <ExperAssistanceFlow
          expertAssistanceProduct={expertAssistanceProduct}
          setExpertAsistanceProduct={setExpertAsistanceProduct}
        />
      </>
    );
  }
};
