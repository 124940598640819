import { type CSSProperties } from "react";

type TAlignStart = {
  className?: string;
  style?: CSSProperties;
};

export const AlignStart = ({ className, style }: TAlignStart) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2.125 3.5L14.125 3.5M3.625 8H12.625M12.625 11.75V4.25C12.625 3.83579 12.2892 3.5 11.875 3.5L4.375 3.5C3.96077 3.5 3.625 3.83579 3.625 4.25V11.75C3.625 12.1642 3.96077 12.5 4.375 12.5H11.875C12.2892 12.5 12.625 12.1642 12.625 11.75Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
    />
  </svg>
);
