import { Project, SubscriptionInfo, SubscriptionStatus } from "../../types/types";

/**
 * Retrieves the subscription details of a specific type from a given project.
 *
 * @param project - The project object containing subscription information.
 * @param type - The type of subscription to retrieve details for.
 * @returns An object containing the subscription details of the specified type.
 */
export const getSubscriptionsDetailsByType = (project: Project | undefined, type: SubscriptionInfo["type"]) => {
  const subscriptionsDetails: SubscriptionInfo = {
    type,
    priceId: "",
    id: "",
    status: type === "site_sub" ? "active" : "canceled", // Default status is "active" for site subscriptions and "canceled" for others
    currentPeriodStart: 0,
    currentPeriodEnd: 0,
    isClosed: false,
    nextPlanPriceId: "",
  };

  if (!project) {
    return subscriptionsDetails;
  }

  for (const subscription of project.subscriptions) {
    if (subscription.type === type) {
      subscriptionsDetails.priceId = subscription.priceId;
      subscriptionsDetails.status = subscription.status as unknown as SubscriptionStatus;
      subscriptionsDetails.id = subscription.id;
      subscriptionsDetails.currentPeriodStart = subscription.currentPeriodStart;
      subscriptionsDetails.currentPeriodEnd = subscription.currentPeriodEnd;
      subscriptionsDetails.type = subscription.type;
      subscriptionsDetails.isClosed = subscription.isClosed;
      subscriptionsDetails.nextPlanPriceId = subscription.nextPlanPriceId;
    }
  }

  return subscriptionsDetails;
};
