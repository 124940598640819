import { type CSSProperties } from "react";

type TDelete = {
  className?: string;
  style?: CSSProperties;
};

export const Delete = ({ className, style }: TDelete) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10.6538 3.76923L9.26923 13H2.80769L1.42308 3.76923M0.5 3.76923H11.5769M3.73077 3.76923V1.92308C3.73077 1.67826 3.82802 1.44347 4.00113 1.27036C4.17424 1.09725 4.40903 1 4.65385 1H7.42308C7.6679 1 7.90268 1.09725 8.07579 1.27036C8.2489 1.44347 8.34615 1.67826 8.34615 1.92308V3.76923"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
