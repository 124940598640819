export const getSelectorFromActiveProject = (test: string) => {
  const classNames: string[] = [];
  const regex = /(:root(\s\.\w+)?)/g;
  let match;
  while ((match = regex.exec(test))) {
    classNames.push(match[0]);
  }

  const selectorArray = classNames.map((selector) => {
    const mode = selector.replace(/(:root\s|\.)/g, "");
    return { selector, mode };
  });

  return selectorArray || [];
};
