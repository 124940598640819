import { type CSSProperties } from "react";

type TAlignEnd = {
  className?: string;
  style?: CSSProperties;
};

export const AlignEnd = ({ className, style }: TAlignEnd) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.625 12.5H2.625M13.125 8H4.125M4.125 4.25V11.75C4.125 12.1642 4.46079 12.5 4.875 12.5H12.375C12.7892 12.5 13.125 12.1642 13.125 11.75V4.25C13.125 3.83577 12.7892 3.5 12.375 3.5H4.875C4.46079 3.5 4.125 3.83577 4.125 4.25Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
    />
  </svg>
);
