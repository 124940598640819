import { type CSSProperties } from "react";

type TMore = {
  className?: string;
  style?: CSSProperties;
};

export const More = ({ className, style }: TMore) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <mask id="path-1-outside-1_1_6678" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="22" fill="black">
      <rect fill="white" width="21" height="22" />
      <path d="M1 0H21V22H1V0Z" />
    </mask>
    {/* <path d="M2 22V0H0V22H2Z" fill="#A1280B" mask="url(#path-1-outside-1_1_6678)" /> */}
    <path
      d="M12.3693 6.38462C12.3693 5.61994 11.7494 5 10.9847 5C10.22 5 9.6001 5.61994 9.6001 6.38462C9.6001 7.14929 10.22 7.76923 10.9847 7.76923C11.7494 7.76923 12.3693 7.14929 12.3693 6.38462Z"
      stroke="#A1280B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3693 11C12.3693 10.2353 11.7494 9.61538 10.9847 9.61538C10.22 9.61538 9.6001 10.2353 9.6001 11C9.6001 11.7647 10.22 12.3846 10.9847 12.3846C11.7494 12.3846 12.3693 11.7647 12.3693 11Z"
      stroke="#A1280B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3693 15.6154C12.3693 14.8507 11.7494 14.2308 10.9847 14.2308C10.22 14.2308 9.6001 14.8507 9.6001 15.6154C9.6001 16.3801 10.22 17 10.9847 17C11.7494 17 12.3693 16.3801 12.3693 15.6154Z"
      stroke="#A1280B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
