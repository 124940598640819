import { useEffect, useState } from "react";
import { Trait } from "grapesjs";

import { Switch } from "../../../../../AlphaO/atoms/Switch/Switch.tsx";

export const Checkbox = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState(trait.getValue());
  const label = trait.getLabel();

  useEffect(() => {
    if (value !== trait.getValue()) {
      trait.setValue(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  return (
    <li key={trait.cid} className="trait-manager__trait trait-manager__trait--horizontal">
      <span className="trait-manager__checkbox-label">{label}</span>
      <Switch isDisabled={false} onChange={handleChange} isActive={value} />
    </li>
  );
};
