import React, { type SVGProps } from "react";
export const VideoTutorialIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="49" height="56" viewBox="0 0 49 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_11833_1774)">
      <rect x="4.66602" y="2" width="40" height="40" rx="8" fill="url(#paint0_linear_11833_1774)" />
      <rect x="5.16602" y="2.5" width="39" height="39" rx="7.5" stroke="url(#paint1_linear_11833_1774)" />
      <path
        d="M35.2066 16.42C35.0878 15.9454 34.8459 15.5106 34.5052 15.1594C34.1646 14.8082 33.7373 14.5532 33.2666 14.42C31.5466 14 24.6666 14 24.6666 14C24.6666 14 17.7866 14 16.0666 14.46C15.5958 14.5932 15.1686 14.8482 14.8279 15.1994C14.4873 15.5506 14.2454 15.9854 14.1266 16.46C13.8118 18.2056 13.6578 19.9763 13.6666 21.75C13.6554 23.537 13.8094 25.3213 14.1266 27.08C14.2575 27.5398 14.5049 27.9581 14.8447 28.2945C15.1846 28.6308 15.6054 28.8738 16.0666 29C17.7866 29.46 24.6666 29.46 24.6666 29.46C24.6666 29.46 31.5466 29.46 33.2666 29C33.7373 28.8668 34.1646 28.6118 34.5052 28.2606C34.8459 27.9094 35.0878 27.4746 35.2066 27C35.5189 25.2676 35.6729 23.5103 35.6666 21.75C35.6778 19.963 35.5238 18.1787 35.2066 16.42Z"
        fill="#FF4900"
      />
      <path d="M22.416 25.02L28.166 21.75L22.416 18.48V25.02Z" fill="#FAFAFA" />
    </g>
    <defs>
      <filter
        id="filter0_ddd_11833_1774"
        x="0.666016"
        y="0"
        width="48"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11833_1774" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_11833_1774" result="effect2_dropShadow_11833_1774" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_11833_1774" result="effect3_dropShadow_11833_1774" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_11833_1774" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_11833_1774"
        x1="24.666"
        y1="2"
        x2="24.666"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11833_1774"
        x1="24.666"
        y1="2"
        x2="24.666"
        y2="42"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
