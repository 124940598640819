import { type CSSProperties } from "react";

type TDirectionRowReverse = {
  className?: string;
  style?: CSSProperties;
};

export const DirectionRowReverse = ({ className, style }: TDirectionRowReverse) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M13 4.99232L4.69231 4.99232M1 4.99232L4.69231 1.30002L4.69231 8.68463L1 4.99232Z"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
