import { useEffect, useState } from "react";

import { UserService } from "../../../services/UserService/UserService";
import { ImportModal } from "../../Dashboard/molecules/ImportModal/ImportModal.service";

import { projectsInstance } from "../../../machines/projects/projects.instance";
import { productsService } from "../../../machines/products/products.instance";

import { FigmaInfoModal as FigmaInfoModalService } from "./atoms/FigmaInfoModal/FigmaInfoModal.service";
import { FigmaInfoModal } from "./atoms/FigmaInfoModal/FigmaInfoModal";
import { Seo } from "../../atoms/Seo";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";
import { TutorialCard } from "../../Dashboard/atoms/TutorialCard";
import { Navigation } from "../../Dashboard/organisms/Navigation";
import { RenderContent } from "../../Dashboard/organisms/ProjectsSection/RenderContent";

import { AppTourIcon } from "../../../../assets/svg/app-tour-icon";
import { VideoTutorialIcon } from "../../../../assets/svg/video-tutorial-icon";
import { ImportFromFigmaIcon } from "../../../../assets/svg/import-from-figma-icon";

import type { TMyProjects } from "./MyProjects.types";
import type { Project } from "../../../types/types";

import "./MyProjects.css";

const importModal = ImportModal.getInstance();
const userService = UserService.getInstance();
const figmaInfoModalService = FigmaInfoModalService.getInstance();

const tutCards = [
  {
    icon: (
      <WeirdIconWrapper>
        <AppTourIcon />
      </WeirdIconWrapper>
    ),
    title: "Documentation",
    subtitle: "Learn how to use Codejet tools",
    onClick: "https://docs.codejet.ai",
  },
  {
    icon: (
      <WeirdIconWrapper>
        <VideoTutorialIcon />
      </WeirdIconWrapper>
    ),
    title: "Video tutorial",
    subtitle: "Video tutorials on how to use Codejet tools",
    onClick: "https://www.youtube.com/@codejetai",
  },
  {
    icon: (
      <WeirdIconWrapper>
        <ImportFromFigmaIcon />
      </WeirdIconWrapper>
    ),
    title: "Import from Figma",
    subtitle: "Import your designs directly from Figma",
    onClick: importModal.openModal,
  },
];

const getFigmaInfo = async (setFigmaInfoMessage: React.Dispatch<React.SetStateAction<string>>) => {
  if (!userService.state.figmaInfo) return;

  const responseStatusCode = await userService.getFigmaAssigningInfo();

  const messages: Record<number, string> = {
    200: "Figma ID assigned successfully",
    403: "Figma ID is already assigned to another account",
  };

  if (responseStatusCode) {
    setFigmaInfoMessage(messages[responseStatusCode] || "Server error. Please try again later");
  }
  figmaInfoModalService.openModal();
  userService.clearFigmaInfo();
};

export const MyProjects: TMyProjects = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isNoContent, setIsNoContent] = useState(false);
  const [figmaInfoMessage, setFigmaInfoMessage] = useState("");
  const projectService = projectsInstance.getSnapshot().context.service;

  const productsTemplate = productsService.getTemplate();
  const hasTemplateToAdd = productsTemplate && Object.keys(productsTemplate).length > 0;

  useEffect(() => {
    projectService.subscribe((state) => setProjects(state));
    const projectState = projectService.state;

    getFigmaInfo(setFigmaInfoMessage);

    if (projectState.length === 0 || hasTemplateToAdd) {
      if (isSkeleton === false) {
        setIsSkeleton(true);
      }

      projectsInstance.send("FETCH_PROJECTS");

      projectsInstance.onTransition((state) => {
        if (state.value === "AWAITING_PROJECT_SELECTION") {
          setIsSkeleton(false);
        }
      });
    } else {
      setIsSkeleton(false);
      setProjects(projectState);
    }
    return () => {
      projectService.unsubscribe((state) => setProjects(state));
    };
  }, []);

  useEffect(() => {
    if (isSkeleton === false) {
      if (projects.length === 0) {
        setIsNoContent(true);
      }
    }
  }, [projects, isSkeleton]);

  const reversedProjects = [...projects].reverse();

  return (
    <>
      <Seo title="My projects - Codejet" />
      <Navigation />
      <div className={`my-projects__content container`}>
        <h2 className="my-projects__projects-title">My projects</h2>
        <div className="my-projects__quick-start">
          <h3>Quickstart</h3>
          <div className="my-projects__cards">
            {tutCards.map((card) => (
              <TutorialCard key={`${card.title}`} {...card} />
            ))}
          </div>
        </div>
        <section className="my-projects__projects">
          <div className="my-projects__projects-typography">
            <span className="my-projects__projects-subtitle">Your projects</span>
          </div>
          <div className={`my-projects__projects-content${isNoContent ? " my-projects__projects-content--empty" : ""}`}>
            <RenderContent isSkeleton={isSkeleton} isNoContent={isNoContent} projects={reversedProjects} />
          </div>
        </section>
      </div>
      <FigmaInfoModal message={figmaInfoMessage} />
    </>
  );
};
