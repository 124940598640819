import { type SVGProps } from "react";
export const Business = (props: SVGProps<SVGSVGElement>) => (
  <svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.9678 32.679C20.6519 32.6849 19.3533 32.3661 18.1787 31.7488C17.0041 31.1315 15.9868 30.2334 15.2104 29.1281L9.79883 32.3615C10.0048 32.6085 10.2485 32.8182 10.5204 32.9822L20.1501 38.7562C20.7129 39.0553 21.3359 39.2112 21.9678 39.2112C22.5998 39.2112 23.2227 39.0553 23.7856 38.7562L33.4153 32.9822C33.6871 32.8182 33.9309 32.6085 34.1369 32.3615L28.7392 29.0703C27.9674 30.1883 26.9506 31.0988 25.773 31.7264C24.5954 32.354 23.291 32.6806 21.9678 32.679Z"
      fill="#FF4900"
    />
    <path
      d="M13.4956 23.9656C13.5005 21.9127 14.2984 19.9336 15.7367 18.4064C17.175 16.8793 19.153 15.9112 21.2923 15.6873V9.46484C20.9678 9.5292 20.6555 9.64089 20.3665 9.79598L10.3277 15.3148C9.78207 15.663 9.32837 16.1269 9.00217 16.6703C8.67591 17.2137 8.486 17.8216 8.44727 18.4467V29.4844C8.45173 29.8028 8.50527 30.119 8.60634 30.4226L14.2478 27.3183C13.7545 26.263 13.4983 25.121 13.4956 23.9656Z"
      fill="#FF4900"
    />
    <path
      d="M33.7078 15.311L24.1974 9.79577C23.9237 9.64075 23.6279 9.52914 23.3203 9.46484V15.6694C24.6123 15.8167 25.8519 16.2671 26.9393 16.9845C28.0267 17.7019 28.9314 18.6662 29.5807 19.7996C30.23 20.9331 30.6056 22.2042 30.6774 23.5105C30.7492 24.8167 30.5152 26.1217 29.9941 27.3203L35.3386 30.4226C35.4344 30.1192 35.4851 29.8033 35.4893 29.485V18.4546C35.4547 17.8276 35.2758 17.2172 34.9666 16.6717C34.6574 16.1261 34.2265 15.6603 33.7078 15.311Z"
      fill="#FF4900"
    />
    <path
      d="M40.6348 9.78328L24.9347 0.7033C24.0139 0.24081 22.998 0 21.9678 0C20.9376 0 19.9218 0.24081 19.001 0.7033L3.30079 9.78328C2.43902 10.3455 1.72152 11.1027 1.2061 11.9938C0.69074 12.885 0.39199 13.8849 0.333984 14.9129V33.0728C0.390908 34.1031 0.689118 35.1054 1.20448 35.999C1.71983 36.8926 2.43794 37.6523 3.30079 38.2167L19.001 47.2969C19.9218 47.7593 20.9376 48 21.9678 48C22.998 48 24.0139 47.7593 24.9347 47.2969L40.6348 38.2167C41.4976 37.6523 42.2157 36.8926 42.7311 35.999C43.2465 35.1054 43.5447 34.1031 43.6016 33.0728V14.9129C43.5436 13.8849 43.2448 12.885 42.7295 11.9938C42.2141 11.1027 41.4966 10.3455 40.6348 9.78328ZM38.619 30.984C38.5729 31.7755 38.3421 32.545 37.9452 33.231C37.5484 33.9171 36.9964 34.5004 36.3335 34.9343L24.239 41.9254C23.5325 42.2826 22.7521 42.4687 21.9607 42.4687C21.1692 42.4687 20.3888 42.2826 19.6824 41.9254L7.60206 34.9343C6.93919 34.5004 6.38719 33.9171 5.99034 33.231C5.59343 32.545 5.36269 31.7755 5.31659 30.984V17.016C5.36269 16.2245 5.59343 15.455 5.99034 14.769C6.38719 14.0829 6.93919 13.4996 7.60206 13.0657L19.6681 6.07457C20.3746 5.71741 21.155 5.53129 21.9465 5.53129C22.7379 5.53129 23.5184 5.71741 24.2249 6.07457L36.3193 13.0657C36.9848 13.498 37.5396 14.0807 37.939 14.7668C38.3384 15.4529 38.5713 16.2233 38.619 17.016V30.984Z"
      fill="#FF4900"
    />
    <path
      d="M15.8828 24.0004C15.8828 25.1369 16.2396 26.2479 16.9082 27.1929C17.5767 28.138 18.5271 28.8745 19.6389 29.3095C20.7507 29.7444 21.9741 29.8581 23.1544 29.6365C24.3347 29.4147 25.4188 28.8674 26.2697 28.0638C27.1206 27.2601 27.7001 26.2362 27.9349 25.1215C28.1697 24.0067 28.0492 22.8514 27.5887 21.8013C27.1281 20.7513 26.3484 19.8538 25.3477 19.2224C24.3472 18.5909 23.1707 18.2539 21.9673 18.2539C20.3536 18.2539 18.806 18.8593 17.665 19.937C16.5238 21.0147 15.8828 22.4764 15.8828 24.0004Z"
      fill="#FF4900"
    />
  </svg>
);
