import { createMachine } from "xstate";
import { traitMachineInstance, TraitService } from "./index.ts";

export const traitManagerMachine = (traitService: TraitService) =>
  createMachine<{
    service: TraitService;
  }>(
    {
      id: "modal",
      initial: "INIT",
      context: {
        // get already initialized traitService with editor instance inside
        service: traitService,
      },
      states: {
        INIT: {
          invoke: {
            src: "init",
            onDone: {
              target: "SELECT_COMPONENT",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: "notify",
          on: {
            DELAY: "DELAY",
          },
        },

        DELAY: {
          after: {
            //TODO understand why it works because without it there is no value in inputs
            500: {
              target: "SELECT_COMPONENT",
            },
          },
        },

        SELECT_COMPONENT: {
          invoke: {
            src: "selectComponent",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ERROR: {},
      },
    },
    {
      services: {
        init: ({ service }) => {
          const editor = service.editorService.getEditor();
          // if (editor && !service.isInitialized) {
          if (editor) {
            console.log("init trait manager");
            editor.on("component:selected", (props) => {
              traitMachineInstance.send("DELAY");
            });
          }
          return Promise.resolve();
        },
        selectComponent: ({ service }) => {
          const selectedComponentTraits = service.getTraitsFromSelectedComponent();

          if (selectedComponentTraits) {
            service.update({ traits: selectedComponentTraits });
          }

          return Promise.resolve();
        },
      },

      actions: {
        notify: (context) => {
          context.service.notifySubscribers();
        },
      },
    }
  );
