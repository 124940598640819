import { Categories } from "./FontMode.types";

export const formatLabel = (label: string) => {
  const categories = label.match(/[^_]+(?=_)/g);
  //remove all _ and replace with space
  let formattedLabel = label.replace(/_/g, " ");
  categories?.forEach((category) => {
    formattedLabel = formattedLabel.replace(category, "");
  });
  // replace - sign with space
  formattedLabel = formattedLabel.replace(/-/g, " ");
  // remove multiple spaces
  formattedLabel = formattedLabel.replace(/ {2,}/g, " ");
  return formattedLabel;
};

const formatCategory = (label: string) => {
  return label.replace(/_/g, " ").replace(/--/g, " & ").replace(/-/g, " ");
};

const addToCategory = (categoryObj: any, keys: string[], currentKey: string, currentValue: string) => {
  if (keys.length === 0) return;
  const currentCategory = formatCategory(keys[0]);
  if (!(currentCategory in categoryObj)) {
    categoryObj[currentCategory] = { fonts: [], categories: {} };
  }

  if (keys.length === 1) {
    categoryObj[currentCategory].fonts.push({
      fontLabel: formatLabel(currentKey),
      fontName: currentKey,
      fontValue: currentValue,
    });
  } else {
    addToCategory(categoryObj[currentCategory].categories, keys.slice(1), currentKey, currentValue);
  }
};

export const getTypographyCategories = (colors: Record<string, any>) => {
  return Object?.entries(Object.assign({}, colors)).reduce<Categories>((acc, [currentKey, currentValue]) => {
    if (!currentKey.startsWith("--typography")) return acc;

    let keys = currentKey.match(/[^_]+(?=_)/g);
    // if we don't have keys we need to add default keys
    if (!keys) {
      keys = ["--typography", "without-category"];
    }

    if (keys[0] === "--typography") {
      keys.shift();
    }

    addToCategory(acc, keys, currentKey, currentValue);

    return acc;
  }, {});
};
