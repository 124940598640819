import { type CSSProperties } from "react";

type TSearch = {
  className?: string;
  style?: CSSProperties;
};

export const Search = ({ className, style }: TSearch) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M18 17.9999L14.6668 14.6667M11.0769 16.1538C13.8808 16.1538 16.1538 13.8808 16.1538 11.0769C16.1538 8.27299 13.8808 6 11.0769 6C8.27299 6 6 8.27299 6 11.0769C6 13.8808 8.27299 16.1538 11.0769 16.1538Z"
      stroke="#E4E4E7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
