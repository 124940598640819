import { type CSSProperties } from "react";

type TPageLink = {
  className?: string;
  style?: CSSProperties;
};

export const PageLink = ({ className, style }: TPageLink) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.40002 12.6154H6.47695M2.09233 10.3077H7.16926M3.70772 8.92308L2.78464 14M6.01541 8.92308L5.09233 14M3.24618 6.61538V2.92308C3.24618 2.67826 3.34343 2.44347 3.51654 2.27036C3.68965 2.09725 3.92444 2 4.16926 2H10.1693L13.4 5.23077V13.0769C13.4 13.3217 13.3027 13.5566 13.1297 13.7296C12.9566 13.9027 12.7217 14 12.4769 14H8.78464"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
