export const mockProjectResponse = [
  {
    projectId: "1",
    name: "Development",
    description: "Developers are the creative minds behind new computer software.",
    thumbnail: "http://example.com/thumbnail.png",
    timestamp: "2023-10-01T00:00:00Z",
    subdomain: {
      address: "test.example.com",
      published: true,
    },
    customDomain: {
      address: "custom.example.com",
      published: false,
    },
    pages: [],
    subscriptions: [],
    policy: {
      maxPages: 10,
      projectId: "123",
      plan: "basic",
      canPublishOnCustomDomain: true,
      version: "1.0",
    },
    active: true,
    html: "<html></html>",
    css: "body { margin: 0; }",
    variables: "var(--primary-color)",
    projectHead: "<head></head>",
    projectBody: "<body></body>",
    projectCloseTag: "</html>",
    projectCSSResetLink: "http://example.com/reset.css",
    history: [],
    colorReferencesToMix: "#FFFFFF",
    jsScripts: [],
  },
  {
    projectId: "2",
    name: "Business",
    description:
      "Business analysts are responsible for bridging the gap between IT and the business using data analytics to assess processes, determine requirements and deliver data-driven recommendations and reports to executives and stakeholders.",
    thumbnail: "http://example.com/thumbnail.png",
    timestamp: "2023-10-01T00:00:00Z",
    subdomain: {
      address: "test.example.com",
      published: true,
    },
    customDomain: {
      address: "custom.example.com",
      published: false,
    },
    pages: [],
    subscriptions: [],
    policy: {
      maxPages: 10,
      projectId: "123",
      plan: "basic",
      canPublishOnCustomDomain: true,
      version: "1.0",
    },
    active: true,
    html: "<html></html>",
    css: "body { margin: 0; }",
    variables: "var(--primary-color)",
    projectHead: "<head></head>",
    projectBody: "<body></body>",
    projectCloseTag: "</html>",
    projectCSSResetLink: "http://example.com/reset.css",
    history: [],
    colorReferencesToMix: "#FFFFFF",
    jsScripts: [],
  },
  {
    projectId: "3",
    name: "Marketing",
    description: "Marketing is the process of getting people interested in your company's product or service.",
    thumbnail: "http://example.com/thumbnail.png",
    timestamp: "2023-10-01T00:00:00Z",
    subdomain: {
      address: "test.example.com",
      published: true,
    },
    customDomain: {
      address: "custom.example.com",
      published: false,
    },
    pages: [],
    subscriptions: [],
    policy: {
      maxPages: 10,
      projectId: "123",
      plan: "basic",
      canPublishOnCustomDomain: true,
      version: "1.0",
    },
    active: true,
    html: "<html></html>",
    css: "body { margin: 0; }",
    variables: "var(--primary-color)",
    projectHead: "<head></head>",
    projectBody: "<body></body>",
    projectCloseTag: "</html>",
    projectCSSResetLink: "http://example.com/reset.css",
    history: [],
    colorReferencesToMix: "#FFFFFF",
    jsScripts: [],
  },
  {
    projectId: "4",
    name: "Design",
    description:
      "Design is the creation of a plan or convention for the construction of an object, system or measurable human interaction",
    thumbnail: "http://example.com/thumbnail.png",
    timestamp: "2023-10-01T00:00:00Z",
    subdomain: {
      address: "test.example.com",
      published: true,
    },
    customDomain: {
      address: "custom.example.com",
      published: false,
    },
    pages: [],
    subscriptions: [],
    policy: {
      maxPages: 10,
      projectId: "123",
      plan: "basic",
      canPublishOnCustomDomain: true,
      version: "1.0",
    },
    active: true,
    html: "<html></html>",
    css: "body { margin: 0; }",
    variables: "var(--primary-color)",
    projectHead: "<head></head>",
    projectBody: "<body></body>",
    projectCloseTag: "</html>",
    projectCSSResetLink: "http://example.com/reset.css",
    history: [],
    colorReferencesToMix: "#FFFFFF",
    jsScripts: [],
  },
];
