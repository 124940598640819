import { type CSSProperties } from "react";

type TTargetSquare = {
  className?: string;
  style?: CSSProperties;
};

export const TargetSquare = ({ className, style }: TTargetSquare) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M12 10V14M14 12H10M20 12H17.3333M6.66667 12H4M12 4V6.66667M12 17.3333V20M18.6667 4H5.33333C4.59696 4 4 4.59696 4 5.33333V18.6667C4 19.4031 4.59696 20 5.33333 20H18.6667C19.4031 20 20 19.4031 20 18.6667V5.33333C20 4.59696 19.4031 4 18.6667 4Z"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
