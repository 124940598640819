import { useState } from "react";

import type { TChip } from "./Chip.types";

import "./Chip.css";

export const Chip: TChip = ({ label, checked: checkedProp, onClick, disabled }) => {
  const [checked, setChecked] = useState(checkedProp ?? false);
  const classes = ["chip", checked && "chip--active"].filter(Boolean).join(" ");

  const handleClick = () => {
    if (!disabled) {
      const newState = !checked;
      setChecked(newState);
      if (onClick) onClick(newState);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!disabled) {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        handleClick();
      }
    }
  };

  return (
    <button className={classes} onClick={handleClick} onKeyDown={handleKeyDown} aria-pressed={checked} type="button">
      <span className="chip__label">{label}</span>
    </button>
  );
};
