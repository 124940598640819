import { useEffect, useState } from "react";

import "./UploadAsset.css";
import { assetManagerInstance } from "../../../asset-manager";
import { MyDropzone } from "./DropZone.tsx";
import { stylesManagerInstance } from "../../../styles-manager/index.ts";

const imageTypes = {
  "image/png": [".png"],
  "image/svg": [".svg"],
  "image/jpg": [".jpg"],
};

const videoTypes = {
  "video/mp4": [".mp4"],
};
export const UploadAsset = () => {
  const [isFilePickerOpen, setIsFilePickerOpen] = useState<boolean>(false);
  const [acceptedTypes, setAcceptedTypes] = useState<Record<string, string[]>>(imageTypes);

  useEffect(() => {
    assetManagerInstance.onTransition((state) => {
      const keys = Object.keys(state.value);
      // if asset manager is rebuild fix this place, this is a guard
      if (keys.length === 1) {
        // without this condition error console show error that filePicker is open multiple times
        setIsFilePickerOpen(
          keys[0] === "CHANGE_BACKGROUND" ||
            keys[0] === "UPLOAD" ||
            keys[0] === "UPLOAD_POSTER" ||
            keys[0] === "UPLOAD_VIDEO"
        );

        if (keys[0] === "UPLOAD_VIDEO") {
          setAcceptedTypes(videoTypes);
        } else {
          setAcceptedTypes(imageTypes);
        }
      } else {
        setIsFilePickerOpen(false);
      }
    });
  }, []);

  return (
    // <div className="unpublish-modal" style={{ display: isFilePickerOpen ? "none" : "block" }}>
    // hide for now dropzone
    <div className="unpublish-modal" style={{ display: "none" }}>
      <div className="unpublish-modal__cta-wrapper">
        <button
          className="unpublish-modal__cta unpublish-modal__cta--cancel"
          onClick={() => {
            assetManagerInstance.send("CLOSE_MODAL");
            stylesManagerInstance.send("CANCEL_BG_CHANGE");
          }}
        >
          Cancel
        </button>
        <MyDropzone acceptedTypes={acceptedTypes} isBackgroundChange={isFilePickerOpen} />
      </div>
    </div>
  );
};
