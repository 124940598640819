import { type SVGProps } from "react";

export const Logotype = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="81" height="32" viewBox="0 0 81 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Vector">
        <path
          d="M6.829 28.0059C2.62254 28.0059 0 24.9454 0 20.9772C0 17.009 2.59658 13.9486 6.64724 13.9486C10.1007 13.9486 12.2299 15.9456 12.6973 18.8245H9.24382C9.01012 17.6574 8.10132 16.7756 6.80303 16.7756C4.67384 16.7756 3.58328 18.4095 3.58328 20.9772C3.58328 23.493 4.59594 25.1788 6.7511 25.1788C8.17922 25.1788 9.19188 24.4267 9.42558 22.9224H12.8271C12.5934 25.7494 10.3863 28.0059 6.829 28.0059Z"
          fill="#27272A"
        />
        <path
          d="M19.37 28.0059C15.5682 28.0059 12.7379 24.9454 12.7379 20.9772C12.7379 17.009 15.5682 13.9486 19.37 13.9486C23.4985 13.9486 26.002 17.009 26.002 20.9772C26.002 24.9454 23.4985 28.0059 19.37 28.0059ZM19.37 25.3085C21.5251 25.3085 22.4187 23.5708 22.4187 20.9772C22.4187 18.3836 21.5251 16.62 19.37 16.62C17.5156 16.62 16.3212 18.3836 16.3212 20.9772C16.3212 23.5708 17.5156 25.3085 19.37 25.3085Z"
          fill="#27272A"
        />
        <path
          d="M31.9754 28.0059C28.6258 28.0059 26.3408 25.2826 26.3408 20.9772C26.3408 16.8275 28.7037 13.9486 32.0273 13.9486C33.8969 13.9486 35.0653 14.8044 35.7924 15.9197H35.8703V9.0726H39.4016V27.6168H36.0001V25.8791H35.9482C35.1692 27.15 33.8449 28.0059 31.9754 28.0059ZM32.7544 25.0492C34.8576 25.0492 35.9482 23.493 35.9482 21.055C35.9482 18.5133 35.1173 16.8015 32.8582 16.8015C30.9108 16.8015 29.9241 18.5133 29.9241 20.9772C29.9241 23.5449 30.9368 25.0492 32.7544 25.0492Z"
          fill="#27272A"
        />
        <path
          d="M46.9746 28.0059C42.5604 28.0059 40.0677 24.9195 40.0677 20.9513C40.0677 17.009 42.7422 13.9486 46.637 13.9486C50.9214 13.9486 53.2064 17.2165 53.2064 21.9628H43.5471C43.8068 24.0117 44.9492 25.2826 46.9486 25.2826C48.3248 25.2826 49.1297 24.6601 49.5192 23.6486H52.9986C52.5053 26.0088 50.4021 28.0059 46.9746 28.0059ZM46.689 16.6718C44.8194 16.6718 43.9106 17.7871 43.599 19.5248H49.5192C49.4154 17.839 48.2988 16.6718 46.689 16.6718Z"
          fill="#27272A"
        />
        <path
          d="M53.6527 32C53.0036 32 52.1986 31.9481 51.6534 31.8444V29.3286C53.0815 29.3027 53.8605 29.173 53.8605 27.954V14.3117H57.3918V28.1355C57.3918 29.6917 57.0802 30.4698 56.509 31.0404C55.8339 31.7406 54.7952 32 53.6527 32ZM53.8605 12.2368V9.0726H57.3918V12.2368H53.8605Z"
          fill="#27272A"
        />
        <path
          d="M65.925 28.0059C61.5108 28.0059 59.0181 24.9195 59.0181 20.9513C59.0181 17.009 61.6926 13.9486 65.5874 13.9486C69.8718 13.9486 72.1568 17.2165 72.1568 21.9628H62.4975C62.7572 24.0117 63.8997 25.2826 65.899 25.2826C67.2752 25.2826 68.0802 24.6601 68.4697 23.6486H71.9491C71.4557 26.0088 69.3525 28.0059 65.925 28.0059ZM65.6394 16.6718C63.7698 16.6718 62.861 17.7871 62.5494 19.5248H68.4697C68.3658 17.839 67.2493 16.6718 65.6394 16.6718Z"
          fill="#27272A"
        />
        <path
          d="M72.7587 16.6459V14.3117H74.6022V10.1619H78.0557V14.3117H80.3407V16.6459H78.0557V23.7005C78.0557 24.6861 78.601 25.0232 79.3799 25.0232C79.8214 25.0232 80.4186 24.9973 80.4186 24.9973V27.5909C80.4186 27.5909 79.6136 27.6428 78.2115 27.6428C76.4977 27.6428 74.6022 26.9684 74.6022 24.4526V16.6459H72.7587Z"
          fill="#27272A"
        />
        <path
          d="M60.7249 9.91342L57.3916 6.9985V3.57263H53.8609V6.9985L50.5299 9.91342V4.79705L55.6263 0L60.7249 4.79705V9.91342Z"
          fill="#FF4900"
        />
      </g>
    </svg>
  );
};
