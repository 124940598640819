import type { SVGProps } from "react";
export const Cons = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 6.09668L6 18.0967M6 6.09668L18 18.0967"
      stroke="#F50808"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
