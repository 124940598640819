import { useEffect, useRef, useState } from "react";

import { Close } from "../../../../../../../assets/svg/Close";
import { Info } from "../../../../../../../assets/svg/Info";

import { TPropertyLabel } from "./PropertyLabel.types";

import "./PropertyLabel.css";

export const PropertyLabel: TPropertyLabel = ({
  text,
  modifier = "primary",
  clearValue,
  startIcon = <Info />,
  info,
}) => {
  const [position, setPosition] = useState<{ top: number | undefined; left: number | undefined }>({
    top: undefined,
    left: undefined,
  });
  const labelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleMouseOver = () => {
      if (labelRef.current && info) {
        const coordinates = labelRef.current?.getBoundingClientRect();
        setPosition({
          top: coordinates?.y,
          left: coordinates?.x,
        });
      }
    };

    const handleMouseOut = () => {
      setPosition({
        top: undefined,
        left: undefined,
      });
    };

    labelRef.current?.addEventListener("mouseover", handleMouseOver);
    labelRef.current?.addEventListener("mouseleave", handleMouseOut);

    return () => {
      labelRef.current?.removeEventListener("mouseover", handleMouseOver);
      labelRef.current?.removeEventListener("mouseleave", handleMouseOut);
    };
  }, [labelRef]);

  return (
    <span className={`property__label property__label--${modifier}`} ref={labelRef}>
      {info ? (
        <>
          <span className="property__hint" data-info={info}>
            {startIcon}
          </span>
          {position.top && position.left && (
            <div
              className="property__tooltip"
              style={{
                top: `${position.top - 4}px`,
                left: `${position.left - 16}px`,
              }}
            >
              <span className="property__tooltip-text">{info}</span>
            </div>
          )}
        </>
      ) : null}
      <span style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{text}</span>
      {clearValue !== undefined && (
        <button onClick={clearValue}>
          <Close />
        </button>
      )}
    </span>
  );
};
