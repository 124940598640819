import { type CSSProperties } from "react";

type TTrash3 = {
  className?: string;
  style?: CSSProperties;
};

export const Trash3 = ({ className, style }: TTrash3) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="ico"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78571 1.28571C4.66737 1.28571 4.57143 1.38165 4.57143 1.5V2.35714H7.57143V1.5C7.57143 1.38165 7.47549 1.28571 7.35714 1.28571H4.78571ZM1.14286 2.35714H3.28571V1.5C3.28571 0.671573 3.95729 0 4.78571 0H7.35714C8.18557 0 8.85714 0.671573 8.85714 1.5V2.35714H11C11.355 2.35714 11.6429 2.64496 11.6429 3C11.6429 3.35504 11.355 3.64286 11 3.64286H10.6941L9.49529 11.635C9.46383 11.8448 9.28357 12 9.07143 12H3.07143C2.85929 12 2.67907 11.8448 2.64761 11.635L1.44878 3.64286H1.14286C0.787817 3.64286 0.5 3.35504 0.5 3C0.5 2.64496 0.787817 2.35714 1.14286 2.35714Z"
      fill="#71717A"
    />
  </svg>
);
