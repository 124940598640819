import { Button } from "../../atoms/Button";
import { Trash3 } from "../../../../assets/svg/Trash3";
import { DragDrop } from "../../../../assets/svg/DragDrop";

import { TSelectOption } from "./SelectOption.types";

import "./SelectOptions.css";

export const SelectOption: TSelectOption = ({ model, removeOption, selectOption }) => {
  const handleRemoveOption = () => {
    model.remove();
    removeOption(model);
  };

  const selectThisLayer = () => {
    selectOption(model);
  };

  return (
    <li className="trait-manager__options-list-element">
      <button onClick={() => {}} className="stack-property__drag">
        <DragDrop />
      </button>
      <button className="trait-manager__options-label" onClick={selectThisLayer}>
        {model.content}
      </button>
      <Button
        type="icon"
        iconStart={<Trash3 />}
        onClick={handleRemoveOption}
        className="stack-property__remove-action"
      />
    </li>
  );
};
