import { type CSSProperties } from "react";

type TJustifyEnd = {
  className?: string;
  style?: CSSProperties;
};

export const JustifyEnd = ({ className, style }: TJustifyEnd) => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M10.25 1L10.25 13M5.75 2.5L5.75 11.5M2 11.5L9.5 11.5C9.91421 11.5 10.25 11.1642 10.25 10.75L10.25 3.25C10.25 2.83577 9.91421 2.5 9.5 2.5L2 2.5C1.58577 2.5 1.25 2.83577 1.25 3.25L1.25 10.75C1.25 11.1642 1.58577 11.5 2 11.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
    />
  </svg>
);
