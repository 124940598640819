import { useEffect, useState } from "react";

import { UserService } from "../../../../services/UserService/UserService";

import { ImportModal as ImportModalService } from "./ImportModal.service";
import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";

import { ButtonNew } from "../../../atoms/ButtonNew";
import { IconButton } from "../../../atoms/IconButton";

import { Close } from "../../../../../assets/svg/close";
import { ExternalLink } from "../../../../../assets/svg/external-link";
import tempImage from "../../../../../assets/png/temp.png";
import { FigmaLogo } from "../../../../../assets/svg/figma_logo";

import { TImportModal } from "./ImportModal.types";

import "./ImportModal.css";

const importModal = ImportModalService.getInstance();
const userService = UserService.getInstance();

const FIGMA_DESIGN_SYSTEM_URI = import.meta.env.VITE_FIGMA_DESIGN_SYSTEM_URI;
const FIGMA_PLUGIN_URI = import.meta.env.VITE_FIGMA_PLUGIN_URI;

export const ImportModal: TImportModal = () => {
  const [_, setState] = useState(false);
  useEffect(() => {
    importModal.subscribe(setState);
    return () => importModal.unsubscribe(setState);
  }, []);
  const isOpen = importModal.state;
  const closeModal = () => importModal.closeModal();
  const ref = useOutsideClick<HTMLDivElement>(closeModal);

  const clearFigmaInfo = () => {
    userService.clearFigmaAssingingInfo();
    closeModal();
  };

  return (
    <div className="import-modal__wrapper">
      <div className={"import-modal__backdrop" + (isOpen ? " import-modal__backdrop--visible" : "")}>
        <div className={`import-modal ${isOpen && "import-modal--visible"}`} ref={ref}>
          <div className="import-modal__header">
            <span className="import-modal__typography import-modal__typography--bold">Import design from Figma</span>
            <IconButton
              className="import-modal__btn-icon"
              ariaLabel="Close 'Import design from Figma' modal"
              onClick={() => closeModal()}
            >
              <Close />
            </IconButton>
          </div>
          <img src={tempImage} alt={"figma "} />
          <ol className="import-modal__steps import-modal__typography import-modal__typography--regular">
            <li>
              Open the{" "}
              <ButtonNew
                className="import-modal__btn-link"
                orange
                type="text"
                iconEnd={<ExternalLink />}
                href={FIGMA_DESIGN_SYSTEM_URI}
              >
                Jet UI Design System
              </ButtonNew>{" "}
              in Figma and use it to design your project
            </li>
            <li>
              Install and run{" "}
              <ButtonNew
                className="import-modal__btn-link"
                orange
                type="text"
                iconEnd={<ExternalLink />}
                href={FIGMA_PLUGIN_URI}
              >
                Codejet Plugin
              </ButtonNew>
            </li>
            <li>Select designs to import</li>
            <li>{'Click "Generate Code"'}</li>
            <li>Edit in visual editor</li>
          </ol>
          <ButtonNew className="import-modal__btn" orange href={FIGMA_PLUGIN_URI}>
            Get the plugin
          </ButtonNew>
          <div className="import-modal__footer">
            <div className="import-modal__typography import-modal__typography--regular import-modal__account-name">
              Logged in as: {userService.data?.FIGMA_EMAIL}
            </div>
            <ButtonNew iconStart={<FigmaLogo />} className="connect-figma-modal__skip-button" onClick={clearFigmaInfo}>
              Dismiss
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  );
};
