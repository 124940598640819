import React, { type SVGProps } from "react";
export const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="youtube 1">
      <path
        id="Vector"
        d="M12.1667 10.4999L9.25 12.1666V8.83325L12.1667 10.4999Z"
        fill="black"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M2.16602 11.0896V9.91034C2.16602 7.49754 2.16602 6.29116 2.92059 5.51494C3.67517 4.73871 4.86315 4.70511 7.23909 4.63791C8.36496 4.60607 9.51502 4.58325 10.4993 4.58325C11.4837 4.58325 12.6338 4.60607 13.7596 4.63791C16.1355 4.70511 17.3235 4.73871 18.0781 5.51494C18.8327 6.29116 18.8327 7.49754 18.8327 9.91034V11.0896C18.8327 13.5023 18.8327 14.7088 18.0781 15.4849C17.3235 16.2612 16.1356 16.2948 13.7597 16.362C12.6338 16.3938 11.4837 16.4167 10.4993 16.4167C9.51502 16.4167 8.36491 16.3938 7.23902 16.362C4.86311 16.2948 3.67516 16.2612 2.92058 15.4849C2.16602 14.7088 2.16602 13.5023 2.16602 11.0896Z"
        stroke="black"
        strokeWidth="1.25"
      />
    </g>
  </svg>
);
