import type { SVGProps } from "react";

export const DiscordIconNew = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.2362 5.3627C17.8862 4.7627 16.4612 4.3127 14.9612 4.0127H14.8862C14.7362 4.3127 14.5112 4.7627 14.3612 5.1377C12.7112 4.9127 11.1362 4.9127 9.56124 5.1377C9.41124 4.7627 9.18624 4.3877 9.03624 4.0127H8.96124C7.46124 4.2377 6.03624 4.6877 4.68624 5.3627C1.98624 9.4127 1.23624 13.3877 1.61124 17.2877V17.3627C3.41124 18.7127 5.13624 19.4627 6.86124 19.9877H6.93624C7.31124 19.4627 7.68624 18.8627 7.98624 18.2627V18.1877C7.38624 17.9627 6.86124 17.7377 6.33624 17.4377C6.26124 17.4377 6.26124 17.3627 6.33624 17.3627C6.41124 17.2877 6.56124 17.2127 6.63624 17.1377H6.71124C10.1612 18.7127 13.8362 18.7127 17.2862 17.1377H17.3612C17.4362 17.2127 17.5862 17.2877 17.6612 17.3627C17.7362 17.3627 17.6612 17.4377 17.6612 17.4377C17.1362 17.7377 16.6112 18.0377 16.0112 18.1877C16.0112 18.1877 15.9362 18.2627 16.0112 18.2627C16.3112 18.8627 16.6862 19.4627 17.0612 19.9877H17.1362C18.8612 19.4627 20.5862 18.6377 22.3862 17.3627V17.2877C22.8362 12.7877 21.6362 8.88769 19.2362 5.3627ZM8.51124 14.9627C7.46124 14.9627 6.63624 13.9877 6.63624 12.8627C6.63624 11.7377 7.46124 10.7627 8.51124 10.7627C9.56124 10.7627 10.3862 11.7377 10.3862 12.8627C10.3862 13.9877 9.56124 14.9627 8.51124 14.9627ZM15.4862 14.9627C14.4362 14.9627 13.6112 13.9877 13.6112 12.8627C13.6112 11.7377 14.4362 10.7627 15.4862 10.7627C16.5362 10.7627 17.3612 11.7377 17.3612 12.8627C17.3612 13.9877 16.5362 14.9627 15.4862 14.9627Z"
      fill="#71717A"
    />
  </svg>
);
