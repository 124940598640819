import { useState, useEffect } from "react";

import { Select } from "../Select";
import { HTML5, Youtube } from "./partials";

import type { Trait } from "grapesjs";

type Value = "html" | "youtube" | "youtube-no-cookie";

export const Video = ({ trait }: { trait: Trait }) => {
  const [value, setValue] = useState<Value>();

  const traitOptions = trait.getOptions().map((option) => ({
    id: option.id,
    label: option.label ?? option.id,
  }));
  const traitValue: string = trait.getValue();
  const hasTraitValue = traitValue.length > 0;

  useEffect(() => {
    if (hasTraitValue) {
      const selectedOption = traitOptions.find((option) => option.id === traitValue);
      return setValue(selectedOption?.id as Value);
    }

    const defaultOption = traitOptions.find((option) => option.id);
    if (defaultOption) {
      setValue(defaultOption.id as Value);
    }
  }, [trait, traitOptions]);

  return (
    <>
      <Select trait={trait} />
      {value === "html" && <HTML5 />}
      {value === "youtube" && <Youtube />}
      {value === "youtube-no-cookie" && <Youtube noCookie />}
    </>
  );
};
