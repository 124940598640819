import { TSwitch } from "./Switch.types";

import "./Switch.css";

export const Switch: TSwitch = ({ isActive, onChange, isDisabled = false }) => (
  <label
    className={`alpha-o-switch${isActive ? " alpha-o-switch--active" : ""}${
      isDisabled ? " alpha-o-switch--disabled" : ""
    }`}
  >
    <input
      type="checkbox"
      className="alpha-o-switch__input vs-hidden"
      checked={isActive}
      onChange={onChange}
      disabled={isDisabled}
    />
    <div className="alpha-o-switch__marker"></div>
  </label>
);
