import { useState } from "react";

import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";
import { styleManagerService } from "../../StylesManager.service";

import { NumberProperty } from "../NumberProperty";
import { ColorProperty } from "../ColorProperty/ColorProperty";
import { SelectProperty } from "../SelectProperty";
import { RadioProperty } from "../RadioProperty";
import { FileProperty } from "../FileProperty";
import { GradientProperty } from "../GradientProperty";
import { Button } from "../../../../../../AlphaO/atoms/Button";
import { Close } from "../../../../../../../../assets/svg/close";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick/useOutsideClick";
import { usePositionInScreen } from "../../../../../../../hooks/usePositionInScreen/usePositionInScreen";

import { TStackProperties } from "./StackProperties.types";

import "./StackProperties.css";

export const StackProperties: TStackProperties = ({ property, allVariables, handleClose, styles }) => {
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const optionsRef = useOutsideClick<HTMLDivElement>(() => {
    const isDropdownOpen = styleManagerService.isOpen();
    const isColorPickerOpen = colorPickerServiceInstance.isOpen();

    if (isDropdownOpen || isColorPickerOpen) return;
    handleClose();
  });

  const parentX = styles?.x ? styles.x - 240 : 0;
  const parentY = styles?.y ? styles.y : 0;

  const { coordinates } = usePositionInScreen(
    optionsRef,
    { x: parentX, y: parentY },
    setIsPositionCalculated,
    0,
    8,
    false
  );

  const positionStyles = () => {
    if (!coordinates) return {};

    return {
      top: coordinates.top,
      left: coordinates.left,
    };
  };

  return (
    <div
      className="properties-popup"
      style={{ ...positionStyles(), opacity: isPositionCalculated ? 1 : 0 }}
      ref={optionsRef}
    >
      <header className="properties-popup__header">
        <h3>{property.getLabel()}</h3>
        <Button
          type="icon"
          iconStart={<Close />}
          onClick={() => {
            handleClose();
          }}
        />
      </header>
      <div className="properties-popup__content">
        {property.getProperties().map((prop) => {
          const requires = prop.get("requires");

          // TODO refactor this
          if (requires) {
            const canRender = Object.keys(requires).every((key) => {
              const requiredProperty = property.getProperty(key);
              // TODO refactor this, grapejs type is Record<string, any> | undefined
              const requiresType = typeof requires[key];
              if (requiresType !== "string" && !Array.isArray(requires[key])) {
                console.error(`Invalid type of required property ${key} in ${prop.getLabel()}`);

                return false;
              }

              if (Array.isArray(requires[key])) {
                return requires[key].includes(requiredProperty?.getValue());
              }

              if (requiredProperty && requiredProperty.getValue() === requires[key]) return true;
            });

            if (!canRender) return null;
          }

          switch (prop.get("type")) {
            case "number":
              return <NumberProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "color":
              return <ColorProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "select":
              return <SelectProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "radio":
              return <RadioProperty property={prop} key={prop.getId()} />;
            case "file":
              return <FileProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "gradient":
              return <GradientProperty property={prop} key={prop.getId()} />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};
