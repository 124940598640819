import { useState, useEffect } from "react";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";
import { LoaderBackdrop } from "../LoaderBackdrop";
import type { TLoadingWrapper } from "./LoadingWrapper.types";

export const LoadingWrapper: TLoadingWrapper = ({ renderState, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dashboardInstance.onTransition((state) => {
      setIsLoading(state.value !== renderState);
    });
  }, [renderState]);
  return isLoading ? <LoaderBackdrop /> : children;
};
