import { useEffect, useState } from "react";
import { editorService } from "../../../pages/Alpha_O/modules/editor/index.ts";
import { projectsService } from "../../../../machines/projects/projects.instance.ts";
import { ColorMode } from "../ColorMode/ColorMode.tsx";
import { Select } from "../../../atoms/Select/Select.tsx";
import { Hint } from "../../../../assets/svg/Hint.tsx";
import { LocalColors } from "../LocalColors/LocalColors.tsx";
import { Accordion } from "../../atoms/Accordion/Accordion.tsx";

import "./ColorVariables.css";

const getSelectorFromActiveProject = (test: string) => {
  const classNames: string[] = [];
  const regex = /(:root(\s\.\w+)?)/g;
  let match;
  while ((match = regex.exec(test))) {
    classNames.push(match[0]);
  }

  const selectorArray = classNames.map((selector) => {
    const mode = selector.replace(/(:root\s|\.)/g, "");
    return { selector, mode };
  });

  return selectorArray || [];
};

export const ColorVariables = () => {
  const [_, updateState] = useState(projectsService.state);
  const [currentMode, setCurrentMode] = useState<string>("");
  const [selectors, setSelectors] = useState<{ selector: string; mode: string }[]>([]);
  const [localColorsVariables, setLocalColorVariables] = useState<Record<string, any>>({});
  const [activeTab, setActiveTab] = useState<"Global" | "Local">("Global");
  const editorCSS = editorService.getEditor()?.getCss();
  const isCSSReady = editorCSS && editorCSS.length > 0;

  useEffect(() => {
    projectsService.subscribe(updateState);

    return () => projectsService.unsubscribe(updateState);
  }, []);

  useEffect(() => {
    if (editorCSS) {
      const selectors = getSelectorFromActiveProject(editorCSS);
      if (selectors && selectors.length !== 0) {
        setCurrentMode(selectors[0].mode);
        setSelectors(selectors);
      }
    }
  }, [isCSSReady]);

  const handleUpdateLocalColors = (styles: Record<string, any>) => {
    setLocalColorVariables(styles);
  };

  if (selectors.length === 0 || !editorCSS?.includes(selectors[0].selector)) return null;

  return (
    <section className="rootStyle">
      {/* <Accordion label="Colors" className="rootStyle__heading"> */}
      <div className="rootStyle__content">
        <div className="rootStyle__tabs">
          <button
            className={`rootStyle__tab${activeTab === "Global" ? " rootStyle__tab--active" : ""}`}
            onClick={() => setActiveTab("Global")}
          >
            Global variables
          </button>
          <button
            className={`rootStyle__tab${activeTab === "Local" ? " rootStyle__tab--active" : ""}`}
            onClick={() => setActiveTab("Local")}
          >
            Local variables
          </button>
        </div>
        <div className={`rootStyle__tabpanel${activeTab === "Global" ? " rootStyle__tabpanel--active" : ""}`}>
          {selectors.length > 1 ? (
            <div className="rootStyle__theme-selector">
              <span className="rootStyle__mode-label">
                <Hint />
                <span>Mode</span>
              </span>

              <Select
                defaultValue={currentMode}
                options={selectors.map(({ mode }) => ({ label: mode }))}
                onChange={(selected) => setCurrentMode(selected)}
              />
            </div>
          ) : null}
          <div className="rootStyle__color-modes">
            {selectors.map(({ mode, selector }) => {
              return (
                <ColorMode
                  key={selector}
                  selector={selector}
                  mode={mode}
                  currentMode={currentMode}
                  setLocalColors={handleUpdateLocalColors}
                />
              );
            })}
          </div>
        </div>
        <div className={`rootStyle__tabpanel${activeTab !== "Global" ? "rootStyle__tabpanel--active" : ""}`}>
          <LocalColors colors={localColorsVariables} />
        </div>
      </div>
      {/* </Accordion> */}
    </section>
  );
};
