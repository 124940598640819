import { type CSSProperties } from "react";

type TSections2 = {
  className?: string;
  style?: CSSProperties;
};

export const Sections2 = ({ className, style }: TSections2) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2 3H14M2 13.1538H14M2 6.23077V9.92308C2 10.4329 2.41328 10.8462 2.92308 10.8462H13.0769C13.5867 10.8462 14 10.4329 14 9.92308V6.23077C14 5.72097 13.5867 5.30769 13.0769 5.30769H2.92308C2.41328 5.30769 2 5.72097 2 6.23077Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
