import React from "react";
import { Project } from "../../types/types.ts";
import { Subscriber } from "../abstract/Subscriber/Subscriber.ts";

export type Product = {
  priceId: string;
  type: "oneOffProduct" | "subscription";
  productType: "customizedByExperts" | "sitePlan" | "marketingPlan";
  price: number;
  metadata: ProductMeta;
};

export type ProductMeta = {
  name: string;
  billingPeriodDescription: string;
  tooltipItems?: TooltipItem[];
  billingPeriod?: "month" | "year" | "implementation";
};

export type ExpertAssistance = {
  name: string;
  category: string;
  goal: string;
  comments: string;
};

export type TooltipItem = {
  icon?: React.ReactNode;
  textBold?: string;
  textRegular?: string;
};

export type ProductsState = {
  products: Product[];
  template?: Template;
  expertAssistance?: ExpertAssistance;
};

type Template = Pick<Project, "projectId">;

export class Products extends Subscriber<ProductsState> {
  state = { products: [], template: {} as Template, expertAssistance: {} as ExpertAssistance } as ProductsState;

  constructor() {
    super();

    const products = localStorage.getItem("products");
    const template = localStorage.getItem("template");

    if (products) {
      this.state.products = JSON.parse(products);
    }

    if (template) {
      this.state.template = JSON.parse(template);
    }
  }

  getProducts() {
    return this.state.products;
  }

  getTemplate() {
    return this.state.template;
  }

  getTotalPrice() {
    const totalPrice = this.state.products.reduce((acc, product) => acc + product.price, 0);
    return Math.round(totalPrice * 100) / 100;
  }

  getProductByPriceId() {
    return this.state.products.find((product) => product.priceId);
  }

  isTemplateSelected() {
    return typeof (this.state.template && this.state.template.projectId) === "string";
  }

  isSitePlanSelected() {
    return this.state.products.some((product) => product.productType === "sitePlan");
  }

  isMarketingPlanSelected() {
    return this.state.products.some((product) => product.productType === "marketingPlan");
  }

  setTemplate(template: Template) {
    this.state.template = template;
    localStorage.setItem("template", JSON.stringify(template));

    this.notifySubscribers();
  }

  addProduct(product: Product) {
    const products = this.state.products;
    const existingProductIndex = products.findIndex((item) => item.productType === product.productType);

    if (existingProductIndex !== -1) {
      products[existingProductIndex] = product;
    } else {
      products.push(product);
    }

    localStorage.setItem("products", JSON.stringify(products));

    this.notifySubscribers();
  }

  removeProduct(product: Product) {
    const products = this.state.products;
    const productIndex = products.findIndex((item) => item.priceId === product.priceId);

    if (products[productIndex].productType === "customizedByExperts") {
      this.removeExpertAsistance();
    }

    if (productIndex !== -1) {
      this.state.products.splice(productIndex, 1);
    }

    localStorage.setItem("products", JSON.stringify(products));

    this.notifySubscribers();
  }

  setExpertAsistance(expertAssistance: ExpertAssistance) {
    this.state.expertAssistance = expertAssistance;

    localStorage.setItem("expertAssistance", JSON.stringify(expertAssistance));
  }

  getExpertAssistance() {
    const expertAsistance = localStorage.getItem("expertAssistance");
    return expertAsistance && JSON.parse(expertAsistance);
  }

  removeExpertAsistance() {
    localStorage.removeItem("expertAssistance");
  }

  clear() {
    this.state.products = [];
    this.state.template = {} as Template;
    this.state.expertAssistance = {} as ExpertAssistance;

    localStorage.removeItem("products");
    localStorage.removeItem("template");
    localStorage.removeItem("expertAssistance");

    this.notifySubscribers();
  }
}
