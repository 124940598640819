import { useEffect, useState } from "react";

import { ButtonNew } from "../../atoms/ButtonNew";
import { WeirdIconWrapper } from "../../atoms/WeirdIconWrapper";

import { Logotype } from "../../../../assets/svg/logotype";
import { Checked } from "../../../../assets/svg/checked";
import { MonitorIcon } from "../../../../assets/svg/monitor_icon";
import { DiscordIconNew } from "../../../../assets/svg/discord_icon_new";

import type { TMobileBrickwall } from "./MobileBrickwall.types";

import "./MobileBrickwall.css";

export const MobileBrickwall: TMobileBrickwall = () => {
  const [isOnboarding, setIsOnboarding] = useState(false);

  useEffect(() => {
    const isOnboarding = JSON.parse(sessionStorage.getItem("isOnboarding") || "false");
    if (isOnboarding) setIsOnboarding(true);
  }, []);

  return (
    <div className="mobile-brickwall">
      <div className="mobile-brickwall__wrapper">
        <Logotype />
        <div className="mobile-brickwall__content">
          <WeirdIconWrapper>
            <Checked />
          </WeirdIconWrapper>
          <div className="mobile-brickwall__introduction">
            <h1 className="mobile-brickwall__title">{isOnboarding ? "Sign up Complete!" : "Sign in Complete!"}</h1>
            <div className="mobile-brickwall__options">
              <div className="mobile-brickwall__option">
                <i>
                  <MonitorIcon />
                </i>
                <p>
                  To begin using the site, you will
                  <br />
                  need to launch <span>Codejet</span> from a PC or Mac
                </p>
              </div>
              <div className="mobile-brickwall__option">
                <i>
                  <DiscordIconNew />
                </i>
                <p>
                  Feel free to join our <span>{"Discord"}</span>
                  <br />
                  community for help and updates
                </p>
              </div>
            </div>
          </div>
          <ButtonNew
            orange
            className="mobile-brickwall__discord-btn"
            href="https://discord.gg/XHwhKVAQM9"
            target="_blank"
            rel="noreferrer noopener"
          >
            Join Discord
          </ButtonNew>
        </div>
        <div className="mobile-brickwall__navigation"></div>
      </div>
    </div>
  );
};
