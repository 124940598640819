import type { SurveyQuestions } from "../../../services/Survey/Survey";
import type { ISendStateToComponent, ISubscriber, TState } from "./CreateAccount.types";
import { Survey } from "../../../services/Survey/Survey";

const survey = new Survey();

export type Answer = {
  fields: {
    QUESTION_ID: [string];
    TEXT_AREA?: string;
    MULTIPLE_SELECT?: string[];
    NUMBER_FROM_0_TO_5?: string;
    USER_FIGMA_ID: null | string;
  };
};
export class CreateAccountService implements ISendStateToComponent {
  questions: SurveyQuestions[] = [];
  isLoading = false;
  currentQuestion = 0;
  filterBy = "NEW_USER";
  state: TState = {
    questions: this.questions,
    currentQuestion: this.currentQuestion,
  };
  subscribers: ISubscriber[] = [];

  async fetchQuestions() {
    return this.getAllQuestions().then((questions) => {
      if (questions) {
        this.questions = questions;
      }
      this.notifySubscribers();
    });
  }

  static instance: CreateAccountService;

  static getInstance() {
    if (!CreateAccountService.instance) {
      CreateAccountService.instance = new CreateAccountService();
    }
    return CreateAccountService.instance;
  }

  loading(state: boolean) {
    this.isLoading = state;
    this.notifySubscribers();
  }

  getCurrentQuestion() {
    if (this.questions.length === 0) {
      return "Loading...";
    }

    return this.questions[this.currentQuestion].question;
  }

  getAnswerOptions() {
    if (this.questions.length === 0) {
      return [];
    }
    return this.questions[this.currentQuestion].answers;
  }

  async getAllQuestions() {
    try {
      const questions = await survey.getSurvey();
      return questions.filter((question: SurveyQuestions) => {
        return question.form === this.filterBy;
      });
    } catch (err) {
      console.log("error", err);
    }
  }

  notifySubscribers(): void {
    this.subscribers.forEach((sb) => sb({ ...this.state }));
  }

  subscribe(callback: ISubscriber): void {
    this.subscribers.push(callback);
  }

  unsubscribe(callback: ISubscriber): void {
    this.subscribers = this.subscribers.filter((el) => el !== callback);
  }

  getAnswerOptionsChecked(answer: string) {
    if (this.questions.length === 0) return false;
    const currentQuestion = this.questions[this.currentQuestion];
    if (Array.isArray(currentQuestion.toSubmit)) {
      return currentQuestion.toSubmit.includes(answer);
    }
    return false;
  }

  isContinueButtonDisabled() {
    const currentQuestion = this.questions[this.currentQuestion];

    if (this.questions.length === 0) return true;
    if (Array.isArray(currentQuestion.toSubmit)) {
      return currentQuestion.toSubmit.length === 0;
    }
    return true;
  }

  //TODO write tests
  async submitAnswer(answer: string, isChecked: boolean) {
    const currentQuestion = this.questions[this.currentQuestion];
    const lowerCaseAnswer = answer.toLowerCase();

    if (!currentQuestion.toSubmit) {
      currentQuestion.toSubmit = [];
    }
    if (isChecked) {
      currentQuestion.toSubmit.push(answer);
    } else {
      currentQuestion.toSubmit = currentQuestion.toSubmit.filter((el) => el.toLowerCase() !== lowerCaseAnswer);
    }

    this.notifySubscribers();
  }

  isOtherOptionChecked() {
    if (this.questions.length === 0) return false;

    const currentQuestion = this.questions[this.currentQuestion];
    if (Array.isArray(currentQuestion.toSubmit)) {
      return currentQuestion.toSubmit.includes("Other");
    }
    return false;
  }

  async nextQuestion() {
    // submit answer to BE
    // await survey.sendAnswers(this.prepareAnswer());

    if (this.currentQuestion < this.questions.length - 1) {
      this.currentQuestion++;
    }

    this.notifySubscribers();
  }
  isFinalQuestion() {
    return this.currentQuestion === this.questions.length - 1;
  }

  async sendAnswers() {
    await survey.sendAnswers(this.preparedAnswers);
  }

  preparedAnswers: Answer[] = [];

  prepareAnswers() {
    this.preparedAnswers = this.questions.map((question) => {
      const recordId = question.recordId;
      const selectedCheckboxes = question.toSubmit?.filter((el) => el.toLowerCase() !== "other") || [];

      return {
        fields: {
          QUESTION_ID: [recordId],
          MULTIPLE_SELECT: selectedCheckboxes,
          //id will be added on BE
          USER_FIGMA_ID: null,
          TEXT_AREA: question.otherOptionValue,
        },
      };
    });
  }
  handleBack() {
    if (this.currentQuestion > 0) {
      this.currentQuestion--;
    }
    this.notifySubscribers();
  }

  get isBackDisabled() {
    return this.currentQuestion === 0;
  }

  getOtherOptionValue() {
    if (this.questions.length === 0) return "";
    const currentQuestion = this.questions[this.currentQuestion];
    return currentQuestion.otherOptionValue || "";
  }

  updateOther(value: string) {
    if (this.questions.length === 0) return;
    const currentQuestion = this.questions[this.currentQuestion];
    currentQuestion.otherOptionValue = value;
    this.notifySubscribers();
  }
}
