import { editorService } from "../editor";
import { TraitService } from "./TraitService.ts";
import { interpret } from "xstate";
import { traitManagerMachine } from "./machine.ts";
import { Trait } from "grapesjs";

//TraitService instance with editorService instance to handle logic of traits
export const traitService = new TraitService(editorService);

//Type definitions for react component to subscribe to TraitService
export type TraitServiceState = {
  traits: Trait[];
};

export { TraitService } from "./TraitService.ts";

//Should be started when properties tab is open
export const traitMachineInstance = interpret(traitManagerMachine(traitService));
