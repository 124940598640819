import { useEffect, useState } from "react";
import { ProductsState } from "../../services/Products/Products";
import { productsService } from "../../machines/products/products.instance";

export const useProducts = () => {
  const [productState, setProducts] = useState<ProductsState>({ products: [] });

  useEffect(() => {
    productsService.subscribe((state) => setProducts(state));
    const productsState = productsService.state;

    setProducts(productsState);

    return () => {
      productsService.unsubscribe((state) => setProducts(state));
    };
  }, []);

  return { products: productState.products, totalPrice: productsService.getTotalPrice() };
};
