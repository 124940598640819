import { Helmet } from "react-helmet-async";

import favicon from "../../../../assets/png/favicon.png";

import type { TSeo } from "./Seo.types";

// More info about Open Graph tags - https://ogp.me/

export const Seo: TSeo = ({
  title,
  description,
  author = "Codejet",
  keywords = "",
  image = favicon,
  siteName = "Codejet",
}) => {
  const siteUrl = import.meta.env.VITE_SITE_URL;
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
      <meta name="keywords" content={keywords} />
      <link rel="icon" href={image} />

      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="en_US" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@Codejetai" />
      <meta name="twitter:creator" content="@Codejetai" />
    </Helmet>
  );
};
