import React, { type SVGProps } from "react";
export const ImportFromFigmaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="49" height="56" viewBox="0 0 49 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_11833_46)">
      <rect x="4.33398" y="2" width="40" height="40" rx="8" fill="url(#paint0_linear_11833_46)" />
      <rect x="4.83398" y="2.5" width="39" height="39" rx="7.5" stroke="url(#paint1_linear_11833_46)" />
      <path
        d="M16.834 13.579C16.834 11.6036 18.4376 10 20.413 10H23.992V17.1575H20.413C18.4375 17.1575 16.834 15.5543 16.834 13.579Z"
        fill="#D4D4D8"
      />
      <path
        d="M32.4141 21.9996C32.4141 20.0243 30.8106 18.4211 28.8351 18.4211C26.8596 18.4212 25.2562 20.0243 25.2562 21.9996C25.2562 23.975 26.8597 25.5786 28.8351 25.5786C30.8106 25.5786 32.4141 23.975 32.4141 21.9996Z"
        fill="#D4D4D8"
      />
      <path
        d="M16.834 21.9996C16.834 20.0242 18.4376 18.4206 20.413 18.4206H23.992V25.5789H20.413C18.4376 25.5789 16.834 23.9751 16.834 21.9996Z"
        fill="#D4D4D8"
      />
      <path
        d="M20.413 26.842H23.992V30.421C23.992 32.3964 22.3884 34 20.413 34C18.4376 34 16.834 32.3964 16.834 30.421C16.834 28.4456 18.4376 26.842 20.413 26.842Z"
        fill="#D4D4D8"
      />
      <path
        d="M25.2551 10V17.1575L28.8351 17.158C30.8105 17.1579 32.4141 15.5544 32.4141 13.579C32.4141 11.6036 30.8106 10 28.8351 10H25.2551Z"
        fill="#D4D4D8"
      />
      <path
        d="M25.2559 10V17.1575L28.8359 17.158C30.8113 17.1579 32.4149 15.5544 32.4149 13.579C32.4149 11.6036 30.8113 10 28.8359 10H25.2559Z"
        fill="#FF4900"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_11833_46"
        x="0.333984"
        y="0"
        width="48"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11833_46" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_11833_46" result="effect2_dropShadow_11833_46" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_11833_46" result="effect3_dropShadow_11833_46" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_11833_46" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_11833_46" x1="24.334" y1="2" x2="24.334" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint1_linear_11833_46" x1="24.334" y1="2" x2="24.334" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
