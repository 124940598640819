import React, { type SVGProps } from "react";

export const HamburgerSlim = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="49" viewBox="0 0 25 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.666 25.5C13.2183 25.5 13.666 25.0523 13.666 24.5C13.666 23.9477 13.2183 23.5 12.666 23.5C12.1137 23.5 11.666 23.9477 11.666 24.5C11.666 25.0523 12.1137 25.5 12.666 25.5Z"
      stroke="#71717A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.666 18.5C13.2183 18.5 13.666 18.0523 13.666 17.5C13.666 16.9477 13.2183 16.5 12.666 16.5C12.1137 16.5 11.666 16.9477 11.666 17.5C11.666 18.0523 12.1137 18.5 12.666 18.5Z"
      stroke="#71717A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.666 32.5C13.2183 32.5 13.666 32.0523 13.666 31.5C13.666 30.9477 13.2183 30.5 12.666 30.5C12.1137 30.5 11.666 30.9477 11.666 31.5C11.666 32.0523 12.1137 32.5 12.666 32.5Z"
      stroke="#71717A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
