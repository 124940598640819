import { type CSSProperties } from "react";

type TSizeLaptop = {
  className?: string;
  style?: CSSProperties;
};

export const SizeLaptop = ({ className, style }: TSizeLaptop) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2.38462 7H11.6094V1.92308C11.6094 1.41328 11.1961 1 10.6863 1H3.30769C2.75385 1 2.38462 1.36923 2.38462 1.92308V7ZM2.38462 7L1.06192 10.0863C1.02107 10.1816 1 10.2842 1 10.388C1 10.8109 1.34289 11.1538 1.76588 11.1538H12.2341C12.6571 11.1538 13 10.8109 13 10.388C13 10.2842 12.979 10.1816 12.9381 10.0863L11.6154 7H2.38462Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
