import { Subscriber } from "../../../services/abstract/Subscriber/Subscriber";

export class FormModalService extends Subscriber<boolean> {
  static instance: FormModalService;
  static getInstance() {
    if (!FormModalService.instance) {
      FormModalService.instance = new FormModalService();
    }
    return FormModalService.instance;
  }

  state = false;
  openModal = () => {
    this.state = true;
    this.notifySubscribers();
  };
  closeModal = () => {
    this.state = false;
    this.notifySubscribers();
  };
}
