import { type CSSProperties } from "react";

type TJustifyStart = {
  className?: string;
  style?: CSSProperties;
};

export const JustifyStart = ({ className, style }: TJustifyStart) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.25 14V2M8.75 12.5V3.5M12.5 3.5H5C4.58579 3.5 4.25 3.83579 4.25 4.25V11.75C4.25 12.1642 4.58579 12.5 5 12.5H12.5C12.9142 12.5 13.25 12.1642 13.25 11.75V4.25C13.25 3.83579 12.9142 3.5 12.5 3.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
    />
  </svg>
);
