import React, { type SVGProps } from "react";
export const GridMiniIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.9922 17H16.9922M16.9922 17H19.9922M16.9922 17V14M16.9922 17V20" stroke="#FF4900" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 9.4V4.6C4 4.2686 4.26863 4 4.6 4H9.4C9.73137 4 10 4.2686 10 4.6V9.4C10 9.7314 9.73137 10 9.4 10H4.6C4.26863 10 4 9.7314 4 9.4Z" fill="#D4D4D8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.75 4.75V9.25H9.25V4.75H4.75ZM3.25 4.6C3.25 3.85436 3.85444 3.25 4.6 3.25H9.4C10.1456 3.25 10.75 3.85436 10.75 4.6V9.4C10.75 10.1456 10.1456 10.75 9.4 10.75H4.6C3.85444 10.75 3.25 10.1456 3.25 9.4V4.6Z" fill="#D4D4D8" />
    <path d="M4 19.4V14.6C4 14.2686 4.26863 14 4.6 14H9.4C9.73137 14 10 14.2686 10 14.6V19.4C10 19.7314 9.73137 20 9.4 20H4.6C4.26863 20 4 19.7314 4 19.4Z" fill="#D4D4D8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.75 14.75V19.25H9.25V14.75H4.75ZM3.25 14.6C3.25 13.8544 3.85444 13.25 4.6 13.25H9.4C10.1456 13.25 10.75 13.8544 10.75 14.6V19.4C10.75 20.1456 10.1456 20.75 9.4 20.75H4.6C3.85444 20.75 3.25 20.1456 3.25 19.4V14.6Z" fill="#D4D4D8" />
    <path d="M14 9.4V4.6C14 4.26863 14.2686 4 14.6 4H19.4C19.7314 4 20 4.26863 20 4.6V9.4C20 9.73137 19.7314 10 19.4 10H14.6C14.2686 10 14 9.73137 14 9.4Z" fill="#FF4900" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.75 4.75V9.25H19.25V4.75H14.75ZM13.25 4.6C13.25 3.85444 13.8544 3.25 14.6 3.25H19.4C20.1456 3.25 20.75 3.85444 20.75 4.6V9.4C20.75 10.1456 20.1456 10.75 19.4 10.75H14.6C13.8544 10.75 13.25 10.1456 13.25 9.4V4.6Z" fill="#FF4900" />
  </svg>
);
