import { ButtonNew } from "../ButtonNew";

import { Hint } from "../../../../assets/svg/hint";
import { Circle } from "../../../../assets/svg/circle";

import type { TSitePlanCard } from "./SitePlanCard.types";

import "./SitePlanCard.css";

export const SitePlanCard: TSitePlanCard = ({
  icon,
  withoutBorder,
  itemsDefinitions,
  header,
  subheader,
  price,
  priceDescription,
  discount,
  isBestSeller,
  selected,
  isFirst,
  isLast,
  onClick,
}) => {
  const containerClassName =
    "site-plan-card__container" +
    (withoutBorder ? " without-border" : "") +
    (selected ? " selected" : "") +
    (isFirst ? " first" : "") +
    (isLast ? " last" : "");

  return (
    <div className={containerClassName}>
      {isBestSeller && (
        <div className="site-plan-card__best-seller">
          <Circle className="site-plan-card__best-seller__icon" />
          <span className="site-plan-card__typography site-plan-card__best-seller__content">Best Seller</span>
        </div>
      )}
      <div>
        <div className="site-plan-card__header">
          <div className="site-plan-card__icon">{icon}</div>
          <h2 className="site-plan-card__typography site-plan-card__heading">{header}</h2>
          <h3 className="site-plan-card__typography site-plan-card__subheading">{subheader}</h3>
        </div>
        <div className="site-plan-card__price">
          <span className="site-plan-card__typography site-plan-card__price-value">${price}</span>
          <span className="site-plan-card__typography site-plan-card__price-desc"> {priceDescription}</span>
        </div>
        {discount && (
          <div className="site-plan-card__discount">
            <div className="site-plan-card__discount-info site-plan-card__typography">
              <s className="site-plan-card__discount-info__old-price">{discount.oldPrice}</s>
              <div className="site-plan-card__discount-info__highlited site-plan-card__typography">
                {discount.highlitedText}
              </div>
            </div>
            <div className="site-plan-card__discount-description site-plan-card__typography">
              {discount.description}
            </div>
          </div>
        )}
        <ul className="site-plan-card__list">
          {itemsDefinitions &&
            itemsDefinitions.map(({ icon, textBold, textRegular, tooltipText }, index) => (
              <li className="site-plan-card__list-item" key={index}>
                <div className="site-plan-card__list-item__content">
                  <div className="site-plan-card__list-item__icon">{icon}</div>
                  <strong className="site-plan-card__typography">{textBold}</strong>&nbsp;
                  <span className="site-plan-card__typography">{textRegular}</span>
                </div>
                <div className="site-plan-card__list-item__tooltip">
                  <Hint />
                  <div className="site-plan-card__typography site-plan-card__list-item__tooltip-content">
                    {tooltipText}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <ButtonNew className={"site-plan-card__button" + (selected ? " selected" : "")} onClick={onClick}>
        {selected ? "Selected" : "Select"}
      </ButtonNew>
    </div>
  );
};
