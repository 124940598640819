import { type CSSProperties } from "react";

type TBorderBottomWidth = {
  className?: string;
  style?: CSSProperties;
};

export const BorderBottomWidth = ({ className, style }: TBorderBottomWidth) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2 4L2 3C2 2.7348 2.10536 2.4804 2.29289 2.2929C2.48043 2.1054 2.73478 2 3 2H4M2 9.5L2 6.5M6.5 2L9.5 2M2 14L14 14M14 4V3C14 2.7348 13.8946 2.4804 13.7071 2.2929C13.5196 2.1054 13.2652 2 13 2H12M14 9.5L14 6.5"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
