const calcStart = "calc(var(";
const varStart = "var(";

const valueFromVar = (value: string) => {
  if (typeof value !== "string") return value;

  if (value.startsWith(varStart) && value.endsWith(")")) {
    return value.slice(varStart.length, -1);
  }

  if (value.startsWith(calcStart) && value.endsWith(")")) {
    const endIndex = value.indexOf(")");
    if (endIndex !== -1) {
      return value.slice(calcStart.length, endIndex);
    }
    return value.slice(8, -1);
  }

  return value;
};

export const getValueFromVariable = (allVariables: Record<string, string>, value: string): string => {
  const striptValue = valueFromVar(value);

  if (!allVariables[striptValue]) {
    return value;
  }

  if (!allVariables[striptValue].startsWith("var(") && !allVariables[striptValue].startsWith("calc(")) {
    return allVariables[striptValue];
  }

  return getValueFromVariable(allVariables, allVariables[striptValue]);
};
