import { DoneCheck } from "../../../../../../assets/svg/done_check";

import type { TLoginSuccessful } from "./LoginSuccessful.types";
import { UserService } from "../../../../../services/UserService/UserService";

import "./LoginSuccessful.css";

export const LoginSuccessful: TLoginSuccessful = () => {
  const email = UserService.getInstance().data?.EMAIL;

  return (
    <div className="login-successful">
      <div className="login-successful__content">
        <DoneCheck className="login-successful__icon" />
        <div className="login-successful__user-email login-successful__typography">{email}</div>
        <div className="login-successful__heading login-successful__typography">Login successful</div>
        <div className="login-successful__subheading login-successful__typography">
          Your account is all set up! You can now proceed with the payment.
        </div>
      </div>
    </div>
  );
};
