import { type CSSProperties } from "react";

type THome2 = {
  className?: string;
  style?: CSSProperties;
};

export const Home2 = ({ className, style }: THome2) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7 9V13M1 6.9399V12C1 12.5523 1.44772 13 2 13H12C12.5523 13 13 12.5523 13 12V6.9399C13 6.6596 12.8824 6.39216 12.6757 6.20275L7.67573 1.61942C7.2934 1.26895 6.7066 1.26895 6.32427 1.61942L1.32428 6.20275C1.11765 6.39216 1 6.6596 1 6.9399Z"
      stroke="#E4E4E7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
