import { type CSSProperties } from "react";

type TAdd = {
  className?: string;
  style?: CSSProperties;
};

export const Add = ({ className, style }: TAdd) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M9 5V13M5 9H13M1 2.33333V15.6667C1 16.4031 1.59696 17 2.33333 17H15.6667C16.4031 17 17 16.4031 17 15.6667V2.33333C17 1.59695 16.4031 1 15.6667 1H2.33333C1.59696 1 1 1.59696 1 2.33333Z"
      stroke="#CC3202"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
