// type KeyValuePair<T> = { [key: string]: T };

export const formatKeys = <T, T1>(data: T): T1 => {
  const result: Record<string, T[keyof T]> = {};

  for (const key in data) {
    const newKey = key
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, " ")
      .trim()
      .split(" ")
      .map((word, i) => (i === 0 ? word : word[0].toUpperCase() + word.slice(1)))
      .join("");

    result[newKey] = data[key];
  }
  return result as T1;
};
