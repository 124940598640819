import { useEffect, useState } from "react";

import { UserService } from "../../../../services/UserService/UserService";

import { Button } from "../../atoms/Button";
import { Dropdown } from "../../atoms/Dropdown";
import { ButtonNew } from "../../../atoms/ButtonNew";
import { ImportModal } from "../../molecules/ImportModal";
import { ConnectFigmaModal } from "../../../molecules/ConnectFigmaModal";
import { ConnectFigmaModalService } from "../../../molecules/ConnectFigmaModal/ConnectFigmaModal.service";

import { Plus } from "../../../../../assets/svg/plus";
import { Logotype } from "../../../../../assets/svg/logotype";
import { FigmaLogo } from "../../../../../assets/svg/figma_logo";

import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";
import { ImportModal as ImportModalService } from "../../molecules/ImportModal/ImportModal.service";

import type { TNavigation } from "./Navigation.types";

import "./Navigation.css";

const importModal = ImportModalService.getInstance();
const connectFigmaModal = ConnectFigmaModalService.getInstance();
const userService = UserService.getInstance();

export const Navigation: TNavigation = () => {
  const openModal = () => {
    if (userService.data?.FIGMA_ID) {
      importModal.openModal();
      return;
    }

    connectFigmaModal.openModal();
  };
  const [location, setLocation] = useState(window.location.pathname);

  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);

  return (
    <nav className="navigation container">
      <a className="naviation__btn-logo" href="/" aria-label="Home">
        <Logotype />
      </a>
      <div className="navigation__btns">
        <Button active={location === "/projects"} onClick={() => dashboardInstance.send("PROJECTS")}>
          My projects
        </Button>
        <Button active={location === "/templates"} onClick={() => dashboardInstance.send("TEMPLATES")}>
          Templates
        </Button>
        <Button active={location === "/community"} onClick={() => dashboardInstance.send("COMMUNITY")}>
          Community
        </Button>
      </div>
      <ButtonNew className="navigation__btns--import-modal" iconStart={<FigmaLogo />} onClick={openModal}>
        Import from Figma
      </ButtonNew>
      <ButtonNew
        className="navigation__btns--import-modal"
        orange
        iconStart={<Plus />}
        onClick={() => dashboardInstance.send("PICK_TEMPLATE")}
      >
        Create new site
      </ButtonNew>
      <Dropdown />
      <ImportModal />
      <ConnectFigmaModal />
    </nav>
  );
};
