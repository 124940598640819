import { useEffect, useRef, useState } from "react";
import { parseGradient, toGradient } from "grapesjs-style-gradient";

import { styleManagerService } from "../../StylesManager.service";
import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";

import { Close } from "../../../../../../../../assets/svg/close";
import { Marker } from "../../../../../../../assets/svg/Marker";

import { TGradientProperty } from "./GradientProperty.types";

import "./GradientProperty.css";

export const GradientProperty: TGradientProperty = ({ property }) => {
  const value = property.get("value");
  const classes = property.get("className");
  const parsed = parseGradient(value);
  const gradientRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      const coordinates = target.getBoundingClientRect();
      //i need place in tagregt where is clicked in % from left;
      const position = Math.round(((e.clientX - coordinates.left) / coordinates.width) * 100) + "%";

      const newStops = [...parsed.stops, { color: "rgba(255, 0, 0, 1)", position: position }]
        .sort((a, b) => {
          const parsePosition = (pos: string) => parseFloat(pos.replace("%", ""));

          return parsePosition(a.position) - parsePosition(b.position);
        })
        .map((stop, i) => {
          return `${stop.color} ${stop.position}`;
        })
        .join(", ");

      property.upValue(toGradient(parsed.type, parsed.direction, newStops));
    };

    gradientRef.current?.addEventListener("mousedown", handleMouseDown);

    return () => {
      gradientRef.current?.removeEventListener("mousedown", handleMouseDown);
    };
  }, [parsed]);
  const handleStopRemove = (index: number) => {
    const newStops = parsed.stops
      .filter((_, i) => i !== index)
      .map((stop, i) => {
        return `${stop.color} ${stop.position}`;
      })
      .join(", ");

    property.upValue(toGradient(parsed.type, parsed.direction, newStops));
  };
  const handleColorChange = (color: string, index: number) => {
    const newStops = parsed.stops
      .map((stop, i) => {
        if (i === index) {
          return { ...stop, color: color };
        }
        return stop;
      })
      .map((stop, i) => {
        return `${stop.color} ${stop.position}`;
      })
      .join(", ");
    property.upValue(toGradient(parsed.type, parsed.direction, newStops));
    styleManagerService.notifySubscribers();
  };
  const colorChangeHandler = (currentColor: string, index: number) => {
    if (!gradientRef.current) return;
    const position = gradientRef.current.getBoundingClientRect();
    colorPickerServiceInstance.setupColorPicker(
      currentColor,
      (color: string) => handleColorChange(color, index),
      {
        x: position.left,
        y: position.top,
      },
      "left"
    );
  };
  const handleMouseDown = (index: number) => {
    setActiveIndex(index);
  };
  const handleMouseUp = () => {
    setActiveIndex((prev) => null);
  };
  const handleMouseMove = (e: any) => {
    if (!activeIndex && activeIndex !== 0) return;

    const target = e.target as HTMLElement;
    const coordinates = target.getBoundingClientRect();
    const position = Math.round(((e.clientX - coordinates.left) / coordinates.width) * 100) + "%";

    const direction = parsed.stops[activeIndex].position < position ? "up" : "down";
    let changeIndex: false | "up" | "down" = false;

    const newStops = parsed.stops
      .map((stop, i) => {
        if (i === activeIndex) {
          return { ...stop, position: position };
        }
        return stop;
      })
      .sort((a, b) => {
        const parsePosition = (pos: string) => parseFloat(pos.replace("%", ""));
        if (parsePosition(a.position) - parsePosition(b.position) < 0 && direction === "down") {
          changeIndex = "down";
        }
        if (parsePosition(a.position) - parsePosition(b.position) < 0 && direction === "up") {
          changeIndex = "up";
        }

        return parsePosition(a.position) - parsePosition(b.position);
      })
      .map((stop) => `${stop.color} ${stop.position}`)
      .join(", ");

    if (changeIndex) {
      if (changeIndex === "up") {
        setActiveIndex((prev) => activeIndex + 1);
      }

      if (changeIndex === "down") {
        setActiveIndex((prev) => activeIndex - 1);
      }
    }

    property.upValue(toGradient(parsed.type, parsed.direction, newStops));
  };
  const handleMouseLeave = () => {
    setActiveIndex((prev) => null);
  };

  return (
    <div
      className={`gradient-property${classes ? " " + classes : ""}`}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseLeave}
    >
      <div
        style={{ backgroundImage: toGradient("linear", "to right", parsed.colors) }}
        className="gradient-property__gradient"
        ref={gradientRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      ></div>
      {parsed.stops.map((stop, i) => {
        return (
          <div key={i} className="gradient-property__stop" style={{ left: stop.position }}>
            <button className="gradient-property__color-remove" onClick={() => handleStopRemove(i)}>
              <Close />
            </button>
            <button
              className="gradient-property__slider"
              onMouseDown={() => handleMouseDown(i)}
              onMouseUp={handleMouseUp}
            ></button>
            <div className="gradient-property__color-stop-container">
              <Marker />
              <button
                style={{ backgroundColor: stop.color }}
                className="gradient-property__color-stop"
                onClick={() => colorChangeHandler(stop.color.trim(), i)}
              ></button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
