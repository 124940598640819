import { useState, useEffect } from "react";

import { dashboardInstance } from "../../../../machines/dashboard/dashboard.instance";
import { editorInstance, editorService } from "../../../pages/Alpha_O/modules/editor";
import { projectsInstance } from "../../../../machines/projects/projects.instance";
import { pagesService } from "../../../pages/Alpha_O/modules/pages/pages.service";
import { modalMachineInstance } from "../../../pages/Alpha_O/modules/modal";

import { Button } from "../../atoms/Button";

import { CodejetRocket } from "../../../../assets/svg/CodejetRocket";
import { Dashboard } from "../../../../assets/svg/Dashboard";
import { Pages2 } from "../../../../assets/svg/Pages2";
import { Redo } from "../../../../assets/svg/Redo";
import { Settings } from "../../../../assets/svg/Settings";
import { SizeLaptop } from "../../../../assets/svg/SizeLaptop";
import { Undo } from "../../../../assets/svg/Undo";
import { SizeTablet } from "../../../../assets/svg/SizeTablet";
import { SizeMobile } from "../../../../assets/svg/SizeMobile";
import { Preview } from "../../../../assets/svg/Preview";
import { Home2 } from "../../../../assets/svg/Home2";

import { TTopBar } from "./TopBar.types";

import "./TopBar.css";

export const TopBar: TTopBar = ({ isPreview, togglePreview }) => {
  const [_, updatePageManagerState] = useState(pagesService.state);
  const editor = editorService.getEditor();
  const project = projectsInstance.getSnapshot().context.service;
  const activeProject = project.getActiveProject();
  const activeProjectId = activeProject?.projectId;

  const allPages = editor?.Pages.getAll() || [];
  const currentPage = editor?.Pages.getSelected()?.getName() || "";
  const device = editor?.getDevice();
  const isHomePage = allPages.length === 1 || (allPages.length > 1 && allPages[0].getName() === currentPage);

  useEffect(() => {
    pagesService.subscribe((state) => updatePageManagerState(state));
    return () => {
      pagesService.unsubscribe(updatePageManagerState);
    };
  }, []);

  const handleDeviceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deviceType = e.target.value;
    editorInstance.send("CHANGE_DEVICE_TYPE", { deviceType });
  };

  const handleMovetoDashboard = () => {
    dashboardInstance.send("PROJECTS");
  };

  // const handleToggleOutline = () => {
  //   editorInstance.send("TOGGLE_OUTLINE");
  // };

  const handleUndo = () => {
    editorInstance.send("UNDO");
  };

  const handleRedo = () => {
    editorInstance.send("REDO");
  };

  const handleMoveToSettings = () => {
    dashboardInstance.send("PROJECT_SETTINGS", { projectId: activeProjectId });
  };

  const handlePublishBtn = () => {
    modalMachineInstance.send("OPEN", { modalId: "domains" });
  };

  return (
    <div className="top-bar">
      <div className="top-bar__left-column">
        <button key="save" onClick={() => {}} title="Codejet Logo">
          <CodejetRocket />
        </button>
      </div>
      <div className="top-bar__right-column">
        <div>
          <div className="top-bar__current-page">
            {isHomePage ? <Home2 /> : <Pages2 />}
            <span>{currentPage}</span>
          </div>
        </div>
        <div className="top-bar__device-pick-wrapper">
          <div className="top-bar__device-pick">
            <input
              id="device-desktop"
              type="radio"
              name="device"
              value="Desktop"
              onChange={handleDeviceChange}
              checked={device === "Desktop"}
              className="vs-hidden"
            />
            <label htmlFor="device-desktop">
              <SizeLaptop />
              <span className="top-bar__device-name">Desktop</span>
            </label>
            <input
              id="device-tablet"
              type="radio"
              name="device"
              value="Tablet"
              onChange={handleDeviceChange}
              checked={device === "Tablet"}
              className="vs-hidden"
            />
            <label htmlFor="device-tablet">
              <SizeTablet />
              <span className="top-bar__device-name">Tablet</span>
            </label>
            <input
              id="device-mobile"
              type="radio"
              name="device"
              value="Mobile"
              onChange={handleDeviceChange}
              checked={device === "Mobile"}
              className="vs-hidden"
            />
            <label htmlFor="device-mobile">
              <SizeMobile />
              <span className="top-bar__device-name">Mobile</span>
            </label>
          </div>
        </div>
        <div className="top-bar__cta-wrapper">
          <Button
            type="fifth-small"
            aria-label="Undo changes"
            disabled={!editor?.UndoManager.hasUndo()}
            iconStart={<Undo />}
            onClick={handleUndo}
          />
          <Button
            type="fifth-small"
            aria-label="Redo changes"
            disabled={!editor?.UndoManager.hasRedo()}
            iconStart={<Redo />}
            onClick={handleRedo}
          />
          <Button
            type="fifth-small"
            aria-label="Move to project settings"
            iconEnd={<Settings />}
            onClick={handleMoveToSettings}
          >
            {/* Settings */}
          </Button>
          <Button
            type="fifth-small"
            aria-label="Page preview"
            iconEnd={<Preview />}
            onClick={togglePreview}
            isActive={isPreview}
          >
            {/* Preview */}
          </Button>
          <Button type="secondary" size="small" onClick={handlePublishBtn}>
            Publish
          </Button>
          <Button type="secondary-small" truncate iconStart={<Dashboard />} onClick={handleMovetoDashboard}>
            Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};
