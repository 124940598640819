import { WeirdIconWrapper } from "../../../atoms/WeirdIconWrapper";

import { Catalog } from "../../../../../assets/svg/catalog";

export const NoContent = () => {
  return (
    <div className="my-projects__projects-no-content">
      <WeirdIconWrapper>
        <Catalog />
      </WeirdIconWrapper>
      <h1 className="my-projects__projects-content-title">No templates</h1>
      <h2 className="my-projects__projects-content-subtitle"></h2>
    </div>
  );
};
