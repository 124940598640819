import { type CSSProperties } from "react";

type TComposition = {
  className?: string;
  style?: CSSProperties;
};

export const Composition = ({ className, style }: TComposition) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M4 16V8M20 16V8M8 4L16 4M8 20H16" stroke="white" strokeWidth="1.25" strokeLinecap="round" />
  </svg>
);
