import { useEffect, useState, useRef } from "react";
import { Trait } from "grapesjs";

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return debouncedValue;
};

export const Range = ({ trait }: { trait: Trait }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [localValue, setLocalValue] = useState(trait.getValue());
  const [elementWidth, setElementWidth] = useState(0);
  const label = trait.getLabel();
  const debouncedValue = useDebounce(localValue, 400);

  useEffect(() => {
    if (trait.getValue() !== debouncedValue) {
      trait.setValue(debouncedValue);
    }
  }, [debouncedValue, trait]);

  useEffect(() => {
    if (sliderRef.current) {
      setElementWidth(sliderRef.current.getBoundingClientRect().width);
    }
  }, [sliderRef.current]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const thumbSize = 14;
  const size = elementWidth * (localValue / 100) + thumbSize * (1 - localValue / 100);
  const thumbPosition = `calc(${localValue}% - ${thumbSize * (localValue / 100)}px)`;

  return (
    <li key={trait.cid} className="trait-manager__trait">
      <span className="trait-manager__checkbox-label">{label}</span>
      <div className="custom_slider" ref={sliderRef}>
        <input
          type="range"
          min="0"
          max="100"
          value={localValue}
          onChange={handleChange}
          className="range-slider__range"
        />
        <div className="custom_slider__thumb" style={{ left: thumbPosition }}></div>
        <div className="custom_slider__track">
          <div className="custom_slider__track-before" style={{ width: `${size}px` }}></div>
          <div className="custom_slider__track-after"></div>
        </div>
      </div>
    </li>
  );
};
