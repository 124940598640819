import type { SVGProps } from "react";
export const Connector = (props: SVGProps<SVGSVGElement>) => (
  <svg width="215" height="6" viewBox="0 0 215 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5H214.667V2.5H3V3.5Z"
      fill="url(#paint0_linear_20466_2073)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20466_2073"
        x1="3"
        y1="3.5"
        x2="214.667"
        y2="3.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.5" stopColor="#FF4900" />
        <stop offset="1" stopColor="#FFD2A5" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
