export const safeFonts = [
  {
    label: "Arial",
  },
  {
    label: "Arial Black",
  },
  {
    label: "Brush Script MT",
  },
  {
    label: "Comic Sans MS",
  },
  {
    label: "Courier New",
  },
  {
    label: "Georgia",
  },
  {
    label: "Helvetica",
  },
  {
    label: "Impact",
  },
  {
    label: "Lucida Sans Unicode",
  },
  {
    label: "Tahoma",
  },
  {
    label: "Times New Roman",
  },
  {
    label: "Trebuchet MS",
  },
  {
    label: "Verdana",
  },
];
