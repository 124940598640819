import { type CSSProperties } from "react";

type TColor = {
  className?: string;
  style?: CSSProperties;
};

export const Color = ({ className, style }: TColor) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M5.69231 13.4768C7.73151 13.4768 9.38462 11.8237 9.38462 9.78452C9.38462 7.74531 7.73151 6.09221 5.69231 6.09221C3.6531 6.09221 2 7.74531 2 9.78452C2 11.8237 3.6531 13.4768 5.69231 13.4768Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3077 13.4768C12.3469 13.4768 14 11.8237 14 9.78452C14 7.74531 12.3469 6.09221 10.3077 6.09221C8.26849 6.09221 6.61538 7.74531 6.61538 9.78452C6.61538 11.8237 8.26849 13.4768 10.3077 13.4768Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.78452C10.0392 9.78452 11.6923 8.13142 11.6923 6.09221C11.6923 4.053 10.0392 2.3999 8 2.3999C5.96079 2.3999 4.30769 4.053 4.30769 6.09221C4.30769 8.13142 5.96079 9.78452 8 9.78452Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
