import { type CSSProperties } from "react";

type TDirectionColumnReverse = {
  className?: string;
  style?: CSSProperties;
};

export const DirectionColumnReverse = ({ className, style }: TDirectionColumnReverse) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M5.00017 12.9923V4.68462M5.00017 0.992309L8.69248 4.68462H1.30786L5.00017 0.992309Z"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
