import { routerInstance } from "../../../machines/router/router.instance";
import { dashboardInstance } from "../../../machines/dashboard/dashboard.instance";

import { ButtonNew } from "../../atoms/ButtonNew";
import { CartItem } from "./atoms/CartItem";

import { Lock } from "../../../../assets/svg/lock";
import { Shield } from "../../../../assets/svg/shield";

import type { TCart } from "./Cart.types";

import "./Cart.css";

export const Cart: TCart = ({
  taxAmount,
  totalAmount,
  cartItemList,
  isPaySectionVisible,
  onPay,
  onProductDelete,
  isAuthorized,
  isLoading = false,
}) => {
  const goToDashboard = () => {
    dashboardInstance.send("PROJECTS");
    localStorage.setItem("isProjectAdded", JSON.stringify(true));
  };

  return (
    <div className="codejet__cart">
      <div className={"codejet__cart-container" + (!isPaySectionVisible ? " without__pay-section" : "")}>
        <h2 className="codejet__typography">Cart</h2>
        {cartItemList.map(
          (
            { heading, subHeading, price, scoredOutPrice, isSubHeadingGreen, billingPeriod, priceId, metadata },
            index
          ) => (
            <CartItem
              key={index}
              heading={heading}
              subHeading={subHeading}
              price={price}
              metadata={metadata}
              priceId={priceId}
              scoredOutPrice={scoredOutPrice}
              isSubHeadingGreen={isSubHeadingGreen}
              billingPeriod={billingPeriod}
              onDelete={() => onProductDelete(priceId)}
              onEdit={() => routerInstance.send("SELECT_PLAN_SITE")}
              tooltip={metadata.tooltipItems}
            />
          )
        )}
        <div className="codejet__cart-divider"></div>
        {taxAmount !== undefined && (
          <div className="codejet__tax">
            <div className="codejet__typography codejet__tax-name">Tax</div>
            <div className="codejet__typography codejet__tax-price">${taxAmount}</div>
          </div>
        )}
        <div className="codejet__total">
          <div className="codejet__typography codejet__total-name">Total</div>
          <div className="codejet__typography codejet__total-price">${totalAmount}</div>
        </div>
        {isPaySectionVisible ? (
          <>
            <ButtonNew className="codejet__cart-button" orange disabled={!isAuthorized || isLoading} onClick={onPay}>
              {isLoading ? (
                <>
                  Adding project to your account
                  <span className="codejet__loader"></span>
                </>
              ) : (
                "Pay now"
              )}
            </ButtonNew>
            <div className="codejet__typography codejet__bottom-description">
              By purchasing, you acknowledge that your subscription will be renewed automatically, unless you switch to
              manual renewal.
            </div>
          </>
        ) : (
          <ButtonNew className="codejet__cart-button" orange disabled={!isAuthorized} onClick={goToDashboard}>
            Go to dashboard
          </ButtonNew>
        )}
      </div>
      {isPaySectionVisible && (
        <div className="codejet__typography codejet__bottom-banner">
          <div className="codejet__bottom-banner__item">
            <Lock className="codejet__bottom-banner__icon" />
            Your payment is 100% secure
          </div>
          <div className="codejet__bottom-banner__item">
            <Shield className="codejet__bottom-banner__icon" />
            14-day money back guarantee
          </div>
        </div>
      )}
    </div>
  );
};
