import { productsInstance } from "../../../machines/products/products.instance";
import { routerInstance } from "../../../machines/router/router.instance";

import { FormModalService } from "../FormModal/FormModal.service";
import { UseTemplateModalService } from "../UseTemplateModal/UseTemplateModal.service";

import { FormModal } from "../FormModal";
import { UseTemplateModal } from "../UseTemplateModal";

import type { TExperAssistanceFlow } from "./ExperAssistanceFlow.types";
import { Product } from "../../../services/Products/Products";

const useTemplateModalService = UseTemplateModalService.getInstance();
const formModalService = FormModalService.getInstance();

export const ExperAssistanceFlow: TExperAssistanceFlow = ({ expertAssistanceProduct, setExpertAsistanceProduct }) => {
  const formModalContinueHandler = () => {
    if (expertAssistanceProduct) {
      productsInstance.send({ type: "SELECT_CUSTOMIZED_BY_EXPERTS_PLAN", payload: expertAssistanceProduct });
    }

    routerInstance.send("SELECT_PLAN_SITE");
  };

  const formModalCancelHandler = () => {
    setExpertAsistanceProduct(undefined);

    formModalService.closeModal();
  };

  const useTemplateModalCancelHandler = () => {
    setExpertAsistanceProduct(undefined);

    useTemplateModalService.closeModal();
  };

  const useTemplateOnSelectHandler = (product: Product) => {
    if (expertAssistanceProduct) {
      setExpertAsistanceProduct(undefined);
      return;
    }

    setExpertAsistanceProduct(product);
  };

  const useTemplateModalContinueHandler = () => {
    formModalService.openModal();
  };

  const useTemplateModalSkipHandler = () => {
    routerInstance.send("SELECT_PLAN_SITE");
  };

  return (
    <>
      <FormModal onContinue={formModalContinueHandler} onCancel={formModalCancelHandler} />
      <UseTemplateModal
        onContinue={useTemplateModalContinueHandler}
        onSkip={useTemplateModalSkipHandler}
        onCancel={useTemplateModalCancelHandler}
        onSelect={useTemplateOnSelectHandler}
        expertAssistanceProduct={expertAssistanceProduct}
      />
    </>
  );
};
