import { Subscriber } from "../../../../services/abstract/Subscriber/Subscriber";

export class ImportModal extends Subscriber<boolean> {
  static instance: ImportModal;
  static getInstance() {
    if (!ImportModal.instance) {
      ImportModal.instance = new ImportModal();
    }
    return ImportModal.instance;
  }

  state = false;
  openModal = () => {
    this.state = true;
    this.notifySubscribers();
  };
  closeModal = () => {
    this.state = false;
    this.notifySubscribers();
  };
}
