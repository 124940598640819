import { SignInSection } from "./atoms/SignInSection";

import type { TCreateAccount } from "./CreateAccount.types";

import "./CreateAccount.css";

export const CreateAccount: TCreateAccount = () => {
  return (
    <div className="codejet__create-account">
      <SignInSection />
    </div>
  );
};
