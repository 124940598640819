import { type CSSProperties } from "react";

type TSizeMobile = {
  className?: string;
  style?: CSSProperties;
};

export const SizeMobile = ({ className, style }: TSizeMobile) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M5.4923 10.6923H6.41537M9.1846 1H2.72306C2.21327 1 1.79999 1.41328 1.79999 1.92308V12.0769C1.79999 12.5867 2.21327 13 2.72306 13H9.1846C9.69442 13 10.1077 12.5867 10.1077 12.0769V1.92308C10.1077 1.41328 9.69442 1 9.1846 1Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
