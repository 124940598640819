import axios, { AxiosResponse } from "axios";
import { dashboardInstance } from "../../machines/dashboard/dashboard.instance";
import { authInstance } from "../../machines/auth/auth.instance";

export type Response = AxiosResponse<Record<"url", string>>;

const paymentsAxios = axios.create();

paymentsAxios.interceptors.response.use(
  (response: Response): Response => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      authInstance.send("SESSION_EXPIRED");
    }
    return Promise.reject(error);
  }
);
export { paymentsAxios };
