import { Login } from "../components/pages/Login/Login";
import { VerifyPage } from "../components/pages/VerifyPage/VerifyPage";
import { CreateAccount } from "../components/pages/CreateAccount/CreateAccount";
import { MobileBrickwall } from "../components/pages/MobileBrickwall/MobileBrickwall";
import { Alpha_O } from "../components/pages/Alpha_O";
import { MyProjects } from "../components/pages/MyProjects";
import { Community } from "../components/pages/Community";
import { ProjectSettings } from "../components/pages/ProjectSettings";
import { TemplatesDashboards } from "../components/pages/TemplatesDashboard";
import { Alpha_O_templates } from "../components/pages/Alpha_O_templates/Alpha_O_templates";
import { UnauthorizedTemplate } from "../components/pages/UnauthorizedTemplates";
import { SelectPlanSite } from "../components/pages/SelectPlanSite";
import { SelectMarketingPlan } from "../components/pages/SelectMarketingPlan";
import { WrapUp } from "../components/pages/WrapUp";

export const LoadingPage = {
  path: "/loading",
  component: <VerifyPage />,
};

export const CreateAccountPage = {
  path: "/createAccount",
  component: <CreateAccount />,
};

export const LoginPage = {
  path: "/login",
  component: <Login />,
};

export const LogoutPage = {
  path: "/login",
  component: (
    <Login
      toastMsg={
        <div>
          <h2>Your session has expired.</h2>
          <p>Please log in again.</p>
        </div>
      }
    />
  ),
};

export const FigmaPluginLoginPage = {
  path: "/login/figma-plugin",
  component: <Login />,
};

export const MobileBrickWall = {
  path: "/mobile",
  component: <MobileBrickwall />,
};

export const AlphaOPage = {
  path: "/alpha_o",
  component: <Alpha_O />,
};

export const AlphaOPageTemplates = {
  path: "/alpha_o_templates",
  component: <Alpha_O_templates />,
};

export const AlphaOPageTemplatesUnauthorized = {
  path: "/alpha_o_templates_unauthorized",
  component: <Alpha_O_templates isAuthenticated={false} />,
};

// export const ProjectsPage = {
//   path: "/projects",
//   component: (
//     <DashboardAlphaO isAlwaysOpen>
//       <Projects />
//     </DashboardAlphaO>
//   ),
// };

export const ProjectsPage = {
  path: "/projects",
  component: <MyProjects />,
};

export const AssignFigmaIdPage = {
  path: "/assign-figma-id",
  component: <MyProjects />,
};

export const TemplatesPage = {
  path: "/templates",
  component: <TemplatesDashboards />,
};

export const PickTemplatePage = {
  path: "/pick_template",
  component: <UnauthorizedTemplate />,
};

export const SelectPlanSitePage = {
  path: "/select_plan_site",
  component: <SelectPlanSite />,
};

export const SelectMarketingPlanPage = {
  path: "/select_marketing_plan",
  component: <SelectMarketingPlan />,
};

export const SignupPage = {
  path: "/sign_up",
  component: <WrapUp isAuthorized={false} />,
};

export const WrapUpPage = {
  path: "/wrap_up",
  // Todo: Add isAuthorized from global scope.
  component: <WrapUp isAuthorized={true} />,
};

export const CommunityPage = {
  path: "/community",
  component: <Community />,
};

export const ProjectSettingsPage = {
  path: "/project/settings",
  component: <ProjectSettings />,
};
