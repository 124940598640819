import { stylesManagerInstance } from "../..";
import { assetManagerInstance } from "../../../asset-manager";
import { styleManagerService } from "../../StylesManager.service";

import { TFileProperty } from "./FileProperty.types";

import "./FileProperty.css";
import { Button } from "../../../../../../AlphaO/atoms/Button";

export const FileProperty: TFileProperty = ({ property }) => {
  const value = property.get("value");

  const handleChange = (url: string) => {
    property.upValue(url);
    styleManagerService.notifySubscribers();
  };

  const handleOpenModal = () => {
    stylesManagerInstance.send("AWAIT_FOR_IMAGE", { func: handleChange });
    assetManagerInstance.send("CHANGE_BACKGROUND");
  };

  const getStyle = (value: string) => {
    if (value.startsWith("linear-gradient")) {
      return { backgroundImage: value };
    }

    if (value.includes("https://codejet")) {
      return { backgroundImage: value };
    }

    return null;
  };

  const imageStyle = getStyle(value) ?? {};
  const hasStyle = Object.keys(imageStyle).length > 0;
  const fileName = value.startsWith("url") ? value.split("/").pop().replace('")', "") : "";

  return (
    <div style={{}} className="file-property">
      <div
        className={`file-property__image${hasStyle ? "" : " file-property__image--empty"}`}
        style={imageStyle}
        onClick={handleOpenModal}
      />
      <span className="file-property__name">{fileName === "url()" ? "white.png" : fileName}</span>
      <Button type="second" onClick={handleOpenModal}>
        Select Image
      </Button>
    </div>
  );
};
