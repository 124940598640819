import { useEffect, useRef } from "react";

/**
 * Custom hook that detects clicks outside of a specified element.
 * @template T - The type of the element to be referenced.
 * @param {() => void} callback - The callback function to be executed when a click occurs outside the element.
 * @returns {React.RefObject<T>} - A React ref object that can be attached to the element to be monitored.
 */
const useOutsideClick = <T extends HTMLElement>(callback: () => void) => {
  const ref = useRef<T>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return ref;
};

export default useOutsideClick;
