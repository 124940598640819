import { useEffect, useState } from "react";
import { editorService } from "../../../pages/Alpha_O/modules/editor";
import { Accordion } from "../../atoms/Accordion/Accordion";
import { NestedFontCategory } from "../NestedFontCategory/NestedFontCategory";
import { FontInput } from "../../atoms/FontInput/FontInput";
import { getTypographyCategories } from "./utils";
import { safeFonts } from "./constants";
import { Select } from "../../../atoms/Select";

import { TNestedFontCategory } from "./FontMode.types";

import "./FontMode.css";

export const FontMode: TNestedFontCategory = ({ selector, isActiveMode }) => {
  const [globalVariables, setGlobalVariables] = useState<Record<string, string>>({});
  const editor = editorService.getEditor();
  const typographyByCategories = getTypographyCategories(globalVariables);
  const hasGlobalVariables = globalVariables && Object.keys(globalVariables).length !== 0;

  useEffect(() => {
    if (!editor) return;
    const rootStyle = editor.Css?.getRule(selector)?.attributes.style;

    if (rootStyle) {
      setGlobalVariables(rootStyle);
    }
  }, []);

  const handleInputChange = (name: string, value: string) => {
    if (!editor) return;
    if (!hasGlobalVariables || !value) return;
    const updatedRule = editor?.Css.setRule(selector, { ...globalVariables, [name]: value })?.attributes.style;

    if (updatedRule) {
      setGlobalVariables((prev) => updatedRule);
    }
  };

  if (!globalVariables || Object.keys(typographyByCategories).length === 0) return null;

  return (
    <div className={`font-mode ${isActiveMode ? " font-mode--active" : ""}`}>
      <ul className="font-mode-wrapper">
        {Object.keys(typographyByCategories).map((category) => {
          return (
            <Accordion label={category} key={category} variant>
              <ul className="font-mode__list">
                {Object.keys(typographyByCategories[category].categories).length !== 0 ? (
                  <li className="font-mode__list--subcategory">
                    {Object.keys(typographyByCategories[category].categories).map((subcategory) => {
                      const { fonts, categories } = typographyByCategories[category].categories[subcategory];
                      return (
                        <NestedFontCategory
                          key={subcategory}
                          mainCategoryName={category}
                          categoryName={subcategory}
                          fonts={fonts}
                          categories={categories}
                          handleChange={handleInputChange}
                        />
                      );
                    })}
                  </li>
                ) : null}
                {typographyByCategories[category].fonts.map(({ fontName, fontValue, fontLabel }) => {
                  return (
                    <li className="font-mode__variable" key={fontName}>
                      <label className="font-mode__label">{fontLabel}</label>
                      {category === "font family" ? (
                        <Select
                          key={fontName}
                          options={safeFonts}
                          defaultValue={fontValue}
                          onChange={(value) => handleInputChange(fontName, value)}
                        />
                      ) : (
                        <FontInput name={fontName} value={fontValue} onChange={handleInputChange} />
                      )}
                    </li>
                  );
                })}
              </ul>
            </Accordion>
          );
        })}
      </ul>
    </div>
  );
};
