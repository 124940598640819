import { useEffect, useState } from "react";

import { projectsInstance } from "../../../machines/projects/projects.instance";
import { Button } from "../../Dashboard/atoms/Button";
import { ButtonNew } from "../../atoms/ButtonNew";
// import { ProjectSettingsBreadcrumbs } from "../../../../Dashboard/atoms/ProjectSettingsBreadcrumbs";
import { ProjectSettingsInput } from "../../Dashboard/atoms/ProjectSettingsInput";
import { ProjectSettingsTextArea } from "../../Dashboard/atoms/ProjectSettingsTextArea";
import { GridMiniIcon } from "../../../../assets/svg/grid-mini-icon";
import { ArrowUpRight } from "../../../assets/svg/ArrowUpRight";

import { useAsyncAction } from "../../../utils/useAsyncAction/useAsyncAction";

import { TGeneralSettings } from "./GeneralSettings.types";
import { Project } from "../../../types/types";

import "./GeneralSettings.css";

export const GeneralSettings: TGeneralSettings = () => {
  const [activeProject, setActiveProject] = useState<Project>();
  const [originalValues, setOriginalValues] = useState({
    name: "",
    description: "",
    customHead: "",
    customCode: "",
  });
  const [currentValues, setCurrentValues] = useState({
    name: "",
    description: "",
    customHead: "",
    customCode: "",
  });
  const [hasChanges, setHasChanges] = useState(false);
  const { isLoading, handleAsyncAction } = useAsyncAction();

  const projectAddress = () => {
    if (activeProject?.customDomain?.published) {
      return activeProject?.customDomain?.address;
    }

    return activeProject?.subdomain?.address;
  };

  useEffect(() => {
    const project = projectsInstance.getSnapshot().context.service.getActiveProject();
    setActiveProject(project);
    if (project) {
      setOriginalValues({
        name: project.name || "",
        description: project.description || "",
        customHead: project.customHead || "",
        customCode: project.customCode || "",
      });
      setCurrentValues({
        name: project.name || "",
        description: project.description || "",
        customHead: project.customHead || "",
        customCode: project.customCode || "",
      });
    }
  }, []);

  useEffect(() => {
    setHasChanges(Object.keys(originalValues).some((key) => originalValues[key] !== currentValues[key]));
  }, [currentValues, originalValues]);

  const handleChange = (field: string, value: string) => {
    setCurrentValues((prevValues) => ({ ...prevValues, [field]: value }));
  };

  const handleClear = (field: string) => {
    setCurrentValues((prevValues) => ({ ...prevValues, [field]: "" }));
  };

  const handleSave = async () => {
    if (activeProject) {
      const projectId = activeProject.projectId;
      const updates: Partial<Omit<Project, "customDomain">> = {};

      if (originalValues.name !== currentValues.name) {
        updates.name = currentValues.name;
      }
      if (originalValues.description !== currentValues.description) {
        updates.description = currentValues.description;
      }
      if (originalValues.customHead !== currentValues.customHead) {
        updates.customHead = currentValues.customHead;
      }
      if (originalValues.customCode !== currentValues.customCode) {
        updates.customCode = currentValues.customCode;
      }

      if (Object.keys(updates).length > 0) {
        try {
          await projectsInstance.getSnapshot().context.service.updateProjectData(projectId, updates);
          setOriginalValues({
            name: currentValues.name,
            description: currentValues.description,
            customHead: currentValues.customHead,
            customCode: currentValues.customCode,
          });
          projectsInstance.getSnapshot().context.service.setSynced(projectId, false);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };
  return (
    <div className="general-settings__tabs-content">
      <header className="general-settings__header">
        {/* <ProjectSettingsBreadcrumbs title={activeProject?.name || ""} /> */}
        <div className="general-settings__header-container">
          <div className="general-settings__typography">
            <a className="project-settings__link" href={projectAddress()}>
              {currentValues.name}
              <ArrowUpRight />
            </a>
            <h1 className="general-settings__title">General</h1>
          </div>
          <Button
            active
            className="project-settings__header-container-btn"
            onClick={() => {
              projectsInstance.send("SELECT_PROJECT", { projectId: activeProject?.projectId });
            }}
          >
            <GridMiniIcon /> Visual Editor
          </Button>
        </div>
      </header>
      <div className="general-settings__main-settings">
        <ProjectSettingsInput
          id="project-title"
          label="Project title"
          onChange={(e) => handleChange("name", e.target.value)}
          value={currentValues.name}
        />
        <ProjectSettingsTextArea
          id="project-description"
          label="Page description"
          onChange={(e) => handleChange("description", e.target.value)}
          onClear={() => handleClear("description")}
          value={currentValues.description}
          button
          maxLength={300}
        />
        <div className="general-settings__search-preview">
          <span className="project-settings__label">Search result preview</span>
          <ButtonNew className="general-settings__search-preview-btn" href={projectAddress()}>
            <span>{projectAddress()}</span>
            <span>{currentValues.name}</span>
            <span>{currentValues.description}</span>
          </ButtonNew>
        </div>
      </div>
      <div className="general-settings__custom-code">
        <div className="general-settings__custom-code-header">
          <h3 className="general-settings__title">Custom code</h3>
          <span className="general-settings__subtitle">Add custom code to your website</span>
        </div>
        <ProjectSettingsTextArea
          id="start-of-head"
          label="<head> tag"
          onChange={(e) => handleChange("customHead", e.target.value)}
          onClear={() => handleClear("customHead")}
          value={currentValues.customHead}
          placeholder="Paste your code here"
        />
        <ProjectSettingsTextArea
          id="start-of-body"
          label="<script> tag"
          onChange={(e) => handleChange("customCode", e.target.value)}
          onClear={() => handleClear("customCode")}
          value={currentValues.customCode}
          placeholder="Paste your code here"
        />
      </div>
      <footer className="general-settings__footer">
        <ButtonNew
          className="general-settings__footer-btn"
          onClick={() => {
            projectsInstance.send("SELECT_PROJECT", { projectId: activeProject?.projectId });
          }}
        >
          Cancel
        </ButtonNew>
        <ButtonNew
          className="general-settings__footer-btn"
          orange
          disabled={!hasChanges}
          onClick={() => handleAsyncAction(() => handleSave())}
          loading={isLoading}
        >
          Save
        </ButtonNew>
      </footer>
    </div>
  );
};
