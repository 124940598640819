import type { SVGProps } from "react";
export const Template = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 6.43652H38C40.2091 6.43652 42 8.22738 42 10.4365V18.4365V38.4365C42 40.6457 40.2091 42.4365 38 42.4365H10C7.79086 42.4365 6 40.6457 6 38.4365V18.4365V10.4365C6 8.22738 7.79086 6.43652 10 6.43652Z"
      fill="#FFEBD3"
    />
    <path
      d="M42 18.4365V10.4365C42 8.22738 40.2091 6.43652 38 6.43652H10C7.79086 6.43652 6 8.22738 6 10.4365V18.4365M42 18.4365V38.4365C42 40.6457 40.2091 42.4365 38 42.4365H10C7.79086 42.4365 6 40.6457 6 38.4365V18.4365M42 18.4365H6"
      stroke="#FF4900"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38 6.43652H10C7.79086 6.43652 6 8.22738 6 10.4365V18.4365H42V10.4365C42 8.22738 40.2091 6.43652 38 6.43652Z"
      fill="white"
      stroke="#FF4900"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 18.4365H42" stroke="#FF4900" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
