import { type CSSProperties } from "react";

type TJustifyAround = {
  className?: string;
  style?: CSSProperties;
};

export const JustifyAround = ({ className, style }: TJustifyAround) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M8.75 14V2M5.75 3.5H3.5C3.08579 3.5 2.75 3.83579 2.75 4.25V11.75C2.75 12.1642 3.08579 12.5 3.5 12.5H5.75C6.16423 12.5 6.5 12.1642 6.5 11.75V4.25C6.5 3.83579 6.16423 3.5 5.75 3.5ZM14 3.5H11.75C11.3358 3.5 11 3.83579 11 4.25V11.75C11 12.1642 11.3358 12.5 11.75 12.5H14C14.4142 12.5 14.75 12.1642 14.75 11.75V4.25C14.75 3.83579 14.4142 3.5 14 3.5Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
