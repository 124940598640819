/* eslint-disable @typescript-eslint/no-empty-function */
const stubWindow = {
  location: {
    pathname: "",
    href: "",
    replace: () => {},
  },
  addEventListener: () => {},
  removeEventListener: () => {},
  __vitest_worker__: true,
};
export const getWindow = () => (typeof window !== "undefined" ? window : stubWindow);
