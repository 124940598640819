import { type CSSProperties } from "react";

type TSpaceLeft = {
  className?: string;
  style?: CSSProperties;
};

export const SpaceLeft = ({ className, style }: TSpaceLeft) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M2 12.5V3.5M9.5 11H6.5C5.67157 11 5 10.3284 5 9.5V6.5C5 5.67157 5.67157 5 6.5 5H9.5C10.3284 5 11 5.67157 11 6.5V9.5C11 10.3284 10.3284 11 9.5 11Z"
      stroke="#E4E4E7"
      strokeLinecap="round"
    />
  </svg>
);
