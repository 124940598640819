import { useState, useEffect } from "react";
import { editorService } from "../../../pages/Alpha_O/modules/editor";
import { fontModalServiceInstance } from "../../atoms/FontModal/FontModal.service";
import { Adjust } from "../../../../assets/svg/Adjust";

import { TLocalFontVariables } from "./LocalFontVariables.types";

import { getDisplayName, getPropertiesBySelector } from "./utils";

import "./LocalFontVariables.css";

export const LocalFontVariables: TLocalFontVariables = ({ rootVariables }) => {
  const [localFontVariables, setLocalFontVariables] = useState<Record<string, any>>({});
  const [rootStyles, setRootStyles] = useState<Record<string, any>>({});
  const [fontModalState, setFontModalState] = useState(fontModalServiceInstance.state);
  const editor = editorService.getEditor();
  const gatheredFontVariables = getPropertiesBySelector(localFontVariables);

  useEffect(() => {
    if (!editor) return;
    const localStyles = editor.Css?.getRule(".local-text-styles-variables")?.attributes.style;
    if (localStyles) {
      setLocalFontVariables(localStyles);
    }

    const rootStyles = editor.Css?.getRule(rootVariables?.selector)?.attributes.style;
    if (rootStyles) {
      setRootStyles(rootStyles);
    }
  });

  useEffect(() => {
    fontModalServiceInstance.subscribe(setFontModalState);
    return () => {
      fontModalServiceInstance.unsubscribe(setFontModalState);
    };
  }, []);

  const handleChangeLocalVariables = (property: string, value: string) => {
    if (!editor) return;
    const currentLocalFontStyles = editor.Css?.getRule(".local-text-styles-variables")?.attributes.style;
    if (!currentLocalFontStyles) return;
    const newLocalFontStyles = { ...currentLocalFontStyles, [property]: value };
    const newRule = editor.Css?.setRule(".local-text-styles-variables", newLocalFontStyles)?.attributes.style;
    if (newRule) setLocalFontVariables(newRule);
  };

  const handleOnClick = (name: string, displayName: string, properties: Record<string, string | number>) => {
    fontModalServiceInstance.setupData(handleChangeLocalVariables, properties, rootStyles, name, displayName);
  };

  if (Object.keys(localFontVariables).length === 0) return null;
  return (
    <div className="local-font-variables">
      {Object.entries(gatheredFontVariables).map(([category, properties]) => {
        const name = getDisplayName(category, rootStyles, properties);
        return (
          <button
            className={`font-variable${fontModalState.displayName === name ? " font-variable--in-edition" : ""}`}
            onClick={() => handleOnClick(category, name, properties)}
            key={name}
          >
            <span>{name}</span>
            <Adjust />
          </button>
        );
      })}
    </div>
  );
};
