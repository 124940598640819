import { type CSSProperties } from "react";

type TCornerTopRight = {
  className?: string;
  style?: CSSProperties;
};

export const CornerTopRight = ({ className, style }: TCornerTopRight) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4 4H9.33334C10.0405 4 10.7189 4.28096 11.2189 4.78104C11.7189 5.28115 12 5.95941 12 6.66667V12"
      stroke="#E4E4E7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
