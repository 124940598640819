import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber.ts";

export type View = {
  id: string;
  component: () => JSX.Element;
};

export type ModalServiceState = {
  isOpen: boolean;
  selectedView?: View;
  views: View[];
};

export class ModalService extends Subscriber<ModalServiceState> {
  constructor(views: View[]) {
    super();
    this.state.views = views;
  }

  state: ModalServiceState = {
    isOpen: false,
    selectedView: undefined,
    views: [],
  };

  open(viewId: string) {
    this.state.selectedView = this.selectView(viewId);
    this.state.isOpen = true;
  }

  close() {
    this.state.isOpen = false;
  }

  loadViews(views: View[]) {
    this.state.views = views;
  }

  selectView(viewId: string) {
    return this.state.views.find((view) => view.id === viewId);
  }

  getSelectedView() {
    return this.state.views.find((view) => view.id === this.state.selectedView?.id);
  }
}
