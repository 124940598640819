import { type CSSProperties } from "react";

type TSizeTablet = {
  className?: string;
  style?: CSSProperties;
};

export const SizeTablet = ({ className, style }: TSizeTablet) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M0.899994 10.2308H11.0538M10.1308 1H1.82307C1.31327 1 0.899994 1.41328 0.899994 1.92308V12.0769C0.899994 12.5867 1.31327 13 1.82307 13H10.1308C10.6406 13 11.0538 12.5867 11.0538 12.0769V1.92308C11.0538 1.41328 10.6406 1 10.1308 1Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
