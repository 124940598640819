import { type CSSProperties } from "react";

type TDirectionColumn = {
  className?: string;
  style?: CSSProperties;
};

export const DirectionColumn = ({ className, style }: TDirectionColumn) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      id="Vector"
      d="M5.00008 0.99231V9.3M5.00008 12.9923L1.30777 9.3H8.69238L5.00008 12.9923Z"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
