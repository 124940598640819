import React, { type SVGProps } from "react";

export const Hint = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.666 9.13672H11.666V7.13672H13.666M13.666 17.1367H11.666V11.1367H13.666M12.666 2.13672C11.3528 2.13672 10.0524 2.39538 8.83918 2.89792C7.62593 3.40047 6.52353 4.13707 5.59495 5.06565C3.71958 6.94101 2.66602 9.48455 2.66602 12.1367C2.66602 14.7889 3.71958 17.3324 5.59495 19.2078C6.52353 20.1364 7.62593 20.873 8.83918 21.3755C10.0524 21.8781 11.3528 22.1367 12.666 22.1367C15.3182 22.1367 17.8617 21.0832 19.7371 19.2078C21.6124 17.3324 22.666 14.7889 22.666 12.1367C22.666 10.8235 22.4074 9.52314 21.9048 8.30988C21.4023 7.09663 20.6657 5.99424 19.7371 5.06565C18.8085 4.13707 17.7061 3.40047 16.4929 2.89792C15.2796 2.39538 13.9792 2.13672 12.666 2.13672Z"
      fill="#D4D4D8"
    />
  </svg>
);
