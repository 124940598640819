import { type CSSProperties } from "react";

type TClose = {
  className?: string;
  style?: CSSProperties;
};

export const Close = ({ className, style }: TClose) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M18 6L6 18" stroke="#D4D4D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="#D4D4D8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
