import { type CSSProperties } from "react";

type TCornerBottomRight = {
  className?: string;
  style?: CSSProperties;
};

export const CornerBottomRight = ({ className, style }: TCornerBottomRight) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4 12H9.33334C10.0405 12 10.7189 11.7189 11.2189 11.2189C11.7189 10.7189 12 10.0405 12 9.33334V4"
      stroke="#E4E4E7"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
