import { type CSSProperties } from "react";

type TInfo = {
  className?: string;
  style?: CSSProperties;
};

export const Info = ({ className, style }: TInfo) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM6.71429 10.0357C6.41842 10.0357 6.17857 10.2756 6.17857 10.5714C6.17857 10.8673 6.41842 11.1071 6.71429 11.1071H9.28571C9.58158 11.1071 9.82143 10.8673 9.82143 10.5714C9.82143 10.2756 9.58158 10.0357 9.28571 10.0357H8.53571V7.57143C8.53571 7.27556 8.29587 7.03571 8 7.03571H7.14286C6.84699 7.03571 6.60714 7.27556 6.60714 7.57143C6.60714 7.8673 6.84699 8.10714 7.14286 8.10714H7.46429V10.0357H6.71429ZM8.85714 5.42857C8.85714 5.90195 8.47338 6.28571 8 6.28571C7.52662 6.28571 7.14286 5.90195 7.14286 5.42857C7.14286 4.95519 7.52662 4.57143 8 4.57143C8.47338 4.57143 8.85714 4.95519 8.85714 5.42857Z"
      fill="#A1A1AA"
    />
  </svg>
);
