import { type SVGProps } from "react";
export const LikeMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.33398 7.8C3.33398 6.11982 3.33398 5.27977 3.66097 4.63803C3.94858 4.07354 4.40753 3.6146 4.97201 3.32698C5.61375 3 6.4538 3 8.13398 3H16.534C18.2142 3 19.0542 3 19.696 3.32698C20.2605 3.6146 20.7194 4.07354 21.007 4.63803C21.334 5.27977 21.334 6.11982 21.334 7.8V13.2C21.334 14.8802 21.334 15.7202 21.007 16.362C20.7194 16.9265 20.2605 17.3854 19.696 17.673C19.0542 18 18.2142 18 16.534 18H14.0177C13.3937 18 13.0816 18 12.7832 18.0613C12.5184 18.1156 12.2622 18.2055 12.0214 18.3284C11.7501 18.467 11.5065 18.662 11.0192 19.0519L8.63372 20.9602C8.21768 21.293 8.0096 21.4595 7.83452 21.4597C7.68224 21.4598 7.53818 21.3906 7.4432 21.2716C7.334 21.1348 7.334 20.8684 7.334 20.3355V18C6.404 18 5.93903 18 5.55752 17.8978C4.52225 17.6204 3.71361 16.8118 3.43621 15.7765C3.33398 15.395 3.33398 14.93 3.33398 14V7.8Z"
      stroke="#FF4900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3309 7.69926C11.4312 6.66042 9.93088 6.38094 8.8036 7.33224C7.67632 8.28348 7.51762 9.87396 8.40286 10.9991C8.95132 11.6961 10.3279 12.9691 11.2959 13.837C11.6516 14.1557 11.8293 14.3151 12.0427 14.3793C12.2258 14.4344 12.4361 14.4344 12.6191 14.3793C12.8325 14.3151 13.0103 14.1557 13.3659 13.837C14.3339 12.9691 15.7105 11.6961 16.259 10.9991C17.1442 9.87396 17.0049 8.27352 15.8582 7.33224C14.7116 6.39096 13.2306 6.66042 12.3309 7.69926Z"
      stroke="#FF4900"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
