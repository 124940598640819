import { toast } from "react-toastify";
import { authInstance } from "../../auth/auth.instance";

const AccountMismatchToast = () => {
  return (
    <>
      <p
        style={{
          fontFamily: "TT Octosquares",
        }}
      >
        Logged in with a <span style={{ textDecoration: "underline" }}>Different Account</span>:
      </p>
      <p
        style={{
          fontFamily: "Nekst",
          fontSize: "0.8rem",
          fontWeight: 400,
        }}
      >
        The account you&apos;re using does not match the one you&apos;re attempting to create via the Figma plugin.
      </p>
      <p
        style={{
          fontFamily: "Nekst",
          fontSize: "0.9rem",
          fontWeight: 400,
        }}
      >
        Please{" "}
        <button
          type="button"
          onClick={() => authInstance.send("LOGOUT")}
          style={{
            border: "none",
            background: "transparent",
            color: "#FF7F41",
            fontFamily: "TT Octosquares",
            fontWeight: "inherit",
            fontSize: "inherit",
            lineHeight: "inherit",
            margin: 0,
            padding: 0,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          log out
        </button>{" "}
        of the current account and return to Figma for a consistent experience.
      </p>
    </>
  );
};

export const showAccountMismatchToast = () => {
  toast.info(<AccountMismatchToast />);
};
