import { type CSSProperties } from "react";

type TPages2 = {
  className?: string;
  style?: CSSProperties;
};

export const Pages2 = ({ className, style }: TPages2) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7.48573 1V4.42857H10.9143M8.05716 1H2.34287C1.71169 1 1.20001 1.51168 1.20001 2.14286V11.8571C1.20001 12.4883 1.71169 13 2.34287 13H9.77144C10.4026 13 10.9143 12.4883 10.9143 11.8571V3.85714L8.05716 1Z"
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
