import React, { type SVGProps } from "react";
export const XIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="x 1">
      <path
        id="Vector"
        d="M14.5146 17.807L3.62578 3.80697C3.37034 3.47854 3.60438 3 4.02045 3H6.08693C6.24122 3 6.38688 3.07123 6.4816 3.19303L17.3705 17.193C17.626 17.5215 17.3919 18 16.9758 18H14.9094C14.7551 18 14.6094 17.9287 14.5146 17.807Z"
        stroke="#27272A"
        strokeWidth="1.25"
      />
      <path id="Vector_2" d="M17.1654 3L3.83203 18" stroke="#27272A" strokeWidth="1.25" strokeLinecap="round" />
    </g>
  </svg>
);
