import React, { type SVGProps } from "react";
export const Catalog = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="57" viewBox="0 0 48 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_ddd_10637_11393)">
      <rect x="4" y="2.5" width="40" height="40" rx="8" fill="url(#paint0_linear_10637_11393)" />
      <rect x="4.5" y="3" width="39" height="39" rx="7.5" stroke="url(#paint1_linear_10637_11393)" />
      <path
        d="M34 29.5C34 30.0304 33.7893 30.5391 33.4142 30.9142C33.0391 31.2893 32.5304 31.5 32 31.5H16C15.4696 31.5 14.9609 31.2893 14.5858 30.9142C14.2107 30.5391 14 30.0304 14 29.5V15.5C14 14.9696 14.2107 14.4609 14.5858 14.0858C14.9609 13.7107 15.4696 13.5 16 13.5H21L23 16.5H32C32.5304 16.5 33.0391 16.7107 33.4142 17.0858C33.7893 17.4609 34 17.9696 34 18.5V29.5Z"
        fill="#E4E4E7"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_10637_11393"
        x="0"
        y="0.5"
        width="48"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10637_11393" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_10637_11393" result="effect2_dropShadow_10637_11393" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_10637_11393" result="effect3_dropShadow_10637_11393" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_10637_11393" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_10637_11393" x1="24" y1="2.5" x2="24" y2="42.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9F9F9" />
      </linearGradient>
      <linearGradient id="paint1_linear_10637_11393" x1="24" y1="2.5" x2="24" y2="42.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2F2F2" />
        <stop offset="1" stopColor="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
);
