import { useState } from "react";

import { pagesInstance } from "../../pages.instance";

import { pageActionsServiceInstance } from "../../../../../../AlphaO/molecules/PageActionsModal/PageActionsModal.service";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick/useOutsideClick";
import { Button } from "../../../../../../AlphaO/atoms/Button";
import { Pages } from "../../../../../../../assets/svg/Pages";
import { More } from "../../../../../../../assets/svg/More";
import { Save } from "../../../../../../../assets/svg/Save";
import { Home2 } from "../../../../../../../assets/svg/Home2";

import { TPagesMenuElement } from "./PagesMenuElement.types";

import "./PagesMenuElement.css";

export const PagesMenuElement: TPagesMenuElement = ({ page, selected, isMainPage }) => {
  const [isRenameInputVisible, setIsRenameInputVisible] = useState(false);
  const [pageName, setPageName] = useState(page.getName());
  const ref = useOutsideClick<HTMLLIElement>(() => {
    setIsRenameInputVisible(false);
  });

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    const target = ref.current?.querySelector(".pages-menu__page-actions") || ref.current;
    if (!target) return;

    const { left, top, width } = target?.getBoundingClientRect();
    pageActionsServiceInstance.setupActionsModal({
      position: {
        x: left + width / 2 || 0,
        y: top || 0,
      },
      setIsRenameInputVisible,
      page,
    });
  };

  return (
    <>
      <li
        key={page.getId()}
        className={`pages-menu__pages-element${selected ? " pages-menu__pages-element--selected" : ""}`}
        onClick={() => pagesInstance.send("SELECT_PAGE", { id: page.getId() })}
        ref={ref}
      >
        {isRenameInputVisible ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              pagesInstance.send("RENAME_PAGE", { id: page.getId(), pageName });
              setIsRenameInputVisible(false);
            }}
            className="pages-menu__rename-form"
          >
            <input
              name="pageName"
              className="pages-menu__rename-input"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              autoFocus
            />
            <Button type="icon" buttonType="submit" iconStart={<Save />} />
          </form>
        ) : (
          <>
            {isMainPage ? <Home2 /> : <Pages />}
            <span className="pages-menu__page-name truncate-text">{page.getName()}</span>
            <Button
              aria-label="show actions"
              type="icon-stroke-gray"
              onClick={handleClick}
              iconStart={<More />}
              className="pages-menu__page-actions"
            />
          </>
        )}
      </li>
    </>
  );
};
