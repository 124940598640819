import { ButtonNew } from "../../../../atoms/ButtonNew";

import { FigmaLogo } from "../../../../../../assets/svg/figma_logo";
import { WeirdIconWrapper } from "../../../../atoms/WeirdIconWrapper";
import { DesignSystem } from "../../../../../../assets/svg/design_system";
import { Divider } from "../../../../../../assets/svg/divider";
import { FigmaPlugin } from "../../../../../../assets/svg/figma_plugin";
import { VisualEditor } from "../../../../../../assets/svg/visual_editor";

import type { TImportFigmaSection } from "./ImportFigmaSection.types";

import "./ImportFigmaSection.css";

export const ImportFigmaSection: TImportFigmaSection = ({ openImportModal }) => {
  return (
    <div className="codejet__import-figma">
      <div className="codejet__import-figma__description">
        <h2 className="codejet__import-figma__heading">Already prepared Figma designs?</h2>
        <h3 className="codejet__import-figma__subheading">Import them to Codejet using Figma plugin!</h3>
        <ButtonNew
          onClick={() => openImportModal()}
          iconStart={<FigmaLogo />}
          className="codejet__import-figma__button"
        >
          Import from Figma
        </ButtonNew>
      </div>
      <div className="codejet__import-figma__flow">
        <div className="codejet__import-figma__flow-icon__container">
          <WeirdIconWrapper>
            <DesignSystem />
          </WeirdIconWrapper>
          <h3>Design System</h3>
        </div>
        <Divider />
        <div className="codejet__import-figma__flow-icon__container">
          <WeirdIconWrapper>
            <FigmaPlugin />
          </WeirdIconWrapper>
          <h3>Figma Plugin</h3>
        </div>
        <Divider />
        <div className="codejet__import-figma__flow-icon__container">
          <WeirdIconWrapper>
            <VisualEditor />
          </WeirdIconWrapper>
          <h3>Visual Editor</h3>
        </div>
      </div>
    </div>
  );
};
