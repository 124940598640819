import { useEffect } from "react";

import { LoadingDots } from "../../../../../atoms/LoadingDots";
import { QuestionMark } from "../../../../../../assets/svg/QuestionMark";

import { TModal } from "./Modal.types";

import "./Modal.css";

export const Modal: TModal = ({
  onClick,
  onClose,
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  isLoading,
  loaderText,
}) => {
  const handleKeyPropagation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
      if (event.key === "Enter") {
        onClick();
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  if (isLoading) {
    return (
      <div
        className="project-card-modal__backdrop"
        role="menu"
        tabIndex={-1}
        onClick={stopPropagation}
        onKeyDown={handleKeyPropagation}
      >
        <div className="project-card-modal__content project-card-modal__content--loader">
          <div className="project-card-modal__loader"></div>
          <h4 className="project-card-modal__title">
            {loaderText}
            <LoadingDots />
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div
      className="project-card-modal__backdrop"
      role="menu"
      tabIndex={-1}
      onClick={stopPropagation}
      onKeyDown={handleKeyPropagation}
    >
      <div className="project-card-modal__content">
        <div className="project-card-modal__header">
          <div className="project-card-modal__icon project-card-modal__icon--question">
            <QuestionMark />
          </div>
          <h4 className="project-card-modal__title">{title}</h4>
          <span className="project-card-modal__message">{description}</span>
        </div>
        <div className="project-card-modal__footer">
          <button
            className="project-card-modal__button project-card-modal__button--cancel"
            type="button"
            onClick={onClose}
          >
            {cancelButtonLabel}
          </button>
          <button
            className="project-card-modal__button project-card-modal__button--delete"
            type="button"
            onClick={onClick}
          >
            {confirmButtonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
