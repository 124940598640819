import { type CSSProperties } from "react";

type THint = {
  className?: string;
  style?: CSSProperties;
};

export const Hint = ({ className, style }: THint) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M8.66732 6.49967H7.33398V5.16634H8.66732M8.66732 11.833H7.33398V7.83301H8.66732M8.00065 1.83301C7.12517 1.83301 6.25827 2.00545 5.44943 2.34048C4.64059 2.67551 3.90566 3.16657 3.28661 3.78563C2.03636 5.03587 1.33398 6.73156 1.33398 8.49967C1.33398 10.2678 2.03636 11.9635 3.28661 13.2137C3.90566 13.8328 4.64059 14.3238 5.44943 14.6589C6.25827 14.9939 7.12517 15.1663 8.00065 15.1663C9.76876 15.1663 11.4645 14.464 12.7147 13.2137C13.9649 11.9635 14.6673 10.2678 14.6673 8.49967C14.6673 7.6242 14.4949 6.75729 14.1598 5.94845C13.8248 5.13961 13.3338 4.40469 12.7147 3.78563C12.0956 3.16657 11.3607 2.67551 10.5519 2.34048C9.74304 2.00545 8.87613 1.83301 8.00065 1.83301Z"
      fill="#71717A"
    />
  </svg>
);
