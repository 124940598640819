import type { TPageHeader } from "./PageHeader.types";

import "./PageHeader.css";

export const PageHeader: TPageHeader = ({ header, subheader, avatar }) => {
  return (
    <div className="page-header__heading-container">
      <h1 className="page-header__heading">{header}</h1>
      <div className="page-header__heading-subheader__container">
        {avatar && <img src={avatar} />}
        {subheader && <h2 className="page-header__subheading">{subheader}</h2>}
      </div>
    </div>
  );
};
