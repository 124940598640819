import { useState } from "react";

import { ElementsBlock } from "./components/ElementsBlock";
import { Button } from "../../../../AlphaO/atoms/Button";
import { Close } from "../../../../../assets/svg/Close";
import { Search } from "../../../../../assets/svg/Search";

import "./BlocksMenu.css";

export const BlocksMenu = () => {
  const [filter, setFilter] = useState("");

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value);
  };
  const clearFilter = () => {
    setFilter("");
  };

  return (
    <div className="blocks-menu" id={"gjs-blocks"}>
      <h2 className="blocks-menu__heading blocks-menu__heading--main">
        Elements
        {/* <Button
          type="icon"
          onClick={() => editorInstance.send("CLOSE_ADD_NEW_ELEMENT_PANEL")}
          aria-label="Close blocks menu"
          iconStart={<Close />}
        ></Button> */}
      </h2>
      <div className="blocks-menu__search-bar">
        <Search className="blocks-menu__icon-white" />
        <input type="text" value={filter} onChange={handleFilterChange} placeholder="Search..." />
        <Button type="icon" iconStart={<Close />} onClick={clearFilter} />
      </div>
      <div className="blocks-menu__tabpanel">
        <ElementsBlock label="Basic" category="Layout" filter={filter} />
        <ElementsBlock label="Form" category="Forms" filter={filter} />
        <ElementsBlock label="Extra" category="Extra" filter={filter} />
      </div>
    </div>
  );
};
