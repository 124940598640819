import type { TButton } from "./Button.types";

import "./Button.css";

export const Button: TButton = ({ className, type, children, active, onClick }) => {
  const baseClass = "btn-dashboard";
  const isTextButton = type === "text";

  const classes = [
    baseClass,
    // orange && `${baseClass}--orange`,
    // disabled && `${baseClass}--disabled`,
    active && `${baseClass}--active`,
    isTextButton && `${baseClass}--text`,
    className,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <button type="button" className={classes} onClick={onClick}>
      {children}
    </button>
  );
};
