import { type CSSProperties } from "react";

type TPhone = {
  className?: string;
  style?: CSSProperties;
};

export const Phone = ({ className, style }: TPhone) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M6.98258 13.5301C6.41531 13.8965 5.73933 14.0563 5.0683 13.9824C4.39728 13.9084 3.77213 13.6054 3.29798 13.1241L2.88346 12.7181C2.70173 12.532 2.59998 12.282 2.59998 12.0217C2.59998 11.7613 2.70173 11.5113 2.88346 11.3251L4.64285 9.58142C4.82718 9.4 5.07528 9.29834 5.33372 9.29834C5.59216 9.29834 5.84026 9.4 6.02458 9.58142C6.21046 9.76343 6.4601 9.86534 6.72005 9.86534C6.98001 9.86534 7.22964 9.76343 7.41552 9.58142L10.179 6.81372C10.2712 6.72274 10.3444 6.6143 10.3943 6.49473C10.4443 6.37514 10.47 6.24682 10.47 6.11719C10.47 5.98756 10.4443 5.85923 10.3943 5.73964C10.3444 5.62007 10.2712 5.51163 10.179 5.42064C9.99783 5.23604 9.89632 4.98756 9.89632 4.72873C9.89632 4.46989 9.99783 4.2214 10.179 4.0368L11.9291 2.28392C12.115 2.10191 12.3646 2 12.6246 2C12.8846 2 13.1342 2.10191 13.3201 2.28392L13.7254 2.69907C14.2059 3.17396 14.5085 3.80006 14.5823 4.47213C14.6562 5.14418 14.4967 5.8212 14.1307 6.38934C12.2239 9.20385 9.79767 11.6275 6.98258 13.5301Z"
      stroke="#A1A1AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
