import { type CSSProperties } from "react";

type TBorderLeftWidth = {
  className?: string;
  style?: CSSProperties;
};

export const BorderLeftWidth = ({ className, style }: TBorderLeftWidth) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M12 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4M6.5 2H9.5M14 6.5V9.5M2 2V14M12 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V12M6.5 14H9.5"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
