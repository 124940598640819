import React, { type SVGProps } from "react";

import "./loader-light.css";

export const LoaderLight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="54" viewBox="0 0 30 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M30 15.0029V30L20 19.9987L20.0051 10.0051H10V20.0006L0 30V15.0029L15.0088 0L30 15.0029Z" fill="#FF4900" />
    <path className="loader-step-1" d="M20 40.0016V30.0025H10V40.0016H20Z" fill="#D9D9D9" />
    <path className="loader-step-2" d="M20 54.0011V44.002H10V54.0011H20Z" fill="#D9D9D9" />
  </svg>
);
