import { useState, useEffect, useRef } from "react";
import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { SelectButton } from "../SelectButton";

import { TIconWeightProperty } from "./IconWeightProperty.types";
import { editorService } from "../../../editor";

import "./IconWeightProperty.css";
import { createPropertyString, materialSymbolRules } from "../../helpers/materialSymbols";

const options = [
  {
    id: "1",
    value: "100",
    label: "100",
    name: "100",
  },
  {
    id: "2",
    value: "200",
    label: "200",
    name: "200",
  },
  {
    id: "3",
    value: "300",
    label: "300",
    name: "300",
  },
  {
    id: "4",
    value: "400",
    label: "400",
    name: "400",
  },
  {
    id: "5",
    value: "500",
    label: "500",
    name: "500",
  },
  {
    id: "6",
    value: "600",
    label: "600",
    name: "600",
  },
  {
    id: "7",
    value: "700",
    label: "700",
    name: "700",
  },
];

export const IconWeightProperty: TIconWeightProperty = () => {
  const [value, setValue] = useState("100");
  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        const weight = ps.getProperty("wght");
        setValue(weight);
      }
    });
  }, []);

  const handleChange = (ev: string) => {
    materialSymbolRules((rule) => {
      const attributeStyle = rule?.attributes.style;
      if (attributeStyle) {
        setValue(ev);

        const fontVariationSettings = attributeStyle["font-variation-settings"];
        const ps = createPropertyString(fontVariationSettings);
        ps.setProperty("wght", ev);
        const newFontVariationSettings = ps.toString();

        rule.set("style", {
          "font-variation-settings": newFontVariationSettings,
        });
      }
    });
  };

  const handleUnitDropdown = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    const target = propertyRef.current?.querySelector(".select-button") || propertyRef.current;
    const coordinates = target?.getBoundingClientRect();

    if (!coordinates) return;

    styleManagerService.openUnitDropdown(options, value, coordinates, handleChange);
  };

  return (
    <div className={`property-select vertical full-width`} ref={propertyRef}>
      <PropertyLabel
        text={"Weight"}
        modifier={"primary"}
        info={
          "Weight defines the symbol's stroke weight, with a range of weights between thin (100) and bold (700). Weight can also affect the overall size of the symbol."
        }
      />
      <SelectButton currentOption={value} options={options} onClick={handleUnitDropdown} />
    </div>
  );
};
