import { useState, useEffect } from "react";

import { Switch } from "../../../../../../../AlphaO/atoms/Switch";

import { editorService } from "../../../../editor";
import { createYoutubeQueryUrl, type YoutubePlayerProps } from "../../../helpers/createYoutubeQueryUrl";

type YoutubeAttributes = {
  class: string;
  frameborder: number;
  src: string;
  style: string;
  title: string;
};

export const Youtube = ({ noCookie }: { noCookie?: boolean }) => {
  const baseURL = noCookie ? "https://www.youtube-nocookie.com/embed/" : "https://www.youtube.com/embed/";
  const [ID, setID] = useState("");
  const [options, setOptions] = useState({
    autoplay: false,
    loop: false,
    controls: true,
    related: true,
    modest: false,
  });

  const youtubeOptionKeys = Object.keys(options) as (keyof typeof options)[];

  const updateVideoSrc = (id: string, playerOptions: typeof options) => {
    const editor = editorService.editor;
    if (editor) {
      const selected = editor.getSelected();
      if (selected) {
        const selectedChildren = selected.getLastChild();
        if (selectedChildren) {
          const attributes = selectedChildren.getAttributes() as YoutubeAttributes;
          const types: YoutubePlayerProps[] = [
            playerOptions.autoplay && "autoplay",
            playerOptions.loop && "loop",
            !playerOptions.controls && "controls",
            !playerOptions.related && "related",
            playerOptions.modest && "modest",
          ].filter(Boolean) as YoutubePlayerProps[];
          const src = createYoutubeQueryUrl(baseURL, types, id);
          selectedChildren.setAttributes({ ...attributes, src });
        }
      }
    }
  };

  const handleIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newID = e.target.value;
    const youtubeVideoUrlPattern = new RegExp("^(http(s)?:\\/\\/)?(www\\.)?youtube\\.com\\/watch\\?v=");

    if (youtubeVideoUrlPattern.test(newID)) {
      const url = new URL(newID);
      const videoId = url.searchParams.get("v");
      if (videoId) {
        newID = videoId;
      }
    }

    setID(newID);
    updateVideoSrc(newID, options);
  };

  const toggleOption = (optionName: keyof typeof options) => {
    setOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [optionName]: !prevOptions[optionName] };
      updateVideoSrc(ID, newOptions);
      return newOptions;
    });
  };

  useEffect(() => {
    const editor = editorService.editor;
    if (editor) {
      const selected = editor.getSelected();
      if (selected) {
        const selectedChildren = selected.getLastChild();
        if (selectedChildren) {
          const attributes = selectedChildren.getAttributes() as YoutubeAttributes;
          const src = attributes.src;

          if (src) {
            try {
              const url = new URL(src);
              const pathnameParts = url.pathname.split("/");
              const id = pathnameParts[pathnameParts.length - 1];
              setID(id || "");

              const params = url.searchParams;

              setOptions({
                autoplay: params.get("autoplay") === "1",
                loop: params.get("loop") === "1",
                controls: params.get("controls") !== "0",
                related: params.get("rel") !== "0",
                modest: params.get("modestbranding") === "1",
              });
            } catch (error) {
              console.error("Invalid YouTube URL:", error);
            }
          }
        }
      }
    }
  }, []);

  return (
    <>
      <li className="trait-manager__trait">
        <label className="trait-manager__trait-label">Video ID</label>
        <input placeholder="eg. dQw4w9WgXcQ" onChange={handleIDChange} value={ID} />
      </li>
      {youtubeOptionKeys.map((option) => (
        <li key={option} className="trait-manager__trait trait-manager__trait--horizontal">
          <span className="trait-manager__checkbox-label">{option.charAt(0).toUpperCase() + option.slice(1)}</span>
          <Switch isDisabled={false} onChange={() => toggleOption(option)} isActive={options[option]} />
        </li>
      ))}
    </>
  );
};
